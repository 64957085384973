import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import {
  AIModelModel,
  ENDPOINTS,
  RunModel,
  SummaryModel,
  TranscriptionItem,
  TranscriptionModel,
} from './models/endpoints.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { Observable } from 'rxjs';
import { createErrorContext } from '../../error-handler';

@Injectable({
  providedIn: 'root',
})
export class TranscriptionService {
  constructor(private apiService: ApiService) {}

  listForAsset = (assetId: string): Observable<TranscriptionModel[]> => {
    const context = createErrorContext({ 404: false });

    return this.apiService
      .get<ApiResponse<TranscriptionModel[]>>(`${ENDPOINTS.ASSET_ROOT}/${assetId}/transcriptions`, { context })
      .pipe(GetResponseData);
  };

  listModels = (): Observable<AIModelModel[]> =>
    this.apiService.get<ApiResponse<AIModelModel[]>>(`${ENDPOINTS.AI_ROOT}/models`).pipe(GetResponseData);

  runTranscription = (assetId: string, modelUuid: string) =>
    this.apiService
      .post<RunModel, ApiResponse<TranscriptionModel>>(`${ENDPOINTS.ASSET_ROOT}/${assetId}/transcription/run`, {
        modelUuid,
      })
      .pipe(GetResponseData);

  transcriptionItems = (id: string) =>
    this.apiService
      .get<ApiResponse<TranscriptionItem[]>>(`${ENDPOINTS.TRANSCRIPTION_ROOT}/${id}/list`)
      .pipe(GetResponseData);

  generateSummary = (id: string, modelUuid: string, tone?: string): Observable<SummaryModel> => {
    const payload: RunModel = tone ? { modelUuid, options: { tone } } : { modelUuid };

    return this.apiService
      .post<RunModel, ApiResponse<SummaryModel>>(`${ENDPOINTS.TRANSCRIPTION_ROOT}/${id}/summary`, payload)
      .pipe(GetResponseData);
  };

  getSummaries = (id: string): Observable<SummaryModel[]> =>
    this.apiService
      .get<ApiResponse<SummaryModel[]>>(`${ENDPOINTS.TRANSCRIPTION_ROOT}/${id}/summaries`)
      .pipe(GetResponseData);
}
