import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { firstValueFrom, Observable } from 'rxjs';
import { ClientEndpoints } from './endpoints';
import {
  Client,
  ClientLogo,
  ClientResponse,
  ExportGroupConfigPayload,
  GroupLogoStatusEnum,
  PatchGroupLogoInputInterface,
  PostGroupLogoInputInterface,
  PostGroupLogoResponseInterface,
  SupplierAsperaKey,
  SupplierAsperaKeysResponse,
} from './client.model';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ToolsEndpoints } from '../tools/tools';
import { map } from 'rxjs/operators';
import { DefaultPolicies } from '../policy/policy.model';

@Injectable({ providedIn: 'root' })
export class ClientsService {
  constructor(private readonly apiService: ApiService) {}

  getClients(): Observable<Client[]> {
    return this.apiService.get<ApiResponse<Client[]>>(ClientEndpoints.ROOT_PLURAL).pipe(GetResponseData);
  }

  public getClient(uuid: string): Observable<Client> {
    return this.apiService.get<ApiResponse<Client>>(`${ClientEndpoints.ROOT}/${uuid}`).pipe(GetResponseData);
  }

  public patchClient(uuid: string, data: Client): Observable<ClientResponse> {
    return this.apiService
      .patch<Client, ApiResponse<ClientResponse>>(`${ClientEndpoints.ROOT}/${uuid}`, data)
      .pipe(GetResponseData);
  }

  public postClient(data: Client): Observable<ClientResponse> {
    return this.apiService.post<Client, ApiResponse<ClientResponse>>(ClientEndpoints.ROOT, data).pipe(GetResponseData);
  }

  public deleteClientLogo(uuid: string) {
    return this.apiService.delete<ApiResponse<ClientResponse>>(`${ClientEndpoints.ROOT}/${uuid}/logo`);
  }

  public configExport(uuid: string, payload: ExportGroupConfigPayload) {
    return this.apiService.post(`${ClientEndpoints.ROOT}/${uuid}/configuration/export`, payload);
  }

  public configImport(uuid: string, jsonFile: object) {
    return this.apiService.post(`${ClientEndpoints.ROOT}/${uuid}/configuration/import`, jsonFile);
  }

  // LOGO UPLOAD
  async generateUploadLogoSignature(
    uuid: string,
    data: PostGroupLogoInputInterface,
  ): Promise<PostGroupLogoResponseInterface> {
    return await firstValueFrom(
      this.apiService
        .post<
          PostGroupLogoInputInterface,
          ApiResponse<PostGroupLogoResponseInterface>
        >(`${ClientEndpoints.ROOT}/${uuid}/logo`, data)
        .pipe(GetResponseData),
    );
  }

  // LOGO PATCH - STATUS UPDATE
  patchClientLogo(
    group_logo_uuid: string,
    group_uuid: string,
    status: GroupLogoStatusEnum.UPLOADED | GroupLogoStatusEnum.ERROR,
  ): Observable<ClientLogo> {
    return this.apiService
      .patch<PatchGroupLogoInputInterface, ApiResponse<ClientLogo>>(`${ClientEndpoints.ROOT}/${group_uuid}/logo`, {
        group_logo_uuid,
        status: status,
      })
      .pipe(GetResponseData);
  }

  getSupplierAsperaKeys(uuid: string) {
    return this.apiService
      .get<ApiResponse<SupplierAsperaKeysResponse[]>>(`${ClientEndpoints.ROOT}/${uuid}/supplier-aspera-keys`)
      .pipe(GetResponseData);
  }

  getSupplierAsperaKeysExtra(uuid: string) {
    return this.apiService
      .get<ApiResponse<SupplierAsperaKeysResponse>>(`${ClientEndpoints.ROOT}/${uuid}/supplier-aspera-keys/extra`)
      .pipe(GetResponseData);
  }

  postSupplierAsperaKey(uuid: string, supplierUuid: string, data: { name: string }) {
    return this.apiService
      .post<
        { name: string },
        ApiResponse<SupplierAsperaKey>
      >(`${ClientEndpoints.ROOT}/${uuid}/supplier-aspera-key/${supplierUuid}`, data)
      .pipe(GetResponseData);
  }

  deleteSupplierAsperaKey(uuid: string, supplierUuid: string) {
    return this.apiService.delete<ApiResponse<unknown>>(
      `${ClientEndpoints.ROOT}/${uuid}/supplier-aspera-key/${supplierUuid}`,
    );
  }

  deleteAsperaKey(keyId: string) {
    return this.apiService.delete<ApiResponse<unknown>>(`${ToolsEndpoints.ROOT}/delete-aspera-key/${keyId}`);
  }

  getClientDefaultPolicies(uuid: string) {
    return this.apiService
      .get<ApiResponse<Client>>(`${ClientEndpoints.ROOT}/${uuid}`)
      .pipe(GetResponseData)
      .pipe(map((client) => client.default_policies || ({} as DefaultPolicies)));
  }
}
