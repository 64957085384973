<div class="time--handler__buttons" *ngIf="playerService.duration$ | async; let duration">
  <vdms-hq-form-input-timecode
    #timecodeInput
    [(ngModel)]="timecode"
    [framerate]="(playerService.framerate$ | async) ?? framerateDefault"
    (keyup)="keyUp($event)"
    [autofocus]="true"
    [hideFramerate]="true"
    [withFooter]="false"
    [mask]="(playerService.type$ | async) === 'audio' ? '00:00:00' : '00:00:00:00'"
    label="Go to timecode"
  ></vdms-hq-form-input-timecode>
  <fieldset class="fieldset-wrapper">
    <legend>Go to marker</legend>
    <vdms-hq-ui-button
      class="custom--advanced-player__button"
      (click)="playerService.handleAction('playToIn')"
      color="secondary"
      size="fieldset"
      >In</vdms-hq-ui-button
    >
    <vdms-hq-ui-button
      class="custom--advanced-player__button"
      (click)="playerService.handleAction('playToOut')"
      color="secondary"
      size="fieldset"
      >Out</vdms-hq-ui-button
    >
  </fieldset>
  <fieldset class="fieldset-wrapper">
    <legend>10 Sec</legend>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', -10)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="remove"
      size="fieldset"
    ></vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', 10)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="add"
      size="fieldset"
    ></vdms-hq-ui-button>
  </fieldset>
  <fieldset class="fieldset-wrapper">
    <legend>3 Sec</legend>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', -3)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="remove"
      size="fieldset"
    ></vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', 3)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="add"
      size="fieldset"
    ></vdms-hq-ui-button>
  </fieldset>
  <fieldset class="fieldset-wrapper">
    <legend>1 Sec</legend>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', -1)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="remove"
      size="fieldset"
    ></vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('secondForward', 1)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="add"
      size="fieldset"
    ></vdms-hq-ui-button>
  </fieldset>
  <fieldset class="fieldset-wrapper">
    <legend>1 Frame</legend>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('frameForward', -1)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="remove"
      size="fieldset"
    ></vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="playerService.handleAction('frameForward', 1)"
      class="custom--advanced-player__button"
      [iconOnly]="true"
      color="secondary"
      icon="add"
      size="fieldset"
    ></vdms-hq-ui-button>
  </fieldset>
</div>
