import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../../ui-data-presentation/components/runtime-error/runtime-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataHiddenPipe } from '../../ui-data-presentation/pipes/metadata-hidden.pipe';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject } from 'rxjs';
import { E2eIdDirective } from '@vdms-hq/shared';
import { DisableIfRowPipe, GridAdvancedMetadata } from '../../ui-data-presentation';
import { MatIconModule } from '@angular/material/icon';
import { GetTypeIconPipe } from '../../ui-pipes/pipes/get-type-icon.pipe';
import { GetTypeDescriptivePipe } from '../../ui-pipes/pipes/get-type-descriptive.pipe';
import { Action, ActionIdentifier } from '../../ui-data-presentation/logic/common-config';
import { HoverDirective } from '../../directives';
import { NameTransformerPipe } from '../../ui-floating-controls';

export type SelectableListTilesConfig<T> = {
  label: string;
  icon?: string | ((item: T) => string);
  key: string;
  disabledIf?: {
    label: (item: T) => string;
    icon?: (item: T) => string;
    condition: (item: T) => boolean;
  };
  hiddenIf?: (item: T) => boolean;
  actions?: Action<any>[] | null;
  metadata: GridAdvancedMetadata<T>[];
};

@Component({
  selector: 'vdms-hq-ui-selectable-list-tiles',
  templateUrl: './ui-selectable-list-tiles.component.html',
  styleUrls: ['./ui-selectable-list-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    UIPipesModule,
    TranslateModule,
    MetadataHiddenPipe,
    ValueRendererComponent,
    MatTooltipModule,
    DisableIfRowPipe,
    E2eIdDirective,
    MatIconModule,
    GetTypeIconPipe,
    GetTypeDescriptivePipe,
    HoverDirective,
    NameTransformerPipe,
  ],
})
export class UISelectableListTilesComponent<T> {
  @Input() config: SelectableListTilesConfig<T> | null = null;
  @Input() items: T[] | null = null;
  @Input() selected: T[] = [];
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() selectedChange = new EventEmitter<T>();
  @Output() action = new EventEmitter<{ key: string; item: T }>();

  itemHover: T | null = null;

  emitAction(event: { key: ActionIdentifier; item: T }, mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    this.action.emit(event);
  }
}
