import { AudioChannel, EncodeProfile } from './dolby.model';

export interface DolbySessionData {
  security_token: string;
  authentication?: unknown;
  data: {
    source_info: DolbySourceInfo[];
    media_info: DolbyMediaInfo[];
    master_manifests: DolbyMasterManifest[];
    media_tracks: DolbyMediaTracks;
    presentation: DolbyPresentation;
  };
}

export interface DolbySourceInfo {
  id: string;
  name: string;
  modified: string;
  format: string;
  size_kb: number;
  bandwidth_kb: number;
  duration_sec: number;
  video_tracks: number;
  audio_tracks: number;
}

interface DolbyMediaInfo {
  source_id: string;
  general_properties?: DolbyGeneralProperties;
}

interface DolbyGeneralProperties {
  container: DolbyContainer;
  audio: DolbyAudio[];
  video: DolbyVideo;
  timecode: DolbyTimecode[];
  asset: DolbyAsset;
}

interface DolbyAsset {
  asset_url: string;
  creator: string;
  creation_date: string;
  tagged_date: string;
  content_type: string;
}

interface DolbyContainer {
  kind: string;
  faststart: boolean;
  duration_sec: number;
  bitrate_kb: number;
  size_kb: number;
}

interface DolbyAudio {
  file_track_idx: number;
  pid: number;
  sample_format: string;
  codec: string;
  sample_rate: number;
  channels: number;
  sample_size: number;
  channel_designators: string[];
  duration_sec: number;
  bitrate_mode: string;
  bitrate_kb: number;
  channel_order: string;
  language: string;
}

interface DolbyVideo {
  file_track_idx: number;
  pid: number;
  codec: string;
  profile: string;
  bitrate_mode: string;
  bitrate_kb: number;
  frame_rate: number;
  height: number;
  width: number;
  interlace_mode: string;
  dar: number;
  par: number;
  chroma_format: string;
  color_primaries: string;
  color_matrix: string;
  color_trc: string;
  duration_sec: number;
  precise_frame_rate: number;
  frame_rate_mode: string;
  clean_aperture_height: number;
  clean_aperture_width: number;
  bit_resolution: number;
  color_space: string;
  range: string;
}

interface DolbyTimecode {
  file_track_idx: number;
  source: string;
  start_value: string;
  format: string;
}

interface DolbyMediaTrack {
  id: string;
  name: string;
  source_id: string;
}

export interface DolbyMediaTracks {
  video?: DolbyVideoMediaTrack[];
  audio?: DolbyAudioMediaTrack[];
  text?: DolbyTextMediaTrack[];
}

type TextMediaTrackUsageType = 'subtitles' | 'fn_subtitles' | 'captions' | 'transcriptions';

export interface DolbyTextMediaTrack extends DolbyMediaTrack {
  program_name: string;
  usage_type?: TextMediaTrackUsageType;
  analysis?: DolbyAnalysis[];
  language?: string;
}

export interface DolbyAnalysis {
  id: string;
  name: string;
  type: AnalysisType;
  visualization: AnalysisVisualization;
  url: string;
  color?: string;
}

type AnalysisType = 'event' | 'events' | 'chart';
type AnalysisVisualization = 'marker' | 'point' | 'bar' | 'led' | 'line';

export interface DolbyAudioMediaTrack extends DolbyMediaTrack {
  program_name: string;
  sound_field?: string;
  channel_layout?: string;
  language?: string;
  channels?: DolbyChannel[];
  analysis?: DolbyAnalysis[];
  visual_reference?: DolbyVisualReference[]; // can appear in media files without channels (ie. audio only files)
}

export interface DolbyVisualReference {
  id: string;
  type: VisualReferenceType;
  url: string;
}

type VisualReferenceType = 'marker' | 'thumbnails' | 'waveform';

export interface DolbyChartAnalysis extends DolbyAnalysis {
  y_min?: number;
  y_max?: number;
  scale?: string;
}

export interface DolbyChannel {
  id: string;
  channel_order?: string;
  program_name: string;
  visual_reference: DolbyVisualReference[];
}

export interface DolbyVideoMediaTrack extends DolbyMediaTrack {
  manifest_ids: string[];
  visual_reference: DolbyVisualReference[];
  analysis: DolbyAnalysis[];
}

export interface DolbyMasterManifest {
  id: string;
  name: string;
  width?: number;
  height?: number;
  bitrate_kb?: number;
  codec?: string;
  color_range?: string;
  frame_rate?: string;
  url: string;
  drop_frame?: boolean;
  ffom?: string;
}

export interface DolbyPresentation {
  layout?: DolbyLayout;
  info_tabs?: DolbyInfoTab[];
  player_configuration?: {
    manifest_id: string;
    subtitle_id: string;
    audio_track_id: string;
  };
  timeline_configuration?: {
    visual_reference_height: Record<string, string>;
    track_display: {
      id: string;
      visual_reference_id: string;
      channel_ids: number[];
      analysis_ids: number[];
    }[];
  };
}

interface DolbyInfoTab {
  name: string;
  type: string;
  visualization: string;
  data: any; // TODO
}

interface DolbyLayout {
  default_view: string;
  color_scheme: string;
  video_position: string;
}

export enum DolbyPlayerVurlAdvisor {
  HLS_PACKAGER = 'hls-packager',
  MEDIA_PROPERTIES = 'media-properties',
  POSTERFRAME = 'posterframe',
  STORAGE = 'storage',
  THUMBNAILS = 'thumbnails',
  WAVEFORM = 'waveform',
}

export enum DolbyPlayerVariant {
  MINIMAL = 'minimal',
  BBC_AUDIOWAVEFORM = 'bbc-audiowaveform',
}

export interface DolbyPlayerRegisterAssetBody {
  urls: DolbyAssetUrl[];
  name?: string;
  metadata_selector?: 'asset_media_descriptor' | 'asset_track_map' | 'media_index' | 'rasp_manifest' | 'rasp_info';
  expiration?: string;
  asset_mime?: string;
  vurls?: DolbyPlayerVurls[];
}

interface DolbyAssetUrl {
  url: string;
  id?: string;
  dolby_vision_metadata_xml?: string;
  mime?: string;
}

export interface DolbyTrackMap {
  file_id: string;
  track_idx: number;
}

export interface DolbyMediaProperties {
  id: string;
  url: string;
  size_bytes: number;
  bitrate_kb: number;
  duration_sec: number;
  video_tracks: number;
  audio_tracks: number;
  video: DolbyVideo[];
  audio: DolbyAudio[];
  timecode: DolbyTimecode[];
}

export interface DolbyPlayerVurls {
  vurl: string;
  advisor?: DolbyPlayerVurlAdvisor;
  config: {
    advisor?: DolbyPlayerVurlAdvisor;
    encode_profile?: EncodeProfile;
    variant?: DolbyPlayerVariant;
    width?: number;
    height?: number;
    codec?: string;
    quantization?: number;
    interval_sec?: number;
    transformation_map?: {
      video?: { track_idx: number }[];
      audio?: { channels: AudioChannel[]; channel_order?: 'mono' }[];
    };
    track_selector?: {
      track_idx: number;
    };
    channels?: number;
    sample_duration_ms?: number;
    payload?: DolbySessionData;
  };
  mime?: string;
  prefetch?: string;
}
