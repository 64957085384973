import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CONFIG, ApiConfig } from '../config-token';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) private env: ApiConfig,
  ) {}

  getAbsoluteUrl = <RESPONSE>(url: string) => this.http.get<RESPONSE>(url);

  get = <RESPONSE>(url: string, options?: { headers?: HttpHeaders; params?: HttpParams; context?: HttpContext }) =>
    this.http.get<RESPONSE>(`${this.env.apiUrl}/${url}`, {
      context: options?.context,
      headers: options?.headers,
      params: options?.params,
    });

  put = <PUT, RESPONSE>(url: string, body: PUT, headers?: HttpHeaders, context?: HttpContext) =>
    this.http.put<RESPONSE>(`${this.env.apiUrl}/${url}`, body, { headers, context });

  post = <POST, RESPONSE>(url: string, body: POST, headers?: HttpHeaders, context?: HttpContext) =>
    this.http.post<RESPONSE>(`${this.env.apiUrl}/${url}`, body, { headers, context });

  textPost = <POST>(url: string, body: POST, headers?: HttpHeaders) =>
    this.http.post(`${this.env.apiUrl}/${url}`, body, {
      headers,
      responseType: 'text',
    });

  patch = <PATCH, RESPONSE>(url: string, body: PATCH, headers?: HttpHeaders, context?: HttpContext) =>
    this.http.patch<RESPONSE>(`${this.env.apiUrl}/${url}`, body, { headers, context });

  patchNoBody = <RESPONSE>(url: string, headers?: HttpHeaders, context?: HttpContext) =>
    this.http.patch<RESPONSE>(`${this.env.apiUrl}/${url}`, null, { headers, context });

  delete = <RESPONSE, BODY = undefined>(
    url: string,
    body?: BODY,
    headers?: HttpHeaders,
    params?: HttpParams | Record<string, string[]>,
    context?: HttpContext,
  ) =>
    this.http.delete<RESPONSE>(`${this.env.apiUrl}/${url}`, {
      headers,
      body,
      params,
      context,
    });
}
