import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UIButtonModule } from '@vdms-hq/ui';
import { PersistenceSearchParams } from '@vdms-hq/api-contract';
import { AsyncPipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { SharedModule, TruncatePipe } from '@vdms-hq/shared';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { DefinedFilter } from '@vdms-hq/api-contract';

export type QueryAutocomplete = {
  text?: string;
  filters: { key: string; value: DefinedFilter }[];
  label?: string;
  key?: string;
  params: PersistenceSearchParams;
  extraMetadata?: number;
};

@Component({
  selector: 'vdms-hq-search-autocomplete-options',
  templateUrl: './search-autocomplete-options.component.html',
  styleUrl: './search-autocomplete-options.component.scss',
  standalone: true,
  imports: [
    UIButtonModule,
    AsyncPipe,
    MatOption,
    NgForOf,
    NgIf,
    MatAutocomplete,
    TruncatePipe,
    KeyValuePipe,
    SharedModule,
    DynamicFiltersModule,
  ],
  providers: [],
})
export class SearchAutocompleteOptionsComponent {
  @Input() autocompleteOptions: QueryAutocomplete[] = [];

  @Output() selectOption = new EventEmitter<QueryAutocomplete>();
  @Output() removeOption = new EventEmitter<QueryAutocomplete>();

  @ViewChild('auto') matAutocomplete: any;
}
