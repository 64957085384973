<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    *ngIf="authService.displayName$ | async as displayName"
    [title]="displayName"
    [subtitle]="'common.account_settings.subheader' | translate"
    [size]="'large'"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-loader
    [mode]="'over-parent'"
    [backdrop]="true"
    *ngIf="isFetching$ | async; else content"
  ></vdms-hq-ui-loader>
  <ng-template #content>
    <vdms-hq-ui-inner-sidebar>
      <vdms-hq-ui-inner-sidebar-menu [navItems]="navItems" left></vdms-hq-ui-inner-sidebar-menu>
      <router-outlet></router-outlet>
    </vdms-hq-ui-inner-sidebar>
  </ng-template>

  <vdms-hq-ui-actions-footer *ngIf="!settingsFormService.isPristine || !notificationSettingsFormService.form.pristine">
    <ng-container right>
      <vdms-hq-ui-button
        (click)="handleHeaderAction({ key: 'user-settings.save' })"
        [loading]="
          (settingsDataService.isSaving$ | async) || (notificationSettingsFormService.loading$ | async) || false
        "
        color="primary"
      >
        {{ 'common.global.save_changes' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-actions-footer>
</div>
