<div
  class="material-custom-field-radio"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.horizontal]="horizontalView"
>
  <mat-label class="material-custom-field-label" *ngIf="label" [style.width]="labelWidth ?? 'unset'">
    {{ label }}
    <span class="required-indicator" *ngIf="isRequired">*</span>
  </mat-label>
  <mat-radio-group [formControl]="innerFormControl" [required]="isRequired" (blur)="propagateTouch()">
    <div class="lists" [class.columns-1]="columns === 1" [class.columns-2]="columns === 2">
      <div
        *ngFor="let list of listsArray; let listIndex = index"
        [class.horizontal]="orientation === 'horizontal'"
        class="column"
      >
        <div *ngFor="let item of options">
          <label class="label">
            <mat-radio-button
              (change)="propagateByClick($event)"
              [value]="item.key"
              [sharedE2eId]="'radio-button-' + item.key"
            >
              <div [innerHTML]="item.label | translate"></div>
            </mat-radio-button>
            <div class="suffix" *ngIf="item.suffix">{{ item.suffix }}</div>
          </label>
        </div>
      </div>
    </div>
  </mat-radio-group>

  <div class="mt-medium" *ngIf="withFooter">
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="innerFormControl.touched">
      <span *ngFor="let error of errors">{{ error.transLang | translate: error.contextParams }}</span>
    </mat-error>
  </div>
</div>
