import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { QuarantineConfig, QuarantineConfigRules, QuarantinePostConfig } from './quarantine.model';
import { HttpHeaders } from '@angular/common/http';

const enum QUARANTINE_ENDPOINTS {
  ALL_CONFIGS = 'quarantine-configs',
  ONE_CONFIG = 'quarantine-config',
}

@Injectable({ providedIn: 'root' })
export class QuarantineApiService {
  private apiService = inject(ApiService);

  updateConfig(config: QuarantinePostConfig, rules: QuarantineConfigRules) {
    if (!config?.uuid) {
      throw new Error('Config UUID is missing');
    }

    if (Object.values(rules).every((rule) => !rule) && config.supplier_uuid) {
      return this.apiService.delete<ApiResponse<any>>(`${QUARANTINE_ENDPOINTS.ONE_CONFIG}\\${config.uuid}`);
    }

    return this.apiService.patch<QuarantinePostConfig, ApiResponse<any>>(
      `${QUARANTINE_ENDPOINTS.ONE_CONFIG}\\${config.uuid}`,
      {
        ...config,
        rules,
      },
    );
  }

  createConfig(clientUuid: string, rules: QuarantineConfigRules, supplierUuid?: string) {
    const payload = {
      group_uuid: clientUuid,
      rules,
    } as QuarantinePostConfig;

    if (supplierUuid) {
      payload.supplier_uuid = supplierUuid;
    }

    return this.apiService.post<QuarantinePostConfig, ApiResponse<any>>(`${QUARANTINE_ENDPOINTS.ONE_CONFIG}`, payload);
  }

  getAllConfigs() {
    return this.apiService.get<ApiResponse<QuarantineConfig[]>>(QUARANTINE_ENDPOINTS.ALL_CONFIGS);
  }

  getConfig(uuid: string) {
    return this.apiService.get<ApiResponse<QuarantineConfig>>(`${QUARANTINE_ENDPOINTS.ONE_CONFIG}/${uuid}`);
  }

  getClientConfig(clientUuid: string | null | undefined) {
    if (!clientUuid) {
      throw new Error('Client UUID is missing');
    }
    let headers = new HttpHeaders();
    headers = headers.append('per-page', '1000');
    return this.apiService
      .get<ApiResponse<QuarantineConfig[]>>(`${QUARANTINE_ENDPOINTS.ALL_CONFIGS}`, { headers })
      .pipe(
        take(1),
        map((configs) => {
          const clientConfig = configs.data.find(
            (config) => config.group_uuid === clientUuid && !config.supplier?.uuid,
          );
          if (!clientConfig) {
            throw new Error('Client config not found');
          }

          clientConfig.suppliers_rules = configs.data
            .filter((config) => config.supplier?.uuid)
            .map((config) => ({
              name: config.supplier?.name ?? '',
              rules: Object.entries(config.rules).filter((rule) => rule[1]),
            }))
            .filter((config) => config.rules.length)
            .map((config) => ({ name: config.name, rules: config.rules.map((rule) => rule[0]) }));
          return clientConfig;
        }),
      );
  }

  getClientSupplierConfig(clientUuid: string | null | undefined, supplierUuid: string | null | undefined) {
    if (!clientUuid) {
      throw new Error('Client UUID is missing');
    }

    if (!supplierUuid) {
      throw new Error('Supplier UUID is missing');
    }
    let headers = new HttpHeaders();
    headers = headers.append('per-page', '1000');

    return this.apiService
      .get<ApiResponse<QuarantineConfig[]>>(`${QUARANTINE_ENDPOINTS.ALL_CONFIGS}`, { headers })
      .pipe(
        take(1),
        map((configs) => {
          return configs.data.find(
            (config) => config.group_uuid === clientUuid && config.supplier?.uuid === supplierUuid,
          );
        }),
      );
  }
}
