import { inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CollectionAccessType, CollectionModelGet } from '@vdms-hq/api-contract';
import { SimpleDetailsList } from '@vdms-hq/ui';
import { StorageUrlService } from '@vdms-hq/storage';
import { COLLECTIONS_ROUTER_BASE } from '../../pages/collections-routes';
import { SingleCollectionViewModel } from '../models/collection-view.model';

@Injectable({ providedIn: 'root' })
export class CollectionMetadataTransformerSnippet {
  private storageUrlService = inject(StorageUrlService);
  private datePipe = inject(DatePipe);

  transformCollectionWithMetadata(collection: CollectionModelGet, userId?: string): SingleCollectionViewModel {
    return {
      ...collection,
      metadata: this.createMetadata(collection, userId),
      statuses: {
        color: 'success',
        hideDot: false,
        label: '',
      },
      title: collection.name,
      children: collection.children,
      routerLink: `/${COLLECTIONS_ROUTER_BASE.COLLECTIONS}/${collection.uuid}`,
      custom_cover_path: this.storageUrlService.updateCdn(collection.custom_cover_path) ?? '',
    };
  }

  createMetadata(collection: CollectionModelGet, userId?: string): SimpleDetailsList[] {
    let metadata: SimpleDetailsList[] = [];
    if (collection.owner && userId && collection.owner === userId) {
      metadata = [
        {
          title: 'common.collection_type',
          value: 'common.collection_type_owned',
        },
      ];
    }

    switch (collection.access_type) {
      case CollectionAccessType.DASHBOARD:
        metadata = [
          {
            title: 'common.collection_type',
            value: 'common.collection_type_dashboard',
          },
        ];
        break;
      case CollectionAccessType.SHARED:
        metadata = [
          {
            title: 'common.collection_type',
            value: 'common.collection_type_shared',
          },
        ];
        break;
      case CollectionAccessType.TEAM:
        metadata = [
          {
            title: 'common.collection_type',
            value: 'common.collection_type_team',
          },
        ];
        break;
    }

    if (collection.number_of_assets) {
      metadata = [
        ...metadata,
        {
          title: 'common.assets_number',
          value: collection.number_of_assets,
        },
      ];
    }

    if (collection.created_at && collection.created_at !== '0000-00-00 00:00:00') {
      metadata = [
        ...metadata,
        {
          title: 'common.created_at',
          value: this.datePipe.transform(collection.created_at, 'dd-MM-yyyy hh:mm'),
        },
      ];
    }

    if (collection.updated_at && collection.updated_at !== '0000-00-00 00:00:00') {
      metadata = [
        ...metadata,
        {
          title: 'common.updated_at',
          value: this.datePipe.transform(collection.updated_at, 'dd-MM-yyyy hh:mm'),
        },
      ];
    }

    if (collection.shared_to_teams && collection.shared_to_teams.length) {
      metadata = [
        ...metadata,
        {
          title: 'common.teams',
          value: collection.shared_to_teams.map((team) => team.name).join(', '),
          size: 'wide',
        },
      ];
    }

    metadata = [
      ...metadata,
      {
        title: 'common.description',
        value: collection.description,
        size: 'wide',
      },
    ];

    return metadata;
  }
}
