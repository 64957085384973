import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';

export interface ClientIdProvider {
  clientIdDefinite$: Observable<string>;
}

export const API_CLIENT_ID_PROVIDER: InjectionToken<ClientIdProvider> = new InjectionToken('API_CLIENT_ID_PROVIDER');

export interface ApiConfig {
  clientIdProvider: Type<ClientIdProvider>;
  apiUrl: string;
  production: boolean;
  releaseNotes?: {
    dark: string;
    light: string;
  };
  dolbyRaspApiUrl?: string;
  dolbyRaspApiUrlV2?: string;
  websocketUrl?: string;
  interceptors: {
    throttling:
      | boolean
      | {
          period: number;
          limit: number;
          limitFor?: {
            endpoint: string;
            limit: number;
          }[];
        };
    bearer: boolean;
    client: boolean;
    errorHandling: boolean;
    cloudfrontCookie:
      | boolean
      | {
          notRequiredForPrefixes: string[];
        };
    ccSalesforceToken: boolean;
    cacheControl: boolean;
  };
  cookieDomain?: string;
  errorsRouting?: {
    notFound?: string;
  };
}

export const API_CONFIG: InjectionToken<ApiConfig> = new InjectionToken('API_CONFIG');
