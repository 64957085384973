import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent } from './component/destroy.component';
import { ReplaceWhenExceedsPipe } from './pipes/string/replace-when-exceeds.pipe';
import {
  CapitalizePipe,
  ClientCurrencyPipe,
  FormatBitratePipe,
  FormatBytesPipe,
  ReactiveTzDatePipe,
  RenamePipe,
  IsEmptyPipe,
  ToIterablePipe,
  SortByExceptNullPipe,
  SetStatusColor,
} from './pipes';

const modules = [CommonModule, TranslateModule];
const components = [DestroyComponent];

@NgModule({
  imports: [modules],
  exports: [
    ...modules,
    ...components,
    ReplaceWhenExceedsPipe,
    FormatBitratePipe,
    FormatBytesPipe,
    IsEmptyPipe,
    ToIterablePipe,
    SortByExceptNullPipe,
    RenamePipe,
    ClientCurrencyPipe,
    SetStatusColor,
    CapitalizePipe,
  ],
  providers: [DatePipe, AsyncPipe, ReactiveTzDatePipe, SetStatusColor, FormatBytesPipe, RenamePipe],
  declarations: [
    ...components,
    ReplaceWhenExceedsPipe,
    ReactiveTzDatePipe,
    FormatBitratePipe,
    FormatBytesPipe,
    IsEmptyPipe,
    ToIterablePipe,
    SortByExceptNullPipe,
    RenamePipe,
    ClientCurrencyPipe,
    SetStatusColor,
    CapitalizePipe,
  ],
})
export class SharedModule {}
