import { inject, Injectable } from '@angular/core';
import {
  FilterStreamMsgFactory,
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
  WsProgressToast,
} from '@vdms-hq/shared';
import { StreamService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';

@Injectable({ providedIn: 'root' })
export class OrderActionsRetryWsService {
  private imageCache = inject(ImageCacheService);
  private streamService = inject(StreamService);
  private toastService = inject(ToastService);

  popToast = {
    WS_RESTART_PROGRESS: (
      id: string,
      percent: number,
      counter?: {
        processing: number;
        all: number;
        errors: WebsocketAnyNotificationMessage;
      },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Retrying delivery destinations',
          percent,
          this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
          counter,
        ),
      }),
    WS_RESTART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Retrying delivery destinations success`,
      }),
    WS_RESTART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Retrying delivery destinations error`,
      }),
  };

  readonly connect$ = this.streamService.connect();
  #initToastElement() {
    this.imageCache.cacheImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON);
  }

  registerWebSocketListener() {
    this.#initToastElement();
    return this.connect$.pipe(
      FilterStreamMsgFactory([WebsocketNotificationActionEnum.RETRY_DD_JOBS]),
      WsProgressToast({
        SUCCESS: this.popToast.WS_RESTART_SUCCESS,
        PROGRESS: this.popToast.WS_RESTART_PROGRESS,
        ERROR: this.popToast.WS_RESTART_ERROR,
      }),
    );
  }
}
