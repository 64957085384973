import { FormControl, FormGroup } from '@angular/forms';
import { inject, Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { PaginationAPIModel as Pagination, UserApiService, UserModel } from '@vdms-hq/api-contract';
import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';
import { SelectOption } from '@vdms-hq/shared';
import { filter, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface UsersShareData {
  key: string;
  label: string;
  mail: string;
}

export type UsersShareFiltersView = {
  keyword: FormControl<string>;
};

export type UsersFiltersView = {
  keyword: string;
};

@Injectable({ providedIn: 'root' })
export class UsersShareDataSource
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<UsersShareFiltersView>, UserModel>
{
  private usersService = inject(UserApiService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  filters = new FormGroup({
    keyword: new FormControl<string>('', { nonNullable: true }),
  });

  refresh$ = new BehaviorSubject(new Date());
  isLoading$ = new BehaviorSubject<boolean>(true);

  showUsers$ = new BehaviorSubject<boolean>(true);

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.filters.valueChanges.pipe(
      startWith(this.filters.value),
      withLatestFrom(this.showUsers$),
      filter(([, showUsers]) =>
        !showUsers ? this.filters.controls.keyword.valid && this.filters.controls.keyword.value.length > 2 : true,
      ),
      debounceTime(400),
      switchMap(() => {
        const formValue = this.filters.value;
        const filters = <UsersFiltersView>{};

        if (formValue?.keyword) {
          filters.keyword = formValue.keyword;
        }

        this.changePageIndex$.next(0);
        return of(filters);
      }),
    ),
    this.showUsers$,
    this.activatedClientService.clientId$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([_, pageIndex, pageSize, filters, showUsers, clientId]) => {
      if (!clientId) {
        this.isLoading$.next(false);
        return EMPTY;
      }
      if (showUsers) {
        const pagination: Pagination = Pagination.create({
          page: pageIndex,
          perPage: pageSize,
          orderBy: 'user.displayName',
          orderDir: 'ASC',
        });
        return this.usersService
          .getUsersShare(
            {
              text: filters.keyword,
            },
            pagination,
          )
          .pipe(
            catchError((error) => {
              this.#errorHandler(error);
              throw error;
            }),
          );
      } else {
        if (filters.keyword.length < 3 || !filters.keyword) {
          this.isLoading$.next(false);
          return EMPTY;
        } else {
          this.isLoading$.next(true);
          return this.usersService
            .getUsersShare(
              { mail: filters.keyword },
              Pagination.create({
                page: 0,
                perPage: 1,
              }),
            )
            .pipe(
              catchError((error) => {
                this.#errorHandler(error);
                throw error;
              }),
            );
        }
      }
    }),
    shareReplay(1),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<UsersShareData[]> = this.responseData$.pipe(
    map((response) =>
      response.data.map((user) => {
        return {
          key: user.uuid,
          label: user.name,
          mail: user.email,
        };
      }),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<UsersShareFiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh(force: boolean) {
    if (this.filters.dirty || this.changePageIndex$.value !== 0 || force) {
      this.filters.controls['keyword'].setValue('');
      this.changePageIndex$.next(0);
    }
  }

  #errorHandler(err: HttpErrorResponse) {
    this.isLoading$.next(false);
    this.emptyResults$ = of(true);
    console.error(err);
    return EMPTY;
  }
}
