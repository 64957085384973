import { inject, Injectable } from '@angular/core';
import { FilterableDataSource, LoadableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { DeliveryPackApiService, MandatoryField } from '@vdms-hq/api-contract';
import { MandatoryFieldsRefreshService } from './mandatory-fields-refresh.service';

@Injectable()
export class MandatoryFieldsDatasource implements Partial<LoadableDataSource>, Partial<FilterableDataSource> {
  private refreshService: MandatoryFieldsRefreshService = inject(MandatoryFieldsRefreshService);
  private deliveryPackApiService: DeliveryPackApiService = inject(DeliveryPackApiService);

  isLoading$ = new BehaviorSubject(false);
  filterValue$ = new BehaviorSubject('');
  refresh$ = this.refreshService.refresh$;
  allFields$ = this.refresh$.pipe(
    switchMap(() => this.deliveryPackApiService.getMandatoryFields()),
    shareReplay(),
  );

  allData$: Observable<MandatoryField[]> = combineLatest([this.allFields$, this.filterValue$]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(250),
    map(([data, value]) => {
      if (value) {
        return data.filter(
          (item) =>
            item.contentType?.toLowerCase().includes(value.toLowerCase()) ||
            item.assetType?.toLowerCase().includes(value.toLowerCase()) ||
            item.contentClass?.toLowerCase().includes(value.toLowerCase()) ||
            item.fields.join(',').toLowerCase().includes(value.toLowerCase()),
        );
      }

      return data;
    }),
    tap(() => this.isLoading$.next(false)),
  );
  connection$ = this.allData$;

  applyFilter(value: string) {
    this.filterValue$.next(value);
  }
}
