import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIButtonModule } from '../ui-button';
import { UITilesModule } from '../ui-tiles';
import { UILoaderModule } from '../ui-loader';
import { UILoadMoreModule } from '../ui-load-more';
import { UIEmptyResultsModule } from '../ui-empty-results';
import { UIStatsModule } from '../ui-stats';
import { CommonModule } from '@angular/common';
import { UIFormModule } from '../ui-form';
import { UIStatusModule } from '../ui-status';
import { UIDialogModule } from '../ui-dialog';
import { UILayoutModule } from '../ui-layout';
import { FormUsageComponent } from './components/form-usage/form-usage.component';
import { ControlStateDemoComponent } from './components/control-state-demo/control-state-demo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UIResultsWrapperModule } from '../ui-results-wrapper';
import { UINativeUploadModule } from '../ui-native-upload';
import { PreviewUsageComponent } from './components/preview-usage/preview-usage.component';
import { UIPreviewModule } from '../ui-preview';
import { PlaygroundComponent } from './page/playground/playground.component';
import { MiscComponent } from './components/misc/misc.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FiltersUsageComponent } from './components/filters-usage/filters-usage.component';
import { MatTooltip } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    PlaygroundComponent,
    FormUsageComponent,
    FiltersUsageComponent,
    PreviewUsageComponent,
    ControlStateDemoComponent,
    MiscComponent,
  ],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: PlaygroundComponent,
        children: [
          { path: 'form', component: FormUsageComponent },
          { path: 'filters', component: FiltersUsageComponent },
          {
            path: 'data-presentation',
            loadComponent: () =>
              import('./components/data-presentation/data-presentation.component').then(
                (mod) => mod.DataPresentationComponent,
              ),
          },
          { path: 'preview', component: PreviewUsageComponent },
          { path: 'misc', component: MiscComponent },
          {
            path: '**',
            redirectTo: 'data-presentation',
          },
        ],
      },
    ]),
    UILayoutModule,
    UIButtonModule,
    UITilesModule,
    UILoaderModule,
    UILoadMoreModule,
    UIEmptyResultsModule,
    UIPreviewModule,
    UIStatsModule,
    CommonModule,
    UIFormModule,
    UIStatusModule,
    UIDialogModule,
    MatExpansionModule,
    UIResultsWrapperModule,
    UINativeUploadModule,
    MatToolbarModule,
    MatTabsModule,
    TranslateModule,
    MatTooltip,
  ],
})
export class UIPlaygroundModule {}
