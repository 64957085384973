<vdms-hq-ui-dialog-wrapper [size]="'narrow'">
  <h1 title>
    {{ title | translate }}
  </h1>
  <div content class="relative p-scroll">
    <vdms-hq-ui-form-section layout="single" [divider]="false">
      <div>
        <div [formGroup]="form">
          <vdms-hq-ui-form-section
            layout="single"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="(editMode$ | async) ? ('common.dialogs.license_package.create.basic' | translate) : ''"
          >
            <vdms-hq-ui-form-input-text
              formControlName="name"
              [label]="'common.dialogs.license_package.create.fields.name' | translate"
              [sharedE2eId]="'license-package-dialog-name'"
            ></vdms-hq-ui-form-input-text>
            <vdms-hq-ui-form-input-text-area
              formControlName="description"
              [label]="'common.dialogs.license_package.create.fields.description' | translate"
              [sharedE2eId]="'license-package-dialog-description'"
            ></vdms-hq-ui-form-input-text-area>
          </vdms-hq-ui-form-section>
          <ng-container *ngIf="editMode$ | async">
            <vdms-hq-ui-form-section layout="single" [divider]="false">
              <vdms-hq-ui-form-input-select
                formControlName="contract_uuids"
                [selectOptions]="contractsOptions"
                [label]="'common.dialogs.license_package.update.fields.contracts' | translate"
                [sharedE2eId]="'license-package-dialog-contracts'"
                [multiple]="true"
              ></vdms-hq-ui-form-input-select>
            </vdms-hq-ui-form-section>
            <vdms-hq-ui-form-section layout="grid-2-columns" [divider]="false">
              <vdms-hq-ui-form-input-select
                formControlName="status"
                [selectOptions]="statusOptions"
                [label]="'common.dialogs.license_package.update.fields.status' | translate"
                [sharedE2eId]="'license-package-dialog-status'"
              ></vdms-hq-ui-form-input-select>
              <vdms-hq-ui-form-input-select
                formControlName="view_state"
                [selectOptions]="viewStateOptions"
                [label]="'common.dialogs.license_package.update.fields.view_state' | translate"
                [isSortedByLabel]="true"
              ></vdms-hq-ui-form-input-select>
            </vdms-hq-ui-form-section>
            <vdms-hq-ui-form-section layout="grid-2-columns" [divider]="false" formGroupName="options">
              <vdms-hq-ui-form-input-select
                formControlName="custom_cover_position"
                [selectOptions]="cropOptions"
                [label]="'common.dialogs.license_package.update.fields.crop' | translate"
                [isSortedByLabel]="true"
              ></vdms-hq-ui-form-input-select>
            </vdms-hq-ui-form-section>
          </ng-container>
        </div>
        <div class="field-wrapper" *ngIf="editMode$ | async">
          <span class="field-label">{{ 'common.lists.cover_photo' | translate }}</span>
          <vdms-hq-ui-thumb
            *ngIf="form.controls['custom_cover'].value; else upload"
            [src]="form.controls['custom_cover_path'].value"
            (deleteFile)="deleteCover()"
          ></vdms-hq-ui-thumb>
          <ng-template #upload>
            <vdms-hq-storage-shared-form-file
              *ngIf="!file"
              [(ngModel)]="file"
              [multiple]="false"
              (ngModelChange)="uploadState = null"
              type="image"
            >
            </vdms-hq-storage-shared-form-file>
            <ng-container *ngIf="file">
              <vdms-hq-storage-shared-selected-file
                [file]="file"
                [uploadState]="uploadState"
                (clear)="file = null"
              ></vdms-hq-storage-shared-selected-file>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </vdms-hq-ui-form-section>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      [loading]="isLoading$ | async"
      (click)="onSubmit()"
      [disabled]="form.invalid"
      [sharedE2eId]="'license-package-dialog-submit'"
    >
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
