import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from './button/button.component';
import { UILoaderModule } from '../ui-loader';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, MatTooltipModule, MatButtonModule, UILoaderModule, MatIconModule, TranslateModule],
  exports: [ButtonComponent, ButtonComponent],
})
export class UIButtonModule {}
