import { MetadataSnapshot, MetadataSnapshotEndpoints } from './metadata-snapshots.model';
import { Injectable } from '@angular/core';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { createErrorContext } from '../../error-handler';

@Injectable({ providedIn: 'root' })
export class MetadataSnapshotsService {
  constructor(private readonly apiService: ApiService) {}

  getSnapshotConfig() {
    const context = createErrorContext({ '*': 'common.notifications.admin.metadata_snapshots.get.failure' });

    return this.apiService
      .get<ApiResponse<MetadataSnapshot | null>>(`${MetadataSnapshotEndpoints.ONE}`, { context })
      .pipe(GetResponseData);
  }

  postSnapshotConfig(body: Partial<MetadataSnapshot>) {
    const context = createErrorContext({ '*': 'common.notifications.admin.metadata_snapshots.create.failure' });

    return this.apiService.post<Partial<MetadataSnapshot>, unknown>(
      `${MetadataSnapshotEndpoints.ONE}`,
      body,
      undefined,
      context,
    );
  }

  updateSnapshotConfig(body: Partial<MetadataSnapshot>) {
    const context = createErrorContext({ '*': 'common.notifications.admin.metadata_snapshots.update.failure' });

    return this.apiService.patch<Partial<MetadataSnapshot>, unknown>(
      `${MetadataSnapshotEndpoints.ONE}`,
      body,
      undefined,
      context,
    );
  }

  deleteSnapshotConfig() {
    const context = createErrorContext({ '*': 'common.notifications.admin.metadata_snapshots.delete.failure' });

    return this.apiService.delete<ApiResponse<MetadataSnapshot>>(
      `${MetadataSnapshotEndpoints.ONE}`,
      undefined,
      undefined,
      undefined,
      context,
    );
  }

  runSnapshot() {
    const context = createErrorContext({ '*': 'common.notifications.admin.metadata_snapshots.run.failure' });

    return this.apiService.post(`${MetadataSnapshotEndpoints.RUN}`, {}, undefined, context);
  }
}
