import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryDestination } from '@vdms-hq/api-contract';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { UIButtonModule, UIDialogWrapperModule } from '@vdms-hq/ui';
import { BehaviorSubject } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { OrderAssetViewModel2 } from '../../logic/models';
import { Router } from '@angular/router';

@Component({
  selector: 'vdms-hq-transcode-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    MatExpansionModule,
    ActivatedClientModule,
  ],
  templateUrl: './new-assets-dialog.component.html',
  styleUrls: ['../transcode-details-dialog/transcode-details-dialog.component.scss'],
})
export class NewAssetsDialogComponent extends WithPermissions() {
  loading$ = new BehaviorSubject(false);

  constructor(
    private dialogRef: MatDialogRef<NewAssetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      delivery_destinations: DeliveryDestination[];
      asset: OrderAssetViewModel2;
    },
    private router: Router,
  ) {
    super();
  }

  goToAssetDetails(uuid: string) {
    this.close();
    this.router.navigate(['/asset', uuid]);
  }

  close() {
    this.dialogRef.close();
  }
}
