import { Injectable, Injector } from '@angular/core';
import { ToastService, ToastInput } from '@vdms-hq/toast';
import { Router } from '@angular/router';
import { ErrorHandlingRoutes } from './error-handling.enum';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  toastService!: ToastService;
  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
  ) {
    setTimeout((): ToastService => (this.toastService = injector.get(ToastService)));
  }

  triggerErrorToast(error: ToastInput): void {
    this.toastService.error(error);
  }

  redirectToErrorPage(error?: string): void {
    this.router.navigate([`error/${error ?? ErrorHandlingRoutes.NOT_FOUND}`]);
  }
}
