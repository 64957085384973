<div class="upload-container" [class.full-width]="fullWidth" [ngClass]="size ?? ''">
  <div
    [attr.data-component-id]="id"
    [attr.data-component-allow-drop]="multiple || (transfersForComponent$ | async)?.length === 0 ? '1' : '0'"
    class="upload-dropzone"
    [class.upload-dropzone--active]="withDropzone"
    [class.upload-dropzone--is-over]="(asperaUploadService.dragOver$ | async) === id"
  ></div>

  <vdms-hq-ui-loader
    *ngIf="(asperaUploadService.connected$ | async) === false"
    mode="over-parent"
    [backdrop]="true"
  ></vdms-hq-ui-loader>

  <div class="upload-placeholder">
    <div class="batches" *ngIf="progressVisibility === ProgressVisibilityEnum.ALL">
      <vdms-hq-storage-transferable-batch
        [@enterAnimation]
        [batch]="item"
        *ngFor="let item of transfersForComponent$ | async; let index = index"
        (deleteElement)="cancelTransfer(item)"
        (closeElement)="asperaUploadService.removeFromTransfers(item)"
      >
      </vdms-hq-storage-transferable-batch>
    </div>
    @if (multiple || (transfersForComponent$ | async)?.length === 0) {
      <div [class]="'button-container button-container__' + direction">
        <mat-icon [class]="'upload-icon upload-icon__' + iconSize" [class.disabled]="isReadOnly"
          >cloud_upload
        </mat-icon>
        <ng-content></ng-content>
        <vdms-hq-ui-button
          color="primary"
          class="upload-dropzone"
          (click)="selectFiles()"
          [disabled]="isReadOnly"
          [size]="buttonSize"
        >
          {{ (multiple ? 'common.aspera.upload_button.plural' : 'common.aspera.upload_button') | translate }}
        </vdms-hq-ui-button>
      </div>

      <span *ngIf="customText?.length; else defaultText" [innerText]="customText"></span>

      <ng-template #defaultText>
        <span *ngIf="withDropzone">{{
          (multiple ? 'common.aspera.or_drop.plural' : 'common.aspera.or_drop') | translate
        }}</span>
      </ng-template>
    }
  </div>
</div>
