<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[
    {
      name: 'common.delivery_destinations.fields.delivery_configs' | translate,
    },
    {
      name: formGroup.controls.name.value || 'N/A',
    },
  ]"
  *ngIf="formService.currentConfigFormGroup; let formGroup"
>
  <form [formGroup]="formGroup">
    <vdms-hq-ui-form-input-text
      formControlName="name"
      [label]="'common.delivery_destinations.fields.config_name' | translate"
    ></vdms-hq-ui-form-input-text>
    <ng-container formArrayName="rules">
      <mat-accordion
        class="material-custom-accordion-v2 accordion mb-24"
        *ngFor="let rules of formGroup.controls.rules.controls; let index = index"
        [formGroupName]="index"
      >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ rules.value?.['assetType'] === 'subtitle' ? 'TIMED TEXT' : rules.value?.['assetType']?.toUpperCase() }}
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon (click)="$event.stopPropagation(); formService.deleteRule(index)">delete</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="form--content__wrapper">
            <vdms-hq-selector
              [sourceType]="SelectorSourceType.CONTENT_CLASS"
              [label]="'common.delivery_destinations.fields.allowed_content_classes' | translate"
              [multiple]="true"
              formControlName="contentClass"
            ></vdms-hq-selector>

            <ng-container *ngIf="rules.controls?.['assetType']?.value === 'video'">
              <vdms-hq-ui-form-input-select
                [selectOptions]="resolutionsDataSource"
                [label]="'common.delivery_destinations.fields.allowed_source_resolution' | translate"
                [multiple]="true"
                formControlName="resolutions"
              ></vdms-hq-ui-form-input-select>
              <vdms-hq-ui-form-input-select
                [selectOptions]="videoCodecsDataSource$ | async"
                [label]="'common.delivery_destinations.fields.allowed_source_video_codecs' | translate"
                [multiple]="true"
                formControlName="videoCodecs"
              ></vdms-hq-ui-form-input-select>

              <ng-container formGroupName="transcode">
                <vdms-hq-ui-form-input-checkbox
                  formControlName="enabled"
                  [label]="'common.delivery_destinations.fields.transcode' | translate"
                ></vdms-hq-ui-form-input-checkbox>

                <ng-container *ngIf="rules.get('transcode.enabled').value">
                  <vdms-hq-ui-form-input-select
                    [selectOptions]="transcodeDeliveryDestinationTypes"
                    [label]="'common.delivery_destinations.fields.type' | translate"
                    formControlName="type"
                  ></vdms-hq-ui-form-input-select>
                  <vdms-hq-ui-form-input-checkbox
                    formControlName="burnInText"
                    [label]="'common.delivery_destinations.fields.burn_in_text' | translate"
                  ></vdms-hq-ui-form-input-checkbox>
                  <vdms-hq-ui-form-input-text-area
                    [label]="'common.delivery_destinations.fields.hybrik_profile' | translate"
                    [withFooter]="true"
                    formControlName="overrideProfile"
                  ></vdms-hq-ui-form-input-text-area>
                  <ng-container formArrayName="audioTracks">
                    <mat-accordion
                      class="material-custom-accordion-v2 accordion mb-24"
                      *ngFor="
                        let _ of rules.controls['transcode'].controls['audioTracks'].controls;
                        let audioTrackIndex = index
                      "
                      [formGroupName]="audioTrackIndex"
                    >
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{
                              'common.delivery_destinations.fields.audio_track_counter'
                                | translate: { count: audioTrackIndex }
                            }}
                          </mat-panel-title>
                          <mat-panel-description>
                            <mat-icon
                              (click)="$event.stopPropagation(); formService.deleteAudioTrack(audioTrackIndex, index)"
                              >delete
                            </mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="form--content__wrapper">
                          <vdms-hq-selector
                            [sourceType]="SelectorSourceType.LANGUAGE"
                            [label]="'common.delivery_destinations.fields.language' | translate"
                            formControlName="languageUuid"
                          ></vdms-hq-selector>

                          <vdms-hq-selector
                            [sourceType]="SelectorSourceType.HYBRIK_TRACK_CLASS"
                            [label]="'common.delivery_destinations.fields.content_class' | translate"
                            formControlName="contentClass"
                          ></vdms-hq-selector>

                          <vdms-hq-selector
                            [sourceType]="SelectorSourceType.LAYOUT"
                            [label]="'common.delivery_destinations.fields.layout' | translate"
                            formControlName="layout"
                          ></vdms-hq-selector>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </ng-container>

                  <div class="delivery-destinations--add-rules__wrapper">
                    <vdms-hq-ui-button
                      color="primary"
                      (click)="formService.addEmptyAudioTrack(rules)"
                      [disabled]="rules.controls['transcode'].controls['audioTracks'].invalid"
                      >{{ 'common.delivery_destinations.fields.add_new_audio_track' | translate }}
                    </vdms-hq-ui-button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container formGroupName="publish">
              <vdms-hq-ui-info-bar
                *ngIf="formService.publishMethod !== 'vida'"
                [type]="InfoBarType.WARNING"
                [disableClose]="true"
                [message]="'common.delivery_destinations.fields.publish_warning' | translate"
              ></vdms-hq-ui-info-bar>
              <vdms-hq-ui-form-input-checkbox
                formControlName="enabled"
                [label]="
                  formService.publishMethod === 'vida'
                    ? ('common.delivery_destinations.fields.publish' | translate)
                    : ('common.delivery_destinations.fields.publish_override' | translate)
                "
              ></vdms-hq-ui-form-input-checkbox>
              <ng-container *ngIf="rules.get('publish.enabled').value">
                <vdms-hq-ui-form-input-select
                  [selectOptions]="credentialTypeOptions"
                  [label]="'common.delivery_destinations.fields.type' | translate"
                  formControlName="type"
                  (valueChange)="getCredentialsByType($event)"
                ></vdms-hq-ui-form-input-select>
                <ng-container *ngIf="rules.get('publish.type').value">
                  <vdms-hq-ui-loader *ngIf="isPublishTypeLoader"></vdms-hq-ui-loader>
                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.AWS &&
                      credentialsSelectorSourceAWS.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceAWS"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-select
                        [selectOptions]="AwsRegionsOptions"
                        [label]="'common.delivery_destinations.fields.region' | translate"
                        formControlName="region"
                      ></vdms-hq-ui-form-input-select>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.bucket' | translate"
                        formControlName="bucketName"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.ASPERA_HSTS &&
                      credentialsSelectorSourceAsperaHSTS.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceAsperaHSTS"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.ear_passphrase' | translate"
                        formControlName="earPassphrase"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.ASPERA_YOUTUBE &&
                      credentialsSelectorSourceAsperaYOUTUBE.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceAsperaYOUTUBE"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.channel' | translate"
                        formControlName="channel"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.ear_passphrase' | translate"
                        formControlName="earPassphrase"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.ASPERA_AOC &&
                      credentialsSelectorSourceAsperaAOC.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceAsperaAOC"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.ear_passphrase' | translate"
                        formControlName="earPassphrase"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.ASPERA_SHARES &&
                      credentialsSelectorSourceAsperaSHARES.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceAsperaSHARES"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.ear_passphrase' | translate"
                        formControlName="earPassphrase"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.FTPS &&
                      credentialsSelectorSourceFTPS.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceFTPS"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                    <ng-container formGroupName="configData">
                      <vdms-hq-ui-form-input-text
                        [label]="'common.delivery_destinations.fields.prefix' | translate"
                        formControlName="prefix"
                      ></vdms-hq-ui-form-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      rules.get('publish.type').value === CredentialTypeEnum.YOUTUBE &&
                      credentialsSelectorSourceYoutube.length > 0
                    "
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="credentialsSelectorSourceYoutube"
                      [label]="'common.delivery_destinations.fields.credentials' | translate"
                      formControlName="credentialUuid"
                    ></vdms-hq-ui-form-input-select>
                  </ng-container>

                  <ng-container
                    formGroupName="configData"
                    *ngIf="rules.get('publish.type').value === CredentialTypeEnum.INGEST_IN_VIDA"
                  >
                    <vdms-hq-ui-form-input-select
                      [selectOptions]="
                        (deliveryDestinationFilenameConventionsService.conventionsSelectOptions$ | async) ?? []
                      "
                      [formControlName]="rules.controls?.['assetType']?.value"
                      [label]="'Convention Name'"
                    ></vdms-hq-ui-form-input-select>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </form>
  <div class="delivery-destination--new-config__wrapper">
    <vdms-hq-ui-form-input-select
      [formControl]="newContainerControl"
      [label]="'common.delivery_destinations.fields.select_delivery_asset_type' | translate"
      [selectOptions]="availableAssetTypes$ | async"
      [sharedE2eId]="'delivery-destinations-asset-type'"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-button
      color="primary"
      (click)="addNewRule()"
      [disabled]="newContainerControl.invalid"
      [sharedE2eId]="'delivery-destinations-add-rule'"
      >{{ 'common.delivery_destinations.edit.add_new_rule' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-layout-page-wrapper>
