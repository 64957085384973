<div class="ul-list__filters" [formGroup]="form" [class.full-width]="fullWidth">
  <ng-container *ngFor="let definition of fieldsConfig">
    <ng-container *ngIf="form.controls[definition.id]; let ngControl">
      <ng-container [ngSwitch]="definition.filters.type">
        <vdms-hq-ui-form-input-text
          *ngSwitchCase="'master_text'"
          class="ul-list__filter-keywords"
          [class.full-width]="fullWidth"
          [formControlName]="definition.id"
          [label]="(definition.label | translate) ?? ('pages.fields.keywords' | translate)"
          [fallbackSuffixIcon]="'search'"
          [withFooter]="withFooter"
          [sharedE2eId]="'input-text'"
        ></vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-select
          class="ul-list__filter-sort"
          [class.full-width]="fullWidth"
          *ngSwitchCase="'selector'"
          [enableSelectAll]="definition.enableSelectAll ?? false"
          [enableDeselectAll]="definition.enableDeselectAll ?? false"
          [enableClear]="definition.enableClear ?? false"
          [multiple]="definition.isMultiple ?? false"
          [label]="definition.label | translate"
          [formControlName]="definition.id"
          [selectOptions]="definition?.selectOptions ?? []"
          [withFooter]="withFooter"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-radio-list
          [formControlName]="definition.id"
          [label]="definition.label | translate"
          *ngSwitchCase="'radio_list'"
          [orientation]="'horizontal'"
          [selectOptions]="definition.selectOptions ?? []"
          [withFooter]="withFooter"
        ></vdms-hq-ui-form-radio-list>
        <vdms-hq-ui-form-input-toggle
          [formControlName]="definition.id"
          *ngSwitchCase="'toggle'"
          [label]="definition.label | translate"
          [withFooter]="withFooter"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-form-input-date-range
          [formControlName]="definition.id"
          *ngSwitchCase="'datepicker_range'"
          [label]="definition.label | translate"
          [withFooter]="withFooter"
        ></vdms-hq-form-input-date-range>
        <vdms-hq-ui-form-input-chips
          [formControlName]="definition.id"
          *ngSwitchCase="'chips'"
          [placeholder]="definition.label | translate"
          [label]="definition.label | translate"
          [withFooter]="withFooter"
        ></vdms-hq-ui-form-input-chips>
        <span *ngSwitchDefault>Unsupported file type {{ definition.filters.type }}</span>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="hasLoader">
  <vdms-hq-ui-loader *ngIf="isLoading"></vdms-hq-ui-loader>
</ng-container>
