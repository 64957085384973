import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import {
  CountryCode,
  getCountries,
  getExampleNumber,
  getPhoneCode,
  isValidNumber,
  parseNumber,
} from 'libphonenumber-js';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import examples from 'libphonenumber-js/examples.mobile.json';

type OuterValue = string | null | undefined;
type InnerValue = {
  code: string | null;
  number: number | null;
};

const validatePhone: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
  if (!form.get('code') || !form.get('number')) {
    return null;
  }

  const countryCode = form.get('code')?.value;
  const number = form.get('number')?.value;

  if (!countryCode || !number) {
    return null;
  }

  const isValid = isValidNumber(String(number), countryCode);

  if (!isValid) {
    return { validatePhone: true };
  }

  return null;
};

@Component({
  selector: 'vdms-hq-ui-form-input-phone',
  templateUrl: './form-input-phone.component.html',
  styleUrls: ['./form-input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputPhoneComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputPhoneComponent),
    },
  ],
})
export class FormInputPhoneComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements AfterViewInit, OnDestroy
{
  @Input() numberLabel!: string;
  @Input() numberPlaceholder!: string;

  innerFormControl = new FormGroup({
    code: new FormControl<string>('US', { nonNullable: true }),
    number: new FormControl<number | null>(null),
  });
  countryCodes: SelectOption[] = getCountries()
    .map((c) => <SelectOption<CountryCode>>{ label: c, key: c })
    .map((c) => (c.key === 'GB' ? { ...c, label: 'UK' } : c));

  override errorMessages = {
    validatePhone: 'Phone number is invalid for given country code',
  };
  phonePrefix = '';
  exampleNumber = '';

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.innerFormControl.setValidators(validatePhone);
  }

  protected override transformOuterToInner(value: OuterValue): InnerValue {
    const parsed = parseNumber(value ?? '');

    const innerValue = {
      code: 'country' in parsed ? parsed['country'] : 'US',
      number: 'phone' in parsed ? Number(parsed['phone']) : null,
    };

    this.updatePrefix(innerValue.code);

    return innerValue;
  }

  protected override transformInnerToOuter(value: InnerValue): OuterValue {
    if (!this.phonePrefix || !value.number) {
      return null;
    }

    const newValue = `${this.phonePrefix}${value.number}`;

    return newValue;
  }

  updatePrefix(code: CountryCode) {
    const countryCode = getPhoneCode(code);
    this.phonePrefix = `+${countryCode}`;
    this.exampleNumber = getExampleNumber(code, examples)?.nationalNumber.toString() ?? '';
    this.innerFormControl.controls.number.setValue(null);
  }
}
