<vdms-hq-ui-dialog-wrapper [loading]="!!(loading$ | async)">
  <h1 title>{{ 'pages.clients.select_client' | translate }}</h1>
  <div content>
    <ng-container *ngIf="clients$ | async; let clients">
      <vdms-hq-ui-form-checkbox-list [columns]="1" [available]="clients || []" [formControl]="selectedClients">
      </vdms-hq-ui-form-checkbox-list>
    </ng-container>
  </div>
  <ng-container footer>
    <vdms-hq-ui-form-input-checkbox
      class="checkbox"
      [label]="'common.global.all' | translate"
      [ngModel]="false"
      (ngModelChange)="toggleAll($event)"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-checkbox>

    <vdms-hq-ui-button (click)="dialogRef.close()" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="onSubmit()" color="primary">{{ 'common.global.save' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
