import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule, UIListModule } from '@vdms-hq/ui';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormService } from '../../logic/form.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FindSelectOption, FirstPipe, moveItemInFormArray, SelectOption } from '@vdms-hq/shared';
import { Subject } from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { EnabledListPipe } from '../../logic/enabled-list.pipe';
import { AvailableListPipe } from '../../logic/available-list.pipe';
import { ToastService } from '@vdms-hq/toast';
import { MatDialog } from '@angular/material/dialog';
import { EmailSettingsComponent } from '../../components/email-settings/email-settings.component';

@Component({
  selector: 'vdms-hq-admin-page',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    ReactiveFormsModule,
    UIFormModule,
    FormSectionComponent,
    TranslateModule,
    UIButtonModule,
    MatTabsModule,
    DragDropModule,
    UIListModule,
    EnabledListPipe,
    AvailableListPipe,
    FindSelectOption,
    FirstPipe,
  ],
  providers: [FormService],
  templateUrl: './admin-page.component.html',
  styleUrls: [
    './admin-page.component.scss',
    '../../../../../activated-client-admin/src/lib/general/pages/main-form/main-form.component.scss',
  ],
})
export class AdminPageComponent implements OnInit, OnDestroy {
  formService = inject(FormService);
  activatedClient = inject(ActivatedClientService);
  toast = inject(ToastService);
  dialog = inject(MatDialog);

  #destroyed = new Subject<void>();
  name?: string;

  selectedTab = new FormControl(0);
  selectedNewElement: string[] = [];

  ngOnInit(): void {
    this.formService.init();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  drop(event: CdkDragDrop<SelectOption[]>, control: FormControl, list: SelectOption[]) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.formService.form.markAsTouched();
    control.get('fields')?.setValue(list.map((item) => item.key));
  }

  goToActiveTab() {
    setTimeout(() => {
      const tabsCount = this.formService.structureFormArray.controls?.length;
      this.selectedTab.setValue(tabsCount - 1);
    }, 100);
  }

  reorderForward(index: number) {
    const nextElement = this.formService.structureFormArray.controls
      ?.slice(index + 1)
      ?.find((item) => item.controls.label.value);
    const nextElementIndexInArray = this.formService.structureFormArray.controls.findIndex(
      ({ value }) => value.key === nextElement?.value?.key && value?.label === nextElement?.value?.label,
    );
    if (nextElementIndexInArray < 0) {
      return;
    }
    moveItemInFormArray(this.formService.structureFormArray, index, nextElementIndexInArray);
    this.selectedTab.setValue(nextElementIndexInArray, { emitEvent: true });
  }

  reorderBackward(index: number) {
    const prevElement: FormGroup = this.formService.structureFormArray.controls?.[index - 1];
    const prevElementIndexInArray = this.formService.structureFormArray.controls.findIndex(
      ({ value }) => value.key === prevElement?.value?.key && value?.label === prevElement?.value?.label,
    );

    if (prevElementIndexInArray < 0) {
      return;
    }
    moveItemInFormArray(this.formService.structureFormArray, index, prevElementIndexInArray);
    this.selectedTab.setValue(prevElementIndexInArray, { emitEvent: true });
  }

  save() {
    this.formService.save();
  }

  addNewTab() {
    this.formService.addNewTab(this.name ?? '');
    this.goToActiveTab();
    this.formService.form.markAsTouched();
    this.name = undefined;
  }

  openEmailSettings() {
    this.dialog.open(EmailSettingsComponent);
  }
}
