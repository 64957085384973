import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { API_CLIENT_ID_PROVIDER, API_CONFIG, ApiConfig, ClientIdProvider } from '../config-token';
import { first, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isApplicableUrl } from './helper/applicable-url';
import { publicPrefixes } from './config/public-prefixes';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  protected env = inject<ApiConfig>(API_CONFIG);
  protected clientIdProvider = inject<ClientIdProvider>(API_CLIENT_ID_PROVIDER);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!isApplicableUrl(this.env.apiUrl, request.url, publicPrefixes)) {
      return next.handle(request.clone());
    }

    const clientId = this.clientIdProvider.clientIdDefinite$;

    return clientId.pipe(
      first(),
      mergeMap((clientId) => {
        let headers = request.headers;

        if (!headers.get('vida-client-id')) {
          headers = headers.append('vida-client-id', clientId);
        }

        const newReq = request.clone({ headers });

        return next.handle(newReq);
      }),
    );
  }
}
