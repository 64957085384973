import { Pipe, PipeTransform } from '@angular/core';

export type STATUS_COLOR_MAP = 'success' | 'default' | 'alert';

// todo this should be moved to translations!
// todo this looks like domain logic, should be moved out of shared lib.
@Pipe({
  name: 'statusColor',
})
export class SetStatusColor implements PipeTransform {
  transform = SetStatusColor.transform;

  static transform(value: string): STATUS_COLOR_MAP {
    switch (value.toLowerCase()) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'alert';
      default:
        return 'default';
    }
  }
}
