import { BehaviorSubject, combineLatest, Observable, Subject, map, shareReplay, tap } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { SortBy, SortDirection } from '../models/sort/sort.model';
import { filter } from './helpers/filter';
import { sort } from './helpers/sort';
import { FilterableDataSource } from './contracts/filterable.ds';
import { PageableDataSource } from './contracts/pageable.ds';
import { SortableDataSource } from './contracts/sortable.ds';
import { reduce } from './helpers/reduce';
import { ConnectableDataSource } from './contracts/connectable.ds';
import { DataSourceConfig, PAGE_SIZE_OPTIONS } from './config-datasource';

export class LocalDataSource<T>
  implements PageableDataSource, SortableDataSource, FilterableDataSource, ConnectableDataSource<T>
{
  static INITIAL_FILTER = '';

  private filteredValue = LocalDataSource.INITIAL_FILTER;
  private filteredFields$ = new BehaviorSubject<string[]>([]);

  resultsCounter$ = new Subject<number>();
  total$ = new BehaviorSubject(0);
  pageIndex$ = new BehaviorSubject(0);
  pageSize$!: BehaviorSubject<number>;
  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;
  sortDirection$ = new BehaviorSubject<SortDirection>('');
  sortBy$ = new BehaviorSubject<SortBy>(null);
  private defaultConfig: DataSourceConfig;

  public allData$ = new BehaviorSubject<T[]>([]);

  constructor(initialData: Observable<T[]> | T[], defaultConfig?: Partial<DataSourceConfig>) {
    if (initialData instanceof Observable) {
      initialData.subscribe((data) => this.allData$.next(data));
    } else {
      this.allData$.next(initialData);
    }

    this.defaultConfig = {
      defaultPageSize: 12,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      ...defaultConfig,
    };

    this.pageSize$ = new BehaviorSubject(this.defaultConfig.defaultPageSize);
    this.pageSizeOptions = this.defaultConfig.pageSizeOptions;

    this.connection$ = combineLatest([
      this.allData$,
      this.pageIndex$,
      this.pageSize$,
      this.sortDirection$,
      this.sortBy$,
      this.filteredFields$,
    ]).pipe(
      tap(([items]) => this.resultsCounter$.next(items.length)),
      map(([items, pageIndex, pageSize, sortDirection, sortBy, filteredFields]) => ({
        items: items.map((item) => ({
          ...item,
          // todo 9550 add type
          reduced: reduce(item as unknown as any, filteredFields),
        })),
        pageIndex,
        pageSize,
        sortDirection,
        sortBy,
      })),
      map((streams) => ({
        ...streams,
        items: filter(streams.items, this.filteredValue),
      })),
      map((streams) => ({
        ...streams,
        items: sort(streams.items, streams.sortBy, streams.sortDirection),
      })),
      tap(({ items }) => this.total$.next(items.length)),
      map(({ items, pageIndex, pageSize }) => items.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)),
      shareReplay(1),
    );
  }

  connection$!: Observable<T[]>;

  pageChange($event: PageEvent) {
    this.pageIndex$.next($event.pageIndex);
    this.pageSize$.next($event.pageSize);
  }

  sortChange($event: { active: string; direction: SortDirection }) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
  }

  applyFilter(value: string, fields?: string[]) {
    this.filteredValue = value || LocalDataSource.INITIAL_FILTER;
    if (fields?.length) this.filteredFields$.next(fields);
    this.pageIndex$.next(0);
  }

  clearFilters() {
    this.filteredValue = LocalDataSource.INITIAL_FILTER;
    this.filteredFields$.next([]);
    this.pageIndex$.next(0);
  }

  updateData(newData: T[]) {
    this.allData$.next(newData);
  }

  protected close() {
    const complete = (subject: Subject<unknown>) => subject.complete();
    const subjects: Subject<unknown>[] = [
      this.total$,
      this.pageIndex$,
      this.pageSize$,
      this.sortDirection$,
      this.sortBy$,
      this.allData$,
    ] as Subject<unknown>[];

    subjects.forEach(complete);
  }
}
