import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WithPermissions } from '@vdms-hq/activated-client';
import { ResultsActions } from '@vdms-hq/asset-results';
import { CartDataSource } from '../../logic/data-sources/cart-data-source';
import { Selection } from '@vdms-hq/shared';
import { CartStateService } from '@vdms-hq/cart-core';

@Component({
  selector: 'vdms-hq-cart-results-actions',
  templateUrl: './cart-results-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartResultsActionsComponent<T> extends WithPermissions() {
  ResultsActions = ResultsActions;
  @Input({ required: true }) selectionManager!: Selection<T>;
  @Input() cartButtonDisabled = false;
  @Output() actions: EventEmitter<ResultsActions> = new EventEmitter();

  constructor(
    public cartDataSource: CartDataSource,
    public cartState: CartStateService,
  ) {
    super();
  }
}
