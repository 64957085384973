<div class="chart-wrapper">
  <canvas
    baseChart
    [type]="statsChartVM.type"
    [ngStyle]="statsChartVM.styles"
    [data]="statsChartVM.data"
    [options]="statsChartVM.options"
  >
  </canvas>
</div>
