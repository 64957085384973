<div class="grid grid-upto2 grid-gap-x" [formGroup]="innerFormControl">
  <mat-form-field
    class="material-custom-field"
    appearance="outline"
    [class.hide-footer]="!withFooter"
    [class.with-touched-indicator]="withTouchedIndicator"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      formControlName="date"
      [required]="isRequired"
      [min]="minDate"
      [max]="maxDate"
    />

    <mat-datepicker-toggle matSuffix [for]="picker" [disableRipple]="true"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="withFooter">
      <span *ngFor="let error of errors">{{ error.transLang | translate: error.contextParams }}</span>
    </mat-error>
    <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
  </mat-form-field>

  <vdms-hq-ui-form-input-text
    [formControl]="timeFormControl"
    [autocompleteList]="timeAutocompleteList"
    [attr.aria-label]="timeLabel | translate"
    [label]="timeLabel | translate"
    [mask]="'Hh:m0'"
    [fallbackSuffixIcon]="'schedule'"
    (clickAutocompleteItem)="updateDate($event)"
    [autocompleteIncludeNull]="autocompleteIncludeNull"
    [withTouchedIndicator]="withTouchedIndicator"
  >
  </vdms-hq-ui-form-input-text>
</div>
