import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsChartViewModelV2 } from '../logic/models/stats-chart-view.model';
import { NgChartsModule } from 'ng2-charts';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'vdms-hq-ui-stats-chart-v2',
  templateUrl: './stats-chart-v2.component.html',
  styleUrls: ['./stats-chart-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgChartsModule, NgStyle],
  standalone: true,
})
export class StatsChartV2Component {
  @Input({ required: true }) statsChartVM!: StatsChartViewModelV2;
}
