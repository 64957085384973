import { Injectable, inject } from '@angular/core';
import { PolicyService, Policy, PermissionModel, UserApiService, UserModel } from '@vdms-hq/api-contract';
import {
  Subject,
  startWith,
  tap,
  switchMap,
  combineLatest,
  shareReplay,
  map,
  Observable,
  debounceTime,
  merge,
  endWith,
} from 'rxjs';
import { LoadableDataSource, RefreshService, Loadable, SelectOption } from '@vdms-hq/shared';
import { FormControl } from '@angular/forms';
import videojs from 'video.js';
import log = videojs.log;

@Injectable({ providedIn: 'root' })
export class UserAdminDialogDataSource extends Loadable() implements LoadableDataSource {
  private policyService = inject(PolicyService);
  private userAPIService = inject(UserApiService);
  private refreshService = inject(RefreshService);

  filter = new FormControl('');
  userPolicies = new FormControl<string[]>([], { nonNullable: true });
  templateUser = new FormControl('');
  templateUserSearch = new FormControl('', { nonNullable: true });

  #refresh$ = new Subject<void>();
  refresh$ = merge([this.refreshService.refresh$, this.#refresh$]);
  #filter$ = this.filter.valueChanges.pipe(startWith(''));

  allData$: Observable<{
    policies: Policy[];
    users: UserModel[];
  }> = combineLatest([
    this.refresh$.pipe(startWith(null)),
    this.templateUserSearch.valueChanges.pipe(startWith(''), debounceTime(200)),
  ]).pipe(
    tap(() => this.startLoading()),
    switchMap(([, userSearch]) => {
      const perPage = 100;

      return combineLatest({
        policies: this.policyService.getPolicies(),
        users: this.userAPIService
          .getUsers({ page: '0', perPage: perPage.toString(), text: userSearch }, 'user.displayName', 'asc')
          .pipe(map(({ data }) => data.map((user) => user))),
      });
    }),
    map(({ policies, users }) => {
      return {
        policies,
        users,
      };
    }),
    tap(() => this.stopLoading()),
    shareReplay(1),
  );

  policiesList$: Observable<Policy[]> = combineLatest([
    this.allData$,
    this.#filter$.pipe(debounceTime(200)),
    this.refresh$.pipe(map(() => true)),
  ]).pipe(
    map(([{ policies }, filter, refresh]) => {
      return filter ? policies.filter((policy) => policy.name.toLowerCase().includes(filter.toLowerCase())) : policies;
    }),
    shareReplay(1),
  );

  policiesOptions$: Observable<SelectOption[]> = this.policiesList$.pipe(
    map((policies) => policies.map((policy) => ({ key: policy.uuid, label: policy.name }))),
  );

  usersList$ = this.allData$.pipe(map(({ users }) => users));

  usersSelectOptions$ = this.usersList$.pipe(
    map((users) => {
      const usersOptions = users.map((user) => ({
        key: user.uuid,
        label: user.name,
      }));

      usersOptions.unshift({ key: '', label: 'None' });

      return usersOptions;
    }),
  );

  permissionsList$: Observable<{ [label: string]: PermissionModel[] }> = combineLatest([
    this.policiesList$,
    this.userPolicies.valueChanges,
  ]).pipe(
    map(([policies, userPolicies]) => policies.filter((policy) => userPolicies.includes(policy.uuid))),
    map((selectedPolicies) => {
      return selectedPolicies.reduce(
        (acc, policy) => {
          if (!policy.permissions) {
            return acc;
          }
          policy.permissions.forEach((permission) => {
            if (!acc[permission.label]) {
              acc[permission.label] = [];
            }
            acc[permission.label].push(permission);
          });
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
    map((permissions) => {
      return Object.entries(permissions).reduce(
        (acc, [label, permissions]) => {
          acc[label] = permissions.filter((permission, index, self) => {
            return index === self.findIndex((p) => p.uuid === permission.uuid);
          });
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
    map((permissions) => {
      return Object.entries(permissions).reduce(
        (acc, [label, permissions]) => {
          acc[label] = permissions.sort((a, b) => a.name.localeCompare(b.name));
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
    endWith({} as { [label: string]: PermissionModel[] }),
  );

  permissionsListTotal$ = this.permissionsList$.pipe(
    map((permissions) => Object.values(permissions).reduce((acc, permissions) => acc + permissions.length, 0)),
    startWith(0),
  );

  connection$ = this.allData$;

  refresh() {
    this.#refresh$.next();
  }

  emitSelectedPolicies(policies: Policy[]) {
    this.userPolicies.setValue(policies.map((policy) => policy.uuid));
  }

  reset() {
    this.userPolicies.setValue([]);
    this.templateUser.setValue('');
    this.filter.setValue('');
  }

  searchUsers(search: string) {
    this.templateUserSearch.setValue(search);
    this.refresh();
  }
}
