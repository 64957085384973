import { APP_INITIALIZER, Provider } from '@angular/core';
import { initCommon } from './initializer';
import { ThemeSwitcherService } from './logic/services/theme-switcher.service';

export const configureTheming = (): Provider[] => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initCommon,
      deps: [ThemeSwitcherService],
      multi: true,
    },
  ];
};
