import { CartValidationError, ORDER_TYPE } from '@vdms-hq/api-contract';
import { ValidationErrorsOutput } from './dd-cart-errors-checks';
import { ValidationErrorList } from '../components/order-dialog/order-dialog.component';

export const cartErrorsChecks = (
  validationResponse: CartValidationError<ORDER_TYPE>[],
  canUseQuarantined = false,
): ValidationErrorsOutput => {
  let validationErrorList: ValidationErrorList[] = [];
  let errorUuids: string[] = [];

  let hasError = validationResponse.filter(({ type }) => type === 'ERR');
  if (canUseQuarantined) {
    hasError = hasError.filter(({ note }) => !note.includes('quarantined'));
  }
  if (hasError?.length) {
    let allErrorUuids: string[] = [];
    const errorMessages: ValidationErrorList[] = hasError.map((errMessage) => {
      allErrorUuids = [...allErrorUuids, ...(errMessage.cartItemUuids ?? [])];
      return {
        type: 'error',
        subType: errMessage.subType ?? undefined,
        message: errMessage.note,
        assetUuid: errMessage.assetUuid,
        cartItemUuids: errMessage.cartItemUuids,
        assetOriginalFilename: errMessage.assetOriginalFilename,
      };
    });
    errorUuids = [...new Set(allErrorUuids)];
    validationErrorList = [...errorMessages];
  }

  const hasWarning = validationResponse.filter(({ type }) => type === 'WARN');
  if (hasWarning?.length) {
    const warningMessages: ValidationErrorList[] = hasWarning.map((errMessage) => {
      return {
        type: 'warning',
        subType: errMessage.subType ?? undefined,
        message: errMessage.note,
        assetUuid: errMessage.assetUuid,
        cartItemUuids: errMessage.cartItemUuids,
        assetOriginalFilename: errMessage.assetOriginalFilename,
      };
    });
    validationErrorList = [...validationErrorList, ...warningMessages];
  }

  return {
    validationErrorList,
    errorUuids,
  };
};
