<vdms-hq-ui-dialog-wrapper size="wide">
  <ng-container title>
    {{ 'common.global.transcode_new_assets.modal_title' | translate }}
  </ng-container>

  <ng-container content>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let destination of data.asset.deliveryDestinations; first as isFirst"
        [expanded]="isFirst"
        #destinationPanel
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="destination-title" [style.max-width]="!destinationPanel.expanded ? '150px' : 'unset'">
              {{ destination.name }}
            </p>
          </mat-panel-title>
          <mat-panel-description *ngIf="!destinationPanel.expanded">
            <div class="destination-description">
              <span>{{ destination.configs.length }} {{ destination.configs.length > 1 ? 'configs' : 'config' }}</span>
              <span>{{ destination.summary }}</span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="destination-wrapper">
          <div class="config-header">
            <span>Config</span>
            <span>New assets</span>
          </div>
          <div class="destination-config" *ngFor="let config of destination.configs">
            <div class="config-name">
              {{ config.name }}
            </div>
            <div class="config-name" *ngFor="let job of config.jobs">
              <ng-container *ngFor="let assetUuid of job.extra?.newAssetUuids">
                <div class="pill clickable" *ngIf="assetUuid" (click)="goToAssetDetails(assetUuid)">
                  {{ assetUuid }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
