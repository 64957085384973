<mat-autocomplete #auto="matAutocomplete">
  @for (option of autocompleteOptions; track option.key) {
    <mat-option class="selectable-list-autocomplete-option" [value]="option.label" (click)="selectOption.emit(option)">
      <div class="autocomplete-option">
        <div class="autocomplete-option__metadata">
          <ng-container *ngIf="option.text">
            <span
              ><span class="autocomplete-option__label">Keywords</span>:
              <span class="value">{{ option.text | truncate: 250 }}</span></span
            >
          </ng-container>
          <ng-container *ngFor="let entry of option.filters">
            <span class="" *ngFor="let value of entry.value.value | toIterable; index as idx">
              <span class="autocomplete-option__label">{{ entry.value | appliedFilterLabel: idx }}: </span>
              <span
                class="value"
                *ngIf="value | appliedFilterFormat: entry.value.filterDef | async; let value; else: emptyValue"
                >{{ value }}</span
              >
            </span>
          </ng-container>
          <div *ngIf="option.extraMetadata" class="more">+{{ option.extraMetadata }}</div>
        </div>
      </div>
      <vdms-hq-ui-button
        class="delete-button"
        [iconOnly]="true"
        (click)="removeOption.emit(option); $event.stopPropagation()"
        color="transparent"
        icon="delete"
      ></vdms-hq-ui-button>
    </mat-option>
  }
</mat-autocomplete>

<ng-template #emptyValue>
  <span>[ empty ]</span>
</ng-template>
