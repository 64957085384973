import { Client } from '../client/client.model';
import { PermissionModel } from './permission.model';
import { VidaAppTypes } from '../client-user-admin/client-user-admin.model';
import { SimpleType } from '../types.model';

export interface Policy {
  uuid: string;
  name: string;
  usersAssigned: number;
  groups?: Client[];
  permissions?: PermissionModel[];
  isSuperAdmin?: boolean;
  isCrossGroup?: boolean;
  restricted?: boolean;
}

export interface PolicySelectOption {
  uuid?: string;
  name?: string;
  isCrossGroup?: boolean;
  isSuperAdmin?: boolean;
  key: string | null;
  label: string;
}

export interface PolicyAttachableData {
  attach?: string[];
  detach?: string[];
}

export interface PolicyPatchRequest {
  name?: string;
  is_restricted?: boolean;
  groups?: PolicyAttachableData;
  permissions?: PolicyAttachableData;
  users?: PolicyAttachableData;
}

export interface PolicyPostRequest extends PolicyPatchRequest {
  uuid?: string;
}

export interface PolicyResponse {
  status: string;
  data: string[];
}

export interface PolicyLog {
  user: string;
  message: string;
  base_object?: string;
  changeset?: string;
  date: string;
  data: PolicyLogData;
}

export interface PolicyLogData {
  name: {
    old: string;
    new: string;
  };
  groups: {
    attached: SimpleType[];
    detached: SimpleType[];
  };
  permissions: {
    attached: SimpleType[];
    detached: SimpleType[];
  };
  users: {
    attached: SimpleType[];
    detached: SimpleType[];
  };
}

export const SHARING_SHARED_PACK_POLICY_TYPE = 'sharing_shared_pack';
export const SHARING_COLLECTION_POLICY_TYPE = 'sharing_collection';
export const GroupDefaultPolicyTypes = [
  SHARING_SHARED_PACK_POLICY_TYPE,
  SHARING_COLLECTION_POLICY_TYPE,
  ...VidaAppTypes,
] as const;
export type GroupDefaultPolicyType = (typeof GroupDefaultPolicyTypes)[number];
export type DefaultPolicies = {
  [key in GroupDefaultPolicyType]?: string;
};
