import { Injectable } from '@angular/core';
import { FilterableDataSource, LoadableDataSource, PageableDataSource, SelectionManager } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, debounceTime, Observable, switchMap, tap, map } from 'rxjs';
import { UserApiService, UserModel } from '@vdms-hq/api-contract';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { TableAdvancedDataSource } from '@vdms-hq/ui';

@Injectable({ providedIn: 'root' })
export class UsersDatasourceService
  extends RouterParamsPagination
  implements TableAdvancedDataSource<UserModel>, LoadableDataSource, PageableDataSource, FilterableDataSource
{
  text$ = new BehaviorSubject<string>('');
  connection$!: Observable<UserModel[]>;
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  selection: SelectionManager<UserModel> = new SelectionManager<UserModel>(this, (item) => item.uuid);

  allData$: Observable<UserModel[]> = combineLatest([this.pageIndex$, this.pageSize$, this.text$]).pipe(
    debounceTime(500),
    tap(() => {
      this.isLoading$.next(true);
      this.selection.clear();
    }),
    switchMap(([pageIndex, pageSize, text]) =>
      this.userApiService.getUsers({ text, perPage: pageSize.toString(), page: pageIndex.toString() }),
    ),
    tap((response) => {
      this.isLoading$.next(false);
      !response && this.emptyResults$.next(true);
      this.total$.next(response?.total ?? 0);
    }),
    map((response) =>
      response.data.map((user) => ({
        ...user,
        groupsNames: (user.groups ?? []).map((group) => group.name),
      })),
    ),
  );

  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private readonly userApiService: UserApiService) {
    super();
    this.connection$ = this.allData$;
    this.selection = new SelectionManager<UserModel>(this, (item) => item.uuid);
  }

  applyFilter(value: string, fields?: string[]) {
    if (value?.length < 3 && value?.length !== 0) {
      return;
    } else if (value?.length === 0) {
      this.text$.next('');
    } else {
      this.text$.next(value);
    }
    this.changePageIndex$.next(0);
  }
}
