import { inject, Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { map } from 'rxjs/operators';
import { MetadataRecognitionService } from '@vdms-hq/metadata-recognition';
import { AssetTypeEnum } from '@vdms-hq/api-contract';

@Injectable({ providedIn: 'root' })
export class DeliveryDestinationFilenameConventionsService {
  private metadataRecognitionService = inject(MetadataRecognitionService);

  conventions$ = this.metadataRecognitionService.filenameConventions$;
  conventionsSelectOptions$: Observable<SelectOption[]> = this.conventions$.pipe(
    map((conventions) =>
      conventions.map((convention) => ({ key: convention.uuid, label: convention.name }) as SelectOption),
    ),
    map((conventions) => [{ key: null, label: 'None' }, ...conventions]),
    shareReplay(1),
  );

  assetTypeKeys = Object.values(AssetTypeEnum);
}
