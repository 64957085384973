export interface AssetSearchFilterParam {
  text?: string;
  filters?: AssetSearchFilters;
  advanced_mode?: boolean;
}

export interface AssetSearchFilterParamWithPageOptions extends AssetSearchFilterParam {
  filters: AssetSearchFilters & { limit: number; sort_by: string; sort_order: string };
}

export interface AssetSearchExportFieldsParam extends AssetSearchFilterParam {
  fields:
    | {
        value: string;
        label: string;
      }[]
    | string[];
}

export interface AssetSearchRange {
  from?: number;
  to?: number;
}

export interface AssetSearchTimecodeRange {
  from?: string;
  to?: string;
}

export interface AssetSearchDateRange {
  from?: Date;
  to?: Date;
}

export type ApiRanges = AssetSearchRange | AssetSearchDateRange;

export interface AssetSearchFilters {
  advanced_mode?: boolean;
  aspect_ratio?: string[];
  bitrate?: AssetSearchRange;
  category?: string[];
  client_id?: string[];
  client_ref?: string[];
  collection_uuid?: string[];
  content_class?: string[];
  content_class_id?: string[];
  content_type?: string[];
  description?: string[];
  disposition?: string[];
  duration?: AssetSearchRange;
  elements?: string[];
  episode_name?: string[];
  episode_number?: number[];
  facility_of_origin?: string[];
  licensed_package_uuids?: string[];
  filename?: string[];
  framerate?: string[];
  ingest_date?: AssetSearchDateRange;
  is_dirty?: boolean;
  is_preview_app_enabled?: boolean;
  is_virtual?: boolean;
  language_uuid?: string[];
  library_sales_rights?: boolean;
  note?: string[];
  object_id?: string[];
  orphan_filter?: string;
  production_company?: string[];
  production_no?: string[];
  programme_number?: string[];
  release_year?: number[];
  resolution?: string[];
  season_title?: string[];
  segments?: string[];
  series_name?: string[];
  series_number?: number[];
  size?: AssetSearchRange;
  stage?: string[];
  synopsis?: string[];
  tape_no?: string[];
  theme?: string[];
  theme_code?: string[];
  tx_date?: AssetSearchDateRange;
  type?: string[];
  uuid?: string[];
  variation?: string[];
  version?: string[];
  vida_number?: string[];
  video_codec?: string[];
  asset_status?: boolean;
  text?: string;
}

export const isApiTimecodeRange = (obj: any) => {
  return obj && typeof obj === 'object' && ('from' in obj || 'to' in obj) && (obj as AssetSearchTimecodeRange);
};

export const isApiNumberRange = (obj: any) => {
  return obj && typeof obj === 'object' && ('from' in obj || 'to' in obj) && (obj as AssetSearchRange);
};

export const isApiDateRange = (obj: any) => {
  return obj && typeof obj === 'object' && ('from' in obj || 'to' in obj) && (obj as AssetSearchDateRange);
};
