<vdms-hq-ui-dialog-wrapper *ngIf="(dataSource.isLoading$ | async) === false; else customLoader">
  <div title>
    <h1>{{ 'common.metadata_recognition.dialog.title' | translate }}</h1>
    <h3 class="subtitle">
      {{ 'common.metadata_recognition.dialog.subtitle' | translate }}
    </h3>
  </div>
  <div content [formGroup]="form">
    <ng-container *ngIf="dataSource" formArrayName="metadata">
      <table mat-table [dataSource]="(dataSource.connection$ | async) ?? []">
        <ng-container [matColumnDef]="'select'">
          <th mat-header-cell *matHeaderCellDef>
            <vdms-hq-ui-form-input-checkbox
              [formControl]="selectAll"
              [withFooter]="false"
            ></vdms-hq-ui-form-input-checkbox>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <vdms-hq-ui-form-input-checkbox
              [formControl]="
                $castToControl(
                  formEntitiesArray.controls[
                    i + ((dataSource.pageIndex$ | async) ?? 0) * ((dataSource.pageSize$ | async) ?? 0)
                  ]
                )
              "
              [withFooter]="false"
            ></vdms-hq-ui-form-input-checkbox>
          </td>
        </ng-container>
        <ng-container *ngFor="let col of columns; let colIndex = index">
          <ng-container *ngIf="col !== 'select'">
            <ng-container [matColumnDef]="col">
              <th mat-header-cell *matHeaderCellDef>{{ col | keyToTitle }}</th>
              <td
                mat-cell
                *matCellDef="let element; let rowIndex = index"
                [formGroupName]="
                  rowIndex + ((dataSource.pageIndex$ | async) ?? 0) * ((dataSource.pageSize$ | async) ?? 0)
                "
              >
                <ng-container *ngIf="element; else emptyRow">
                  <ng-container *ngIf="readonlyColumns.includes(col); else selectCell">
                    <span
                      class="readonly-cell"
                      [matTooltip]="$castToString($castToMetadataModel(element).props[$castToMetadataModelKey(col)])"
                      >{{ $castToMetadataModel(element).props[$castToMetadataModelKey(col)] }}</span
                    >
                  </ng-container>
                  <ng-template #selectCell>
                    <ng-container
                      *ngIf="
                        selectDataSources[$castToMetadataModel(element).props.uuid] &&
                          selectDataSources[$castToMetadataModel(element).props.uuid][col]?.length;
                        else emptyRow
                      "
                    >
                      <vdms-hq-ui-form-input-select
                        [label]="col | keyToTitle"
                        [selectOptions]="selectDataSources[$castToMetadataModel(element).props.uuid][col]"
                        [formControlName]="col"
                        [withFooter]="false"
                      ></vdms-hq-ui-form-input-select>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>

      <vdms-hq-ui-paginator [dataSource]="dataSource"></vdms-hq-ui-paginator>
    </ng-container>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="skip()" color="secondary"
      >{{ 'common.metadata_recognition.buttons.skip' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="restartRecognition()"
      [loading]="(saveInProgress$ | async) ?? false"
      [disabled]="((selectedEntities$ | async) ?? 0) === 0"
      color="primary"
      >{{
        ('common.metadata_recognition.buttons.restart_recognition' | translate) +
          ' (' +
          ((selectedEntities$ | async) ?? 0) +
          ')'
      }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="save()"
      [loading]="(saveInProgress$ | async) ?? false"
      [disabled]="((selectedEntities$ | async) ?? 0) === 0"
      color="primary"
      >{{
        ('common.metadata_recognition.buttons.save_selected' | translate) +
          ' (' +
          ((selectedEntities$ | async) ?? 0) +
          ')'
      }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #customLoader>
  <div class="wrapper">
    <h2>Metadata recognition for uploaded files</h2>
    <p>Your files are currently undergoing analysis to extract metadata.</p>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </div>
</ng-template>

<ng-template #emptyRow><span class="not-available-cell">N/A</span></ng-template>
