import { Injectable } from '@angular/core';
import { BehaviorSubject, throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBarVisibilityService {
  #searchBarVisibility$ = new BehaviorSubject<boolean>(true);
  searchBarVisibility$ = this.#searchBarVisibility$.pipe(throttleTime(0));

  show = () => this.#searchBarVisibility$.next(true);

  hide = () => this.#searchBarVisibility$.next(false);
}
