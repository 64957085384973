<vdms-hq-ui-dialog-wrapper [size]="'narrow'">
  <div title>
    <h1>{{ 'filename-conventions.help-dialog.title' | translate }}</h1>
  </div>
  <div content>
    <h2>Notable Fields</h2>
    <p>The following metadata fields are available for use in the patterns:</p>
    <ul class="list fields">
      <li><span>episodeName</span></li>
      <li><span>episodeNumber</span></li>
      <li><span>seriesName</span></li>
      <li><span>seriesNumber</span></li>
      <li><span>programmeNumber</span></li>
      <li><span>facilityOfOrigin</span></li>
      <li><span>language</span></li>
      <li><span>elements</span></li>
      <li><span>segments</span></li>
      <li><span>genre</span></li>
      <li><span>productionCompany</span></li>
      <li><span>contentClass</span></li>
      <li><span>contentType</span></li>
      <li><span>theme</span></li>
      <li><span>variation</span></li>
      <li><span>category</span></li>
      <li><span>seasonTitle</span></li>
      <li><span>releaseYear</span></li>
      <li><span>resolution</span></li>
      <li><span>codec</span></li>
      <li><span>orderPackageTitle</span></li>
      <li><span>orderNo</span></li>
      <li><span>audioLayout</span></li>
      <li><span>audioLanguage</span></li>
    </ul>

    <h2>String Processors</h2>
    <p>The string processors are used to extract metadata from the filename.</p>
    <p>The following string processors are available:</p>
    <div class="list columns-2">
      <ul class="list">
        <li><span>trim</span> - Remove whitespace from both ends</li>
        <li><span>trimStart</span> - Remove whitespace from the start</li>
        <li><span>trimEnd</span> - Remove whitespace from the end</li>
        <li><span>toLowerCase</span> - Change case to lowercase</li>
        <li><span>toUpperCase</span> - Change case to UPPERCASE</li>
        <li><span>titleCase</span> - Change case to Title Case</li>
        <li><span>camelCase</span> - Change case to camelCase</li>
        <li><span>pascalCase</span> - Change case to PascalCase</li>
        <li><span>snakeCase</span> - Change case to snake_case</li>
        <li><span>kebabCase</span> - Change case to kebab-case</li>
        <li><span>noSpace</span> - Remove all spaces</li>
        <li><span>toUnderscore</span> - Replace spaces with underscores</li>
        <li><span>toHyphen</span> - Replace spaces with hyphens</li>
      </ul>
      <ul class="list">
        <li><span>removeDots</span> - Remove dots</li>
        <li><span>fileSafe</span> - Allow only safe file system characters</li>
        <li>
          <span>padZero</span> - Pad zeros to the front of a number to ensure it has `n` digits; e.g., padZero(3) gives
          "002"
        </li>
        <li>
          <span>left</span> - Take the first `n` characters from the string; e.g., left(5) change "my-test-input" to
          "my-tes"
        </li>
        <li>
          <span>right</span> - Take the last `n` characters from the string; e.g., right(5) change "my-test-input" to
          "input"
        </li>
        <li>
          <span>regexMatch</span> - Keep only parts of the string that match a given regex pattern; e.g.,
          regexMatch(/[a-z]/) would leave only small letters from a to z.
        </li>
        <li>
          <span>regexRemove</span> - Remove parts of the string that match a given regex pattern; e.g.,
          regexRemove(/[^\d]/) would remove anything that is not a digit.
        </li>
      </ul>
    </div>

    <h2>Example</h2>
    <p>
      If you have a filename like <strong>Series 1 Episode 1</strong> and you want to extract the episode number, you
      can use the following pattern:
    </p>
    <p>
      <code>
        <strong>Series &#123;seriesNumber&#125; Episode &#123;episodeNumber&#125;</strong>
      </code>
    </p>
    <p>
      If you are about to extract an episode name from a file like <strong>some episode name</strong> and wish to change
      the casing, use for example the <strong>titlecase</strong> string processor:
    </p>
    <p>
      <code><strong>&#123;episodeName[titleCase]&#125;</strong></code>
    </p>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="ref.close()"
      >{{ 'common.dialog.close' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
