import { Filters, FilterValue } from './filters.model';
import { clone, omit } from 'lodash';
import { FieldConfigId } from '@vdms-hq/shared';

export class PersistenceSearchQuery {
  readonly keyValues: Record<FieldConfigId, FilterValue>;
  readonly hiddenKeyValues: Record<FieldConfigId, FilterValue>;

  constructor(
    public text?: string,
    public filters: Filters = {},
    public readonly hiddenFilters: Filters = {},
  ) {
    if (filters['text']) {
      if (!text) {
        this.text = filters['text'].value as string;
      }
      this.filters = omit(filters, 'text');
    }

    const filterKeyValues: Record<FieldConfigId, FilterValue> = {};
    const hiddenKeyValues: Record<FieldConfigId, FilterValue> = {};

    Object.entries(filters).forEach(([key, filter]) => (filterKeyValues[key] = filter.value));
    Object.entries(hiddenFilters).forEach(([key, filter]) => (hiddenKeyValues[key] = filter.value));

    this.keyValues = filterKeyValues;
    this.hiddenKeyValues = hiddenKeyValues;
  }

  get allKeyValues() {
    return {
      text: this.text,
      ...this.keyValues,
      ...this.hiddenKeyValues,
    };
  }

  static empty() {
    return new PersistenceSearchQuery();
  }

  static freeText(text: string) {
    return new PersistenceSearchQuery(text);
  }

  static fromFilters(text?: string, filters?: Filters, hiddenFilters?: Filters) {
    return new PersistenceSearchQuery(text, filters, hiddenFilters);
  }

  get(key: string) {
    return this.filters[key] ?? null;
  }

  isEqual = (next: PersistenceSearchQuery) => {
    return JSON.stringify(this.allKeyValues) === JSON.stringify(next.allKeyValues);
  };

  clone = () => clone(this);
}
