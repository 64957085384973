import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SourceProvider } from '../source-provider';
import { map, share, tap } from 'rxjs/operators';
import { SupplierService } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { SelectorSourceType } from '../selector-source.type';

@Injectable({
  providedIn: 'root',
})
export class SuppliersSourceService implements SourceProvider {
  #ratingsService = inject(SupplierService);
  #isLoadingSubject$ = new BehaviorSubject(true);
  isLoading$ = this.#isLoadingSubject$.asObservable();

  #all = this.#ratingsService
    .getSuppliers()
    .pipe(share())
    .pipe(
      tap(() => {
        this.#isLoadingSubject$.next(false);
      }),
    );

  supports(type: SelectorSourceType): boolean {
    return type?.includes(SelectorSourceType.SUPPLIERS) || false;
  }

  listByType(type: SelectorSourceType): Observable<SelectOption[]> {
    return this.#all.pipe(map((items) => items.map(({ uuid, name }) => ({ key: uuid, label: name }))));
  }
}
