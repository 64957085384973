import { Provider } from '@angular/core';
import { CLIENTS_CONFIG, ClientsFirebaseProjectIdConfig } from './lib/logic/clients-token';

export const configureClientsPage = (config: ClientsFirebaseProjectIdConfig): Provider[] => {
  return [
    {
      provide: CLIENTS_CONFIG,
      useValue: config,
    },
  ];
};
