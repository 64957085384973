import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MessageModel,
  MessageState,
  SafeMessageModel,
  TOAST_CONFIG,
  ToastConfig,
} from '../../logic/models/message.model';
import { EMPTY, map, Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastService } from '../../logic/services/toast.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vdms-hq-toast-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyIn', [
      transition(':enter', [
        style({
          transform: 'scale(0.8)',
          opacity: 0,
        }),
        animate(
          '150ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            transform: 'scale(1)',
            opacity: 1,
          }),
        ),
      ]),
    ]),
    trigger('flyOut', [
      transition(':leave', [
        style({
          transform: 'scale(1)',
          opacity: 1,
        }),
        animate(
          '150ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            transform: 'scale(0.8)',
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class MessagesComponent implements OnInit {
  messages$: Observable<SafeMessageModel[]> = EMPTY;

  trackByFn = (index: number, item: SafeMessageModel) => item?.id;

  constructor(
    @Inject(TOAST_CONFIG) public toastConfig: ToastConfig,
    public toastService: ToastService,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.messages$ = this.toastService.getMessages().pipe(
      map((message: MessageModel[]) =>
        message.map((msg) => {
          return {
            ...msg,
            message: this.domSanitizer.bypassSecurityTrustHtml(msg.message),
            additionalMessage: msg.additionalMessage
              ? this.domSanitizer.bypassSecurityTrustHtml(msg.additionalMessage)
              : undefined,
          };
        }),
      ),
    );
  }

  isProcessing(message: SafeMessageModel): boolean {
    return message.state === MessageState.processing;
  }
}
