import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import {
  CollectionCreateUploadResponse,
  CollectionsService,
  CollectionUploadConfirmationRequest,
} from '@vdms-hq/api-contract';
import { v4 as uuidv4 } from 'uuid';
import { catchError, map, take } from 'rxjs/operators';
import { CollectionUploadStatusEnum } from '../models/statuses-type.model';
import { S3SignatureUploadService } from '@vdms-hq/storage';

@Injectable({ providedIn: 'root' })
export class SingleCollectionImportService {
  constructor(
    private collectionsService: CollectionsService,
    private uploadService: S3SignatureUploadService,
  ) {}

  async prepareUpload(file: File): Promise<CollectionCreateUploadResponse> {
    return (await firstValueFrom(
      this.collectionsService.prepareUpload({
        file_type: file.type,
        filename: file.name,
        file_uuid: uuidv4(),
      }),
    )) as CollectionCreateUploadResponse;
  }

  async uploadToS3(file: File, fileMetadata: CollectionCreateUploadResponse) {
    return await firstValueFrom(
      this.uploadService.uploadSmallFileWithSignature(file, fileMetadata.data.upload_access).pipe(
        take(1),
        map(() => ({
          status: CollectionUploadStatusEnum.UPLOADED,
        })),
        catchError(() =>
          of({
            status: CollectionUploadStatusEnum.ERROR,
          }),
        ),
      ),
    );
  }

  async confirmUpload(uploadParams: CollectionUploadConfirmationRequest) {
    return await firstValueFrom(this.collectionsService.confirmUpload({ ...uploadParams }));
  }
}
