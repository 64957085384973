<div class="container-wrapper" [class]="{ 'info-container': showInfo, 'without-background': !showInfo, disabled }">
  @if (showInfo) {
    <mat-icon *ngIf="icon" [class]="'material-icons-outlined icon icon-color--' + iconColor">{{ icon }}</mat-icon>
    <div class="header">
      <span>{{ header | translate }}</span>
      <span *ngIf="subheader" class="subheader">{{ subheader | translate }}</span>
    </div>
  }
  <vdms-hq-ui-button [disabled]="disabled" [color]="buttonColor" [isSubmit]="false" (click)="buttonClick.emit()">
    <ng-content></ng-content>
  </vdms-hq-ui-button>
</div>
