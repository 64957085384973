import { AssetFlat } from '../../asset';
import { FilenameConventionDefaultFor } from '../../filename-convention';

export const AnalyzeMetadataJobStatuses = ['requested', 'analyzed', 'picked', 'saved'] as const;
export type AnalyzeMetadataJobStatus = (typeof AnalyzeMetadataJobStatuses)[number];
export const POSSIBLE_KEYS: string[] = [
  'select',
  'filename',
  'episodeName',
  'episodeNumber',
  'seriesName',
  'seriesNumber',
  'programmeNumber',
  'facilityOfOrigin',
  'language',
  'elements',
  'segments',
  'genre',
  'productionCompany',
  'contentClass',
  'contentType',
  'theme',
  'variation',
  'category',
  'seasonTitle',
  'releaseYear',
] as const;
export const POSSIBLE_CASINGS = ['camelCase', 'PascalCase', 'snake_case', 'kebab-case', 'UPPER_CASE'] as const;
export type PossibleKeyType = (typeof POSSIBLE_KEYS)[number];
export type PossibleCasingType = (typeof POSSIBLE_CASINGS)[number];
export interface AnalyzeMetadataJobInterface {
  uuid: string;
  assetUuid: string;
  deliveryPackUuid: string;
  filename: string;
  conventions: AnalyzeMetadataFilenameConvention[];
  requestedBy: string;
  matches?: AnalyzeMetadataMatchType[];
  pickedData?: AssetFlat;
  pickedBy?: string;
  pickedAt?: string;
  createdAt: string;
}

export const TRANSLATED_POSSIBLE_KEYS: Record<PossibleKeyType, string> = {
  select: 'select',
  filename: 'Filename',
  episodeName: 'Episode Name',
  episodeNumber: 'Episode Number',
  seriesName: 'Series Name',
  seriesNumber: 'Series Number',
  programmeNumber: 'Programme Number',
  facilityOfOrigin: 'Facility of Origin',
  language: 'Language',
  elements: 'Elements',
  segments: 'Segments',
  genre: 'Genre',
  productionCompany: 'Production Company',
  contentClass: 'Content Class',
  contentType: 'Content Type',
  theme: 'Theme',
  variation: 'Variation',
  category: 'Category',
  seasonTitle: 'Season Title',
  releaseYear: 'Release Year',
};

export interface AnalyzeMetadataFilenameConvention {
  uuid: string;
  defaultFor: FilenameConventionDefaultFor[];
}

/**
 * @deprecated Use AnalyzeMetadataFilenameConvention instead
 */
export interface AnalyzeMetadataInputConvention {
  id: number;
  key: string;
  separator: string;
}

export interface AnalyzeMetadataMatchType {
  conventionId: number;
  parts: AnalyzeMetadataResultType[];
}

export interface AnalyzeMetadataResultType extends AnalyzeMetadataConventionObjectType {
  notableField: string;
  value: string;
  score: number;
  match: string;
  percents: number;
}

export interface AnalyzeMetadataConventionObjectType {
  index: number;
  original: string;
  key: PossibleKeyType;
  casing: PossibleCasingType;
}

export interface AnalyzeMetadataRequestInput {
  conventions: SimpleAnalyzeMetadataJobInterface[];
}

export interface AnalyzeMetadataChoseInputData {
  jobUuid: string;
  pickedData: Partial<AssetFlat>;
}

export interface AnalyzeMetadataPickInput {
  data: AnalyzeMetadataChoseInputData[];
}

export interface MetadataRestartInput {
  jobUuids: string[];
  conventions: SimpleAnalyzeMetadataJobInterface[];
}

export interface SimpleAnalyzeMetadataJobInterface {
  uuid: string;
  defaultFor: string[];
}

export enum METADATA_API_PREFIX {
  ROOT = 'analyze-metadata',
}
