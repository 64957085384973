import { Injectable } from '@angular/core';
import { DestinationApiService, DestinationModel, UpdateDestinationModel } from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, Observable, switchMap, take, throwError, withLatestFrom } from 'rxjs';
import { DestroyComponent, filterEmpty } from '@vdms-hq/shared';
import { BreadCrumb, SidebarNestedItem } from '@vdms-hq/ui';
import { DELIVERY_DESTINATIONS_ROUTING } from '../delivery-destinations-routing';
import { map, startWith, tap } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { Router } from '@angular/router';

export type DeliveryDestinationView = DestinationModel;

@Injectable({ providedIn: 'root' })
export class DestinationDataSourceService extends DestroyComponent {
  #popToast = {
    LOAD_FAILURE: (reason = 'Not specified') =>
      this.toastService.error({
        id: 'create_field_failure',
        message: 'common.delivery_destinations.edit.load_failure',
        interpolatedParams: { reason },
      }),
  };

  currentId$ = new BehaviorSubject<string | null>(null);
  currentItem$ = new BehaviorSubject<DeliveryDestinationView | null>(null);
  currentConfigId$ = new BehaviorSubject<string | null>(null);

  loading$ = this.currentItem$.pipe(map((item) => !item));

  breadcrumbs$: Observable<BreadCrumb[]> = this.currentItem$.pipe(
    map((destination) => {
      return [
        {
          path: `/${DELIVERY_DESTINATIONS_ROUTING.ROOT}/`,
          name: 'common.delivery_destinations.title',
        },
        {
          name: destination?.name ?? '',
        },
      ];
    }),
    startWith([
      {
        path: `/${DELIVERY_DESTINATIONS_ROUTING.ROOT}/`,
        name: 'common.delivery_destinations.title',
      },
    ]),
  );

  navLinks$: Observable<SidebarNestedItem[]> = this.currentItem$.pipe(
    filterEmpty(),
    map((item) => [
      {
        name: 'common.delivery_destinations.fields.delivery_configs',
        children: [
          ...item.configs.map((config) => ({
            name: config.name,
            routerLink: config.uuid,
          })),
          {
            name: 'Add new',
            routerLink: 'add',
          },
        ],
      },
    ]),
    startWith([]),
  );

  constructor(
    private apiService: DestinationApiService,
    private toastService: ToastService,
    private router: Router,
  ) {
    super();
    this.currentItem$
      .pipe(this.takeUntilDestroyed(), withLatestFrom(this.currentConfigId$))
      .subscribe(([destination, config]) => {
        if (destination && destination.configs.length && !config) {
          this.router.navigate([
            '/',
            DELIVERY_DESTINATIONS_ROUTING.ROOT,
            destination.uuid,
            destination.configs[0].uuid,
          ]);
          this.currentConfigId$.next(destination.configs[0].uuid);
        }
      });
  }

  setId(id: string | null) {
    this.currentId$.next(id);
    this.currentItem$.next(null);

    if (!id) {
      return;
    }

    this.apiService
      .get(id)
      .pipe(
        catchError((err) => {
          this.#popToast.LOAD_FAILURE();

          return throwError(err);
        }),
      )
      .subscribe((destination) => {
        this.currentItem$.next(destination);
      });
  }

  update(destination: UpdateDestinationModel, id?: string): Observable<DestinationModel> {
    if (id) {
      return this.apiService.update(id, destination).pipe(
        tap((nextDestination) => {
          this.currentItem$.next(nextDestination);
        }),
      );
    }

    return this.currentId$.pipe(
      take(1),
      filterEmpty(),
      switchMap((id) => {
        return this.apiService.update(id, destination);
      }),
      tap((nextDestination) => {
        this.currentItem$.next(nextDestination);
      }),
    );
  }
}
