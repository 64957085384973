import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StatsWrapperComponent } from './stats-wrapper/stats-wrapper.component';
import { StatsChartLineComponent } from './stats-chart-line/stats-chart-line.component';
import { StatsStaticComponent } from './stats-static/stats-static.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgChartsModule } from 'ng2-charts';
import { StatsChartPieComponent } from './stats-chart-pie/stats-chart-pie.component';
import { StatsChartV2Component } from './stats-chart-v2/stats-chart-v2.component';
import { StatsCssBarChartComponent } from './stats-css-bar-chart/stats-css-bar-chart.component';

const declaredComponents = [
  StatsWrapperComponent,
  StatsChartLineComponent,
  StatsChartPieComponent,
  StatsStaticComponent,
];
const exportedComponents = [StatsWrapperComponent];

@NgModule({
  declarations: declaredComponents,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    NgChartsModule,
    StatsChartV2Component,
    StatsCssBarChartComponent,
  ],
  exports: exportedComponents,
})
export class UIStatsModule {}
