<div
  class="search-input-outer"
  [class.touched]="touched && isHero"
  [class.hero]="isHero"
  matAutocompleteOrigin
  #outer="matAutocompleteOrigin"
>
  <div class="search-input-lines">
    <div class="search-input-line">
      <mat-form-field
        *ngIf="!isHero && this.matAutocomplete"
        appearance="outline"
        class="material-custom-field hide-footer alternative-appearance"
      >
        <input
          class="search-input"
          matInput
          #input
          type="text"
          (focusin)="focus = true; touched = true"
          (focusout)="focus = false"
          [(ngModel)]="currentQuery"
          [matAutocomplete]="this.matAutocomplete"
          [matAutocompletePosition]="'below'"
          [matAutocompleteConnectedTo]="outer"
          (keyup)="keyUp($event)"
          [placeholder]="'Search assets'"
        />
        <mat-icon matSuffix class="suffix-button" (click)="toggleAdvancedSearch($event)"
          >{{ advancedSearchStateService.suffixConfig.icon }}
        </mat-icon>
      </mat-form-field>
      <ng-container *ngIf="isHero && this.matAutocomplete">
        <mat-icon class="icon">search</mat-icon>
        <input
          class="search-input"
          #input
          type="text"
          (focusin)="touched = true"
          [(ngModel)]="currentQuery"
          [matAutocomplete]="this.matAutocomplete"
          [matAutocompletePosition]="'below'"
          [matAutocompleteConnectedTo]="outer"
          (keyup)="keyUp($event)"
          [placeholder]="(placeholder$ | async) ?? ''"
        />
      </ng-container>
    </div>
    <div class="search-input-additional-line" *ngIf="touched && availableFilters.length > 1 && isHero">
      Search in
      <span
        class="filter-pill"
        *ngFor="let filter of availableFilters"
        [class.active]="filter.key === selectedFilter"
        (click)="selectedFilter = filter.key"
        >{{ filter.label }}</span
      >
    </div>
  </div>

  <vdms-hq-ui-button *ngIf="isHero" class="button" color="primary" (click)="updateQuery()"> Search </vdms-hq-ui-button>

  <div class="advanced-search--icon__container">
    <span cdkOverlayOrigin #trigger="cdkOverlayOrigin"></span>
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPositions]="advancedSearchStateService.overlayPositionConfig"
      [cdkConnectedOverlayOpen]="advancedSearchOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="advancedSearchOpen = false"
    >
      <vdms-hq-advanced-search-overlay></vdms-hq-advanced-search-overlay>
    </ng-template>
  </div>
</div>

<vdms-hq-search-autocomplete-options
  [autocompleteOptions]="latestQueries$ | async"
  (selectOption)="updateQuery($event)"
  (removeOption)="removeAutocompleteItem($event)"
>
</vdms-hq-search-autocomplete-options>
