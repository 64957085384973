<vdms-hq-ui-dialog-wrapper [size]="'wide'">
  <div title>
    <span class="title">{{ 'pages.lists.select_list' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-loader *ngIf="(initialized$ | async) !== true; else contentLoaded"></vdms-hq-ui-loader>
    <ng-template #contentLoaded>
      <vdms-hq-ui-selectable-tiles
        *ngIf="this.initialized$ | async"
        [dataSource]="dataSource"
        [filters]="dataSource.filters"
        [config]="collectionsSelectableConfig"
        (updateSelectedListEmit)="collectionsSelectableConfig.selectedList.data = $event"
        [selected]="selectedControl.value ?? []"
        (selectedChangeEmit)="selectedControl.setValue($event)"
        (updateConfigEmit)="collectionsSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
        (emptyActionButtonClick)="createNewCollection()"
      >
      </vdms-hq-ui-selectable-tiles>
    </ng-template>
  </div>

  <ng-container footer class="buttons-group">
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="onSubmit()"
      [disabled]="selectedControl.invalid || selectedControl.value.length === 0"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
