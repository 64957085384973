<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">Upload asset</span>
  </div>
  <div content>
    <vdms-hq-storage-aspera-selector
      id="master-upload-asset"
      size="compact"
      [multiple]="false"
      [fullWidth]="true"
      [clearSelectedOnDone]="true"
      (dropBatch)="startUpload($event)"
      (cancelBatch)="assetUploadService.cancelUpload($event)"
    ></vdms-hq-storage-aspera-selector>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
