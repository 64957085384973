<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.aspera-keys.delete_keys_dialog.title' | translate }}</span>
  </div>
  <div content>
    <ng-container [formGroup]="form">
      <vdms-hq-ui-form-input-select
        formControlName="supplierUuid"
        [label]="'common.aspera-keys.new_key_dialog.supplier' | translate"
        [selectOptions]="suppliers$ | async"
      ></vdms-hq-ui-form-input-select>
    </ng-container>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="warn" [loading]="loading$ | async" (click)="save()" [disabled]="form.invalid">
      {{ 'common.aspera-keys.delete_keys_dialog.ok_action' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
