<div class="sticky-banner">
  @if (!isCollapsed) {
    <div class="state-indicator">
      <mat-icon class="icon">info</mat-icon>
    </div>
    <p class="expanded">
      {{ messageExpanded }}
    </p>
  } @else {
    <p class="collapsed">
      {{ messageCollapsed }}
    </p>
  }

  <mat-icon (click)="changeState()" class="state-toggle"
    >{{ !isCollapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
  </mat-icon>
</div>
