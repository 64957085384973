<form [formGroup]="form">
  <vdms-hq-ui-layout-page-wrapper
    [maxWidth]="'600px'"
    [breadcrumbs]="[{ name: 'common.account_settings.time_settings' }]"
  >
    <section class="form-group">
      <vdms-hq-ui-form-radio-list
        class="d-block"
        formControlName="timeFormat"
        [label]="'pages.settings.time_format' | translate"
        [selectOptions]="timeFormats"
        [orientation]="'horizontal'"
        [horizontalView]="true"
        [labelWidth]="radioListLabelWidth"
      ></vdms-hq-ui-form-radio-list>

      <vdms-hq-ui-form-radio-list
        class="d-block"
        formControlName="dateFormat"
        [label]="'pages.settings.date_format' | translate"
        [selectOptions]="dateFormats"
        [orientation]="'horizontal'"
        [horizontalView]="true"
        [labelWidth]="radioListLabelWidth"
      ></vdms-hq-ui-form-radio-list>

      <div formGroupName="attributes">
        <vdms-hq-ui-form-radio-list
          class="d-block"
          formControlName="preferredTimezone"
          [label]="'pages.settings.timezone' | translate"
          [selectOptions]="timezones"
          [orientation]="'horizontal'"
          [horizontalView]="true"
          [labelWidth]="radioListLabelWidth"
        ></vdms-hq-ui-form-radio-list>
      </div>
    </section>
  </vdms-hq-ui-layout-page-wrapper>
  <vdms-hq-ui-layout-page-wrapper
    [maxWidth]="'600px'"
    [breadcrumbs]="[{ name: 'common.account_settings.table_settings' }]"
  >
    <div formGroupName="attributes">
      <section formGroupName="vida" class="form-group">
        <div class="form-group table-view" formGroupName="preferredTableView">
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="assetView"
            [label]="'pages.settings.preferred_table_view.assets' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="collectionsView"
            [label]="'pages.settings.preferred_table_view.collections' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="ordersView"
            [label]="'pages.settings.preferred_table_view.orders' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="downloadsView"
            [label]="'pages.settings.preferred_table_view.downloads' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="assetViewCart"
            [label]="'pages.settings.preferred_table_view.cart' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="licensedPackagesView"
            [label]="'pages.settings.preferred_table_view.licensed_packages' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="licenseesPartnersView"
            [label]="'pages.settings.preferred_table_view.licensees' | translate"
            [selectOptions]="tableViewOptionsNoGrid"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="contractsView"
            [label]="'pages.settings.preferred_table_view.contracts' | translate"
            [selectOptions]="tableViewOptionsNoGrid"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
          <vdms-hq-ui-form-radio-list
            class="d-block"
            formControlName="launchpadView"
            [label]="'pages.settings.preferred_table_view.launchpad' | translate"
            [selectOptions]="tableViewOptions"
            [orientation]="'horizontal'"
            [horizontalView]="true"
            [labelWidth]="radioListLabelWidth"
          ></vdms-hq-ui-form-radio-list>
        </div>
      </section>
    </div>
  </vdms-hq-ui-layout-page-wrapper>
  <vdms-hq-ui-layout-page-wrapper
    [maxWidth]="'600px'"
    [breadcrumbs]="[{ name: 'common.account_settings.other_settings' }]"
  >
    <div formGroupName="attributes">
      <section class="form-group" formGroupName="vida">
        <vdms-hq-ui-form-input-select
          class="d-block"
          formControlName="preferredPageSize"
          [label]="'pages.settings.preferred_asset_number' | translate"
          [selectOptions]="pageSizeOptions"
          [isSortedByLabel]="true"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-checkbox
          class="d-block"
          formControlName="openAssetPreviewInPopup"
          [label]="'pages.settings.open_asset_preview_in_popup' | translate"
        ></vdms-hq-ui-form-input-checkbox>
      </section>
    </div>
  </vdms-hq-ui-layout-page-wrapper>
</form>
