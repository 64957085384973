import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { DestroyComponent } from '@vdms-hq/shared';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'vdms-hq-filename-conventions-help-dialog',
  templateUrl: './filename-conventions-help-dialog.component.html',
  styleUrls: ['./filename-conventions-help-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UIDialogWrapperModule,
    ReactiveFormsModule,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
  ],
})
export class FilenameConventionsHelpDialogComponent extends DestroyComponent {
  public ref = inject(MatDialogRef<FilenameConventionsHelpDialogComponent>);
  public data = inject<{ packageId: string }>(MAT_DIALOG_DATA);
}
