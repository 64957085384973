import { Component } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-form-input-accordions',
  template: `<div class="accordions"><ng-content></ng-content></div>`,
  styles: [
    `
      .accordions {
        display: flex;
        flex-direction: column;
        gap: var(--flex-gap, 8px);
      }
    `,
  ],
})
export class FormInputAccordionsComponent {}
