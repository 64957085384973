import { GroupCurrencyISO } from '@vdms-hq/shared';

export interface Chart {
  chartDataSets: { data: number[]; label: string }[];
  labels: string[];
}

export interface EgressChart {
  [key: string]: Chart;
  egress: Chart;
}

export interface EgressTotals {
  bytes_egressed_last_30days: number;
  egress_since_last_bill: number;
  files_delivered_since_last_bill: number;
  daily_cost: number;
  currency: GroupCurrencyISO;
}

export type EgressStats = {
  charts: EgressChart;
  totals: EgressTotals;
};

export type EgressStatsResponse = {
  data: {
    charts: EgressChart;
    totals: EgressTotals;
  };
  status: string;
};

export type EgressListItem = {
  order_title?: string;
  contact_name?: string;
  purchase_order_number?: string;
  contract_id?: string;
  download_user_email?: string;
  s3_publish_sender_email?: string;
  destination_ip?: string;
  bucket_name?: string;
  method?: string;
  application?: string;
  transfer_start?: string;
  transfer_end?: string;
  country?: string;
  s3_publish_region?: string;
  status: string;
};

export class EgressListItemViewModel {
  static fromEgressListItem(item: EgressListItem): EgressListItemViewModel {
    return new EgressListItemViewModel(item);
  }

  constructor(public props: EgressListItem) {}

  get orderTitle(): string {
    return this.props.order_title || this.props.contact_name || '';
  }

  get purchaseOrderNumber(): string {
    return this.props.purchase_order_number || this.props.contract_id || '';
  }

  get userEmail(): string {
    return this.props.download_user_email || this.props.s3_publish_sender_email || '';
  }

  get destination(): string {
    return this.props.destination_ip || this.props.bucket_name || '';
  }

  get country(): string {
    return this.props.country || '';
  }

  get method(): string {
    return this.props.method ?? '';
  }

  get application(): string {
    return this.props.application ?? '';
  }

  get transferStart(): string {
    return this.props.transfer_start ?? '';
  }

  get transferEnd(): string {
    return this.props.transfer_end ?? '';
  }

  get status(): string {
    return this.props.status;
  }
}
