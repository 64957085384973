import { Pipe, PipeTransform } from '@angular/core';

// todo this should be moved to translations!
// todo this looks like domain logic, should be moved out of shared lib.
@Pipe({
  name: 'rename',
})
export class RenamePipe implements PipeTransform {
  transform = RenamePipe.transform;

  static transform = (value: string) => {
    if (!value) {
      return value;
    }
    switch (value?.toLowerCase()) {
      case 'created':
        return 'Initializing';
      case 'awaiting_analyse':
        return 'Awaiting Stream';
      case 'qa_failed':
        return 'QA Failed';
      case 'creating_stream':
        return 'Creating Stream';
      case 'creating_proxy':
        return 'Creating Proxy';
      case 'vida':
        return 'Vida';
      case 'launchpad':
        return 'Launchpad';
      case 'storefront':
        return 'Storefront';
      case 'connect2':
        return 'Connect2';
      case 'sharing_email_order':
        return 'Sharing Order';
      case 'sharing_shared_pack':
        return 'Download Recipient';
      case 'sharing_collection':
        return 'Shared Collection Recipient';
      case 'delivery_destination':
        return 'Delivery Destination';
      case 'email_delivery':
        return 'Email Delivery';
      case 'warm_up':
        return 'Warm Up';
      case 'workflow':
        return 'AI Processing';
      default:
        return value;
    }
  };
}
