import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ConnectableDataSource,
  FilterableDataSource,
  LoadableDataSource,
  PageableDataSource,
  SortableDataSource,
  SelectOptionKey,
} from '@vdms-hq/shared';
import { FormFilteredListSelectOption, FormSortingOption } from '../../models/form-filtering.model';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';

type ViewDataSource = PageableDataSource &
  LoadableDataSource &
  SortableDataSource &
  FilterableDataSource &
  ConnectableDataSource<FormFilteredListSelectOption>;

type OutsideValue = SelectOptionKey | null | undefined;
type InsideValue = SelectOptionKey | null;

@Component({
  selector: 'vdms-hq-ui-form-filtered-list',
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilteredListComponent),
    },
  ],
})
export class FilteredListComponent
  extends FormControlValueAccessorComponent<OutsideValue, InsideValue>
  implements OnInit
{
  innerFormControl = new UntypedFormControl(null);
  listFormGroup = new FormGroup({
    search: new FormControl<string>(''),
    sort: new UntypedFormControl(''),
  });
  @Input() dataSource?: ViewDataSource;
  @Input() sortingOptions: FormSortingOption[] = [];
  @Input() loaderText!: string;
  @Input() loaderCenterPadding = true;

  override ngOnInit(): void {
    super.ngOnInit();
    this.#applySortOption(0);
  }

  updateSorting($event: any) {
    const index = this.sortingOptions.findIndex((option) => option.key === $event);
    this.#applySortOption(index);
  }

  #applySortOption(index: number) {
    if (this.sortingOptions.length === 0) {
      return;
    }
    const selectedOption = this.sortingOptions[index];

    this.dataSource?.sortChange({
      active: selectedOption.label,
      direction: selectedOption.key,
    });
  }
}
