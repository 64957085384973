import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function filenameSafeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    /* eslint-disable no-useless-escape */
    const isValid = /^[a-zA-Z0-9_\-\.]+$/.test(value);
    return isValid ? null : { filenameSafe: { message: 'Filename contains invalid characters', value } };
  };
}
