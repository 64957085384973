import { Provider, Type } from '@angular/core';
import { APPLIED_FILTER_VALUE_FORMATTER } from './models/applied-filters.config';

export const configureDynamicFilters = (config: { valueFormatterService: Type<any> }): Provider[] => {
  return [
    {
      provide: APPLIED_FILTER_VALUE_FORMATTER,
      useClass: config.valueFormatterService,
    },
  ];
};
