import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveStart, Router, RouterStateSnapshot } from '@angular/router';
import { AssetSearchService, PersistenceSearchParams } from '@vdms-hq/api-contract';
import { ClientAccessChecker } from '@vdms-hq/clients';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SearchParamSerializerService } from './search-param-serializer.service';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({
  providedIn: 'root',
})
export class BrowseRouterService implements Resolve<PersistenceSearchParams> {
  private BASE_SEARCH_PATH = '/browse';

  private assetSearchService = inject(AssetSearchService);
  private router = inject(Router);
  private routeSerializer = inject(SearchParamSerializerService);
  private clientAccessChecker = inject(ClientAccessChecker);
  private activatedClientService = inject(ActivatedClientService);

  #listeners?: Subscription;

  #visitedBefore = false;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersistenceSearchParams> {
    return this.routeSerializer.fromRoute(route.queryParamMap, state.url).pipe(
      switchMap((persistenceQueryParams) => {
        if (this.#visitedBefore) {
          return of(persistenceQueryParams);
        }
        this.#visitedBefore = true;
        return this.clientAccessChecker.checkAccess(persistenceQueryParams).pipe(
          filter((hasAccess) => hasAccess),
          map(() => persistenceQueryParams),
        );
      }),
      tap((persistenceQueryParams) => {
        persistenceQueryParams.save = false;
        this.assetSearchService.applyParams(persistenceQueryParams);
      }),
    );
  }

  registerListeners = () => {
    if (this.#listeners) {
      return;
    }
    this.#listeners = this.assetSearchService.currentParams$
      .pipe(
        withLatestFrom(
          this.router.events.pipe(
            filter((e) => e instanceof ResolveStart),
            map((e) => (e as ResolveStart).url),
          ),
        ),
        switchMap(([persistence, targetUrl]) =>
          this.routeSerializer
            .toRoute(persistence)
            .pipe(withLatestFrom(of(targetUrl), this.activatedClientService.clientIdDefinite$)),
        ),
      )
      .subscribe(([queryParams, targetUrl, clientId]) => {
        if (queryParams['clientId'] !== clientId) {
          return;
        }

        const parsedUrl = this.router.parseUrl(targetUrl);
        parsedUrl.queryParams = {};
        parsedUrl.fragment = null;
        const sliced = parsedUrl.toString().split('/').slice(0, 2).join('/');
        const isBrowseUrl = sliced === this.BASE_SEARCH_PATH;

        if (isBrowseUrl) {
          const parsedTargetUrl = this.router.parseUrl(targetUrl);
          parsedTargetUrl.queryParams = {};
          parsedTargetUrl.fragment = null;

          void this.router.navigate([parsedTargetUrl.toString()], {
            queryParams,
          });
        }

        if (!isBrowseUrl) {
          void this.router.navigate([this.BASE_SEARCH_PATH], {
            queryParams,
          });
        }
      });
  };
}
