import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  MessageModel,
  MessageModelCreate,
  MessageState,
  TOAST_CONFIG,
  ToastConfig,
  ToastInput,
} from '../models/message.model';

@Injectable()
export class ToastService {
  private messages$ = new BehaviorSubject<MessageModel[]>([]);
  private closing$ = new Subject();

  constructor(
    @Inject(TOAST_CONFIG) public toastConfig: ToastConfig,
    private translate: TranslateService,
  ) {}

  success(input: ToastInput) {
    this.add({
      input,
      state: MessageState.success,
      icon: 'check_circle_outline',
      colorClass: 'success-message',
      autoHide: true,
    });
  }

  warning(input: ToastInput) {
    this.add({ input, state: MessageState.warning, icon: 'warning', colorClass: 'warning-message', autoHide: true });
  }

  error(input: ToastInput) {
    this.add({ input, state: MessageState.error, icon: 'error_outline', colorClass: 'error-message', autoHide: true });
  }

  info(input: ToastInput, autoHide = true) {
    this.add({ input, state: MessageState.info, icon: 'info_outline', colorClass: 'info-message', autoHide });
  }

  processing(input: ToastInput) {
    this.add({
      input,
      state: MessageState.processing,
      icon: 'processing',
      colorClass: 'info-message',
      autoHide: false,
    });
  }

  close(id: string) {
    this.messages$.next(this.messages$.getValue().filter((message) => message.id !== id));
  }

  getMessages(): Observable<MessageModel[]> {
    return this.messages$.asObservable();
  }

  private add(create: MessageModelCreate) {
    this.closing$.next(
      timer(create.state === MessageState.success ? 2500 : this.toastConfig.durationInSeconds * 1000)
        .pipe(take(1))
        .subscribe(() => {
          if (create.autoHide) {
            this.close(create.input.id);
          }
        }),
    );

    this.translate
      .get(create.input.message, create.input.interpolatedParams)
      .pipe(take(1))
      .subscribe((message: string) => {
        this.messages$.next([
          ...this.messages$.getValue().filter((message) => message.id !== create.input.id),
          {
            message: message,
            additionalMessage: create.input.additionalMessage
              ? this.translate.instant(create.input.additionalMessage)
              : undefined,
            id: create.input.id,
            state: create.state,
            icon: create.icon,
            colorClass: create.colorClass,
            autoHide: create.autoHide,
          },
        ]);
      });
  }
}
