import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number, suffix = '...'): string {
    if ((value?.length ?? 0) <= maxLength) {
      return value;
    } else {
      return value.substring(0, maxLength) + suffix;
    }
  }
}
