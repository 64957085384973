import { HttpContext, HttpContextToken } from '@angular/common/http';

type TranslatableName = string;
type CodeOrMessageFragment = string | '*';

interface ErrorsCatcherConfig {
  toastId: string;
  isDefault: boolean;
  messagesMap: Record<CodeOrMessageFragment, TranslatableName | false>;
}

export const ERRORS_CATCHER_CONFIG = new HttpContextToken<ErrorsCatcherConfig>(() => ({
  toastId: 'error' + Date.now(),
  isDefault: true,
  messagesMap: {
    '*': 'common.errors.general',
  },
}));

export const createErrorContext = (
  errors?: ErrorsCatcherConfig['messagesMap'],
  toastId?: ErrorsCatcherConfig['toastId'],
  context: HttpContext = new HttpContext(),
) => {
  context.set(ERRORS_CATCHER_CONFIG, {
    toastId: toastId ?? 'error' + Date.now(),
    messagesMap: errors ?? {
      '*': 'common.errors.general',
    },
    isDefault: false,
  });

  return context;
};
