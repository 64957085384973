import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../../ui-data-presentation/components/runtime-error/runtime-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataHiddenPipe } from '../../ui-data-presentation/pipes/metadata-hidden.pipe';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject } from 'rxjs';
import { E2eIdDirective } from '@vdms-hq/shared';
import { DisableIfRowPipe, GridAdvancedMetadata } from '../../ui-data-presentation';
import { MatIconModule } from '@angular/material/icon';

export type TileSelectableConfig<T> = {
  label: string;
  icon?: string;
  key: string;
  disabledIf?: {
    label: (item: T) => string;
    icon?: (item: T) => string;
    condition: (item: T) => boolean;
  };
  metadata: GridAdvancedMetadata<T>[];
};

@Component({
  selector: 'vdms-hq-ui-add-dialog-selectable-tiles',
  templateUrl: './ui-add-dialog-selectable-tiles.component.html',
  styleUrls: ['./ui-add-dialog-selectable-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    UIPipesModule,
    TranslateModule,
    MetadataHiddenPipe,
    ValueRendererComponent,
    MatTooltipModule,
    DisableIfRowPipe,
    E2eIdDirective,
    MatIconModule,
  ],
})
export class UiAddDialogSelectableTilesComponent<T> {
  @Input() config: TileSelectableConfig<T> | null = null;
  @Input() items!: T[];
  @Input() selected: string[] = [];
  @Input() searchView = false;
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() selectedChange = new EventEmitter<T>();
}
