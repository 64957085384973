import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { CartApiService, CartValidationError, ORDER_TYPE, OrderModelPost, OrderService } from '@vdms-hq/api-contract';
import { CartStateService } from '@vdms-hq/cart-core';
import { Permission } from '@vdms-hq/firebase-contract';
import { ToastService } from '@vdms-hq/toast';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import {
  defer,
  forkJoin,
  iif,
  Observable,
  of,
  catchError,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { OrderDialogComponent, SubmitOrderDialogOutput } from '../../components/order-dialog/order-dialog.component';
import { CartDataSource } from '../data-sources/cart-data-source';

@Injectable({ providedIn: 'root' })
export class CartSubmitService {
  private readonly router = inject(Router);
  private readonly activatedClientService = inject(ActivatedClientService);
  private cartDataSource = inject(CartDataSource);
  private cartState = inject(CartStateService);
  private dialog = inject(MatDialog);
  private apiService = inject(OrderService);
  private cartApiService = inject(CartApiService);
  private toastService = inject(ToastService);
  private permissionService = inject(PermissionService);
  private confirmation = inject(UIConfirmationDialogService);

  #popToast = {
    SUBMIT_SUCCESS: () =>
      this.toastService.success({
        id: 'cart_submit',
        message: 'pages.cart.notifications.submit.done',
      }),
    NO_DEFAULT_POLICY: () =>
      this.toastService.error({
        id: 'cart_submit',
        message: 'pages.cart.notifications.submit.no_default_policy',
      }),
  };

  submitCart() {
    this.cartState.isSubmitting$.next(true);
    const navigateToCustomizedCheckout$ = defer(() => this.router.navigate(['customized-checkout']));

    const popStandardCheckoutDialog$: Observable<number | boolean> = this.cartDataSource.total$.pipe(
      take(1),
      switchMap((value): Observable<number | boolean> => {
        if (!value) {
          return of(false);
        }
        const dialogRef = this.dialog.open<OrderDialogComponent, unknown, SubmitOrderDialogOutput>(
          OrderDialogComponent,
          {
            disableClose: true,
          },
        );
        return dialogRef.afterClosed().pipe(
          withLatestFrom(this.activatedClientService.clientDefinite$),
          switchMap(([data, client]) => {
            if (data) {
              this.cartState.isSubmitting$.next(true);
              return this.submitStandardCart(data, client?.vida?.sharedPacks ?? false);
            }
            this.cartState.isSubmitting$.next(false);
            return of(false);
          }),
        );
      }),
    );

    const processCheckout$ = this.activatedClientService.integrations$.pipe(
      withLatestFrom(this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART_V2])),
      take(1),
      switchMap(([integrations, hasAccessToV2]) =>
        iif(
          () => Boolean(integrations.salesforce?.enabled) && hasAccessToV2,
          navigateToCustomizedCheckout$,
          popStandardCheckoutDialog$,
        ),
      ),
    );

    const validateCart$ = (invalidAssets: CartValidationError<ORDER_TYPE.EMAIL_DELIVERY>[], total: number) => {
      if (invalidAssets.length === 0 || total === 0) {
        return of(true);
      }

      return forkJoin(
        invalidAssets.map((invalidAsset: CartValidationError<ORDER_TYPE.EMAIL_DELIVERY>) => {
          return of(
            `${invalidAsset.extra?.invalidTypes ? invalidAsset.extra?.invalidTypes[0].toUpperCase() + ' | ' : ''}${
              invalidAsset.assetOriginalFilename
            } (${'UUID: ' + invalidAsset.assetUuid})`,
          );
        }),
      ).pipe(
        map((titles) => ({
          titles,
          total,
        })),
        tap(() => this.cartState.isSubmitting$.next(false)),
        switchMap((invalid) => {
          let andMore = '';
          if (invalid.total > invalid.titles.length) {
            andMore = `<br/>... and ${invalid.total - invalid.titles.length} more`;
          }

          return this.confirmation.open({
            title: 'Cart validation',
            message: `The shopping cart contains several assets that can't be processed, including: <br/><br/> ${invalid.titles.join(
              '<br/>',
            )}${andMore} <br/><br/>Do you want to remove them from shopping cart to continue?`,
            abortAction: {
              label: 'No',
              color: 'secondary',
            },
            okAction: {
              label: 'Yes',
              color: 'primary',
            },
          });
        }),
      );
    };

    const payload = {
      order_type: ORDER_TYPE.EMAIL_DELIVERY,
      order_data: {},
    };

    this.cartApiService
      .validate(payload)
      .pipe(
        take(1),
        switchMap((invalidAssets) => {
          const filteredInvalidAssets: CartValidationError<ORDER_TYPE.EMAIL_DELIVERY>[] = [];

          if (invalidAssets.some((a) => a.note?.includes('Default Policy needs to be configured'))) {
            this.cartState.isSubmitting$.next(false);
            this.cartState.isValidated$.next(false);
            this.#popToast.NO_DEFAULT_POLICY();
            return of(false);
          }

          invalidAssets.forEach((a: CartValidationError<any>) => {
            if (a.extra?.invalidTypes?.includes('embargoed')) filteredInvalidAssets.push(a);
          });

          const isValid = filteredInvalidAssets.length <= 0;

          let cartItems: string[] = [];
          filteredInvalidAssets.forEach((a: CartValidationError<ORDER_TYPE.EMAIL_DELIVERY>) => {
            cartItems = cartItems.concat(a.cartItemUuids as string[]);
          });

          if (isValid) {
            return processCheckout$;
          }
          return validateCart$(filteredInvalidAssets, filteredInvalidAssets.length).pipe(
            switchMap((confirmed) => {
              if (!confirmed) {
                return of(false);
              }

              this.cartState.isUpdating$.next(true);
              return this.cartApiService.delete(cartItems).pipe(
                switchMap(() => {
                  this.cartState.refreshCart();
                  return processCheckout$;
                }),
              );
            }),
          );
        }),
        finalize(() => this.cartState.isSubmitting$.next(false)),
      )
      .subscribe();
  }

  private submitStandardCart(dialogOutput: SubmitOrderDialogOutput, sendAsSharedPack: boolean): Observable<boolean> {
    this.cartState.isSubmitting$.next(true);

    const order: OrderModelPost = {
      uuid: uuidv4(),
      type: ORDER_TYPE.EMAIL_DELIVERY,
      subject: dialogOutput.subject,
      package_title: dialogOutput.packageTitle,
      purchase_order_no: dialogOutput.purchaseOrderNo,
      delivery_date: dialogOutput.deliveryDate ? dialogOutput.deliveryDate : null,
      expires_at: dialogOutput.expiresAt ? dialogOutput.expiresAt : null,
      comment: dialogOutput.comment,
      send_as_shared_pack: sendAsSharedPack,
      has_estimate: false,
      filename_convention: null,
    };

    if (dialogOutput?.deliveryEmails) {
      order.delivery_emails = dialogOutput?.deliveryEmails;
    }

    if (dialogOutput?.notificationEmails) {
      order.notification_emails = dialogOutput?.notificationEmails;
    }

    if (dialogOutput?.destinations?.length) {
      order.destinations = dialogOutput.destinations.map(({ uuid, configUuid, hasBurnInText, burnInTextValue }) => ({
        uuid,
        config_uuid: configUuid,
        burn_in_text: hasBurnInText ? burnInTextValue : null,
      }));
      order.type = ORDER_TYPE.DELIVERY_DESTINATION;
    }

    return this.apiService.submitOrder(order).pipe(
      map(() => {
        return true;
      }),
      tap(() => {
        this.cartState.refreshCart();
        this.cartState.isSubmitting$.next(false);
        this.#popToast.SUBMIT_SUCCESS();
      }),
      catchError(() => {
        this.cartState.isSubmitting$.next(false);
        return of(false);
      }),
    );
  }
}
