import { inject, Injectable } from '@angular/core';
import { Loadable, LoadableDataSource, Pageable, PageableDataSource, RefreshService } from '@vdms-hq/shared';
import { EgressListItemViewModel, GetResponseData, PaginationAPIModel, StatsService } from '@vdms-hq/api-contract';
import { combineLatest, EMPTY, switchMap } from 'rxjs';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { EgressStatsForm } from './egress-stats.form';

@Injectable({ providedIn: 'root' })
export class EgressListDataSource
  extends Loadable(Pageable(RouterParamsPagination))
  implements LoadableDataSource, PageableDataSource
{
  private statsService = inject(StatsService);
  private refreshService = inject(RefreshService);
  private egressStatsForm = inject(EgressStatsForm);

  response$ = combineLatest([
    this.egressStatsForm.formValue$,
    this.pageIndex$,
    this.pageSize$,
    this.refreshService.refresh$,
  ]).pipe(
    tap(() => this.startLoading()),
    switchMap(([{ selectedPeriod, transferSources }, page, perPage]) => {
      const pagination = PaginationAPIModel.create({ page, perPage });
      return this.statsService
        .getEgressTransfersList(pagination, {
          lookbackPeriod: selectedPeriod,
          transferSources,
        })
        .pipe(
          catchError((error) => {
            return EMPTY;
          }),
        );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  override total$ = this.response$.pipe(map(({ total }) => total));
  override emptyResults$ = this.total$.pipe(map((total) => total === 0));

  allData$ = this.response$.pipe(
    GetResponseData,
    map((data) => data.map((item) => EgressListItemViewModel.fromEgressListItem(item))),
    tap(() => this.stopLoading()),
  );

  connection$ = this.allData$;
}
