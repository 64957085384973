import { OptionType } from '@vdms-hq/shared';

export enum LanguageSources {
  LANGUAGE = 'language',
  AUDIO_LANGUAGE = 'audio_tracks_language',
}

export enum LocalSources {
  IS_DIRTY = 'is_dirty',
  YES_NO = 'yes_no',
  HOT_COLD = 'hot_cold',
  ORPHAN = 'orphan',
  IS_PURGED = 'is_purged',
  IS_DELETED = 'is_deleted',
  IS_COLD_ONLY = 'cold_only',
  IS_QUARANTINED = 'is_quarantined',
  STAGE = 'stage',
  MEDIA_INSIGHTS_SOURCE_LANGUAGE = 'media_insights_source_lang',
  MEDIA_INSIGHTS_TARGET_LANGUAGE = 'media_insights_target_lang',
  ASSET_MASTER_TYPE = 'asset_master_type',
  EMBARGO = 'embargo',
  BILLING_REPORT = 'billing_report',
  ASSET_STATUS = 'asset_status',
  TRANSCODE_STATUS = 'clipping_status',
}

enum RemoteSources {
  ASSET_GENERAL_RATINGS = 'asset_general_ratings',
  SUPPLIERS = 'suppliers',
}

export type SelectorSourceType = OptionType | LanguageSources | LocalSources | RemoteSources | string;

type SelectorSourceTypeKeys =
  | keyof typeof OptionType
  | keyof typeof LanguageSources
  | keyof typeof LocalSources
  | keyof typeof RemoteSources;

export const SelectorSourceType: { [key in SelectorSourceTypeKeys]: SelectorSourceType } = {
  ...OptionType,
  ...LanguageSources,
  ...LocalSources,
  ...RemoteSources,
};
