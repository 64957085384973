import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { AsperaTransferBatch } from '../../logic/aspera/value-object/aspera-batch';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { UIButtonModule, UILoaderModule, UIStatusModule } from '@vdms-hq/ui';
import { TransferableBatchComponent } from '../transferable-batch/transferable-batch.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsperaUpload2Service } from '../../logic/aspera/aspera-upload2.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UploadComponentId } from '../../logic/aspera/model/aspera-transfer-event.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { boolean } from 'zod';

export const ProgressVisibilityEnum = {
  ALL: 'all',
  HIDDEN: 'hidden',
};

type ValueOf<T> = T[keyof T];
export type ProgressVisibility = ValueOf<typeof ProgressVisibilityEnum>;

@Component({
  selector: 'vdms-hq-storage-aspera-selector',
  templateUrl: './aspera-selector.component.html',
  styleUrls: ['./aspera-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    ApiContractModule,
    UILoaderModule,
    UIButtonModule,
    TransferableBatchComponent,
    UIStatusModule,
    TranslateModule,
    MatIconModule,
  ],
  providers: [],
  standalone: true,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(15%)', opacity: 0 }),
        animate('100ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-15%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class AsperaSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  asperaUploadService = inject(AsperaUpload2Service);
  protected readonly ProgressVisibilityEnum = ProgressVisibilityEnum;
  @Input() id: UploadComponentId = 'aspera-selector';

  @Input() size?: 'compact';
  @Input() buttonSize?: 'small' | 'medium';
  @Input() iconSize?: 'medium' | 'large' = 'medium';
  @Input() direction?: 'vertical' | 'horizontal' = 'vertical';
  @Input() progressVisibility: ProgressVisibility = ProgressVisibilityEnum.ALL;
  @Input() fullWidth = false;

  @Input() multiple = true;
  @Input() withDropzone = true;
  @Input() clearSelectedOnDone = false;
  @Input() isReadOnly = false;
  @Input() customText?: string;

  @Output() dropBatch = new EventEmitter<AsperaTransferBatch>();
  @Output() cancelBatch = new EventEmitter<AsperaTransferBatch>();

  private destroyed$ = new Subject<void>();

  transfersForComponent$ = this.asperaUploadService.transfersArray$.pipe(
    map((transfers) => transfers.filter((transfer) => transfer.componentId === this.id)),
  );

  nextTransferForComponent$ = this.asperaUploadService.nextBatch$.pipe(
    filter((transfer) => transfer.componentId === this.id),
  );

  ngOnInit(): void {
    this.asperaUploadService.initialize({
      clearSelectedOnDoneAfterMs: this.clearSelectedOnDone ? 2000 : undefined,
      restorePrevious: false,
    });

    this.nextTransferForComponent$.pipe(takeUntil(this.destroyed$)).subscribe((batch) => {
      this.dropBatch.emit(batch);
    });
  }

  ngAfterViewInit(): void {
    this.asperaUploadService.setDropTargets({
      dropZoneClass: '.upload-dropzone',
      allowMultipleSelection: this.multiple,
    });
  }

  selectFiles() {
    this.asperaUploadService.select({
      allowMultipleSelection: this.multiple,
      componentId: this.id,
    });
  }

  cancelTransfer(item: AsperaTransferBatch) {
    this.asperaUploadService.initCancelTransfer(item);

    this.cancelBatch.emit(item);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
