import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DestroyComponent,
  E2eIdDirective,
  FilterType,
  LoadableDataSource,
  LocalDataSource,
  PageableDataSource,
  ResourceModel,
  SortableDataSource,
  ValueFormat,
} from '@vdms-hq/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIDialogWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { RightsContractsLog } from '@vdms-hq/api-contract';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LogsModel } from '../../logic/logs.model';
import { JsonViewerComponent } from '../json-viewer/json-viewer.component';

export type LogsDataSource = LocalDataSource<LogsModel<unknown>> &
  SortableDataSource &
  PageableDataSource &
  LoadableDataSource & { selectedItem$: BehaviorSubject<LogsModel<unknown> | null> };

@Component({
  selector: 'vdms-hq-logs-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    MatDividerModule,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
    JsonViewerComponent,
    E2eIdDirective,
  ],
  templateUrl: './logs-dialog.component.html',
  styles: [],
})
export class LogsDialogComponent<T> extends DestroyComponent implements OnInit, OnDestroy {
  public dialogRef: MatDialogRef<LogsDialogComponent<T>> = inject(MatDialogRef<LogsDialogComponent<T>>);
  public data: { uuid: string; title: string; dataSource: LogsDataSource } = inject(MAT_DIALOG_DATA);
  public drawerVisible = false;
  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'Search by user or message',
      resource: [ResourceModel.RIGHTS_CONTRACT],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'message',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-logs'],
    },
  ];
  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });
  enabled = ['date', 'user', 'message', 'actions'];
  viewConfiguration: MultipleViewConfiguration<RightsContractsLog> = {
    tableAdvanced: {
      actions: [
        {
          key: 'preview',
          icon: 'visibility',
          label: 'common.global.preview',
          onDoubleClick: true,
          disabledIf: (item) => !item?.changeset,
        },
      ],
      columnsEnabled: this.enabled,
      columns: [
        {
          id: 'date',
          label: 'Date',
          valuePath: 'date',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
        },
        {
          id: 'user',
          label: 'User',
          valuePath: 'user',
          sortable: true,
        },
        {
          id: 'message',
          label: 'Message',
          valuePath: 'message',
          sortable: true,
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
    },
  };
  #destroy$ = new Subject<void>();

  ngOnInit() {
    this.filters.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe((value) => {
      this.data.dataSource.applyFilter(value.keyword ?? '', ['message', 'user']);
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.filters.reset();
  }

  handleAction({ key, item }: { key: string; item?: LogsModel<T> }) {
    switch (key) {
      case 'close_drawer':
        this.drawerVisible = !this.drawerVisible;
        break;
      case 'preview':
        if (!item) {
          return;
        }

        this.data.dataSource.selectedItem$.next(item);
        if (!this.drawerVisible) this.drawerVisible = true;
        break;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
