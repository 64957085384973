import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MandatoryFieldsDatasource } from '../../logic/mandatory-fields.datasource';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, filter, Observable, switchMap, take } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { EditDialogComponent } from '../../components/edit-dialog/edit-dialog.component';
import { DeliveryPackApiService, MandatoryField } from '@vdms-hq/api-contract';
import { ActivatedClientService, Permission, PermissionService } from '@vdms-hq/activated-client';
import { DestroyComponent } from '@vdms-hq/shared';
import { map } from 'rxjs/operators';
import { MandatoryFieldsRefreshService } from '../../logic/mandatory-fields-refresh.service';

@Component({
  selector: 'vdms-hq-mandatory-fields',
  standalone: true,
  imports: [CommonModule, MultipleDataPresentationComponent, TranslateModule, UILoaderModule, UIResultsWrapperModule],
  providers: [MandatoryFieldsDatasource],
  templateUrl: './mandatory-fields.component.html',
})
export class MandatoryFieldsComponent extends DestroyComponent {
  public dataSource = inject(MandatoryFieldsDatasource);
  private dialog = inject(MatDialog);
  private toast = inject(ToastService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private deliveryPackApiService = inject(DeliveryPackApiService);
  private permissionsService: PermissionService = inject(PermissionService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);
  private refreshService: MandatoryFieldsRefreshService = inject(MandatoryFieldsRefreshService);

  headerActions$: Observable<ResultsWrapperViewConfiguration> = this.activatedClientService.permissions$.pipe(
    this.takeUntilDestroyed(),
    map((permissions) => ({
      headerActions: [
        {
          key: 'new',
          name: 'common.mandatory-fields.new',
          color: 'primary',
          hiddenIf: () => !permissions.includes(Permission.EDIT_MANDATORY_FIELDS),
        },
      ],
      withLoading: true,
    })),
  );

  viewConfig$: Observable<MultipleViewConfiguration<MandatoryField>> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_MANDATORY_FIELDS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_MANDATORY_FIELDS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_MANDATORY_FIELDS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([browse, edit, del]) => ({
      multiView: {
        defaultView: 'tableAdvanced',
        fitToVisibleArea: true,
      },
      tableAdvanced: {
        actions: [
          {
            key: 'edit',
            icon: 'edit',
            onDoubleClick: false,
            label: 'common.global.edit',
            hiddenIf: () => !edit,
          },
          {
            key: 'delete',
            icon: 'delete',
            onDoubleClick: false,
            label: 'common.global.delete',
            hiddenIf: () => !del,
          },
        ],
        columns: [
          {
            id: 'assetType',
            label: 'Asset Type',
            valuePath: 'assetType',
            viewFormat: {
              modifiers: {
                textTransform: 'capitalize',
              },
            },
          },
          {
            id: 'contentClass',
            label: 'Content Class',
            valuePath: 'contentClass',
            viewFormat: {
              modifiers: {
                textTransform: 'capitalize',
              },
            },
          },
          {
            id: 'contentType',
            label: 'Content Type',
            valuePath: 'contentType',
            viewFormat: {
              modifiers: {
                textTransform: 'capitalize',
              },
            },
          },
          {
            id: 'fields',
            label: 'Fields',
            valuePath: 'fields',
            foldValues: true,
          },
          {
            id: 'actions',
            type: 'actions',
          },
        ],
        columnsEnabled: ['assetType', 'contentClass', 'contentType', 'fields', 'actions'],
      },
    })),
  );

  handleAction($event: { key: ActionIdentifier; item?: MandatoryField }) {
    const { key, item } = $event;

    switch (key) {
      case 'new':
        this.#new();
        break;
      case 'edit':
        if (!item?.uuid) {
          return;
        }

        this.#edit(item?.uuid);
        break;
      case 'delete':
        if (!item?.uuid) {
          return;
        }

        this.#remove(item?.uuid);
        break;
    }
  }

  #new() {
    this.dialog
      .open(EditDialogComponent)
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.refreshService.refresh();
      });
  }

  #edit(uuid: string) {
    this.dialog
      .open(EditDialogComponent, {
        data: {
          uuid,
        },
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.refreshService.refresh();
      });
  }

  #remove(uuid: string) {
    this.confirmationDialog
      .openDelete()
      .pipe(
        filter(Boolean),
        switchMap(() => this.deliveryPackApiService.deleteMandatoryField(uuid)),
      )
      .subscribe(() => {
        this.toast.success({ id: 'deleted', message: 'common.notifications.remove.done' });
        this.refreshService.refresh();
      });
  }
}
