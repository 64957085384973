import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  GridAdvancedMetadata,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
} from '@vdms-hq/ui';
import { SingleRightsContractDataSource } from '../../logic/single-rights-contract.data-source';
import { combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';
import { LICENSED_PACKAGE_VIEW_STATE, LicensedPackage, SORT_BY } from '@vdms-hq/api-contract';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import {
  E2eIdDirective,
  enumToSelectOptions,
  FieldsScopeKey,
  FilterType,
  ResourceModel,
  ResultDefinitionModel,
  ValueFormat,
} from '@vdms-hq/shared';
import { ColumnsConfigDialogComponent, FieldsFetcherService } from '@vdms-hq/fields';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-single-rights-contract',
  standalone: true,
  imports: [
    CommonModule,
    DataPresentationHeaderComponent,
    MultipleDataPresentationComponent,
    DynamicFiltersModule,
    E2eIdDirective,
  ],
  templateUrl: './single-rights-contract.component.html',
  styleUrls: ['./single-rights-contract.component.scss'],
  providers: [SingleRightsContractDataSource],
})
export class SingleRightsContractComponent implements OnDestroy {
  public dataSource = inject(SingleRightsContractDataSource);
  private viewSettingsService = inject(ViewSettingsService);
  private fieldConfig = inject(FieldsFetcherService);
  private activatedClientService = inject(ActivatedClientService);
  private router = inject(Router);
  private scope: FieldsScopeKey = 'other-licensed-packages';
  private rightsContractsActionsService = inject(RightsContractsActionsService);
  private rightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private matDialog = inject(MatDialog);

  #destroyed = new Subject<void>();
  #actions: Observable<Action<LicensedPackage>[]> = of([
    {
      key: 'preview',
      label: 'common.license_package.table.actions.preview',
      icon: 'visibility',
      onDoubleClick: true,
      onDoubleClickTooltip: 'common.license_package.table.actions.preview_tooltip',
    },
  ]);

  headerActions$: Observable<ActionContextLess[]> = this.activatedClientService.permissions$.pipe(
    takeUntil(this.#destroyed),
    map((permissions) => [
      {
        key: 'edit',
        label: 'common.rights_contracts.table.actions.edit',
        icon: 'edit',
        hiddenIf: () => !permissions.includes(Permission.EDIT_RIGHTS_CONTRACTS),
      },
    ]),
  );

  licensePackageConfig$: Observable<MultipleViewConfiguration<LicensedPackage>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.LICENSED_PACKAGE),
    this.fieldConfig.getConfiguration$(this.scope),
  ]).pipe(
    map(([actions, viewType, config]) => {
      const metadata = config.list.getMetadata().map((metadataItem) => {
        let hiddenIf: GridAdvancedMetadata<LicensedPackage>['hiddenIf'];

        if (metadataItem.valuePath === 'view_state') {
          hiddenIf = (item) => item.view_state === LICENSED_PACKAGE_VIEW_STATE.NONE;
        }
        return {
          ...metadataItem,
          hiddenIf,
        };
      });

      // todo move to config
      const additionalDef = config.list.system.find(
        (def) => def.id === 'licensedPackageStatus',
      ) as ResultDefinitionModel;

      return {
        multiView: {
          defaultView: viewType,
          emptyResults: {
            message: 'Could not find any Licensed Packages',
            icon: 'folder_special',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columns: config.table.getColumns(),
          columnsEnabled: config.table.getVisibleKeys(),
          settingsAction: {
            id: 'settings',
            label: 'common.global.edit_columns',
            icon: 'settings',
            color: 'transparent',
          },
        },
        gridTiles: {
          actions: actions,
          content: {
            titlePath: 'name',
          },
          layout: {
            columns: 'up-to-6',
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
        gridAdvanced: {
          actions: actions,
          layout: {
            columns: 'up-to-2',
          },
          content: {
            titlePath: 'name',
            additionalInfo: additionalDef
              ? {
                  label: additionalDef.label,
                  valuePath: additionalDef.results2.objectPath,
                  viewFormat: additionalDef.results2.viewFormat,
                }
              : undefined,
            metadata,
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
      };
    }),
  );

  filtersConfig$: Observable<DynamicFilterInput[]> = this.rightsPartnersAddDsService.allData$.pipe(
    takeUntil(this.#destroyed),
    map((partners) => [
      {
        id: 'keyword',
        label: 'common.license_package.table.filter_placeholder',
        resource: [ResourceModel.LICENSED_PACKAGE],
        format: ValueFormat.AS_IS,
        filters: {
          objectPath: 'name',
          validFormat: 'keyword',
          type: FilterType.MASTER_TEXT,
        },
        scope: ['other-licensed-packages'],
      },
      {
        id: 'partners',
        label: 'common.license_package.table.partners_placeholder',
        resource: [ResourceModel.LICENSED_PACKAGE],
        format: ValueFormat.SELECTOR_MULTI,
        filters: {
          objectPath: 'partners_uuid',
          validFormat: 'keyword',
          type: FilterType.SELECTOR,
        },
        selectOptions: partners,
        isMultiple: true,
        enableDeselectAll: true,
        enableSelectAll: true,
        scope: ['other-licensed-packages'],
      },
      {
        id: 'contract_in_out',
        label: 'common.license_package.table.contract_in_out_placeholder',
        resource: [ResourceModel.LICENSED_PACKAGE],
        format: ValueFormat.DATETIME,
        filters: {
          objectPath: 'contract_in_out',
          validFormat: 'keyword',
          type: FilterType.DATEPICKER_RANGE,
        },
        scope: ['other-licensed-packages'],
      },
      {
        id: 'sort',
        label: 'common.orders.filters.sort',
        resource: [ResourceModel.LICENSED_PACKAGE],
        format: ValueFormat.AS_IS,
        filters: {
          validFormat: 'keyword',
          objectPath: 'sort',
          type: FilterType.SELECTOR,
        },
        selectOptions: SORT_BY,
        scope: ['other-licensed-packages'],
      },
      {
        id: 'view_state',
        label: 'common.dialogs.license_package.update.fields.view_state',
        resource: [ResourceModel.LICENSED_PACKAGE],
        format: ValueFormat.AS_IS,
        filters: {
          validFormat: 'keyword',
          objectPath: 'view_state',
          type: FilterType.SELECTOR,
        },
        selectOptions: enumToSelectOptions(
          LICENSED_PACKAGE_VIEW_STATE,
          'common.dialogs.license_package.view_state.',
          true,
        ).filter((option) => option.key !== LICENSED_PACKAGE_VIEW_STATE.NONE),
        enableClear: true,
        scope: ['other-licensed-packages'],
      },
    ]),
  );

  ngOnDestroy() {
    this.#destroyed.next();
  }

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.LICENSED_PACKAGE, $event);
  }

  customActionHandler(event: { key: string; item?: LicensedPackage }): boolean {
    switch (event.key) {
      case 'settings':
        this.matDialog.open(ColumnsConfigDialogComponent, { data: { scope: this.scope } });
        return true;
      case 'preview':
        if (!event.item) {
          return false;
        }
        this.router.navigate(['licensed-packages', event.item.uuid]).then();
        return true;
      case 'edit':
        if (this.dataSource.contractUuid === undefined) {
          return false;
        }
        this.rightsContractsActionsService.editDialog(this.dataSource.contractUuid);
        return true;
    }
    return false;
  }
}
