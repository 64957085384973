<vdms-hq-ui-dialog-wrapper size="narrow">
  <h1 title>{{ 'common.mfa.reminder.modal.title' | translate }}</h1>

  <div content>
    {{ 'common.mfa.reminder.modal.message' | translate }}
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="later()" color="secondary">{{
      'common.global.remind_later' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="onSubmit()" color="primary">{{
      'common.global.go_to_settings' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
