<div class="timecodes__table" #timecodesTableParentElement>
  <vdms-hq-ui-loader *ngIf="dataSource.isLoading$ | async" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
  <form [formGroup]="filtersService.filters" #formElement>
    <div class="timecodes__table__header">
      <div *ngFor="let column of columns" class="timecodes__table__header__cell">
        <ng-container *ngIf="enabledColumns.includes(column.id)">
          <ng-container [ngSwitch]="column.id">
            <vdms-hq-ui-form-input-select
              *ngSwitchCase="'type'"
              formControlName="type"
              [withFooter]="false"
              [selectOptions]="(filtersService.typeOptions$ | async) ?? []"
              [enableDeselectAll]="true"
              [enableSelectAll]="true"
              [multiple]="true"
              label="Type"
            >
            </vdms-hq-ui-form-input-select>

            <vdms-hq-ui-form-input-chips
              *ngSwitchCase="'content'"
              formControlName="phrase"
              [withFooter]="false"
              label="Content"
              [chipsPosition]="'right'"
              [chipsLimit]="1000"
            >
            </vdms-hq-ui-form-input-chips>

            <vdms-hq-ui-form-input-select
              *ngSwitchCase="'confidence'"
              label="Confidence"
              formControlName="confidence"
              [selectOptions]="filtersService.confidenceOptions"
              [withFooter]="false"
              [isSortedByLabel]="true"
            >
            </vdms-hq-ui-form-input-select>

            <span *ngSwitchDefault>{{ $asDataValueColumn(column).label }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>

  <vdms-hq-ui-empty-results *ngIf="dataSource.emptyResults$ | async; else table"></vdms-hq-ui-empty-results>

  <ng-template #table>
    <ng-container *ngIf="dataSource.allData$ | async; let timeCodeData">
      <cdk-virtual-scroll-viewport
        itemSize="37"
        class="timecodes__table__body"
        [style.height]="viewportHeight$ | async"
      >
        <ng-container *cdkVirtualFor="let row of timeCodeData">
          <div
            class="timecodes__table__row"
            [class.active]="
              currentFrame
                | isBetween
                  : row?.timestamp?.timecodeIn?.countFrames() ?? 0
                  : (row?.timestamp?.timecodeIn?.countFrames() ?? 0) + 100
            "
            *ngIf="$metadataItem(row); let row"
          >
            <div
              class="timecodes__table__cell border-left"
              [ngClass]="row.pill"
              *ngFor="let enabledColumn of enabledColumns"
            >
              <ng-container [ngSwitch]="enabledColumn">
                <div class="timecodes__table__cell__value icon-cell" *ngSwitchCase="'type'">
                  <mat-icon class="icon" *ngIf="row?.language">translate</mat-icon>
                  <mat-icon class="icon" *ngIf="row?.loggingType">description</mat-icon>
                  {{ row.type | translateType: row?.language : row?.loggingType }}
                </div>
                <div class="timecodes__table__cell__value grey-text mw-175" *ngSwitchCase="'timestamp'">
                  <div
                    class="timecodes__table__cell__value--arrow grey-text"
                    [class.clickable-timecode]="row.timestamp.timecodeIn"
                    (click)="onTimecodeClick(row?.timestamp?.timecodeIn, row?.boundingBoxes)"
                  >
                    <mat-icon class="icon">arrow_downward</mat-icon>
                    {{ row.timestamp.timecodeIn ?? '__:__:__:__' | offsetAdd | async }}
                  </div>
                  <div
                    class="timecodes__table__cell__value--arrow grey-text clickable-timecode"
                    *ngIf="row.timestamp.timecodeOut"
                    (click)="onTimecodeClick(row.timestamp.timecodeOut, row?.boundingBoxes)"
                  >
                    <mat-icon class="icon">arrow_upward</mat-icon>
                    {{ row.timestamp.timecodeOut | offsetAdd | async }}
                  </div>
                </div>
                <div class="timecodes__table__cell__value grey-text" *ngSwitchCase="'duration'">
                  {{ row.duration }}
                </div>
                <div
                  class="timecodes__table__cell__value timecodes__table__cell__content grey-text"
                  *ngSwitchCase="'content'"
                >
                  <div [class.edited-text]="row.isEdited" [innerHTML]="row.contentHtml"></div>
                  <div class="timecodes__table__cell__links" *ngFor="let url of row.urls">
                    <div *ngIf="url | parseUrl; let parsedUrl">
                      <a
                        [href]="parsedUrl.url"
                        [ngSwitch]="parsedUrl.shortName"
                        class="timecodes__table__cell__link"
                        target="_blank"
                      >
                        <img *ngSwitchCase="'imdb.com'" src="/assets/common/icons/imdb.svg" alt="IMDB" />
                        <img *ngSwitchCase="'wikidata.org'" src="/assets/common/icons/wikidata.svg" alt="Wikidata" />
                        <span *ngSwitchDefault>{{ parsedUrl.shortName }}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="timecodes__table__cell__value grey-text" *ngSwitchCase="'confidence'">
                  <vdms-hq-ui-floating-controls-v2
                    class="timecodes__table__cell__value__controls"
                    [actions]="actions"
                    (action)="handleAction($event)"
                    [item]="row"
                  >
                  </vdms-hq-ui-floating-controls-v2>
                  {{ row.confidence | confidenceNoteType }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </ng-template>
  <div class="timecodes__table__footer">
    <div
      class="timecodes__table__footer__refresh"
      *ngIf="dataSource.fetchedAt$ | async; let fetchedAt"
      (click)="reloadData()"
    >
      Fetched at: {{ fetchedAt | date: 'medium' }} | Click to refresh | Total rows: {{ dataSource.total$ | async }}
    </div>
    <div class="timecodes__table__footer__save_list" *ngIf="dataSource.assetUuid$ | async; let assetUuid">
      <vdms-hq-ui-button
        *ngIf="(modifiedItems$ | async)?.length; let modifiedItemsCount"
        color="primary"
        (click)="metadataActionsService.saveModifiedJobItems(assetUuid)"
        [loading]="metadataActionsService.isLoading$.value"
        >Save list ({{ modifiedItemsCount }})
      </vdms-hq-ui-button>
    </div>
    <div
      class="timecodes__table__footer____seamless-result-asset"
      *ngIf="dataSource.detectSeamlessResultAsset$ | async; let detectSeamlessAssetUuid"
    >
      <vdms-hq-ui-button
        color="primary"
        *ngIf="detectSeamlessAssetUuid"
        [routerLink]="['/asset', detectSeamlessAssetUuid]"
        >Detect Seamless Result Asset
      </vdms-hq-ui-button>
    </div>
  </div>
</div>
