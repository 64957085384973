<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'common.import_export.header' | translate }}</h1>
  <a #downloadLink [href]="downloadJsonHref" [download]="filename"></a>
  <mat-tab-group class="material-custom-tab" #tabGroup content>
    <mat-tab [label]="'common.import_export.export_header' | translate">
      <vdms-hq-ui-form-checkbox-list
        [(ngModel)]="exportPaths"
        [columns]="1"
        [available]="exportOptions"
        (ngModelChange)="updatePayload()"
      ></vdms-hq-ui-form-checkbox-list>
    </mat-tab>
    <mat-tab [label]="'common.import_export.import_header' | translate">
      <vdms-hq-native-upload-component [loading]="loading"></vdms-hq-native-upload-component>
    </mat-tab>
  </mat-tab-group>

  <ng-container footer *ngIf="tabGroup.selectedIndex === 0">
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" (click)="export()" [disabled]="exportPaths.length === 0">{{
      'common.import_export.export_button' | translate
    }}</vdms-hq-ui-button>
  </ng-container>

  <ng-container footer *ngIf="tabGroup.selectedIndex === 1">
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" (click)="uploadFile()" [disabled]="(hasSelected$ | async) === false">{{
      'common.import_export.import_button' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
