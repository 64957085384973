import { Injectable } from '@angular/core';
import { ConnectableDataSource, filterEmpty, LoadableDataSource, PageableDataSource, PageEvent } from '@vdms-hq/shared';
import { MetadataRecognitionModel } from './metadata-recognition.model';
import { BehaviorSubject, Observable, combineLatest, switchMap, map, tap, shareReplay, takeWhile } from 'rxjs';
import { MetadataRecognitionApiService, SortOptions, PaginationAPIModel as Pagination } from '@vdms-hq/api-contract';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MetadataRecognitionDatasource
  implements ConnectableDataSource<MetadataRecognitionModel>, LoadableDataSource, PageableDataSource
{
  isLoading$ = new BehaviorSubject<boolean>(false);
  pageIndex$ = new BehaviorSubject<number>(0);
  pageSize$ = new BehaviorSubject<number>(12);
  orderBy$ = new BehaviorSubject<string>('');
  orderDir$ = new BehaviorSubject<SortOptions['direction']>('asc');
  pageSizeOptions = [12, 24, 48, 96];
  total$ = new BehaviorSubject<number>(0);
  currentDetailsId$ = new BehaviorSubject<string | null>(null);

  allData$: Observable<MetadataRecognitionModel[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.orderBy$,
    this.orderDir$,
    this.currentDetailsId$.pipe(filterEmpty()),
  ]).pipe(
    switchMap(([page, perPage, orderBy, orderDir, detailsId]) => {
      const headers = Pagination.create({ page, perPage, orderBy, orderDir }).applyToHeaders(new HttpHeaders());

      return this.metadataRecognitionApi.getMetadata(detailsId, headers);
    }),
    tap(({ total }) => {
      this.isLoading$.next(false);
      this.total$.next(total);
    }),
    map((metadata) => metadata?.data?.map((data) => new MetadataRecognitionModel(data)) ?? []),
  );
  connection$: Observable<MetadataRecognitionModel[]> = this.allData$;

  constructor(private readonly metadataRecognitionApi: MetadataRecognitionApiService) {}

  trackBy(index: number, item: MetadataRecognitionModel) {
    return item.props.uuid;
  }

  pageChange($event: PageEvent): void {
    this.isLoading$.next(true);
    this.pageSize$.next($event.pageSize);
    this.pageIndex$.next($event.pageIndex);
  }
}
