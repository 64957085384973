import {
  BubbleDataPoint,
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartType,
  Color,
  DefaultDataPoint,
  LegendItem,
  ScatterDataPoint,
  Tick,
} from 'chart.js';

type ChartConfig = ChartConfiguration<
  'bar' | 'line' | 'scatter' | 'bubble' | 'pie' | 'doughnut' | 'polarArea' | 'radar',
  Array<number | ScatterDataPoint | null | BubbleDataPoint>
>;

type ColorTheme = {
  dark: string;
  neutral: string;
  light: string;
  onNeutral: string;
};
export const STATS_COLORS = {
  primary: {
    dark: '#4230c6',
    neutral: '#5b45ff',
    light: '#8474FF',
    onNeutral: '#ffffff',
  },
  primaryTransparent: {
    dark: 'rgba(50,36,151,0.45)',
    neutral: 'rgba(91,69,255,0.45)',
    light: 'rgba(132,116,255,0.45)',
    onNeutral: '#ffffff',
  },
  primaryTransparentVariant: {
    dark: 'rgba(49,35,158,0.25)',
    neutral: 'rgba(91,69,255,0.25)',
    light: 'rgba(132,116,255,0.25)',
    onNeutral: '#ffffff',
  },
  success: {
    dark: '#076e1d',
    neutral: '#00B127',
    light: '#22e14a',
    onNeutral: '#ffffff',
  },
  grey: {
    dark: '#171719',
    neutral: '#2E3138',
    light: '#3b3f49',
    onNeutral: '#ffffff',
  },
};
export const defaultFlatPalette = [
  '#5b45ff',
  '#FF8A58',
  '#FF22C1',
  '#5B3F97',
  '#FD78C0',
  '#BAC9FF',
  '#88E7B3',
  '#BAF413',
  '#B558FF',
  '#767378',
] as Array<Color>;

type ColorPalette = typeof STATS_COLORS.primary;

export const axisLabelFont = {
  font: {
    family: 'Source Sans Pro',
    size: 16,
  },
};

export const axisLabelSmallFont = {
  font: {
    family: 'Source Sans Pro',
    size: 14,
  },
};

export class StatsChartViewModelV2 {
  styles = {
    maxHeight: '180px',
  };

  type: ChartConfig['type'] = 'line';

  data: ChartData<ChartType, DefaultDataPoint<ChartType>> = {
    datasets: [],
    labels: [],
  };
  options: ChartConfig['options'] = {
    responsive: true,
  };

  hoverColor(color: string): string {
    const amt = Math.round(2.55 * 15);
    return `#${
      color
        .slice(1)
        .match(/.{2}/g)
        ?.map((c) =>
          Math.min(255, parseInt(c, 16) + amt)
            .toString(16)
            .padStart(2, '0'),
        )
        .join('') || ''
    }`;
  }

  setType(type: StatsChartViewModelV2['type']) {
    this.type = type;
  }

  setOptions(options: ChartConfig['options'] & { cutout?: number }) {
    this.options = options;
  }

  setLabels(labels: string[]) {
    this.data.labels = labels;
  }

  addDataset(dataset: ChartDataset) {
    this.data.datasets.push({
      ...dataset,
    });
  }

  static createDoughnutChart(data: number[], labels: string[], valueColors?: string[]): StatsChartViewModelV2 {
    const statsChart = new StatsChartViewModelV2();
    const borderColor = valueColors ?? defaultFlatPalette ?? STATS_COLORS.primary.neutral;
    const hoverBorderColor = (Array.isArray(borderColor) ? borderColor : [borderColor]).map((color) =>
      statsChart.hoverColor(color.toString()),
    );
    statsChart.setType('doughnut');
    statsChart.addDataset({
      data,
      radius: 40,
      borderWidth: 7,
      borderColor: borderColor,
      hoverBorderColor: hoverBorderColor,
    });

    statsChart.setLabels(labels);
    statsChart.setOptions({
      cutout: 80,
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'center',
          labels: {
            ...axisLabelSmallFont,
            boxHeight: 15,
            boxWidth: 15,
            generateLabels(): LegendItem[] {
              return labels.map((label, index) => ({
                text: `${label}: ${data[index]}`,
                lineCap: 'round',
                fontColor: '#A4A9B7',
                fillStyle: (valueColors ?? [])[index] ?? defaultFlatPalette[index] ?? STATS_COLORS.primary.neutral,
                borderRadius: 7,
                lineWidth: 0,
                textAlign: 'left',
              }));
            },
          },
        },
        tooltip: {
          boxPadding: 5,
          callbacks: {
            labelColor(tooltipItem) {
              return {
                borderRadius: 6,
                borderColor:
                  (borderColor ?? [])[tooltipItem.dataIndex] ??
                  defaultFlatPalette[tooltipItem.dataIndex] ??
                  STATS_COLORS.primary.neutral,
                backgroundColor:
                  (hoverBorderColor ?? [])[tooltipItem.dataIndex] ??
                  defaultFlatPalette[tooltipItem.dataIndex] ??
                  STATS_COLORS.primary.neutral,
              };
            },
          },
        },
      },
    });

    return statsChart;
  }

  static createStackedData(
    data: {
      label: string;
      data: number[];
    }[],
    labels: string[],
    valueColors: ColorPalette[],
  ) {
    const statsChart = new StatsChartViewModelV2();

    statsChart.setType('bar');
    statsChart.setLabels(labels);
    data.forEach((dataset, index) => {
      statsChart.addDataset({
        label: dataset.label,
        fill: true,
        backgroundColor: valueColors[index].neutral,
        hoverBackgroundColor: valueColors[index].light,
        pointBackgroundColor: '#d11313',
        borderColor: valueColors[index].dark,
        borderRadius: 20,
        radius: 20,
        hoverRadius: 20,
        barThickness: 'flex',
        maxBarThickness: 32,
        data: dataset.data,
      });
    });

    statsChart.setOptions({
      indexAxis: 'y',
      scales: {
        x: {
          display: true,
          stacked: true,
          grid: {
            display: true,
            color: '#2e3138',
            offset: true,
            tickLength: 1,
            z: 1,
          },
        },
        y: {
          display: false,
          stacked: true,
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            boxHeight: 10,
            boxWidth: 10,
            pointStyle: 'circle',
            padding: 10,
          },
        },
        tooltip: {
          boxPadding: 5,
          callbacks: {
            labelColor(tooltipItem) {
              return {
                borderColor: valueColors[tooltipItem.datasetIndex].dark,
                backgroundColor: valueColors[tooltipItem.datasetIndex].neutral,
                borderRadius: 6,
              };
            },
          },
        },
      },
    });

    return statsChart;
  }
}

/**
 * @deprecated use StatsChartViewModelV2 instead
 */
export type StatsChartViewModel = {
  legend?: boolean;
  legendAlign?: 'start' | 'end' | 'center' | undefined;
  labelsTextColor: string;
  labels: string[];
  chartDataSets: ChartDataset[];
  labelSettings?: LabelSettings;
  additionalText?: string;
  beginAtZero?: boolean;
  maxHeight?: string;
  pieSettings?: {
    radius?: number;
    cutout?: number;
  };
  lineSettings?: {
    gradientUnderLine?: GradientConfig[];
  };
  yAxisCallback?: (value: any, index: number, ticks: Tick[]) => string;
};

type GradientConfig = {
  step: number;
  color: string;
};

interface LabelSettings {
  boxHeight: number;
  boxWidth: number;

  generateLabels?(chart: Chart): LegendItem[];
}
