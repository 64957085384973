import { Injectable } from '@angular/core';
import { LanguagesSourceService } from './sources/languages-source.service';
import { ApiFieldsSourceService } from './sources/api-fields-source.service';
import { map } from 'rxjs/operators';
import { EnabledOption, Option, SelectOption, SelectOptionGroup } from '@vdms-hq/shared';
import { SelectorSourceType } from './selector-source.type';
import { LocalSourceService } from './sources/local-source/local-source.service';
import { SourceGroupProvider, SourceProvider } from './source-provider';
import { Observable } from 'rxjs';
import { RatingsSourceService } from './sources/ratings-source.service';
import { AssetType } from '@vdms-hq/api-contract';
import { SuppliersSourceService } from './sources/suppliers-source.service';

@Injectable({
  providedIn: 'root',
})
export class DataProviderService {
  constructor(
    private languagesSourceService: LanguagesSourceService,
    private apiFieldsSourceService: ApiFieldsSourceService,
    private localSourceService: LocalSourceService,
    private ratingSourceService: RatingsSourceService,
    private suppliersSourceService: SuppliersSourceService,
  ) {}

  listForSelectors = (
    type: SelectorSourceType,
    controlContext?: 'search' | 'forms',
    multiple = false,
    nullValue = true,
    filterMethod?: (items: SelectOption[]) => SelectOption[],
    assetType?: AssetType,
  ): Observable<SelectOption[]> =>
    this.#getProvider(type)
      .listByType(type, assetType)
      .pipe(
        map((items) => (!multiple && nullValue ? this.#addNotApplicable(items) : [...items])),
        map((items: SelectOption[]) => (filterMethod ? filterMethod(items) : items)),
        map(this.#addContext(controlContext)),
      );

  listForAutocomplete = (type: SelectorSourceType, controlContext?: 'search' | 'forms'): Observable<SelectOption[]> =>
    this.#getProvider(type)
      .listByType(type)
      .pipe(map(this.#addContext(controlContext)));

  isLoading = (type: SelectorSourceType) => this.#getProvider(type)?.isLoading$;

  listForGroupSelectors = (type: SelectorSourceType): Observable<SelectOptionGroup[]> => {
    return this.#getGroupProvider(type).listByType(type);
  };

  isGroupLoading = (type: SelectorSourceType) => this.#getGroupProvider(type)?.isLoading$;

  #addNotApplicable = (items: SelectOption[]) => [
    {
      key: null,
      label: 'N/A',
    },
    ...items,
  ];

  #addContext = (controlContext?: string) => (selectOptions: SelectOption[]) => {
    let context: keyof EnabledOption;

    switch (controlContext) {
      case 'search':
        context = 'in_search';
        break;
      default:
        context = 'in_forms';
    }

    return (selectOptions as Option[]).filter((opt) => {
      return !('enabled' in opt) || opt.enabled[context] || opt.key === null;
    });
  };

  getValueForSelector(type: SelectorSourceType, keys?: string | string[]): Observable<string> {
    return this.listForSelectors(type)?.pipe(
      map((item) => {
        if (typeof keys === 'string' || !keys) {
          keys = keys ? [keys as string] : [];
        }
        keys = keys.map((k) => k.toUpperCase());
        return item
          .filter((item) => !!item.key && keys?.includes((item.key as string).toUpperCase()))
          .map((item) => item.label)
          .join(', ');
      }),
    );
  }

  getValueForGroupSelector(type: SelectorSourceType, keys: string | string[]) {
    return this.listForGroupSelectors(type)?.pipe(
      map((list: SelectOptionGroup[]) => {
        if (typeof keys === 'string' || !keys) {
          keys = keys ? [keys as string] : [];
        }
        const options: SelectOption[] = list.reduce(
          (prev: SelectOption[], current: SelectOptionGroup) => [...prev, ...current.options],
          [],
        );

        const getGroup = (rating: string) =>
          list.find((sog: SelectOptionGroup) => sog.options.some((so: SelectOption) => so.key === rating))?.label;
        const getOption = (rating: string) => options.find((so: SelectOption) => so.key === rating)?.label;
        return keys.map((key: string) => `${getGroup(key)}: ${getOption(key)}`).join(', ');
      }),
    );
  }

  #getProvider = (type: SelectorSourceType): SourceProvider => {
    switch (true) {
      case this.localSourceService?.supports(type):
        return this.localSourceService;

      case this.languagesSourceService?.supports(type):
        return this.languagesSourceService;

      case this.ratingSourceService?.supports(type):
        return this.ratingSourceService;

      case this.suppliersSourceService?.supports(type):
        return this.suppliersSourceService;

      default:
        return this.apiFieldsSourceService;
    }
  };

  #getGroupProvider = (type: SelectorSourceType): SourceGroupProvider => {
    switch (true) {
      case this.ratingSourceService?.supports(type):
        return this.ratingSourceService;

      default:
        return this.ratingSourceService;
    }
  };
}
