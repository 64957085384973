import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, Observable, skip } from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { isEqual } from 'lodash';
import { AuthService } from '@vdms-hq/auth';
import { filter, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReturnButtonService {
  #router = inject(Router);
  constructor(
    private readonly router: Router,
    private readonly activatedClient: ActivatedClientService,
    private readonly authService: AuthService,
  ) {}

  returnButtonVisibility$: Observable<boolean> = this.#router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => {
      return !!localStorage.getItem('lastAssetQuery') && !this.isBrowsePage && !this.isHomePage;
    }),
  );

  get isBrowsePage(): boolean {
    return this.router.url?.split(/[/?]/)?.filter(Boolean)[0] === 'browse';
  }

  get isHomePage(): boolean {
    return this.router.url?.split(/[/?]/)?.filter(Boolean)[0] === 'home';
  }

  registerListeners(): void {
    this.activatedClient.clientDefiniteValueChanges$
      .pipe(skip(1), distinctUntilChanged(isEqual))
      .subscribe(() => this.resetLastSavedRoute());
  }

  resetLastSavedRoute(): void {
    localStorage.removeItem('lastAssetQuery');
  }
}
