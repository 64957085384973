import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client, ClientsService, UserApiService, UserModelFlat } from '@vdms-hq/api-contract';
import { DestroyComponent, DialogResponse, SharedModule } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

export interface ClientListDialogInput {
  user: UserModelFlat;
  clients?: Client[];
}

@Component({
  selector: 'vdms-hq-client-list-dialog',
  templateUrl: './client-list-dialog.component.html',
  styleUrls: ['./client-list-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    UIDialogWrapperModule,
    SharedModule,
    UIButtonModule,
    FormSectionComponent,
    TranslateModule,
    UIFormModule,
    UIButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListDialogComponent extends DestroyComponent implements OnInit {
  loading$ = new BehaviorSubject(true);
  allClients: string[] = [];
  clients$ = this.clientsService.getClients().pipe(
    map((clients) =>
      clients.map((client) => ({
        key: client.uuid,
        label: client.name,
      })),
    ),
    tap((clients) => (this.allClients = clients.map((client) => client.key))),
    tap(() => this.loading$.next(false)),
  );
  selectedClients = new FormControl<string[]>([]);
  constructor(
    public dialogRef: MatDialogRef<ClientListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientListDialogInput,
    private userApiService: UserApiService,
    private clientsService: ClientsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userApiService
      .getUser(this.data.user.uuid)
      .pipe(this.takeUntilDestroyed())
      .subscribe((user) => {
        this.selectedClients.setValue(user?.groups ? [...user.groups].map(({ uuid }) => uuid) : []);
      });
  }

  onSubmit(): void {
    this.dialogRef.close({
      status: DialogResponse.OK,
      user: {
        ...this.data.user,
        groups: this.selectedClients.value,
      },
    });
  }

  toggleAll(toggle: boolean): void {
    this.selectedClients.setValue(toggle ? this.allClients : []);
  }
}
