<section *ngIf="dataSource" class="filtered-list" [class.with-touched-indicator]="withTouchedIndicator">
  <div class="filtered-list-controls" [formGroup]="listFormGroup">
    <vdms-hq-ui-form-input-text
      [formControlName]="'search'"
      [label]="'common.global.search' | translate"
      (keyup)="dataSource.applyFilter($any($event.target).value, ['filterableValue'])"
    ></vdms-hq-ui-form-input-text>
    <vdms-hq-ui-form-input-select
      *ngIf="sortingOptions.length"
      [formControlName]="'sort'"
      [label]="'common.global.sort_order' | translate"
      (valueChange)="updateSorting($event)"
      [selectOptions]="sortingOptions"
    ></vdms-hq-ui-form-input-select>
  </div>
  <div>
    <div class="filtered-list-results" *ngIf="dataSource.connection$ | async; let options">
      <ng-container *ngIf="(dataSource.isLoading$ | async) === false">
        <ng-container *ngIf="options.length; else noData">
          <vdms-hq-ui-form-radio-list
            [selectOptions]="options"
            [formControl]="innerFormControl"
            [label]="label"
            [hint]="hint"
            (blur)="propagateTouch()"
            [withFooter]="withFooter"
          >
          </vdms-hq-ui-form-radio-list>
        </ng-container>

        <ng-template #noData>
          <vdms-hq-ui-empty-results type="compact"></vdms-hq-ui-empty-results>
        </ng-template>
      </ng-container>
    </div>
    <vdms-hq-ui-loader
      *ngIf="dataSource.isLoading$ | async"
      [centerPadding]="loaderCenterPadding"
      [text]="loaderText"
    ></vdms-hq-ui-loader>
    <div class="paginator-container">
      <vdms-hq-ui-paginator [dataSource]="dataSource" [disabled]="innerFormControl.disabled" aria-label="Select page">
      </vdms-hq-ui-paginator>
    </div>
  </div>
</section>
