import { ParamsPagination } from '@vdms-hq/view-settings';
import { RefreshService, SelectOption, SortBy, SortDirection } from '@vdms-hq/shared';
import { inject, Injectable } from '@angular/core';
import { PaginationAPIModel as Pagination, UserApiService, UserModel } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';

export interface UserAddData {
  key: string;
  label: string;
  email: string;
}

export type FiltersView = {
  keyword: FormControl<string>;
};

@Injectable({ providedIn: 'root' })
export class UsersShareAddDataSource
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<FiltersView>, UserModel>
{
  private userService = inject(UserApiService);
  private refreshService = inject(RefreshService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  readonly defaultPerPage = 192;
  readonly defaultPage = 0;

  isLoading$ = new BehaviorSubject(true);

  refresh$ = this.refreshService.refresh$;

  filters = new FormGroup<FiltersView>({
    keyword: new FormControl<string>('', { nonNullable: true }),
  });

  sortBy$ = new BehaviorSubject<SortBy>('user.displayName');
  sortDirection$ = new BehaviorSubject<SortDirection>('asc');

  filters$ = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(300),
    switchMap(() => {
      this.changePageIndex$.next(0);
      return of(this.filters.value);
    }),
  );

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.activatedClientService.clientIdDefinite$,
  ]).pipe(
    switchMap(([, page, perPage, orderBy, orderDir, filters, clientId]) => {
      this.isLoading$.next(true);
      const pagination: Pagination = Pagination.create({ page: page, perPage, orderBy, orderDir });
      return this.userService.getUsersShare({ text: filters.keyword }, pagination);
    }),
    shareReplay(1),
  );
  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<UserAddData[]> = this.responseData$.pipe(
    map((response) => response.data),
    map((users) =>
      users.map((user) => ({
        key: user.uuid,
        label: user.name,
        email: user.email,
      })),
    ),
    tap(() => this.isLoading$.next(false)),
    catchError((error) => {
      this.toastService.error({
        id: 'error',
        message: 'An error occurred while fetching client users data. Please try again.',
      });
      return EMPTY;
    }),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<FiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh() {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.controls.keyword.setValue('');
      this.changePageIndex$.next(0);
    }
  }
}
