import { Injectable } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetFlatCartItem, CartApiService, SortOptions } from '@vdms-hq/api-contract';
import { CartAssetViewModel, CartStateService } from '@vdms-hq/cart-core';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { DataProviderService } from '@vdms-hq/selectors';
import { FieldsScopeKey, ResultDefinitionModel, SelectionManager, SortBy, SortDirection } from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, shareReplay, switchMap } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { RouterParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class CartDataSource extends RouterParamsPagination implements TableAdvancedDataSource<CartAssetViewModel> {
  isLoading$ = this.cartStateService.isUpdating$;
  total$ = new BehaviorSubject<number>(0);

  sortBy$ = new BehaviorSubject<SortBy>('');
  sortDirection$ = new BehaviorSubject<SortDirection>('asc');
  selection: SelectionManager<CartAssetViewModel>;

  cartAssets$ = new BehaviorSubject<{ uuid: string; filename: string; itemUuid: string }[]>([]);
  scope: FieldsScopeKey = 'cart';

  allData$ = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.fieldsConfigService.getConfiguration$(this.scope).pipe(take(1)),
    this.cartStateService.refresh$.pipe(
      tap(() => {
        this.selection.clear();
        this.isLoading$.next(true);
      }),
    ),
  ]).pipe(
    switchMap(([page, perPage, orderBy, orderDir, config]) => {
      const sort: ResultDefinitionModel | undefined = config.table.visible.find((field) => {
        return field.id == orderBy;
      });

      return this.cartApiService
        .getCartAssets({
          pagination: {
            page,
            perPage,
            orderBy: sort?.results2?.sortObjectPath ?? sort?.results2?.objectPath ?? orderBy,
            orderDir,
          },
        })
        .pipe(
          map((response) => {
            const data = response.data.map((item: AssetFlatCartItem) =>
              CartAssetViewModel.fromCartItem(item, {
                dataProvider: this.dataProvider,
                storageUrlService: this.storageUrlService,
                activatedClientService: this.activatedClientService,
              }),
            );

            this.cartAssets$.next(
              data.map((item) => ({
                uuid: item.props.uuid,
                filename: item.props.filename,
                itemUuid: item.context?.uuid,
              })),
            );

            return {
              ...response,
              data,
            };
          }),
          catchError((err) => {
            this.isLoading$.next(false);
            this.toastService.error({ id: 'cart', message: err.message });

            return [];
          }),
        );
    }),
    map(({ data, total }) => {
      this.total$.next(total);
      this.isLoading$.next(false);

      return data;
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  emptyResults$ = combineLatest([this.isLoading$, this.total$]).pipe(
    map(([isLoading, total]) => {
      return isLoading === false && total === 0;
    }),
  );

  constructor(
    private cartApiService: CartApiService,
    private toastService: ToastService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private activatedClientService: ActivatedClientService,
    private cartStateService: CartStateService,
    private fieldsConfigService: FieldsFetcherService,
  ) {
    super();
    this.selection = new SelectionManager<CartAssetViewModel>(this, (item) => item.context?.uuid);
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.isLoading$.next(true);
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.changePageIndex$.next(0);
  }
}
