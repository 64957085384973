import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RateCardService,
  RatesMonthlyType,
  RatesTransactionalAwsType,
  RatesTransactionalHybrikType,
  RatesTransactionalIngestType,
  RatesTransactionalOpenAiType,
  RatesTransactionalProxyType,
  RatesTransactionalTranscodeType,
  RatesTransactionalType,
  RatesTransactionSpecCorrectType,
  RatesType,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, shareReplay, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupCurrencyISO, groupCurrencyOptions, SelectOption } from '@vdms-hq/shared';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-rates-card',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UILayoutModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    FormSectionComponent,
  ],
  templateUrl: './rates-card.component.html',
  styleUrls: ['./rates-card.component.scss'],
})
export class RatesCardComponent implements OnInit, OnDestroy {
  activatedClient = inject(ActivatedClientService);
  rateCardService = inject(RateCardService);
  toastService = inject(ToastService);

  excludeMonthly = ['number_of_vida_users', 'number_of_sharing_users', 'included_number_of_assets'];
  excludeTransactional = ['transcode', 'proxy', 'hybrik', 'openai', 'aws', 'ingest', 'spec_correct', 'vida'];

  ratesForm = new FormGroup({
    currency: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    billing_day_of_the_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    monthly: new FormGroup({
      number_of_vida_users: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      number_of_sharing_users: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      included_number_of_assets: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      vida_subscription_price_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      launchpad_price_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      connect2_price_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      storefront_price_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      hierarchical_storage_management_price_per_month: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      a_v_assets_price_per_asset_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      additional_ancillary_assets_price_per_asset_per_month: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      online_support_price_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      hot_storage_price_per_tb_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      cold_storage_price_per_tb_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      second_hot_region_price_per_tb_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      second_cold_region_price_per_tb_per_month: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    }),
    transactional: new FormGroup({
      egress_price_per_gb: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      https_egress_price_per_gb: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      restoration_price_per_gb: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      asset_processing_fee_price_per_asset: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      asset_delete_fee_price_per_asset: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      manual_a_v_asset_ingest_vida_price_per_asset: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      manual_ancillary_asset_ingest_vida_price_per_asset: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      self_service_a_v_asset_ingest_launchpad_price_per_asset: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      self_service_ancillary_asset_ingest_launchpad_price_per_asset: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      vida_team_metadata_upgrade_price_per_asset: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      transcode: new FormGroup({
        transcode_sd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_hd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_uhd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_5k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_6k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_8k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_minimum_duration_minutes: new FormControl<number>(5, { nonNullable: true }),
        transcode_audio_only_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_frame_rate_modifier_high_fps: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        transcode_frame_rate_modifier_ultra_high_fps: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
      }),
      proxy: new FormGroup({
        proxy_transcode_sd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_hd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_uhd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_5k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_6k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_8k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        proxy_transcode_audio_only_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
      spec_correct: new FormGroup({
        spec_correct_sd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_hd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_uhd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_5k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_6k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_8k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        spec_correct_minimum_duration_minutes: new FormControl<number>(15, { nonNullable: true }),
        spec_correct_audio_only_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
      ingest: new FormGroup({
        ingest_sd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_hd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_uhd_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_5k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_6k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_8k_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        ingest_audio_only_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
      hybrik: new FormGroup({
        hybrik_regenerate_proxy_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_pse_certification_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_video_levels_analysis_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        hybrik_audio_levels_analysis_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        hybrik_vmaf_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_black_boarder_detection_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        hybrik_black_detection_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_interlacing_detection_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        hybrik_audio_normalisation_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_audio_volume_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_video_blockiness_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_video_bitrate_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_make_seamless_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        hybrik_detect_silence_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
      openai: new FormGroup({
        openai_transcription_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        openai_synopsis_generation_price_per_accepted_synopsis: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        openai_translate_transcription_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        openai_generate_ad_breaks_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
      aws: new FormGroup({
        aws_celebrity_recognition_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_object_recognition_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_content_moderation_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_s3_publish_price_per_gb: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_text_detection_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_face_detection_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        aws_video_segment_detection_price_per_min: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
      }),
      vida: new FormGroup({
        vida_detect_seamless_price_per_min: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      }),
    }),
  });

  currencyOptions: SelectOption<GroupCurrencyISO>[] = groupCurrencyOptions;
  #destroyed = new Subject<void>();
  loading$ = new BehaviorSubject(true);

  ngOnInit(): void {
    this.rateCardService
      .getRates()
      .pipe(
        tap(() => this.loading$.next(true)),
        withLatestFrom(this.activatedClient.permissions$),
        takeUntil(this.#destroyed),
        shareReplay(1),
        catchError(() => {
          this.toastService.error({
            id: 'client rates',
            message: 'pages.clients.rate_card.notifications.rate_card_upload_failed',
          });
          this.loading$.next(false);
          this.ratesForm.disable();
          return [];
        }),
      )
      .subscribe((data) => {
        const rateCard = data[0] as RatesType;
        const hasEditRateCard: boolean = data[1].some((permission) => permission === Permission.EDIT_RATES);

        const monthly = {} as RatesMonthlyType;
        if (rateCard.monthly) {
          const notFloat = ['number_of_vida_users', 'number_of_sharing_users', 'included_number_of_assets'];
          const monthlyArray: [string, number | undefined][] = Object.entries(rateCard.monthly);
          monthlyArray.forEach(([key, value]) => {
            if (notFloat.includes(key)) {
              monthly[key] = Number.isFinite(value) ? Number(value) : undefined;
            } else {
              monthly[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
            }
          });
        }

        const transactional = {} as RatesTransactionalType;
        if (rateCard.transactional) {
          const notFloat = [
            'transcode_minimum_duration_minutes',
            'transcode_frame_rate_modifier_high_fps',
            'transcode_frame_rate_modifier_ultra_high_fps',
            'spec_correct_minimum_duration_minutes',
          ];
          const transactionalArray = Object.entries(rateCard.transactional);
          transactionalArray.forEach(([key, value]) => {
            switch (key) {
              case 'transcode': {
                const transcodeArray: [string, number | undefined][] = Object.entries(value);
                const transcode = {} as RatesTransactionalTranscodeType;
                transcodeArray.forEach(([key, value]) => {
                  if (notFloat.includes(key)) {
                    transcode[key] = Number.isFinite(value) ? Number(value) : undefined;
                  } else {
                    transcode[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                  }
                });
                transactional[key] = transcode;
                break;
              }
              case 'proxy': {
                const proxyArray: [string, number | undefined][] = Object.entries(value);
                const proxy = {} as RatesTransactionalProxyType;
                proxyArray.forEach(([key, value]) => {
                  proxy[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                });
                transactional[key] = proxy;
                break;
              }
              case 'ingest': {
                const ingestArray: [string, number | undefined][] = Object.entries(value);
                const ingest = {} as RatesTransactionalIngestType;
                ingestArray.forEach(([key, value]) => {
                  ingest[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                });
                transactional[key] = ingest;
                break;
              }
              case 'spec_correct': {
                const specArray: [string, number | undefined][] = Object.entries(value);
                const spec = {} as RatesTransactionSpecCorrectType;
                specArray.forEach(([key, value]) => {
                  if (notFloat.includes(key)) {
                    spec[key] = Number.isFinite(value) ? Number(value) : undefined;
                  } else {
                    spec[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                  }
                });
                transactional[key] = spec;
                break;
              }
              case 'hybrik': {
                const hybrikArray: [string, number | undefined][] = Object.entries(value);
                const hybrik = {} as RatesTransactionalHybrikType;
                hybrikArray.forEach(([key, value]) => {
                  hybrik[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                });
                transactional[key] = hybrik;
                break;
              }
              case 'openai': {
                const openaiArray: [string, number | undefined][] = Object.entries(value);
                const openai = {} as RatesTransactionalOpenAiType;
                openaiArray.forEach(([key, value]) => {
                  openai[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                });
                transactional[key] = openai;
                break;
              }
              case 'aws': {
                const awsArray: [string, number | undefined][] = Object.entries(value);
                const aws = {} as RatesTransactionalAwsType;
                awsArray.forEach(([key, value]) => {
                  aws[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
                });
                transactional[key] = aws;
                break;
              }
              default:
                transactional[key] = Number.isFinite(value) ? Number(value) / 100 : undefined;
            }
          });
        }

        const rates = {
          currency: rateCard.currency,
          billing_day_of_the_month: rateCard.billing_day_of_the_month ?? 24,
          monthly: monthly,
          transactional: transactional,
        };

        this.ratesForm.patchValue(rates);
        if (!hasEditRateCard) {
          this.ratesForm.disable();
        } else {
          this.ratesForm.enable();
        }
        this.loading$.next(false);
      });
  }

  ngOnDestroy() {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  get monthly() {
    return Object.keys(this.ratesForm.get('monthly')?.getRawValue()).filter(
      (key) => !this.excludeMonthly.includes(key),
    );
  }

  get transactional() {
    return Object.keys(this.ratesForm.get('transactional')?.getRawValue()).filter(
      (key) => !this.excludeTransactional.includes(key),
    );
  }

  get transcode() {
    return Object.keys(this.ratesForm.get('transactional.transcode')?.getRawValue());
  }

  get proxy() {
    return Object.keys(this.ratesForm.get('transactional.proxy')?.getRawValue());
  }

  get hybrik() {
    return Object.keys(this.ratesForm.get('transactional.hybrik')?.getRawValue());
  }

  get openai() {
    return Object.keys(this.ratesForm.get('transactional.openai')?.getRawValue());
  }

  get vida() {
    return Object.keys(this.ratesForm.get('transactional.vida')?.getRawValue());
  }

  get aws() {
    return Object.keys(this.ratesForm.get('transactional.aws')?.getRawValue());
  }

  get ingest() {
    return Object.keys(this.ratesForm.get('transactional.ingest')?.getRawValue());
  }

  get spec_correct() {
    return Object.keys(this.ratesForm.get('transactional.spec_correct')?.getRawValue());
  }

  prepareMonthlyRates() {
    const notFloat = ['number_of_vida_users', 'number_of_sharing_users', 'included_number_of_assets'];
    const monthlyValues = {} as RatesMonthlyType;
    const monthlyValuesArray: [string, number | undefined][] = Object.entries(
      this.ratesForm.get('monthly')?.getRawValue(),
    );
    monthlyValuesArray.forEach(([key, value]) => {
      if (notFloat.includes(key)) {
        monthlyValues[key] = value;
      } else {
        monthlyValues[key] = Number.isFinite(value) ? Math.round(Number(value) * 100) : null;
      }
    });

    console.log('monthlyValues', monthlyValues);

    return monthlyValues;
  }

  prepareTransactionalRates() {
    const notFloat = [
      'transcode_minimum_duration_minutes',
      'transcode_frame_rate_modifier_high_fps',
      'transcode_frame_rate_modifier_ultra_high_fps',
      'spec_correct_minimum_duration_minutes',
      'included_number_of_assets',
    ];
    const transactionalValues = {} as RatesTransactionalType;
    const transactionalValuesArray: [string, number | undefined][] = Object.entries(
      this.ratesForm.get('transactional')?.getRawValue(),
    );
    transactionalValuesArray.forEach(([key, value]) => {
      if (value === null || typeof value !== 'object') {
        transactionalValues[key] = Number.isFinite(value) ? Math.round(Number(value) * 100) : null;
      } else {
        const transactionalValuesObjectArray: [string, number | undefined][] = Object.entries(value);
        const transactionalValuesObject = {} as any;
        transactionalValuesObjectArray.forEach(([key, value]) => {
          if (notFloat.includes(key)) {
            transactionalValuesObject[key] = value;
          } else {
            transactionalValuesObject[key] = Number.isFinite(value) ? Math.round(Number(value) * 100) : null;
          }
        });
        transactionalValues[key] = transactionalValuesObject;
      }
    });

    return transactionalValues;
  }

  save() {
    this.loading$.next(true);

    const rates: RatesType = {
      currency: this.ratesForm.get('currency')?.value,
      billing_day_of_the_month: this.ratesForm.get('billing_day_of_the_month')?.value,
      monthly: this.prepareMonthlyRates(),
      transactional: this.prepareTransactionalRates(),
    };

    this.rateCardService
      .updateRates(rates)
      .pipe(takeUntil(this.#destroyed))
      .subscribe({
        next: () => {
          this.toastService.success({
            id: 'client rates',
            message: 'pages.clients.rate_card.notifications.rate_card_saved',
          });
          this.loading$.next(false);
        },
        error: () => {
          this.toastService.error({
            id: 'client rates',
            message: 'pages.clients.rate_card.notifications.rate_card_save_failed',
          });
          this.loading$.next(false);
        },
      });
  }
}
