import { AssetType } from '../asset';

export type DestinationFlatModel = DestinationModel;

export enum DestinationStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
}

export enum DestinationAccessMethodEnum {
  VIDA = 'VIDA',
  AWS = 'AWS',
  ASPERA_HSTS = 'ASPERA_HSTS',
  ASPERA_AOC = 'ASPERA_AOC',
  ASPERA_SHARES = 'ASPERA_SHARES',
  ASPERA_YOUTUBE = 'ASPERA_YOUTUBE',
  FTPS = 'FTPS',
  YOUTUBE = 'YOUTUBE',
  INGEST_IN_VIDA = 'INGEST_IN_VIDA',
}

export type DestinationConfigStatus = 'not_initialized' | 'submitted' | 'completed' | 'failed' | 'draft';

type DestinationParams = {
  uuid: string;
  status: DestinationStatus;
  name: string;
  type: 'file' | 'title';
  email: {
    delivery: string[];
    notification: string[];
  };
  configs: DestinationConfigModel[];
  publish: {
    type: DestinationAccessMethodEnum;
    credentialUuid?: string;
    configData: {
      region?: string;
      channel?: string;
      bucketName?: string;
      prefix?: string;
      earPassphrase?: string;
      usedEarPassphrase?: boolean;
      path?: string;
      video?: string;
      audio?: string;
      image?: string;
      subtitles?: string;
      archive?: string;
      document?: string;
      other?: string;
    };
  } | null;
};

export type DestinationConfigModel = {
  uuid: string;
  name: string;
  rules: DestinationRule[];
  status?: DestinationConfigStatus;
};

export type DestinationRule = SimpleDestinationRule | VideoDestinationRule;

export type SimpleDestinationRule = {
  assetType: Exclude<AssetType, 'video'>;
  contentClass: string[];
  publish:
    | {
        type: DestinationAccessMethodEnum;
        credentialUuid: string;
        configData: {
          channel?: string;
          region?: string;
          bucketName?: string;
          prefix?: string;
          path?: string;
          video?: string;
          audio?: string;
          image?: string;
          subtitles?: string;
          archive?: string;
          document?: string;
          other?: string;
        };
      }
    | undefined;
};

export type VideoDestinationRule = {
  assetType: 'video';
  contentClass: string[];
  resolutions: string[];
  videoCodecs: string[];
  transcode:
    | {
        type: TranscodeForDeliveryDestinationType;
        overrideProfile: any;
        burnInText?: boolean;
        audioTracks: VideoDestinationAudioTrackModel[];
        enabled?: boolean;
      }
    | undefined;
  publish: {
    type: DestinationAccessMethodEnum;
    enabled?: boolean;
    credentialUuid: string;
    configData: {
      channel?: string;
      region?: string;
      bucketName?: string;
      prefix?: string;
      earPassphrase?: string;
      path?: string;
      video?: string;
      audio?: string;
      image?: string;
      subtitles?: string;
      archive?: string;
      document?: string;
      other?: string;
    };
  };
};

export type VideoDestinationAudioTrackModel = {
  languageUuid?: string;
  contentClass: string;
  layout: string;
};

export type CreateDestinationParams = DestinationParams;

export type UpdateDestinationParams = Partial<DestinationParams>;

export type DestinationModel = DestinationParams & {
  uuid: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDestinationModel = Partial<DestinationParams>;
export type TranscodeForDeliveryDestinationType = 'youtube-vimeo' | 'itunes' | 'hls';
