type StatsData = string | number;

export class CssChartBarViewModel {
  percentValue = 0;
  legendLabel: StatsData = 'Filled metadata';
  legendCounter?: StatsData;
  gridScaleLineCount = 12;

  setLegendLabel(legendLabel?: StatsData) {
    this.legendLabel = legendLabel ?? this.legendLabel;
  }

  setPercentValue(percentValue: number) {
    this.percentValue = percentValue;
  }

  setLegendCounter(legendCounter: StatsData) {
    this.legendCounter = legendCounter;
  }

  setGridScaleLineCount(gridScaleLineCount?: number) {
    this.gridScaleLineCount = gridScaleLineCount ?? this.gridScaleLineCount;
  }

  static createProgressBar(
    percentValue: number,
    legendCounter: StatsData,
    legendLabel?: StatsData,
    gridScaleLineCount?: number,
  ): CssChartBarViewModel {
    const model = new CssChartBarViewModel();

    model.setLegendLabel(legendLabel);
    model.setPercentValue(percentValue);
    model.setLegendCounter(legendCounter);
    model.setGridScaleLineCount(gridScaleLineCount);

    return model;
  }
}
