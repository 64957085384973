<vdms-hq-ui-dialog-wrapper>
  <h1 title class="title">
    {{
      (this.isEditing
        ? 'common.notification_subscriptions.create_dialog.edit_title'
        : 'common.notification_subscriptions.create_dialog.title'
      ) | translate
    }}
  </h1>

  <ng-container content *ngIf="((initialized$ | async) ?? true) === true; else contentLoading">
    <div class="dialog-content" [formGroup]="form" *ngIf="usersToSelect$ | async as clientUsers; else contentLoading">
      <vdms-hq-ui-form-input-select
        [formControl]="selectedUsersControl"
        [multiple]="true"
        [loading]="!!(usersDataSource.isLoading$ | async)"
        [autocompleteOptions]="clientUsers"
        [selectOptions]="clientUsers"
        [requiredOptionsLengthToDisplayFilter]="0"
        [label]="'Select users'"
        (inputChange)="handleUsersInput($event)"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="notification_event_type"
        [label]="'common.notification_subscriptions.notification_type' | translate"
        [selectOptions]="(notificationTypes$ | async) ?? []"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-checkbox
        formControlName="email_notifications_enabled"
        [label]="'common.notification_subscriptions.daily_notifications' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-radio-list
        *ngIf="form.get('email_notifications_enabled')?.value"
        formControlName="email_notification_type"
        [orientation]="'horizontal'"
        [selectOptions]="emailsNotificationTypes"
        [label]="'common.notification_subscriptions.email_notification_type' | translate"
      ></vdms-hq-ui-form-radio-list>
      <vdms-hq-ui-form-input-checkbox
        *ngIf="form.controls.app_notifications_enabled.enabled"
        formControlName="app_notifications_enabled"
        [label]="'common.notification_subscriptions.event_notifications' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </div>
  </ng-container>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="confirm()"
      [loading]="(loading$ | async) ?? false"
      [disabled]="form.invalid"
      color="primary"
    >
      {{ 'common.global.confirm' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #contentLoading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
