<vdms-hq-ui-dialog-wrapper>
  <h1 title>Notification settings</h1>
  <div [formGroup]="notificationsSettingsForm" content>
    <vdms-hq-ui-form-section [divider]="false" layout="single" title="Enable/Disable Email Settings">
      <vdms-hq-ui-form-input-toggle
        formControlName="asset_added_licensed_package"
        label="Assets Added"
      ></vdms-hq-ui-form-input-toggle>
      <vdms-hq-ui-form-input-toggle
        formControlName="material_availability_start"
        label="Contract Start - Assets are available"
      ></vdms-hq-ui-form-input-toggle>
      <vdms-hq-ui-form-input-number
        formControlName="material_availability_days_before_contract_start"
        [label]="'Days Before Contract Start'"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-toggle
        formControlName="rights_contract_start"
        label="Contract Start - Contract starts"
      ></vdms-hq-ui-form-input-toggle>
      <vdms-hq-ui-form-input-toggle
        formControlName="rights_contract_end"
        label="Contract End"
      ></vdms-hq-ui-form-input-toggle>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [divider]="false" title="Assets sort">
      <vdms-hq-ui-form-input-select
        formControlName="sort_assets_list_by_field"
        [label]="'Sort field'"
        [selectOptions]="sortFieldOptions"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="sort_assets_list_order_dir"
        [label]="'Sort direction'"
        [selectOptions]="sortDirOptions"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section [divider]="false" layout="single" title="Download expiry reminders">
      <vdms-hq-ui-form-input-toggle
        formControlName="order_download_expiry_reminder"
        label="Download Expiration Reminder"
      ></vdms-hq-ui-form-input-toggle>
      <vdms-hq-ui-form-input-number
        formControlName="reminder_days_before_order_download_expiry"
        label="Days before expiration reminder"
      >
      </vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      (click)="saveNotificationSettings()"
      [loading]="loading"
      [disabled]="notificationsSettingsForm.pristine || loading"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
