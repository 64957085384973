import { FunctionLike } from './parse';

export const isArray = (value: any): boolean => Array.isArray(value);

export const isFunction = (value: any): boolean => typeof value === 'function';

export const isNil = (value: any): boolean => value === null || typeof value === 'undefined';

export const isNull = (value: any): boolean => value === null;

export const isNumber = (value: any): boolean => typeof value === 'number';

export const isObject = (value: any): boolean => !!value && typeof value === 'object';

export const isString = (value: any): boolean => typeof value === 'string';

export const isUndefined = (value: any): boolean => typeof value === 'undefined';

export function toArray(object: any): Array<any> {
  return isArray(object) ? object : Object.keys(object).map((key) => object[key]);
}

export const pad = (requiredLength: number, padChar = ' '): string =>
  padChar.repeat(requiredLength).slice(0, requiredLength);

export function equals(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (!isObject(a) || !isObject(b)) {
    return a === b;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  const hasOwn = Object.prototype.hasOwnProperty;

  for (const key of keysA) {
    if (!hasOwn.call(b, key) || !equals(a[key], b[key])) {
      return false;
    }
  }
  return true;
}

export function objectContains(partial: { [key: string]: any }, object: { [key: string]: any }): boolean {
  return Object.keys(partial).every((key: string) => key in object && object[key] === partial[key]);
}

export const convertToDecimal = (num: number, decimal: number): number => {
  return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

export function getFirstMatches(array: any, n: number, getter: FunctionLike) {
  let count = 0;

  return array.filter((elm: any) => {
    const rest = !isUndefined(getter) ? count < n && getter(elm) : count < n;
    count = rest ? count + 1 : count;

    return rest;
  });
}
