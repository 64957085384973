import { Routes } from '@angular/router';
import { CollectionsComponent } from './collections/collections.component';
import { SingleCollectionComponent } from './single-collection/single-collection.component';
import { SingleCollectionResolverService } from '../logic/services/single-collection-resolver.service';

export enum COLLECTIONS_ROUTER_BASE {
  COLLECTIONS = 'collections',
  COLLECTIONS_COLLECTION = ':uuid',
}

export const collectionsRoutes: Routes = [
  {
    path: '',
    component: CollectionsComponent,
  },
  {
    path: COLLECTIONS_ROUTER_BASE.COLLECTIONS_COLLECTION,
    component: SingleCollectionComponent,
    resolve: {
      uuid: SingleCollectionResolverService,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
