<vdms-hq-ui-dialog-wrapper>
  <ng-container title>
    {{ 'common.orders.change_discount.title' | translate }}
  </ng-container>

  <div content class="relative">
    <vdms-hq-ui-form-input-select
      placeholder="Select a discount"
      [formControl]="selectFormControl"
      [selectOptions]="this.discountsOptions$ | async"
      [loading]="loadingOptions"
    >
    </vdms-hq-ui-form-input-select>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="abort()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="save()" color="primary">{{
      'common.orders.change_discount.change' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
