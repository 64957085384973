<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.mandatory-fields.title' | translate }}</span>
  </div>
  <div content [formGroup]="form">
    @if (loading$ | async) {
      <vdms-hq-ui-loader [center]="true" [backdrop]="true"></vdms-hq-ui-loader>
    } @else {
      <vdms-hq-ui-form-input-select
        formControlName="asset_type"
        [label]="'common.mandatory-fields.modal_fields.asset_type' | translate"
        [selectOptions]="assetTypes"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-selector
        [sourceType]="selectorType.CONTENT_TYPE"
        formControlName="content_type"
        [label]="'common.mandatory-fields.modal_fields.content_type' | translate"
        [multiple]="false"
      ></vdms-hq-selector>

      <vdms-hq-selector
        formControlName="content_class"
        [sourceType]="selectorType.CONTENT_CLASS"
        [label]="'common.mandatory-fields.modal_fields.content_class' | translate"
        [multiple]="false"
      ></vdms-hq-selector>

      <ng-container formArrayName="fields">
        @for (control of formArray.controls; track control; let arrayIndex = $index) {
          <div class="form-field">
            <vdms-hq-ui-form-input-select
              [formControlName]="arrayIndex"
              [label]="('common.mandatory-fields.modal_fields.field' | translate) + ' #' + (arrayIndex + 1)"
              [selectOptions]="allPossibleFieldsSelectOptions$ | async"
            ></vdms-hq-ui-form-input-select>
            <vdms-hq-ui-button [iconOnly]="true" icon="remove" (click)="removeField(arrayIndex)"></vdms-hq-ui-button>
          </div>
        }
      </ng-container>

      <vdms-hq-ui-button color="secondary" (click)="addField()"
        >{{ 'common.mandatory-fields.add_field' | translate }}
      </vdms-hq-ui-button>
    }
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="loading$ | async" (click)="save()" [disabled]="form.invalid">
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
