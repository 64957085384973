<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.metadata_snapshots.heading' | translate }]">
  <form [formGroup]="form" class="form">
    <vdms-hq-ui-form-input-select
      formControlName="delivery_type"
      [selectOptions]="deliveryOptions"
      [label]="'common.metadata_snapshots.fields.delivery_type' | translate"
    ></vdms-hq-ui-form-input-select>

    <vdms-hq-ui-form-section
      *ngIf="this.form.value.delivery_type !== MetadataSnapshotDeliveryType.DAILY"
      [layout]="this.getDeliveryLayout()"
      [title]="'common.metadata_snapshots.fields.delivery_frequency_options' | translate"
    >
      <vdms-hq-ui-form-input-select
        *ngIf="this.form.value.delivery_type === MetadataSnapshotDeliveryType.ANNUALLY"
        formControlName="deliveryMonth"
        [selectOptions]="months"
        [label]="'common.metadata_snapshots.fields.month' | translate"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-select
        *ngIf="this.form.value.delivery_type === MetadataSnapshotDeliveryType.WEEKLY"
        formControlName="deliveryDayWeek"
        [selectOptions]="weekdays"
        [label]="'common.metadata_snapshots.fields.day_of_week' | translate"
        [isSortedByLabel]="true"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-number
        *ngIf="this.showDayMonth()"
        formControlName="deliveryDayMonth"
        [label]="'common.metadata_snapshots.fields.day_of_month' | translate"
        [min]="1"
        [max]="28"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [layout]="'grid-2-columns'"
      [title]="'common.metadata_snapshots.fields.metadata_header' | translate"
      [dividerMarginBlock]="true"
    >
      <vdms-hq-ui-form-input-select
        *ngIf="columns$ | async; let columns"
        formControlName="columns"
        [selectOptions]="columns"
        [multiple]="true"
        [label]="'common.metadata_snapshots.fields.metadata' | translate"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-number
        formControlName="storage_days"
        [label]="'common.metadata_snapshots.fields.storage_days' | translate"
      >
      </vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [divider]="true"
      [title]="'common.metadata_snapshots.fields.delivery_destination' | translate"
    >
      <vdms-hq-ui-form-input-select
        formControlName="delivery_destination"
        [selectOptions]="deliveryDestinationOptions"
        [label]="'common.metadata_snapshots.fields.delivery_destination' | translate"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [divider]="false"
      [title]="'common.metadata_snapshots.fields.s3_header' | translate"
      *ngIf="this.form.value.delivery_destination === MetadataSnapshotDeliveryDestination.S3"
      [layout]="'grid-2-columns'"
    >
      <vdms-hq-ui-form-input-select
        formControlName="s3Region"
        [selectOptions]="AwsRegionsOptions"
        [label]="'common.delivery_destinations.fields.region' | translate"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-text
        formControlName="s3BucketName"
        [label]="'common.metadata_snapshots.fields.s3_bucket' | translate"
      >
      </vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="s3Prefix"
        [label]="'common.metadata_snapshots.fields.s3_prefix' | translate"
      >
      </vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        formControlName="s3CredentialUuid"
        [selectOptions]="credentialsSelectorSourceAWS"
        [label]="'common.delivery_destinations.fields.credentials' | translate"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [divider]="false"
      [title]="'common.metadata_snapshots.fields.emails_header' | translate"
      *ngIf="this.form.value.delivery_destination === MetadataSnapshotDeliveryDestination.EMAIL"
    >
      <div class="emails">
        <vdms-hq-ui-form-input-text
          [formControl]="addUser"
          [placeholder]="'common.metadata_snapshots.fields.email_add_hint' | translate"
          [label]="'common.metadata_snapshots.fields.emails' | translate"
          (keyup.enter)="!addUser.errors && attachUser(addUser.value)"
          [fallbackSuffixIcon]="'add'"
          (clickSuffix)="!addUser.errors && attachUser(addUser.value)"
        ></vdms-hq-ui-form-input-text>
        <mat-chip-listbox class="material-custom-chip-input">
          <mat-chip-option
            *ngFor="let user of form.get('emails').value"
            [selectable]="false"
            [removable]="true"
            (removed)="detachUser(user.key)"
            class="users-chip"
          >
            {{ user.label }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
        <mat-error *ngIf="form.hasError('emailRequired')">Email is required</mat-error>
      </div>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-actions-footer>
      <vdms-hq-ui-button right color="warn" (click)="delete()" [disabled]="configNotSet">
        {{ 'common.global.delete' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button right [disabled]="form.invalid" color="primary" (click)="save()">
        {{ 'common.global.save' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button left color="secondary" (click)="runSnapshot()">
        {{ 'Run snapshot' }}
      </vdms-hq-ui-button>
    </vdms-hq-ui-actions-footer>
  </form>
</vdms-hq-ui-layout-page-wrapper>
