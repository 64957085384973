import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeLogDirection, EmbargoDates } from '@vdms-hq/api-contract';
import { DataProviderService, SelectorSourceType } from '@vdms-hq/selectors';
import {
  FormatBitratePipe,
  FormatBytesPipe,
  FormatterOutput,
  ReactiveTzDatePipe,
  ValueFormat,
  ValueFormatter,
  ViewFormat,
} from '@vdms-hq/shared';
import moment from 'moment';
import { of, take } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DefaultValueFormatterService implements ValueFormatter {
  readonly formatting: {
    dateFormat: string;
    dateTimeFormat: string;
    ellipsisMaxLength: number;
  } = {
    dateFormat: 'dd-MM-yyyy',
    dateTimeFormat: 'dd-MM-yyyy hh:mm a',
    ellipsisMaxLength: 50,
  };

  constructor(
    private datePipe: DatePipe,
    private provider: DataProviderService,
    private preferredTzDate: ReactiveTzDatePipe,
    private translate: TranslateService,
  ) {}

  format(value: any, definition: { sourceListKey?: SelectorSourceType; valueFormat: ValueFormat }): FormatterOutput {
    switch (definition.valueFormat) {
      case ValueFormat.PROCESSING_STATUS: {
        if (!value) {
          return null;
        }
        if (value?.toLowerCase() === 'created') {
          return 'Initializing';
        } else {
          return value.split('_').join(' ');
        }
      }
      case ValueFormat.SELECTOR:
      case ValueFormat.SELECTOR_MULTI:
        if (!definition.sourceListKey || !value) {
          return null;
        }
        if (typeof value !== 'string' && value.every((val: string) => val.match('(([0-9a-fA-F]{4}\\-){3})'))) {
          return this.provider.getValueForGroupSelector(SelectorSourceType.ASSET_GENERAL_RATINGS, value);
        }
        return this.provider.getValueForSelector(definition.sourceListKey, value)?.pipe(map((value) => value ?? null));
      case ValueFormat.SELECT_GROUP:
        if (!definition.sourceListKey || !value) {
          return null;
        }
        if (typeof value === 'string') {
          value = [value];
        }
        return of(value);
      case ValueFormat.TRANSLATE:
        return this.translate.instant(value);
      case ValueFormat.UPPERCASE:
        return typeof value === 'string' ? value.toUpperCase() : value;
      case ValueFormat.DATE:
        return this.datePipe.transform(value, this.formatting.dateFormat);
      case ValueFormat.DATETIME:
        return this.datePipe.transform(value, this.formatting.dateTimeFormat);
      case ValueFormat.TZ_DATETIME:
        return this.preferredTzDate.transform(value, this.formatting.dateTimeFormat);
      case ValueFormat.TZ_DATE:
        return this.datePipe.transform(value, this.formatting.dateFormat);
      // WAITING FOR THE FINAL DECISION FROM DAVID about TX DATE where should it be applied
      // return this.tzDaTe.transform(value, this.formatting.dateFormat);
      case ValueFormat.HOT_COLD:
        return value === ChangeLogDirection.COLD_TO_HOT ? 'HOT' : 'COLD';
      case ValueFormat.COLD:
        return value === 'cold' ? 'Yes' : 'No';
      case ValueFormat.YES_NO:
        return !isNaN(Number(value)) && Number(value) === 1 ? 'Yes' : 'No';
      case ValueFormat.YES_EMPTY:
        return !isNaN(Number(value)) && Number(value) === 1 ? 'Yes' : '';
      case ValueFormat.TICK_CROSS:
        return !isNaN(Number(value)) && Number(value) === 1 ? '✓' : '✗';
      case ValueFormat.TICK_CROSS_WAIT:
        return !isNaN(Number(value)) && Number(value) === 1 ? '✓' : Number(value) === 2 ? '✗' : '◔';
      case ValueFormat.APPROVAL_STATUS:
        return !isNaN(Number(value)) && Number(value) === 1 ? 'approved' : Number(value) === 2 ? 'rejected' : 'pending';
      case ValueFormat.VALUES_OF_OBJECT:
        return value
          .filter((value: any) => {
            return !Object.values(value).every((v) => v == null);
          })
          .map((v: any) => {
            return Object.values(v)
              .filter((v) => v)
              .join(', ');
          });
      case ValueFormat.FILE_SIZE:
        return FormatBytesPipe.transformWithUnit(value);
      case ValueFormat.MINIFIED_USER_DETAILS:
        if (!value) {
          return 'N/A';
        }
        return `${value.name} (${value.email})`;
      case ValueFormat.CAMEL_CASE:
        return typeof value === 'string'
          ? value
              .replace(/([A-Z])/g, ' $1')
              .trim()
              .toUpperCase()
          : value;
      case ValueFormat.ELLIPSIS:
        if (typeof value !== 'string' || !value) {
          return value;
        }
        if (value.length > this.formatting.ellipsisMaxLength) {
          return value.substring(0, this.formatting.ellipsisMaxLength) + '...';
        }
        return value;
      case ValueFormat.DATE_TIME_UNIX_TIMESTAMP_LIMITED:
        switch (definition.sourceListKey) {
          case SelectorSourceType.EMBARGO: {
            const date = moment(value).utc().toISOString();
            return this.provider.getValueForSelector(definition.sourceListKey, date).pipe(
              take(1),
              map((result) => {
                if (result) {
                  return result;
                }
                if (!value) {
                  return '';
                }
                if (!Object.values(EmbargoDates).includes(date as EmbargoDates)) {
                  return this.datePipe.transform(value, this.formatting.dateTimeFormat);
                }
                return value;
              }),
            );
          }
          default:
            return this.datePipe.transform(value, this.formatting.dateTimeFormat);
        }
      case ValueFormat.BITRATE_UNIT:
        return FormatBitratePipe.transformWithUnit(value);
      case ValueFormat.NATURAL_NUMBERS_WITH_ZERO:
        if (!isNaN(value) && ![null, undefined, ''].includes(value)) {
          return Number(value) === 0 ? '0' : Number(value);
        }
        return '';
      case ValueFormat.FRAMERATE:
        return ![null, undefined, '', 'null'].includes(value) ? value : null;
      case ValueFormat.TAGS:
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return null;
        }
        return value
          .map((value: { name: string; value: string }) => ({ name: value.name, value: value.value }))
          .map((value: { name: string; value: string }) => Object.values(value).join(': '))
          .join(', ');
    }

    return (Array.isArray(value) ? value.map((value) => value.toString()) : value?.toString()) ?? value;
  }

  formatClasses(
    value: any,
    definitionData?: { path: string; valueFormat: ValueFormat; viewFormat: ViewFormat },
  ): string {
    if (!definitionData) {
      return value === 'completed' ? 'done' : value === 'failed' ? 'failed' : '';
    }

    if (!definitionData?.viewFormat?.pills) {
      return '';
    }

    let pillValue = value;
    if (
      [ValueFormat.DATE, ValueFormat.DATETIME, ValueFormat.DATE_TIME_UNIX_TIMESTAMP_LIMITED].includes(
        definitionData.valueFormat,
      )
    ) {
      const isPast = new Date(value).getTime() < new Date().getTime();
      pillValue = isPast ? 'past' : 'future';
    }
    return ['pill', definitionData.viewFormat.pills[pillValue] ?? definitionData.viewFormat.pills['default']].join(' ');
  }
}
