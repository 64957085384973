<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.account_settings.personal_details' }]">
  <section [formGroup]="personalControl">
    <div class="form-group mb-0">
      <vdms-hq-ui-form-input-email
        formControlName="email"
        [placeholder]="'pages.settings.email_placeholder' | translate"
        [label]="'pages.settings.email' | translate"
        [hint]="!personalControl.get('email')?.disabled ? '' : ('pages.settings.email_permission_hint' | translate)"
      ></vdms-hq-ui-form-input-email>
    </div>

    <div class="form-group mb-0">
      <vdms-hq-ui-form-input-text
        formControlName="displayName"
        [placeholder]="'pages.settings.name_placeholder' | translate"
        [label]="'pages.settings.name' | translate"
      ></vdms-hq-ui-form-input-text>
    </div>

    <div formGroupName="phone" class="form-group form-group__inline">
      <div class="w-140px">
        <vdms-hq-ui-form-input-select
          formControlName="type"
          [label]="'pages.settings.phone_type' | translate"
          [selectOptions]="phoneTypes"
        ></vdms-hq-ui-form-input-select>
      </div>
      <vdms-hq-ui-form-input-phone
        [numberLabel]="numberLabel"
        [numberPlaceholder]="numberPlaceholder"
        formControlName="number"
        class="w-100 d-block"
      ></vdms-hq-ui-form-input-phone>
    </div>
  </section>
</vdms-hq-ui-layout-page-wrapper>
<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.account_settings.company_address' }]">
  <section [formGroup]="companyControl">
    <div class="form-group">
      <vdms-hq-ui-form-input-text
        formControlName="address1"
        [label]="'pages.settings.address_line' | translate: { lineNumber: 1 }"
      ></vdms-hq-ui-form-input-text>
    </div>

    <div class="profile-company-address__wrapper">
      <div class="w-140px">
        <vdms-hq-ui-form-input-text
          formControlName="postalCode"
          [label]="'pages.settings.postal_code' | translate"
          [mask]="'0*'"
        ></vdms-hq-ui-form-input-text>
      </div>
      <div class="w-100">
        <vdms-hq-ui-form-input-select
          formControlName="country"
          [label]="'pages.settings.country' | translate"
          [selectOptions]="countries"
        ></vdms-hq-ui-form-input-select>
      </div>
    </div>
  </section>
  <section>
    <div class="w-100">
      <vdms-hq-ui-form-input-select
        *ngIf="departmentOptions.length"
        [formControl]="departmentControl"
        [label]="'Department' | translate"
        [selectOptions]="departmentOptions"
      ></vdms-hq-ui-form-input-select>
    </div>
  </section>
</vdms-hq-ui-layout-page-wrapper>
<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.generate_api_token.heading' }]">
  <ng-container *requiresPermissions="[Permission.CREATE_API_TOKENS]">
    <vdms-hq-generate-user-api-token
      [userApiToken]="settingsDataService.userToken$ | async"
      [expiredAt]="settingsDataService.userTokenExpiredAt$ | async | date: 'short'"
      (generate)="settingsDataService.generateToken($event)"
    ></vdms-hq-generate-user-api-token>
  </ng-container>
</vdms-hq-ui-layout-page-wrapper>
