<app-chat-agent *requiresPermissions="[Permission.SUPPORT_CHAT_AGENT]"></app-chat-agent>
@if (stickyMessageVisible) {
  <vdms-hq-ui-sticky-message
    [messageCollapsed]="'common.global.aspera_maintenance_window_collapsed' | translate"
    [messageExpanded]="'common.global.aspera_maintenance_window_expanded' | translate"
  ></vdms-hq-ui-sticky-message>
}
<vdms-hq-ui-site-navigation [disableVerticalScroll]="true">
  <vdms-hq-ui-navbar
    navbar
    [tourConfig]="{ userPanel, menuSidebarToggle }"
    [displayName]="(displayName$ | async) ?? ''"
    [sidenavState]="(sidenavState$ | async) ?? 'expanded'"
    [theme]="(theme$ | async) ?? Theme.dark"
    (toggleSidenav)="toggleSidenav()"
    (toggleTheme)="toggleTheme()"
    (logout)="logout()"
  >
    <app-logo logo></app-logo>

    <div class="search-bar" *requiresPermissions="[Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS]" search>
      @if (searchbarVisibilityService.searchBarVisibility$ | async) {
        <vdms-hq-search-autocomplete class="full-width"></vdms-hq-search-autocomplete>
      }
      @if (returnButtonService.returnButtonVisibility$ | async) {
        <vdms-hq-return-results></vdms-hq-return-results>
      }
    </div>

    <vdms-hq-ac-client-switch clients [bdcWalkTriggerFor]="clientSwitcher"></vdms-hq-ac-client-switch>

    <vdms-hq-notifications-button
      notificationsButton
      *requiresPermissions="[Permission.BROWSE_NOTIFICATIONS]"
    ></vdms-hq-notifications-button>

    <vdms-hq-cart-button
      cartButton
      *requiresPermissions="[Permission.SHOPPING_CART]"
      [bdcWalkTriggerFor]="cartButton"
    ></vdms-hq-cart-button>

    <ng-container menuOptions>
      <button mat-menu-item class="menu-item" (click)="runTour()">Tour guide</button>
      <div mat-menu-item [routerLink]="['/account-settings']" class="menu-item menu-item-settings">
        {{ 'pages.home.menu.settings' | translate }}
      </div>
    </ng-container>
  </vdms-hq-ui-navbar>
  <vdms-hq-ui-sidenav
    [sharedE2eId]="'sidenav'"
    *requiresPermissions="[Permission.GENERAL_LOGIN_TO_VIDA]"
    sidebar
    [config]="(config$ | async) ?? []"
    [tourConfig]="{ menuSidebar, menuAssets, menuLibrary }"
    [isSalesForceEnabled]="isSalesForceEnabled$ | async"
    [isExternalSupport]="true"
  ></vdms-hq-ui-sidenav>
  <router-outlet></router-outlet>
</vdms-hq-ui-site-navigation>

<vdms-hq-tour-item #cartButton configName="cartButton"></vdms-hq-tour-item>
<vdms-hq-tour-item #clientSwitcher configName="clientSwitcher"></vdms-hq-tour-item>
<vdms-hq-tour-item #userPanel configName="userPanel"></vdms-hq-tour-item>
<vdms-hq-tour-item #menuSidebarToggle configName="menuSidebarToggle"></vdms-hq-tour-item>
<vdms-hq-tour-item #menuSidebar configName="menuSidebar"></vdms-hq-tour-item>
<vdms-hq-tour-item #menuLibrary configName="menuLibrary"></vdms-hq-tour-item>
<vdms-hq-tour-item #menuAssets configName="menuAssets"></vdms-hq-tour-item>
