import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-vida-updates-dialog',
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, TranslateModule, UIButtonModule],
  templateUrl: './vida-updates-dialog.component.html',
  styleUrls: ['./vida-updates-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VidaUpdatesDialogComponent {
  private dialogRef = inject(MatDialogRef<VidaUpdatesDialogComponent>);

  dialogTitle = 'New Year, New VIDA';
  dialogContent =
    '<b>We’re thrilled to announce a refreshed user interface in VIDA! Here’s what’s new:</b><br><br>' +
    '<span class="emoticons">🎨</span>  Enhanced Color Scheme: Sleek, modern colors for a visually engaging experience.<br>' +
    '<span class="emoticons">🕹️</span>  Improved Controls: Intuitive and responsive controls for smoother navigation.<br>' +
    '<span class="emoticons">💻</span>  Streamlined Layout: Optimized design for better usability and faster access to key features.<br><br>' +
    'Explore the updated VIDA UI today and enjoy a more vibrant and user-friendly experience!<br><br>';
  dialogImg = 'assets/common/vida-updates/hero-banner.png';
  dialogBtnText = "Ok, let's check it Out!";

  closeDialog() {
    this.dialogRef.close();
  }
}
