import { inject, Injectable } from '@angular/core';
import {
  WebsocketEntitiesUpdate,
  WebsocketEntitiesUpdateInterface,
  WebsocketNotificationInterface,
  WsRequestMessage,
} from '@vdms-hq/shared';
import { UpdatesStreamService } from './updates-stream.service';
import { delay, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { shareReplay, startWith, take } from 'rxjs';
import { AssetFlat } from '@vdms-hq/api-contract';

export type AssetFlatView = Partial<AssetFlat> & Pick<AssetFlat, 'uuid'>;

@Injectable({ providedIn: 'root' })
export class AssetsUpdaterService {
  #existingUuids: string[] = [];
  private readonly stream = inject(UpdatesStreamService);

  listener$ = this.stream.connect().pipe(
    map((next: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) => {
      const isUuidUpdated = this.#isPullAboutChangeUuid(next);

      if (isUuidUpdated) {
        const uuids = (isUuidUpdated.data?.uuids ?? []).filter((nextUuid) => this.#existingUuids.includes(nextUuid));

        if (uuids?.length === 0) {
          return false;
        }

        const message = this.#generateAssetDetailsMessage(uuids, isUuidUpdated.groupUuid);

        if (!message) {
          return false;
        }

        message?.data?.uuids?.length && this.stream.sendWSMessage(message);

        return [];
      }

      const isDataUpdated = this.#isPullAboutChangeAssetDetails(next);

      if (isDataUpdated) {
        return next.data;
      }

      if (this.#isDeletedData(next)) {
        return this.#handleDeletedData(next);
      }
      return next;
    }),
    startWith([]),
    shareReplay(1),
    retryWhen((errors) =>
      errors.pipe(
        tap((err) => console.error('WebSocket connection error', err)),
        delay(5000), // Wait 5 seconds before retrying
        take(10), // Retry up to 10 times
        finalize(() => console.error('Retries unsuccessful after 10 attempts')),
      ),
    ),
  );

  unregisterListener() {
    this.stream.destroy();
  }

  enableFilteringOnlyExistingUuids(uuids: string[]) {
    this.#existingUuids = uuids;
  }

  disableFilteringExistingUuids() {
    this.#existingUuids = [];
  }

  #generateAssetDetailsMessage(uuids: string[], groupUuid: string): WsRequestMessage | undefined {
    if (!uuids.length || !groupUuid) {
      return;
    }

    return {
      action: 'request',
      method: 'getAssetsDetails',
      data: {
        uuids: uuids,
        groupUuid: groupUuid,
      },
    };
  }

  #isPullAboutChangeAssetDetails(message: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) {
    return (
      'data' in message &&
      Array.isArray(message?.data) &&
      (message as unknown as WebsocketNotificationInterface<AssetFlatView[]>)
    );
  }

  #isPullAboutChangeUuid(
    message: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>,
  ): WebsocketNotificationInterface<WebsocketEntitiesUpdate> | false {
    if ('entityType' in (message.data ?? {})) {
      const entityUpdateMsg = message as WebsocketNotificationInterface<WebsocketEntitiesUpdate>;

      if (entityUpdateMsg.data?.entityType === 'Asset' && Array.isArray(entityUpdateMsg.data?.uuids)) {
        return entityUpdateMsg;
      }
    }

    return false;
  }

  #isDeletedData(message: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) {
    return message.context === 'DELETE' && !!message.data && 'uuids' in message.data;
  }

  #handleDeletedData(
    nextData: WebsocketNotificationInterface<WebsocketEntitiesUpdate | WebsocketEntitiesUpdateInterface<AssetFlatView>>,
  ) {
    const deletedAssets = (nextData.data as WebsocketEntitiesUpdate).uuids.map((uuid) => ({
      uuid,
      deleted_at: new Date().toString(),
    })) as AssetFlatView[];

    return deletedAssets;
  }
}
