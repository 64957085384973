import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { AssetApiService, AssetSearchFilterParam, HotColdEstimateResponse } from '@vdms-hq/api-contract';
import {
  InfoBarClickAction,
  InfoBarType,
  UIButtonModule,
  UIDialogWrapperModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@vdms-hq/shared';

export interface AssetsColdEstimationData {
  assetsIds: string[];
  filters?: AssetSearchFilterParam;
}

export interface AssetsColdEstimationResult {
  confirmed: boolean;
}

@Component({
  selector: 'vdms-hq-assets-cold-estimation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UILoaderModule,
    UIButtonModule,
    UILayoutModule,
    SharedModule,
  ],
  templateUrl: './assets-cold-estimation-dialog.component.html',
  styleUrls: ['./assets-cold-estimation-dialog.component.scss'],
})
export class AssetsColdEstimationDialogComponent implements OnInit, OnDestroy {
  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;
  private destroyed$: Subject<void> = new Subject<void>();
  isLoading$ = new BehaviorSubject<boolean>(true);
  estimationData$ = new BehaviorSubject<HotColdEstimateResponse | null>(null);

  dataError$ = new BehaviorSubject<boolean>(false);

  constructor(
    private assetApiService: AssetApiService,
    private dialogRef: MatDialogRef<AssetsColdEstimationDialogComponent, AssetsColdEstimationResult>,
    @Inject(MAT_DIALOG_DATA) public data: AssetsColdEstimationData,
  ) {}

  ngOnInit(): void {
    this.assetApiService
      .getAssetsMoveToColdEstimation(this.data.assetsIds, this.data.filters)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.estimationData$.next(response);
          this.isLoading$.next(false);
        },
        error: () => {
          this.dataError$.next(true);
          this.isLoading$.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  confirm() {
    this.dialogRef.close({ confirmed: true });
  }

  close(): void {
    this.dialogRef.close();
  }
}
