<mat-form-field
  appearance="outline"
  class="material-custom-field textarea"
  [class.alternative-appearance]="alternativeAppearance"
  [class.button-suffix]="!!suffixConfig"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <textarea
    matInput
    [required]="isRequired"
    [formControl]="innerFormControl"
    (blur)="propagateTouch()"
    (keydown.enter)="handleEnter($event)"
    [placeholder]="placeholder"
    autocomplete="off"
    class="mat-form-field"
    #autosize="cdkTextareaAutosize"
    [cdkTextareaAutosize]="true"
    [cdkAutosizeMinRows]="minRows"
    [class.small]="small"
    [matAutocompleteDisabled]="!autocompleteList"
    [matAutocomplete]="auto"
    [matAutocompletePosition]="'below'"
  ></textarea>

  <mat-icon
    matSuffix
    *ngIf="suffixConfig?.onlyIcon && suffixConfig?.icon; else default"
    class="suffix-button"
    (click)="suffixClick($event)"
    >{{ suffixConfig?.icon }}
  </mat-icon>

  <div class="menu--holder"></div>

  <ng-template #default>
    <vdms-hq-ui-button
      matSuffix
      [color]="suffixConfig.type"
      class="suffix-button"
      @enterAnimation
      *ngIf="suffixConfig && !suffixConfig?.onlyIcon; else fallbackSuffixIconTemplate"
      (click)="clickSuffix.emit()"
    >
      <ng-container *ngIf="suffixConfig.text">
        {{ suffixConfig.text }}
      </ng-container>
      <mat-icon *ngIf="suffixConfig.icon">{{ suffixConfig.icon }}</mat-icon>
    </vdms-hq-ui-button>
  </ng-template>

  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate: error.contextParams }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</mat-form-field>

<ng-template #fallbackSuffixIconTemplate>
  <span
    matSuffix
    class="suffix"
    *ngIf="innerFormControl.valid && suffixConfig && !suffixConfig.onFocus"
    (click)="clickSuffix.emit(); $event.stopPropagation()"
  >
    <mat-icon>{{ fallbackSuffixIcon }}</mat-icon>
  </span>
</ng-template>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option
    class="autocomplete"
    *ngFor="let option of autocompleteList"
    [value]="option.label"
    (click)="writeValue(option.label)"
  >
    {{ option.label | truncate: 60 }}
  </mat-option>
</mat-autocomplete>
