import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { EMAIL_NOTIFICATION_TYPES } from '@vdms-hq/api-contract';
import { NotificationSettingsFormService } from '../../logic/notification-settings-form.service';

@Component({
  selector: 'vdms-hq-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, UIButtonModule, UIFormModule, UILayoutModule],
  providers: [],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  readonly emailsNotificationTypes = [
    { key: EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE, label: 'Per Occurrence' },
    { key: EMAIL_NOTIFICATION_TYPES.DAILY_SUMMARY, label: 'Daily Summary' },
  ];

  constructor(protected notificationForm: NotificationSettingsFormService) {}

  ngOnInit(): void {
    this.notificationForm.getUserSubscriptions();
  }

  ngOnDestroy() {
    this.notificationForm.destroy$.next();
    this.notificationForm.destroy$.complete();
  }
}
