import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiDetailedResourcePaginatedResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { APIEndpoints, DetailedPreviewRequest, PreviewRequest } from './preview-request.model';
import { Observable } from 'rxjs';
import { GetDetailedResponsePaginationData, GetResponseData } from '../../operators/get-response-data.operator';
import { HttpHeaders } from '@angular/common/http';
import { PaginationAPIModel as Pagination, PaginationAPIProps as PaginationProps } from '../pagination.model';

interface PreviewRequestFilters {
  filter: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class PreviewRequestApiService {
  constructor(private apiService: ApiService) {}

  getList = (
    filters: PreviewRequestFilters,
    pagination?: PaginationProps,
  ): Observable<ApiPaginatedResponse<PreviewRequest>> => {
    if (pagination) pagination = this.#paginationMap(pagination);

    let headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    return this.apiService.get<ApiPaginatedResponse<PreviewRequest>>(`${APIEndpoints.ROOT_LIST}`, { headers });
  };

  get = (uuid: string) => {
    return this.apiService.get<ApiResponse<PreviewRequest>>(`${APIEndpoints.ROOT}/${uuid}`).pipe(GetResponseData);
  };

  getAssets = (
    uuid: string,
    filters: PreviewRequestFilters,
    pagination: PaginationProps,
  ): Observable<ApiDetailedResourcePaginatedResponse<DetailedPreviewRequest>> => {
    let headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    return this.apiService
      .get<
        ApiDetailedResourcePaginatedResponse<DetailedPreviewRequest>
      >(`${APIEndpoints.ROOT}/${uuid}/${APIEndpoints.ASSETS}`, { headers })
      .pipe(GetDetailedResponsePaginationData);
  };

  #paginationMap(pagination: PaginationProps) {
    return {
      ...pagination,
      orderBy: this.#mapOrderBy(pagination.orderBy),
    };
  }

  #mapOrderBy(orderBy?: string | null) {
    switch (orderBy) {
      case 'owner':
        return 'o.emailAddress';
      case 'sales_force_title':
        return 'p.salesForceTitle';
      case 'sales_force_name':
        return 'p.salesForceName';
      case 'updated_at':
        return 'p.updatedAt';
      case 'created_at':
        return 'p.createdAt';
      default:
        return '';
    }
  }
}
