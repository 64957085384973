<div class="range">
  <vdms-hq-ui-form-input-toggle
    [(ngModel)]="customValues"
    label="Set custom values"
    [withFooter]="false"
    class="custom-values-toggle"
  ></vdms-hq-ui-form-input-toggle>

  <vdms-hq-ui-form-radio-list
    *ngIf="!customValues"
    [selectOptions]="predefinedRangesOptions"
    [disabled]="innerFormControl.disabled"
    [ngModel]="innerFormControl.value"
    (ngModelChange)="updateFromPredefined($event)"
  >
  </vdms-hq-ui-form-radio-list>

  <div class="units" *ngIf="customValues">
    <vdms-hq-ui-form-radio-list
      orientation="horizontal"
      [selectOptions]="unitSelectOptions"
      [(ngModel)]="selectedUnit"
      [withFooter]="false"
      [disabled]="innerFormControl.disabled"
    ></vdms-hq-ui-form-radio-list>
  </div>

  <div class="inputs" *ngIf="customValues">
    <mat-form-field appearance="outline" class="material-custom-field hide-footer custom-suffix">
      <mat-label>From</mat-label>
      <input
        type="number"
        matInput
        [ngModel]="calculatedFrom"
        (ngModelChange)="fromInputValue = $event"
        (blur)="updateFromInput('from', fromInputValue)"
        autocomplete="off"
        [min]="min"
        [disabled]="innerFormControl.disabled"
      />
      <span matSuffix class="suffix">{{ selectedUnit }}</span>
    </mat-form-field>

    <mat-form-field appearance="outline" class="material-custom-field hide-footer custom-suffix">
      <mat-label>To</mat-label>
      <input
        type="number"
        matInput
        [ngModel]="calculatedTo"
        (ngModelChange)="toInputValue = $event"
        (blur)="updateFromInput('to', toInputValue)"
        autocomplete="off"
        [disabled]="innerFormControl.disabled"
      />
      <span matSuffix class="suffix">{{ selectedUnit }}</span>
    </mat-form-field>
  </div>

  <mat-slider [min]="options.floor" [max]="options.ceil" *ngIf="customValues">
    <input [ngModel]="sliderValueFrom" matSliderStartThumb (ngModelChange)="updateFrom($event)" />
    <input [ngModel]="sliderValueTo" matSliderEndThumb (ngModelChange)="updateTo($event)" />
  </mat-slider>
</div>
