import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from '../config-token';
import { Observable } from 'rxjs';
import { serializeUrl } from './helper/shorthand-url';

@Injectable()
export class ThrottlingInterceptor implements HttpInterceptor {
  #requestCounts: Map<string, { count: number; timestamp: number }> = new Map();
  #env = inject<ApiConfig>(API_CONFIG);

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const api = serializeUrl(request);
    const currentTime = Date.now();
    const requestInfo = this.#requestCounts.get(api) || { count: 0, timestamp: currentTime };

    let period = 10000;
    let limit = 15;

    if (typeof this.#env.interceptors.throttling === 'object') {
      period = this.#env.interceptors.throttling.period;
      limit = this.#env.interceptors.throttling.limit;

      for (const customLimit of this.#env.interceptors.throttling?.limitFor ?? []) {
        if (api.includes(customLimit.endpoint)) {
          limit = customLimit.limit;
          break;
        }
      }
    }

    if (currentTime - requestInfo.timestamp < period) {
      requestInfo.count++;
    } else {
      requestInfo.count = 1;
      requestInfo.timestamp = currentTime;
    }

    this.#requestCounts.set(api, requestInfo);

    if (requestInfo.count > limit) {
      return new Observable<HttpEvent<T>>((observer) => {
        observer.error(new Error('Too many requests'));
      });
    }

    return next.handle(request);
  }
}
