import { Injectable, OnDestroy } from '@angular/core';
import { filter, Subject, switchMap, take, takeUntil, tap, catchError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { ToastService } from '@vdms-hq/toast';
import { TranslateService } from '@ngx-translate/core';
import { RightsPartnersService } from '@vdms-hq/api-contract';
import { RightsPartnersResultsMultiDs } from './rights-partners-results-multi-ds.service';
import { RightsPartnersRefreshService } from './rights-partners-refresh.service';
import { RightsPartnersCreateEditDialogComponent } from '../components/rights-partners-create-edit-dialog/rights-partners-create-edit-dialog.component';
import { RightsPartnersAddDsService } from './rights-partners-add-ds.service';
import { RightsPartnersCreateEditFormService } from './rights-partners-create-edit-form.service';

@Injectable({ providedIn: 'root' })
export class RightsPartnersActionsService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create-rights-partners',
        message: 'common.notifications.rights_partners.create.success',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update-rights-partners',
        message: 'common.notifications.rights_partners.update.success',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete-rights-partners',
        message: 'common.notifications.rights_partners.delete.success',
      }),
  };

  constructor(
    private rightsPartnersService: RightsPartnersService,
    private rightsPartnersResultsMultiDsService: RightsPartnersResultsMultiDs,
    private rightsPartnersRefreshService: RightsPartnersRefreshService,
    private rightsPartnersAddDsService: RightsPartnersAddDsService,
    private matDialog: MatDialog,
    private confirmationDialog: UIConfirmationDialogService,
    private toastService: ToastService,
    private translate: TranslateService,
    private formService: RightsPartnersCreateEditFormService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createDialog = () => {
    const dialogRef = this.matDialog.open(RightsPartnersCreateEditDialogComponent, { data: { uuid: undefined } });
    dialogRef.backdropClick().subscribe(() => {
      this.formService.resetForm();
    });
  };

  editDialog = (uuid: string) => {
    const dialogRef = this.matDialog.open(RightsPartnersCreateEditDialogComponent, { data: { uuid } });
    dialogRef.backdropClick().subscribe(() => {
      this.formService.resetForm();
    });
  };

  deleteDialog = (uuid: string, partnerName: string) => {
    this.confirmationDialog
      .openDelete({
        title: 'common.dialogs.rights_partners.delete.title',
        message: this.translate.instant('common.dialogs.rights_partners.delete.message', { partnerName }),
      })
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(Boolean),
        tap(() => this.rightsPartnersResultsMultiDsService.isLoading$.next(true)),
        switchMap(() =>
          this.rightsPartnersService.remove(uuid).pipe(
            tap(() => {
              this.rightsPartnersResultsMultiDsService.isLoading$.next(false);
              this.rightsPartnersRefreshService.refresh$.next(true);
              this.rightsPartnersAddDsService.refresh$.next(true);
              this.popToast.DELETE_SUCCESS();
            }),
            catchError((error) => {
              this.rightsPartnersResultsMultiDsService.isLoading$.next(false);
              throw error;
            }),
          ),
        ),
      )
      .subscribe();
  };
}
