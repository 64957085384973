<div class="multi-data-container relative" [sharedE2eId]="'single-collection-wrapper'">
  <vdms-hq-ui-data-presentation-header
    (action)="handleHeaderAction($event)"
    [actions]="headerActions$ | async"
    [breadcrumbs]="[
      { name: 'Home', path: '/' },
      { name: 'common.title', path: '../' },
    ]"
    [title]="(dataSource.collectionData$ | async)?.name ? (dataSource.collectionData$ | async)?.name : ' '"
    [counterIndicator]="dataSource.total$ | async"
    [listConfig]="(dataSource.collectionData$ | async)?.metadata ?? []"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-loader
    *ngIf="dataSource.isLoading$ | async; else contentLoaded"
    [mode]="'default'"
    [backdrop]="true"
  ></vdms-hq-ui-loader>
  <ng-template #contentLoaded>
    <div class="sub-collections" *ngIf="(subCollectionDataSource.allData$ | async)?.length > 0">
      <vdms-hq-ui-multiple-data-presentation
        (action)="emitAction($event)"
        [dataSource]="subCollectionDataSource"
        [configuration]="subCollectionConfig$ | async"
      >
        <h2 filters>{{ 'pages.lists.sub' | translate }}</h2>
      </vdms-hq-ui-multiple-data-presentation>
      <mat-divider></mat-divider>
    </div>

    <ng-container *ngIf="(subCollectionsOnlyView$ | async) === false">
      <vdms-hq-asset-results-2
        *ngIf="actions$ | async as actions"
        [dataSource]="collectionAssetDataSource"
        [actions]="actions"
        [contextActions]="assetResultsMenuConfig2"
        [multiViewKey]="TABLE_TYPE.COLLECTION"
        [scopeName]="scopeName"
        (action)="customActionHandler($event)"
      >
        <vdms-hq-dynamic-form-group-renderer
          filters
          *ngIf="filterService.config as fieldsConfig"
          [form]="filterService.form"
          [fieldsConfig]="fieldsConfig"
          [isLoading]="dataSource.isLoading$ | async"
        ></vdms-hq-dynamic-form-group-renderer>
      </vdms-hq-asset-results-2>
    </ng-container>
  </ng-template>
</div>

<vdms-hq-ui-actions-footer *ngIf="collectionAssetDataSource.selection.total$ | async; let totalSelected">
  <ng-container left>
    <vdms-hq-ui-button color="secondary" [matMenuTriggerFor]="menu" [iconOnly]="true" icon="more_vert">
    </vdms-hq-ui-button>
    <mat-menu #menu="matMenu">
      <div class="wrapper d-flex flex-col align-items-center">
        <button
          mat-menu-item
          *requiresPermissions="[Permission.EDIT_LICENSED_PACKAGES]"
          (click)="customActionHandler({ key: ResultsActions.ADD_TO_LICENSED_PACKAGE })"
        >
          <span>{{ 'pages.lists.add_to_licensed_packages' | translate }}</span>
        </button>
        <ng-container *ngIf="canMoveToCold$ | async">
          <button
            mat-menu-item
            [disabled]="totalSelected > 500 || (selectedVirtualAssets$ | async) || (selectedQuarantinedAssets$ | async)"
            [matTooltipDisabled]="
              (selectedVirtualAssets$ | async) === false &&
              totalSelected <= 500 &&
              (selectedQuarantinedAssets$ | async) === false
            "
            [matTooltip]="
              'Action disabled if selection has virtual assets, has quarantined assets or more than 500 assets selected'
            "
            [matTooltipClass]="'permissions'"
            *requiresPermissions="[Permission.SET_ASSETS_AS_COLD_ONLY]"
            (click)="customActionHandler({ key: ResultsActions.SET_AS_COLD })"
          >
            <span> {{ 'pages.lists.set_as_cold' | translate }}</span>
          </button>
        </ng-container>

        <button
          *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
          mat-menu-item
          (click)="customActionHandler({ key: ResultsActions.BATCH_IMPOSE_QUARANTINE })"
          [disabled]="selectedQuarantinedAssets$ | async"
          [matTooltipDisabled]="(selectedQuarantinedAssets$ | async) === false"
          [matTooltip]="'Action disabled if selection has quarantined assets'"
        >
          <span>{{ 'pages.assets_list.impose_quarantine' | translate }}</span>
        </button>

        <button
          *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
          mat-menu-item
          (click)="customActionHandler({ key: ResultsActions.BATCH_LIFT_QUARANTINE })"
          [disabled]="(selectedQuarantinedAssets$ | async) === false"
        >
          <span>{{ 'pages.assets_list.lift_quarantine' | translate }}</span>
        </button>
      </div>
    </mat-menu>

    <vdms-hq-ui-button color="secondary" (click)="customActionHandler({ key: 'deselect_all' })">
      {{ 'common.global.deselect_all' | translate }}
    </vdms-hq-ui-button>
    <ng-container>
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.EDIT_ASSETS]"
        (click)="customActionHandler({ key: 'batch_update' })"
        color="primary"
        >{{ 'pages.assets_list.batch_update' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <vdms-hq-ui-button
      *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
      (click)="customActionHandler({ key: 'asset.collection' })"
      color="primary"
      >{{ 'pages.lists.add_to_collection' | translate }}
    </vdms-hq-ui-button>
  </ng-container>

  <vdms-hq-ui-button center>
    {{ 'common.global.selected' | translate: { selected: totalSelected } }}
  </vdms-hq-ui-button>
  <ng-container right>
    <ng-container
      *ngIf="
        (collectionAssetDataSource.selection.entities$ | async) &&
        (dataSource.collectionData$ | async)?.access_type !== 'dashboard'
      "
    >
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
        (click)="customActionHandler({ key: ResultsActions.ASSET_DELETE })"
        color="warn"
        >{{ 'common.global.remove_from_collection' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <vdms-hq-ui-button
      *ngIf="cartStateService.isClipOnlyAndPermitted$ | async"
      (click)="customActionHandler({ key: ResultsActions.ADD_TO_CART })"
      color="primary"
      >{{ 'pages.assets_list.add_to_basket' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-actions-footer>
