import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isBetween',
  pure: true,
  standalone: true,
})
export class IsBetweenPipe implements PipeTransform {
  transform(value: string | number, min: number, max: number): boolean {
    return Number(value) >= Number(min) && Number(value) <= Number(max);
  }
}
