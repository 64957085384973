import { InjectionToken, Provider } from '@angular/core';
import { ASPERA_CONFIG, AsperaConfig } from './storage-assets';

export interface StorageConfig {
  cdn: string;
}

export const STORAGE_CONFIG: InjectionToken<StorageConfig> = new InjectionToken('STORAGE_CONFIG');

export const configureStorage = (config: StorageConfig, aspera?: AsperaConfig): Provider[] => {
  const providers: Provider[] = [
    {
      provide: STORAGE_CONFIG,
      useValue: config,
    },
  ];

  if (aspera) {
    providers.push([
      {
        provide: ASPERA_CONFIG,
        useValue: aspera,
      },
    ]);
  }

  return providers;
};
