import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel } from '@angular/forms';
import { concatMap, Observable, repeat, timer } from 'rxjs';
import { delay, map, take, withLatestFrom } from 'rxjs/operators';
import {
  AdvancedSearchOverlayComponent,
  AdvancedSearchStateService,
  TranslatableName,
  UIButtonModule,
  UIFormModule,
  UIPipesModule,
} from '@vdms-hq/ui';
import { FilterDefinitionModel, filterEmpty, SelectOption, TruncatePipe } from '@vdms-hq/shared';
import { AssetSearchService, PersistenceSearchParams, PersistenceSearchQuery } from '@vdms-hq/api-contract';
import { LastFiltersService } from '../../../logic/services/last-filters.service';
import { MatIconModule } from '@angular/material/icon';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import { AuthService } from '@vdms-hq/auth';
import { SearchResultsConfigFieldsService } from '../../../logic/services/search-results-config-fields.service';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ReturnResultsComponent } from '../return-results/return-results.component';
import {
  QueryAutocomplete,
  SearchAutocompleteOptionsComponent,
} from '../search-autocomplete-options/search-autocomplete-options.component';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'vdms-hq-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    TourItemComponent,
    UIFormModule,
    UIButtonModule,
    TruncatePipe,
    UIPipesModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    AdvancedSearchOverlayComponent,
    ReturnResultsComponent,
    SearchAutocompleteOptionsComponent,
  ],
  providers: [],
})
export class SearchAutocompleteComponent implements AfterViewInit {
  #assetSearchService = inject(AssetSearchService);
  #lastFiltersService = inject(LastFiltersService);
  #fieldsConfigService = inject(SearchResultsConfigFieldsService);
  #authService = inject(AuthService);
  advancedSearchStateService = inject(AdvancedSearchStateService);

  touched = false;
  focus = false;
  advancedSearchOpen = false;

  currentQuery = '';
  selectedFilter = 'keyword';

  @Input() isHero = false;
  @Input() availableFilters: SelectOption<string>[] = [];

  @ViewChild(SearchAutocompleteOptionsComponent) autocompleteOptions?: SearchAutocompleteOptionsComponent;
  matAutocomplete: MatAutocomplete | undefined;
  @ViewChild('input', { read: NgModel }) input?: NgModel;
  readonly text = 'Type something here...';

  placeholder$ = timer(0, 100).pipe(
    take(this.text.length),
    map((i) => this.text.slice(0, i + 1)),
    concatMap((char) => timer(0).pipe(map(() => char))),
    delay(3000),
    repeat(),
  );

  latestQueries$: Observable<QueryAutocomplete[]> = this.#lastFiltersService.latestQueries$;

  ngAfterViewInit() {
    this.matAutocomplete = this.autocompleteOptions?.matAutocomplete;
  }

  updateQuery(autocomplete?: QueryAutocomplete) {
    if (!this.input?.valid) {
      return;
    }
    if (autocomplete) {
      autocomplete.params.save = false;
      this.#assetSearchService.applyParams(autocomplete.params);
      return;
    }

    if (this.selectedFilter === 'keyword') {
      this.#authService.userAttributes$.pipe(filterEmpty(), take(1)).subscribe((attributes) => {
        const query = this.currentQuery
          ? PersistenceSearchQuery.freeText(this.currentQuery)
          : PersistenceSearchQuery.empty();

        const unsavedQuery = PersistenceSearchParams.initialQuery(query, attributes.vida.preferredPageSize);
        this.#assetSearchService.applyParams(unsavedQuery);
      });

      return;
    }

    this.#fieldsConfigService.filters$
      .pipe(
        take(1),
        map((config) => config.filter((filter) => !!filter) as FilterDefinitionModel[]),
        withLatestFrom(this.#authService.userAttributes$),
      )
      .subscribe(([filters, attributes]) => {
        const filter = filters.find((filter) => filter.id === this.selectedFilter);

        if (!filter) {
          return;
        }

        const query = PersistenceSearchQuery.fromFilters(undefined, {
          [filter.id]: {
            filterDef: filter,
            value: this.currentQuery,
          },
        });

        const unsavedQuery = PersistenceSearchParams.initialQuery(query, attributes?.vida?.preferredPageSize ?? 12);
        this.#assetSearchService.applyParams(unsavedQuery);
      });
  }

  removeAutocompleteItem(item: SelectOption) {
    this.#lastFiltersService.removeSavedSearchPhrase(item);
  }

  toggleAdvancedSearch($event: MouseEvent) {
    $event.stopPropagation();
    this.advancedSearchOpen = !this.advancedSearchOpen;
    this.focus = false;
  }

  keyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.updateQuery();
    }
  }
}
