import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { HybrikAnalizeData, HybrikJobsList } from './hybrik.model';
import { HybrikAnalizeEndpoints } from './endpoints';
import { ASSETS_ENDPOINTS } from '../asset/models/asset-endpoints.model';
import { createErrorContext } from '../../error-handler';

@Injectable({ providedIn: 'root' })
export class HybrikService {
  constructor(private readonly apiService: ApiService) {}

  public getHybrikAnalyseResults(assetUuid: string): Observable<HybrikAnalizeData> {
    const context = createErrorContext({ '*': 'common.hybrik_analyse_results.load_data_failure' }, 'load_data_failure');

    return this.apiService
      .get<ApiResponse<HybrikAnalizeData>>(`${ASSETS_ENDPOINTS.ONE}/${assetUuid}/${HybrikAnalizeEndpoints.ROOT}`, {
        context,
      })
      .pipe(GetResponseData);
  }

  public getHybrikJobsList(assetUuid: string): Observable<HybrikJobsList[]> {
    const context = createErrorContext({ '*': 'common.hybrik_analyse_results.load_jobs_failure' }, 'load_data_failure');

    return this.apiService
      .get<ApiResponse<HybrikJobsList[]>>(`${HybrikAnalizeEndpoints.ROOT}/${assetUuid}/list`, { context })
      .pipe(GetResponseData);
  }

  public restartHybrikJob(jobId: number) {
    const context = createErrorContext({ '*': 'pages.asset.hybrik.jobs.toasts.restart_failed' }, 'restart_hybrik_job');

    return this.apiService.post(`${HybrikAnalizeEndpoints.ROOT}/job/${jobId}/restart`, null, undefined, context);
  }
}
