<mat-form-field appearance="outline" class="material-custom-field">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="number"
    matInput
    [min]="min"
    [max]="max"
    [formControl]="innerFormControl"
    (blur)="propagateTouch()"
    (change)="handleChanges()"
    [placeholder]="placeholder"
    autocomplete="off"
    class="mat-form-field"
    [required]="isRequired"
  />
  <span class="suffix" matSuffix>%</span>
</mat-form-field>
