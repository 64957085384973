import { cachedPrefixes, notAvailableSuffixes } from '../config/cached-prefixes';
import { HttpRequest } from '@angular/common/http';

export const shorthandUrl = (apiUrl: string, request: HttpRequest<any>): string => {
  const segments = request.url.replace(apiUrl, '');
  const isNotAvailableSuffix = notAvailableSuffixes.some((suffix) => segments.endsWith(suffix));
  const shortedUrl = cachedPrefixes.filter((url) => segments.startsWith(url))[0] || '';
  if (shortedUrl === '' || isNotAvailableSuffix) {
    return '';
  }
  const stringifyHeaders = request.headers
    .keys()
    .filter((key) => !['Authorization'].includes(key))
    .map((key) => `${key}=${request.headers.get(key)}`)
    .join('&');
  return `${shortedUrl}?${stringifyHeaders}`;
};

export const serializeUrl = (request: HttpRequest<any>): string => {
  const stringifyHeaders = request.headers
    .keys()
    .filter((key) => !['Authorization'].includes(key))
    .map((key) => `${key}=${request.headers.get(key)}`)
    .join('&');

  return `${request.url}++h${stringifyHeaders}`;
};
