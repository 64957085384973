import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import moment from 'moment-timezone';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { takeUntil } from 'rxjs/operators';

type OuterValue = string | number | Date | null | undefined;
type InnerValue = {
  date: moment.Moment | '';
  time: string;
};

@Component({
  selector: 'vdms-hq-ui-form-input-date-time',
  templateUrl: './form-input-date-time.component.html',
  styleUrls: ['./form-input-date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDateTimeComponent),
    },
  ],
})
export class FormInputDateTimeComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() minDate?: string;
  @Input() maxDate?: string;

  @Input() dateLabel = 'Date';
  @Input() timeLabel = 'Time';
  @Input() timeAutocompleteList: SelectOption[] = [
    { key: '00:00', label: '12:00 AM' },
    { key: '01:00', label: '1:00 AM' },
    { key: '02:00', label: '2:00 AM' },
    { key: '03:00', label: '3:00 AM' },
    { key: '04:00', label: '4:00 AM' },
    { key: '05:00', label: '5:00 AM' },
    { key: '06:00', label: '6:00 AM' },
    { key: '07:00', label: '7:00 AM' },
    { key: '08:00', label: '8:00 AM' },
    { key: '09:00', label: '9:00 AM' },
    { key: '10:00', label: '10:00 AM' },
    { key: '11:00', label: '11:00 AM' },
    { key: '12:00', label: '12:00 PM' },
    { key: '13:00', label: '1:00 PM' },
    { key: '14:00', label: '2:00 PM' },
    { key: '15:00', label: '3:00 PM' },
    { key: '16:00', label: '4:00 PM' },
    { key: '17:00', label: '5:00 PM' },
    { key: '18:00', label: '6:00 PM' },
    { key: '19:00', label: '7:00 PM' },
    { key: '20:00', label: '8:00 PM' },
    { key: '21:00', label: '9:00 PM' },
    { key: '22:00', label: '10:00 PM' },
    { key: '23:00', label: '11:00 PM' },
  ];
  @Input() autocompleteIncludeNull = false;

  innerFormControl = new FormGroup({
    date: new FormControl<'' | moment.Moment>(''),
    time: new FormControl<string>('00:00'),
  });
  timeFormControl = this.innerFormControl.controls.time;

  ngOnInit() {
    super.ngOnInit();
    this.#listenForControlsDisabled();
  }

  protected override transformOuterToInner(value: OuterValue): InnerValue {
    if (!value) {
      return {
        date: '',
        time: '',
      };
    }

    const dateTime = moment(value);

    return {
      date: dateTime,
      time: dateTime.format('HH:mm'),
    };
  }

  protected override transformInnerToOuter(innerValue: InnerValue): OuterValue {
    if (!innerValue || !innerValue.date) {
      return null;
    }

    const localDate = innerValue.date;

    const [hour, minute] = innerValue.time.split(':');

    localDate.set({
      hour: Number(hour),
      minute: Number(minute),
    });

    return innerValue.date.toISOString();
  }

  updateDate(t: string) {
    const newTime = moment(t, 'HH:mm a');
    this.timeFormControl.setValue(newTime.format('HH:mm'));
  }

  #listenForControlsDisabled() {
    this.innerFormControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      if (this.innerFormControl.disabled) {
        this.innerFormControl.controls.date.markAsUntouched();
        this.innerFormControl.controls.date.markAsPristine();
        this.timeFormControl.markAsUntouched();
        this.timeFormControl.markAsPristine();
      } else {
        this.innerFormControl.controls.date.markAsTouched();
        this.innerFormControl.controls.date.markAsDirty();
        this.timeFormControl.markAsTouched();
        this.timeFormControl.markAsDirty();
      }
    });
  }
}
