export enum CredentialTypeEnum {
  AWS = 'AWS',
  FTP = 'FTP', //(not implemented yet)
  WASABI = 'WASABI',
  ASPERA = 'ASPERA',
  ASPERA_HSTS = 'ASPERA_HSTS',
  ASPERA_AOC = 'ASPERA_AOC',
  ASPERA_SHARES = 'ASPERA_SHARES',
  ASPERA_YOUTUBE = 'ASPERA_YOUTUBE',
  FTPS = 'FTPS',
  YOUTUBE = 'YOUTUBE',
  INGEST_IN_VIDA = 'INGEST_IN_VIDA',
}
export interface VisibleDataTypes {
  [CredentialTypeEnum.AWS]: {
    accessKeyId: string;
  };
  [CredentialTypeEnum.FTP]: {
    username: string;
  };
  [CredentialTypeEnum.WASABI]: {
    host: string;
    name: string;
    region: string;
    accessKeyId: string;
  };
  [CredentialTypeEnum.ASPERA]: {
    apiUrl: string;
    apiPort: string;
    destinationRoot: string;
    username: string;
  };
  [CredentialTypeEnum.ASPERA_HSTS]: {
    server: string;
    username: string;
    sshPort: number;
    udpPort: number;
    multiSession: number;
    usedEarPassphrase: boolean;
  };
  [CredentialTypeEnum.ASPERA_YOUTUBE]: {
    channel: string;
    server: string;
    username: string;
    sshPort: number;
    udpPort: number;
    multiSession: number;
    usedEarPassphrase: boolean;
  };
  [CredentialTypeEnum.ASPERA_AOC]: {
    server: string;
    username: string;
    multiSession: number;
    usedEarPassphrase: boolean;
  };
  [CredentialTypeEnum.ASPERA_SHARES]: {
    server: string;
    username: string;
    multiSession: number;
    usedEarPassphrase: boolean;
    destinationRoot: string;
  };
  [CredentialTypeEnum.FTPS]: {
    server: string;
    port: number;
    username: string;
  };
  [CredentialTypeEnum.YOUTUBE]: undefined;
  [CredentialTypeEnum.INGEST_IN_VIDA]: undefined;
}
export interface SecuredDataTypes {
  [CredentialTypeEnum.AWS]: {
    secretAccessKey: string;
  };
  [CredentialTypeEnum.FTP]: {
    password: string;
  };
  [CredentialTypeEnum.WASABI]: {
    secretAccessKey: string;
  };
  [CredentialTypeEnum.ASPERA]: {
    password: string;
  };
  [CredentialTypeEnum.ASPERA_HSTS]: {
    password: string;
    sshPrivateKey?: string;
    earPassphrase?: string;
  };
  [CredentialTypeEnum.ASPERA_YOUTUBE]: {
    password: string;
    sshPrivateKey?: string;
    earPassphrase?: string;
  };
  [CredentialTypeEnum.ASPERA_AOC]: {
    password: string;
    sshPrivateKey?: string;
    earPassphrase?: string;
  };
  [CredentialTypeEnum.ASPERA_SHARES]: {
    password: string;
    earPassphrase?: string;
  };
  [CredentialTypeEnum.FTPS]: {
    password: string;
  };
  [CredentialTypeEnum.YOUTUBE]: undefined;
  [CredentialTypeEnum.INGEST_IN_VIDA]: undefined;
}

export type VisibleDataType<T extends CredentialTypeEnum> = VisibleDataTypes[T];
export type SecuredDataType<T extends CredentialTypeEnum> = SecuredDataTypes[T];

export interface CredentialInterface<T extends CredentialTypeEnum> {
  is_used: boolean;
  uuid: string;
  name: string;
  type: T;
  visible_data: VisibleDataType<T>;
  secured_data?: SecuredDataType<T>;
}
export type GetCredentialInterface<T extends CredentialTypeEnum> = Omit<CredentialInterface<T>, 'secured_data'>;
export type PatchCredentialInputInterface<T extends CredentialTypeEnum> = Omit<
  CredentialInterface<T>,
  'uuid' | 'type' | 'is_used'
>;
export type PostCredentialInputInterface<T extends CredentialTypeEnum> = CredentialInterface<T>;

export interface PostYoutubeCredential {
  code: string;
}
