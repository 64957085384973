import { Component } from '@angular/core';
import { ResolveEnd, ResolveStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  isLoading$ = this.router.events.pipe(
    filter((event) => event instanceof ResolveStart || event instanceof ResolveEnd),
    map((event) => event instanceof ResolveStart),
  );

  constructor(
    private router: Router,
    public activatedClient: ActivatedClientService,
  ) {}
}
