export enum ErrorHandlingRoutes {
  NOT_FOUND = '404',
  NOT_PERMITTED = '403',
  NOT_AUTHORIZED_STATUS = '401',
  NOT_AUTHORIZED_PAGE = 'multi-factor-authentication-required',
  INTERNAL_SERVER_ERROR = '500',
}

export enum ErrorHandlingMessages {
  REQUIRED_2FA = "User didn't use 2FA which is required",
}
