<vdms-hq-ui-dialog-wrapper [size]="'default'">
  <h1 title>{{ dialogTitle | translate }}</h1>
  <div content class="relative">
    <div [innerHTML]="dialogContent"></div>
    <img [src]="dialogImg" alt="VIDA updates" />
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="primary" (click)="closeDialog()">
      {{ dialogBtnText }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
