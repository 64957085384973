import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

@Component({
  selector: 'vdms-hq-ui-form-input-email',
  templateUrl: './form-input-email.component.html',
  styleUrls: ['./form-input-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputEmailComponent),
    },
  ],
})
export class FormInputEmailComponent extends FormControlValueAccessorComponent implements OnInit {
  innerFormControl = new UntypedFormControl(null);
  @Input() readonly = false;
  @Input({ required: true }) label!: string;
}
