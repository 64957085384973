<vdms-hq-ui-dialog-wrapper>
  <ng-container title>
    <h1 class="title">
      {{ 'common.notification_subscriptions.trigger.title' | translate }}
    </h1>
    <vdms-hq-form-input-buttons-toggle
      [(ngModel)]="emailToggle"
      [rightOptionLabel]="'Emails'"
      [leftOptionLabel]="'Contracts'"
    >
    </vdms-hq-form-input-buttons-toggle>
  </ng-container>

  <div content class="dialog-content">
    <ng-container *ngIf="emailToggle">
      <vdms-hq-ui-form-input-chips
        [formControl]="form"
        [autocompleteOptions]="emailOptions"
        [placeholder]="'pages.delivery_destinations.form.email' | translate"
        matTooltip="Press enter to add"
        matTooltipPosition="above"
        (inputChange)="valueChange($event)"
        [label]="'pages.delivery_destinations.form.email' | translate"
      ></vdms-hq-ui-form-input-chips>
    </ng-container>
    <ng-container *ngIf="!emailToggle">
      <vdms-hq-ui-selectable-tiles
        [dataSource]="contractsDataSource"
        [filters]="contractsDataSource.filters"
        [config]="contractsSelectableConfig"
        (updateSelectedListEmit)="contractsSelectableConfig.selectedList.data = $event"
        [selected]="contract_ids.value ?? []"
        (selectedChangeEmit)="contract_ids.setValue($event)"
        (updateConfigEmit)="contractsSelectableConfig = $event"
      >
      </vdms-hq-ui-selectable-tiles>
    </ng-container>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="confirm()"
      [loading]="(loading$ | async) ?? false"
      [disabled]="!canSave"
      color="primary"
    >
      {{ 'common.global.confirm' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
