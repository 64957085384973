import { Provider } from '@angular/core';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from './logic/filename-conventions.validator';

export const configureMetadataRecognition = (config?: { minimumPercentAccuracy?: number }): Provider[] => {
  return [
    {
      provide: METADATA_MINIMUM_PERCENT_ACCURACY,
      useValue: config?.minimumPercentAccuracy ?? 25,
    },
  ];
};
