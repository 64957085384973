import { PipeTransform, Pipe } from '@angular/core';

export enum AssetTypeEnum {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  SUBTITLES = 'subtitles',
  ARCHIVE = 'archive',
  DOCUMENT = 'document',
  OTHER = 'other',
}

export type AssetType = (typeof AssetTypeEnum)[keyof typeof AssetTypeEnum];

@Pipe({ name: 'getTypeDescriptive', standalone: true })
export class GetTypeDescriptivePipe implements PipeTransform {
  transform(value: AssetType): string {
    switch (value) {
      case AssetTypeEnum.VIDEO:
        return 'Video File';

      case AssetTypeEnum.AUDIO:
        return 'Music File';

      case AssetTypeEnum.IMAGE:
        return 'Image File';

      case AssetTypeEnum.SUBTITLES:
        return 'Typed text File';

      case AssetTypeEnum.ARCHIVE:
        return 'Archive File';

      case AssetTypeEnum.DOCUMENT:
        return 'Document File';

      default:
        return 'Other File Type';
    }
  }
}
