import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Policy, PolicyLog, PolicyPatchRequest, PolicyPostRequest, PolicyResponse } from './policy.model';
import { PolicyEndpoints } from './endpoints';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiService } from '../api.service';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { UserModel } from '../user/user.model';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationAPIModel as Pagination, PaginationAPIProps as PaginationProps } from '../pagination.model';

@Injectable({ providedIn: 'root' })
export class PolicyService {
  constructor(private readonly apiService: ApiService) {}

  public getPolicies(clientUuid: string | null = null): Observable<Policy[]> {
    let headers = new HttpHeaders();
    if (clientUuid) {
      headers = headers.append('client', clientUuid);
    }

    return this.apiService.get<ApiResponse<Policy[]>>(PolicyEndpoints.ROOT_PLURAL, { headers }).pipe(
      GetResponseData,
      map((policies) =>
        policies.map((policy) => ({
          ...policy,
          isSuperAdmin: ['a4e7f94b-ab5a-466a-953c-37b984faf9b8'].includes(policy.uuid), // TODO: Remove this line when vida-backend/4302 will be done
        })),
      ),
    );
  }

  getSimplePolicies(): Observable<Policy[]> {
    return this.apiService
      .get<ApiResponse<Policy[]>>(PolicyEndpoints.ROOT_SIMPLE)
      .pipe(GetResponseData)
      .pipe(map((policies) => policies.filter((policy) => !policy.isSuperAdmin)));
  }

  public getPoliciesUnRestricted(): Observable<Policy[]> {
    return this.apiService.get<ApiResponse<Policy[]>>(PolicyEndpoints.EMPTY).pipe(GetResponseData);
  }

  public postPolicy(policy: PolicyPostRequest): Observable<PolicyResponse> {
    return this.apiService
      .post<PolicyPostRequest, ApiResponse<PolicyResponse>>(PolicyEndpoints.ROOT, policy)
      .pipe(GetResponseData);
  }

  public patchPolicy(uuid: string, policy: PolicyPatchRequest): Observable<PolicyResponse> {
    return this.apiService
      .patch<PolicyPatchRequest, ApiResponse<PolicyResponse>>(`${PolicyEndpoints.ROOT}/${uuid}`, policy)
      .pipe(GetResponseData);
  }

  public getPolicyUsers(uuid: string): Observable<UserModel[]> {
    let headers = new HttpHeaders();
    headers = headers.append('per-page', '1000');
    return this.apiService
      .get<ApiResponse<UserModel[]>>(`${PolicyEndpoints.ROOT}/${uuid}/users`, { headers })
      .pipe(GetResponseData);
  }

  getLogs = (uuid: string) => {
    return this.apiService
      .get<ApiResponse<PolicyLog[]>>(`${PolicyEndpoints.ROOT}/${uuid}/${PolicyEndpoints.LOGS}`)
      .pipe(GetResponseData);
  };

  deletePolicy(uuid: string) {
    return this.apiService.delete(`${PolicyEndpoints.ROOT}/${uuid}`);
  }
}
