import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AudioTrack } from '@vdms-hq/api-contract';

export class AudioTrackValidator {
  static audioCountMatch(maxChannels: number): (control: AbstractControl) => ValidationErrors | null {
    return (form: AbstractControl): ValidationErrors | null => {
      const audioTracks: AudioTrack[] = form.get('audioTracks')?.value;

      const layoutValuesAreValid = audioTracks.every(
        (track: AudioTrack) => !!track.layout && (track.channels as string[])?.length > 0,
      );

      if (!layoutValuesAreValid) {
        return { audioLayoutValuesError: true };
      }

      const channelsSumIsValid =
        audioTracks.map((val: AudioTrack) => val.channels?.length ?? 0).reduce((a, b) => a + b, 0) === maxChannels;

      if (!channelsSumIsValid) {
        return { audioCountMatchError: true };
      }

      const layoutsSumIsValid =
        audioTracks.map((val: AudioTrack) => Math.ceil(+val.layout) ?? 0).reduce((a, b) => a + b, 0) === maxChannels;
      if (!layoutsSumIsValid) {
        return { audioCountMatchError: true };
      }

      return null;
    };
  }
}
