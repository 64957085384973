import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'vdms-hq-users-deletion-confirmation-dialog',
  imports: [CommonModule, UIButtonModule, UIDialogWrapperModule, UIFormModule, TranslateModule],
  templateUrl: './users-deletion-confirmation-dialog.component.html',
})
export class UsersDeletionConfirmationDialogComponent {
  form = new FormGroup({
    reason: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public data = inject<{ total: number }>(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<UsersDeletionConfirmationDialogComponent>);

  ok() {
    this.dialogRef.close({
      reason: this.form.value.reason,
    });
  }
  abort() {
    this.dialogRef.close(false);
  }
}
