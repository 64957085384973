import { Component, inject } from '@angular/core';
import { UIButtonModule } from '@vdms-hq/ui';
import { UrlSerializer } from '@angular/router';
import { SearchParamSerializerService } from '../../../logic/services/search-param-serializer.service';
import { AssetSearchService } from '@vdms-hq/api-contract';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-return-results',
  templateUrl: './return-results.component.html',
  standalone: true,
  imports: [UIButtonModule],
  providers: [],
})
export class ReturnResultsComponent {
  urlSerializer = inject(UrlSerializer);
  routeSerializer = inject(SearchParamSerializerService);
  assetSearchService = inject(AssetSearchService);

  returnToResults() {
    const lastAssetQuery = localStorage.getItem('lastAssetQuery');
    if (lastAssetQuery) {
      const params = this.urlSerializer.parse(lastAssetQuery).queryParamMap;
      this.routeSerializer
        .fromRoute(params, '')
        .pipe(
          take(1),
          tap((persistentSearchParams) => {
            return this.assetSearchService.applyParams(persistentSearchParams);
          }),
        )
        .subscribe();
    }
  }
}
