import { inject, Injectable } from '@angular/core';
import { CartApiService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { combineLatest, of, shareReplay } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { CartStateService } from '@vdms-hq/cart-core';

@Injectable({ providedIn: 'root' })
export class CartTotalDataSource {
  private cartApiService = inject(CartApiService);
  private activatedClientService = inject(ActivatedClientService);
  private cartStateService = inject(CartStateService);

  total$ = combineLatest([this.activatedClientService.clientId$, this.cartStateService.refresh$]).pipe(
    switchMap(([clientId]) => {
      if (!clientId) {
        return of(-1);
      }
      return this.cartApiService.getTotal().pipe(
        startWith(-2),
        map((response) => response ?? 0),
        catchError(() => {
          return of(-1);
        }),
      );
    }),
    shareReplay(1),
  );

  totalIsLoading$ = this.total$.pipe(map((value) => value === -2));
}
