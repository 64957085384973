import { Injectable } from '@angular/core';
import { startWith, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MandatoryFieldsRefreshService {
  #refresh$ = new Subject<boolean>();
  refresh$ = this.#refresh$.asObservable().pipe(startWith(true));

  refresh = () => this.#refresh$.next(true);
}
