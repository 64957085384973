export const axisLabelFont = {
  font: {
    family: 'Source Sans Pro',
    size: 16,
  },
};

export const axisLabelSmallFont = {
  font: {
    family: 'Source Sans Pro',
    size: 14,
  },
};

export const defaultPallete = [
  '#5b45ff',
  '#FF8A58',
  '#FF22C1',
  '#5B3F97',
  '#FD78C0',
  '#BAC9FF',
  '#88E7B3',
  '#BAF413',
  '#B558FF',
  '#767378',
];
