import { Component, inject, OnInit } from '@angular/core';
import { CollectionsService } from '@vdms-hq/api-contract';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@vdms-hq/toast';
import { TeamsAddData, TeamsAddDataSource } from '../../logic/client-teams-add-ds.service';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { DialogResponse, TileSelectableConfig, UIButtonModule, UIDialogWrapperModule } from '@vdms-hq/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiSelectableTilesComponent, SelectableWrapperConfig } from '@vdms-hq/selectable-tiles-wrapper';
import { CommonModule } from '@angular/common';
import { Destroyable, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { takeUntil } from 'rxjs/operators';
import { ViewSettingsService } from '@vdms-hq/view-settings';

export interface AddToTeamsResponse {
  status: DialogResponse;
}

export interface AddToTeamsInput {
  shared_to_teams?: {
    name: string;
    uuid: string;
  }[];
  collectionUuid?: string;
  collectionName?: string;
}

@Component({
  selector: 'vdms-hq-teams-add-dialog',
  templateUrl: './teams-add-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, TranslateModule, UiSelectableTilesComponent, UIButtonModule],
})
export class TeamsAddDialogComponent extends Destroyable() implements OnInit {
  private dialogRef = inject(MatDialogRef<TeamsAddDialogComponent>);
  private collectionsService = inject(CollectionsService);
  public teamsAddDS = inject(TeamsAddDataSource);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private viewSettingsService = inject(ViewSettingsService);

  public data: AddToTeamsInput = inject(MAT_DIALOG_DATA);

  isSaving = false;

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.client-teams.table.filter',
      resource: [ResourceModel.CLIENT_TEAM],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-teams'],
    },
  ];

  teamsTilesConfig: TileSelectableConfig<TeamsAddData> = {
    label: 'label',
    key: 'key',
    icon: 'group_work',
    metadata: [
      {
        label: 'common.client-teams.dialogs.number_of_users',
        valuePath: 'number_of_users',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
      {
        label: 'common.client-teams.dialogs.number_of_collections',
        valuePath: 'number_of_collections',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
      {
        label: 'common.client-teams.dialogs.created_by',
        valuePath: 'created_by',
        viewFormat: {
          modifiers: {
            truncateCharacters: 30,
          },
        },
      },
    ],
  };

  popToast = {
    ADD_SUCCESS: () =>
      this.toastService.success({
        id: 'add_collection_to_teams',
        message: 'common.collections.notifications.add_to_teams.success',
      }),
  };

  #form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });
  selectedControl = this.#form.get('selected') as FormControl<string[]>;

  teamsAddSelectableConfig: SelectableWrapperConfig<TeamsAddData> = {
    fieldsConfig: this.filtersConfig,
    tileConfig: this.teamsTilesConfig,
    selectedList: {
      data: this.data.shared_to_teams ?? [],
      title: 'Teams already added',
    },
    searchView: {
      placeholder: 'Type team name or user email',
      label: 'Search Teams',
      showSearch: true,
    },
    datasourceTitle: 'Available teams',
    emptyMessage: this.translate.instant('common.client-teams.dialogs.teams_empty'),
    loadingText: this.translate.instant('common.client-teams.dialogs.teams_loading'),
  };

  ngOnInit() {
    this.viewSettingsService.preferredSelectableView$.pipe(takeUntil(this.isDestroyed$)).subscribe((view) => {
      this.teamsAddSelectableConfig.searchView.showSearch = view === 'search';
    });
    const sharedTeamsUuids = this.data.shared_to_teams?.map((team) => team.uuid) ?? [];
    this.selectedControl.setValue(sharedTeamsUuids);
  }

  save() {
    if (this.data.collectionUuid) {
      this.isSaving = true;
      this.collectionsService
        .update(this.data.collectionUuid, { shared_to_team_uuids: this.selectedControl.value ?? [] })
        .subscribe({
          next: () => {
            this.popToast.ADD_SUCCESS();
            this.isSaving = false;
            this.close(true);
          },
          error: () => {
            this.isSaving = false;
          },
        });
    }
  }

  close(saved = false) {
    this.teamsAddDS.refresh(saved);
    this.dialogRef.close({
      status: saved ? DialogResponse.OK : DialogResponse.ABORT,
    });
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }
}
