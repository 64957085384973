import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule } from '../../../ui-button';
import { UIDialogWrapperModule } from '../../../ui-dialog-wrapper';
import { UIFormModule } from '../../../ui-form';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-advanced-search-instruction',
  standalone: true,
  imports: [CommonModule, TranslateModule, UIButtonModule, UIDialogWrapperModule, UIFormModule],
  templateUrl: './advanced-search-instruction.component.html',
  styleUrls: ['advanced-search-instruction.component.scss'],
})
export class AdvancedSearchInstructionComponent {
  readonly #ref = inject(MatDialogRef<AdvancedSearchInstructionComponent>);

  close() {
    this.#ref.close();
  }
}
