import { inject, Injectable } from '@angular/core';
import { ASPERA_CONFIG, AsperaConfig } from '../config-token';
import { BehaviorSubject, combineLatest, Observable, shareReplay, startWith, Subject, switchMap, timer } from 'rxjs';
import { AsperaDragEvent, AsperaFile, AsperaSelectEvent } from './model/aspera-select-event.model';
import { TransfersEvent, TransferSpec, UploadComponentId } from './model/aspera-transfer-event.model';
import { ToastService } from '@vdms-hq/toast';
import { AsperaNotifier } from '../../../aspera-notifier.service';
import { map } from 'rxjs/operators';
import { transformAsperaStatus, AsperaTransferBatch } from './value-object/aspera-batch';
import { AsperaTransferFile } from './value-object/aspera-batch-file';

declare const AW4: any;

export type BATCH_ID = string;

/** @internal */
@Injectable({
  providedIn: 'root',
})
export class AsperaUpload2Service {
  #config = inject<AsperaConfig>(ASPERA_CONFIG);
  #toast = inject(ToastService);
  #asperaWeb: any;
  #asperaNotifier = inject(AsperaNotifier);
  dragOver$ = new BehaviorSubject<UploadComponentId | null>(null);

  #transfersSubject$ = new BehaviorSubject<Record<BATCH_ID, AsperaTransferBatch>>({});
  transfers$ = this.#transfersSubject$.asObservable();
  transfersArray$ = this.transfers$.pipe(map((selected) => Object.values(selected)));

  allFiles$ = this.transfersArray$.pipe(
    map((batches) => {
      const files: Record<string, AsperaTransferFile> = {};

      batches.forEach((batches) => {
        batches.files.forEach((file) => {
          files[file.id] = file;
        });
      });

      return files;
    }),
    shareReplay(1),
  );

  nextBatch$ = new Subject<AsperaTransferBatch>();
  connected$ = new BehaviorSubject<boolean>(false);
  isProcessing$: Observable<boolean> = this.transfersArray$.pipe(
    switchMap((batches) =>
      combineLatest(
        batches.map((batch) =>
          batch.state$.pipe(map((state) => state.status === 'pending' || state.status === 'not_started')),
        ),
      ),
    ),
    map((isUploading) => isUploading.some((batch) => batch)),
    startWith(false),
  );

  get config(): AsperaConfig & {
    batchLimit: number;
    initialUploadProgress: 10;
  } {
    return {
      ...this.#config,
      batchLimit: 100,
      initialUploadProgress: 10,
    };
  }

  setDropTargets = (options: { dropZoneClass: string; allowMultipleSelection: boolean }) => {
    this.#asperaWeb?.setDragDropTargets(
      options.dropZoneClass,
      {
        allowPropagation: false,
        dragEnter: true,
        dragOver: true,
        dragLeave: true,
        drop: true,
      },
      (event: AsperaDragEvent & { event: DragEvent }) => {
        const componentId = (event.event?.target as HTMLElement)?.getAttribute('data-component-id');
        const allowDrag = (event.event?.target as HTMLElement)?.getAttribute('data-component-allow-drop') === '1';

        switch (event.event.type) {
          case 'drop':
            if (!allowDrag) {
              return;
            }
            this.dragOver$.next(null);

            if (!componentId) {
              return;
            }

            if (!this.#isValidInput(event.files.dataTransfer.files, options)) {
              return;
            }

            this.createLimitedBatch(event.files.dataTransfer.files, componentId);

            break;
          case 'dragenter':
          case 'dragover':
            if (!allowDrag) {
              return;
            }
            this.dragOver$.next(componentId);
            break;
          case 'dragleave':
            this.dragOver$.next(null);
            break;
        }
      },
    );
  };

  initialize(options: { restorePrevious: boolean; clearSelectedOnDoneAfterMs?: number }) {
    if (this.#asperaWeb) {
      return;
    }

    this.#asperaWeb = new AW4.Connect({
      dragDropEnabled: true,
      sdkLocation: this.config.sdkLocation,
      minVersion: '3.6.0',
    });

    const asperaInstaller = new AW4.ConnectInstaller({
      sdkLocation: this.#config.sdkLocation,
    });

    this.#asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType: unknown, data: unknown) => {
      if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.INITIALIZING) {
        asperaInstaller.showLaunching();
      } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.FAILED) {
        asperaInstaller.showDownload();
      } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.OUTDATED) {
        asperaInstaller.showUpdate();
      } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.RUNNING) {
        asperaInstaller.connected();
        this.connected$.next(true);
      } else if (
        eventType === AW4.Connect.EVENT.STATUS &&
        (data === AW4.Connect.STATUS.EXTENSION_INSTALL || data === AW4.Connect.STATUS.FAILED)
      ) {
        this.#asperaNotifier.notDetected();
      }
    });
    this.#asperaWeb.addEventListener(AW4.Connect.EVENT.TRANSFER, (event: 'transfer', obj: TransfersEvent) => {
      obj.transfers.forEach((eventTransfer) => {
        const inAppTransfer = this.#transfersSubject$.value[eventTransfer.transfer_spec.batch_id];

        if (!inAppTransfer && options.restorePrevious) {
          const batch = AsperaTransferBatch.fromAsperaTransferEvent(eventTransfer);
          this.#addToTransfers(batch);
          return;
        }

        if (inAppTransfer && eventTransfer.status === 'removed') {
          this.removeFromTransfers(inAppTransfer);
          return;
        }

        if (inAppTransfer) {
          const progress = Math.ceil(eventTransfer.percentage * 100);
          const status = transformAsperaStatus(eventTransfer.status);

          if (status === 'failed') {
            inAppTransfer.setError('Aspera transfer failed');
            return;
          }
          inAppTransfer.update({
            status,
            progress,
          });

          if (status !== 'done') {
            return;
          }

          if (options.clearSelectedOnDoneAfterMs) {
            timer(options.clearSelectedOnDoneAfterMs).subscribe(() => {
              this.removeFromTransfers(inAppTransfer);
            });
          }

          if (!inAppTransfer.restored) {
            this.#toast.success({ id: 'aspera_upload', message: 'common.aspera.upload_done' });
          }
        }
      });
    });

    this.#asperaWeb.initSession();
  }

  select = (options: { allowMultipleSelection?: boolean; componentId: UploadComponentId }): void => {
    this.#asperaWeb.showSelectFileDialog(
      {
        success: (event: AsperaSelectEvent) => {
          const isValid = this.#isValidInput(event.dataTransfer.files, options);

          if (!isValid) {
            return;
          }

          this.createLimitedBatch(event.dataTransfer.files, options.componentId);
        },
      },
      {
        allowMultipleSelection: options.allowMultipleSelection,
      },
    );
  };

  createLimitedBatch = (files: AsperaFile[], componentId: UploadComponentId) => {
    const hundredMb = 100 * 1024 * 1024;
    const filesMoreThan100Mb = files.filter((file) => file.size > hundredMb);
    const filesLessThan100Mb = files.filter((file) => file.size <= hundredMb);

    this.splitBigFilesAsSeparateBatches(filesMoreThan100Mb, componentId);
    this.splitFilesByLimit(filesLessThan100Mb, componentId);
  };

  splitBigFilesAsSeparateBatches(files: AsperaFile[], componentId: UploadComponentId) {
    files.forEach((file) => {
      const batch = AsperaTransferBatch.fromAsperaFiles([file], [], componentId);
      this.#addToTransfers(batch);
    });
  }

  splitFilesByLimit(files: AsperaFile[], componentId: UploadComponentId) {
    for (let i = 0; i < files.length; i += this.config.batchLimit) {
      const batch = AsperaTransferBatch.fromAsperaFiles(files.slice(i, i + this.config.batchLimit), [], componentId);
      this.#addToTransfers(batch);
    }
  }

  async startUpload(
    transferConfig: {
      token: string;
      cookie: string;
      destinationRoot: string;
      sshPort?: number;
      remoteHost?: string;
      remoteUser?: string;
    },
    transferBatch: AsperaTransferBatch,
  ): Promise<void> {
    if (!transferBatch.isNotStarted()) {
      return;
    }

    transferBatch.update({
      status: 'pending',
      progress: 0,
    });

    const spec: TransferSpec = {
      component_id: transferBatch.componentId,
      authentication: 'token',
      remote_host: transferConfig.remoteHost ?? this.#config.remoteHost,
      remote_user: transferConfig.remoteUser ?? this.#config.remoteUser,
      destination_root: transferConfig.destinationRoot,
      ssh_port: transferConfig.sshPort ?? 33001,
      direction: 'send',
      target_rate_kbps: 500000,
      resume: 'sparse_checksum',
      batch_id: transferBatch.batchId,
      token: transferConfig.token,
      cookie: transferConfig.cookie,
      files_metadata: transferBatch.files.map((file) => file.toArray()),
      paths: transferBatch.files.map((file) => ({
        source: file.sourcePath,
        destination: file.destinationFilename,
        id: file.id,
        filename: file.filename,
      })),
    };

    if (this.#config.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug('DEBUG: Aspera upload triggered', spec);
    }

    const connectSettings = {
      allow_dialogs: 'no',
    };

    try {
      const startTransferPromise = (spec: TransferSpec, connectSettings: { allow_dialogs: string }) =>
        new Promise<{ transfer_specs: any }>((resolve, reject) => {
          this.#asperaWeb.startTransfer(spec, connectSettings, {
            success(cb: any) {
              resolve({
                transfer_specs: cb.transfer_specs,
              });
            },
            error() {
              reject();
            },
          });
        });

      const response = await startTransferPromise(spec, connectSettings);
      response?.transfer_specs?.[0]?.uuid && transferBatch.setTransferUuid(response.transfer_specs[0].uuid ?? '');
    } catch (e) {
      transferBatch.setError('Aspera failed start transfer');
    }
  }

  #addToTransfers(batch: AsperaTransferBatch) {
    const nextTransfers = {
      ...this.#transfersSubject$.value,
    };

    nextTransfers[batch.batchId] = batch;
    this.#transfersSubject$.next(nextTransfers);
    this.nextBatch$.next(batch);
  }

  removeFromTransfers(batch: AsperaTransferBatch) {
    if (this.#transfersSubject$.value[batch.batchId]) {
      const nextTransfers = {
        ...this.#transfersSubject$.value,
      };

      delete nextTransfers[batch.batchId];
      this.#transfersSubject$.next(nextTransfers);
    }
  }

  #isValidInput(files: AsperaFile[], options: { allowMultipleSelection?: boolean }) {
    if (files.length === 0) {
      return;
    }

    const numberOfFilesInBatch = files.length;
    const userSelectedTooManyFiles = numberOfFilesInBatch > 1 && !options.allowMultipleSelection;

    if (userSelectedTooManyFiles) {
      this.#toast.info({
        id: 'aspera_upload',
        message: 'Please select only one file',
      });
      return false;
    }

    const directory = files.find((file) => file.type.includes('directory'));
    const folder = files.find((file) => file.type.includes('folder'));

    if (folder || directory) {
      this.#toast.info({
        id: 'aspera_upload',
        message: 'common.aspera.folder_not_supported',
      });
      return false;
    }

    return true;
  }

  finalizeCancelTransfer(batch: AsperaTransferBatch) {
    this.#asperaWeb.stopTransfer(batch.transferUuid);
    this.removeFromTransfers(batch);
  }

  initCancelTransfer(transferBatch: AsperaTransferBatch) {
    transferBatch.update({
      status: 'cancelling',
      progress: 0,
    });
    this.#asperaWeb.removeTransfer(transferBatch.transferUuid);
  }
}
