import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from '../config-token';
import { DolbyPlayerRequest, DolbyPlayerResponse } from './dolby.model';
import { DolbyPlayerRegisterAssetBody, DolbyPlayerVurls, DolbyMediaProperties, DolbyTrackMap } from './dolby-v2.model';

@Injectable({
  providedIn: 'root',
})
export class PlayerDolbyRaspApiService {
  private http: HttpClient = inject(HttpClient);
  private env: ApiConfig = inject(API_CONFIG);

  getPlayerConfig = (token: string, body: DolbyPlayerRequest) => {
    if (!this.env.dolbyRaspApiUrl) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.post<DolbyPlayerResponse>(`${this.env.dolbyRaspApiUrl}/hybrik/player`, body, {
      headers: {
        'x-rasp-auth-token': token,
      },
    });
  };

  registerSourceAsset = (token: string, body: DolbyPlayerRegisterAssetBody) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.post(`${this.env.dolbyRaspApiUrlV2}/api/asset`, body, {
      headers: {
        'x-rasp-auth-token': token,
      },
      responseType: 'text',
    });
  };

  putVurls = (ruid: string, payload: DolbyPlayerVurls[]) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.put(`${this.env.dolbyRaspApiUrlV2}/api/asset/vurl?ruid=${ruid}`, payload);
  };

  getPlayerInputConfig = (ruid: string) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.get(`${this.env.dolbyRaspApiUrlV2}/asset/${ruid}/hls/player_input.json`);
  };

  getMediaProperties = (ruid: string) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.get<DolbyMediaProperties[]>(`${this.env.dolbyRaspApiUrlV2}/asset/${ruid}/media-properties.json`);
  };

  getAssetTrackMap = (ruid: string) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.get<Record<'video' | 'audio', DolbyTrackMap[]>>(
      `${this.env.dolbyRaspApiUrlV2}/asset/${ruid}/.metadata/track-map.json`,
    );
  };

  removeVirtualUrl = (ruid: string, vurl: string) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.delete(`${this.env.dolbyRaspApiUrlV2}/api/asset/vurl?ruid=${ruid}&vurl=${vurl}`);
  };

  removeMetadata = (ruid: string) => {
    if (!this.env.dolbyRaspApiUrlV2) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.delete(`${this.env.dolbyRaspApiUrlV2}/api/asset/metadata?ruid=${ruid}`);
  };
}
