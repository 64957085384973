<div class="dialog-wrapper" [class]="'dialog-' + size">
  <ng-container *ngIf="loading; else content">
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </ng-container>

  <ng-template #content>
    <div
      mat-dialog-title
      class="dialog-wrapper-header"
      [class.dialog-wrapper-header__light]="themeSwitcherService.isLight$ | async"
      [class.dialog-wrapper-header--no-gradient]="!withGradient"
    >
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="dialog-wrapper-content" mat-dialog-content>
      <ng-content select="[content]"></ng-content>
    </div>
    <div
      *ngIf="withFooter"
      class="buttons-group dialog-wrapper-footer"
      mat-dialog-actions
      [ngStyle]="{ 'justify-content': footerAlign }"
    >
      <ng-content select="[footer]"></ng-content>
    </div>
  </ng-template>
</div>
