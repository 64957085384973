import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CollectionsService, SimpleType } from '@vdms-hq/api-contract';
import {
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
  RefreshService,
  WebsocketAnyNotificationMessage,
} from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { DialogResponse } from '@vdms-hq/ui';
import { catchError, filter, forkJoin, ObservableInput, Subject, switchMap, take, tap, from, EMPTY } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Router } from '@angular/router';
import { CollectionsRefresh } from '../utils/collections-refresh';
import {
  CollectionAddDialogComponent,
  AddToCollectionResponse,
  AddToCollectionInput,
} from '../../components/collection-add-dialog/collection-add.component';
import { FlatCollectionViewModel } from '../models/collection-view.model';
import { CollectionUploadStatusEnum } from '../models/statuses-type.model';
import { CollectionsAddDataSource } from '../datasources/collections-add-data-source';
import { SingleCollectionImportService } from './single-collection-import.service';
import { CollectionCreateDialogComponent } from '../../components/collection-create-dialog/collection-create-dialog.component';
import { CollectionsMultiDs } from '../datasources/collections-multi-ds.service';

@Injectable({ providedIn: 'root' })
export class CollectionsActionsService implements OnDestroy {
  private matDialog = inject(MatDialog);
  private collectionsService = inject(CollectionsService);
  private collectionsRefresher = inject(CollectionsRefresh);
  private toastService = inject(ToastService);
  private imageCache = inject(ImageCacheService);
  public dataSource = inject(CollectionsMultiDs);
  public addDataSource = inject(CollectionsAddDataSource);
  public router = inject(Router);
  private refreshService = inject(RefreshService);
  private collectionsUploadService = inject(SingleCollectionImportService);

  private destroyed$ = new Subject<void>();

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'common.notifications.collections.create.success',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.update.success',
      }),
    ADD_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.add.success',
      }),
    ADD_FOLDER_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.add_folder.success',
      }),
    REMOVE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.remove.success',
      }),
    REMOVE_ASSET_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.remove_asset.failure',
      }),
    REMOVE_ASSETS_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.remove_assets.success',
      }),
    REMOVE_ASSETS_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.remove_assets.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.delete.failure',
      }),
    DELETE_OWNED_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.delete.success_owned',
      }),
    DELETE_OWNED_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.delete.failure_owned',
      }),
    EXPORT_SUCCESS: () =>
      this.toastService.success({
        id: 'collection_export_success',
        message: 'common.notifications.collections.export.success',
      }),
    IMPORT_SUCCESS: () =>
      this.toastService.success({
        id: 'import_success',
        message: 'common.notifications.collections.import.success',
      }),
    SHARE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.share.success',
      }),

    WS_ADD_TO_COLLECTION_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Collection updating',
          percent,
          this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
          counter,
        ),
      }),
    WS_ADD_TO_COLLECTION_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Collection updated`,
      }),
    WS_ADD_TO_COLLECTION_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Collection update error`,
      }),
  };
  isBrowse = false;

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addAssetsToCollection(
    assetIds: string[],
    assetCollections: SimpleType[] | undefined = undefined,
    collection?: FlatCollectionViewModel,
    browse = false,
  ) {
    this.addAssetsToCollection$(assetIds, assetCollections, collection, browse).subscribe();
  }

  addAssetsToCollection$(
    assetIds: string[],
    assetCollections: SimpleType[] | undefined = undefined,
    collection?: FlatCollectionViewModel,
    browse = false,
  ) {
    this.isBrowse = browse;
    const dialogData: AddToCollectionInput = {
      selectedIds: [],
      selectedSimpleTypes: assetCollections ?? [],
      collectionUuid: collection?.uuid,
    };
    const assetsTmpObj: { asset_uuid?: string; item_uuid?: string; type?: string }[] = [];
    if (assetIds.length === 1 && assetIds[0] === 'cart2collection') {
      assetsTmpObj.push({
        type: 'cart',
      });
      assetIds = [];
    }
    if (collection && !assetCollections) {
      const selectedItems = collection.items?.filter((item) => assetIds.includes(item.asset_uuid));
      selectedItems?.forEach((item) => {
        if (!assetsTmpObj.find((asset) => asset.asset_uuid === item.asset_uuid)) {
          assetsTmpObj.push({ asset_uuid: item.asset_uuid, item_uuid: item.item_uuid });
        }
        item.collections?.forEach((collection) => {
          if (!dialogData.selectedIds?.includes(collection.uuid)) {
            dialogData.selectedIds?.push(collection.uuid);
          }
        });
      });
    } else if (assetCollections) {
      assetCollections.forEach((collection) => {
        if (!dialogData.selectedIds?.includes(collection.uuid)) {
          dialogData.selectedIds?.push(collection.uuid);
        }
      });
    }

    assetIds.forEach((assetUuid) => {
      if (!assetsTmpObj.find((asset) => asset.asset_uuid === assetUuid)) {
        assetsTmpObj.push({ asset_uuid: assetUuid });
      }
      if (dialogData.selectedIds && !assetCollections && !dialogData.selectedIds.find((asset) => asset === assetUuid)) {
        dialogData.selectedIds.push(assetUuid);
      }
    });

    let submitted = false;

    const dialogRef = this.matDialog.open(CollectionAddDialogComponent, { data: dialogData });
    return dialogRef.afterClosed().pipe(
      take(1),
      filter((dialogResponse: AddToCollectionResponse) => !!dialogResponse),
      switchMap((dialogResponse) => {
        if (dialogResponse.status === DialogResponse.OK) {
          submitted = true;
        }

        const itemsArray$: ObservableInput<any>[] = [];

        dialogResponse.selected.forEach((collection) => {
          if (assetsTmpObj.length === 1 && assetsTmpObj[0].type === 'cart') {
            const itemsCart$ = this.collectionsService.addItemsFromCart(collection).pipe(take(1));
            itemsArray$.push(itemsCart$);
          } else {
            const items$ = this.collectionsService
              .addItems(collection, {
                items: assetsTmpObj.map((item) => ({
                  asset_uuid: item.asset_uuid,
                  item_uuid: item.item_uuid ?? uuidv4(),
                })),
              })
              .pipe(take(1));
            itemsArray$.push(items$);
          }
        });

        return forkJoin(itemsArray$);
      }),
      tap(() => {
        if (submitted) {
          this.collectionsRefresher.clearSelection$.next(true);
        }
      }),
    );
  }

  openCreateDialog(name = '', createFromShare = false) {
    const dialogRef = this.matDialog.open(CollectionCreateDialogComponent, { data: { name: name } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          if (dialogResponse.action === 'import') {
            return from(this.collectionsUploadService.confirmUpload(dialogResponse.uploadParams)).pipe(
              tap((response) => {
                if (response.status === CollectionUploadStatusEnum.SUCCESS) {
                  this.popToast.IMPORT_SUCCESS();
                }
              }),
            );
          }

          if (dialogResponse.action === 'create') {
            dialogResponse.collection.uuid = uuidv4();
            return this.collectionsService.create(dialogResponse.collection).pipe(
              tap(() => {
                this.popToast.CREATE_SUCCESS();
                this.refreshService.refresh();
                this.addDataSource.refresh$.next(true);
                this.collectionsRefresher.addToCollection$.next(true);
                if (createFromShare) {
                  this.addDataSource.filters.controls.keyword.setValue(dialogResponse.collection.name);
                }
              }),
            );
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
