import { Progress, TransferableBatch, UploadStatus } from '../../model/transferable-file.interface';
import { BehaviorSubject } from 'rxjs';
import { AsperaFile } from '../model/aspera-select-event.model';
import * as uuid from 'uuid';
import { AsperaStatus, TransferEvent, UploadComponentId } from '../model/aspera-transfer-event.model';
import { AsperaTransferFile } from './aspera-batch-file';
import { HttpErrorResponse } from '@angular/common/http';

export const transformAsperaStatus = (status: AsperaStatus): UploadStatus => {
  switch (status) {
    case 'failed':
      return 'failed';
    case 'cancelled':
      return 'cancelled';
    case 'initiating':
      return 'pending';
    case 'running':
      return 'pending';
    case 'completed':
      return 'done';
    case 'queued':
      return 'queued';
  }

  return 'not_started';
};

export class AsperaTransferBatch implements TransferableBatch {
  public transferUuid?: string;
  error?: any;
  errorPublicMessage?: string;

  constructor(
    public batchId: string,
    public files: AsperaTransferFile[],
    public state$: BehaviorSubject<Progress>,
    public componentId?: UploadComponentId,
    public restored = false,
  ) {}

  public static fromAsperaFiles(
    files: AsperaFile[],
    fileIds: string[] = [],
    componentId?: UploadComponentId,
  ): AsperaTransferBatch {
    const asperaFiles: AsperaTransferFile[] = [];

    const state$ = new BehaviorSubject<Progress>({ status: 'not_started', progress: 0 });

    files.forEach((file, key) => {
      const id = fileIds[key];
      asperaFiles.push(AsperaTransferFile.fromAsperaSelect(file, state$, id));
    });

    return new AsperaTransferBatch(uuid.v4(), asperaFiles, state$, componentId);
  }

  static fromAsperaTransferEvent(eventTransfer: TransferEvent) {
    const batchId = eventTransfer.transfer_spec.batch_id;
    const componentId = eventTransfer.transfer_spec.component_id;
    const state$ = new BehaviorSubject<Progress>({
      status: transformAsperaStatus(eventTransfer.status),
      progress: Math.ceil(eventTransfer.percentage * 100),
    });

    return new AsperaTransferBatch(
      batchId,
      eventTransfer.transfer_spec.files_metadata?.map((file) => AsperaTransferFile.fromArray(file, state$)) ?? [],
      state$,
      componentId,
      true,
    );
  }

  setTransferUuid(uuid: string) {
    this.transferUuid = uuid;
  }

  update(progress: Progress) {
    this.state$.next(progress);
  }

  isNotStarted() {
    return this.state$.value.status === 'not_started';
  }

  setError(error: any) {
    this.state$.next({
      status: 'failed',
      progress: 0,
    });
    this.error = error;
    console.error('Upload error see details: ', error);

    if (error === 'Aspera failed start transfer') {
      this.errorPublicMessage = 'Failed to start upload with Aspera, please try resend the file.';
      return;
    }

    if (error === 'Aspera transfer failed') {
      this.errorPublicMessage = 'Aspera transfer failed, please try resend the file.';
      return;
    }

    if (error instanceof HttpErrorResponse && error.name === 'HttpErrorResponse') {
      switch (true) {
        case error.status === 404:
          this.errorPublicMessage = 'The resource is not found, please double check if the resource exists.';
          return;
        case String(error.status).startsWith('4'):
          this.errorPublicMessage = 'The request is invalid';
          return;
        case String(error.statusText).includes('Unknown Error'):
          // 504 Gateway Timeout or 500 Internal Server Error
          this.errorPublicMessage = 'Something went wrong, please try resend the file.';
          return;
      }

      this.errorPublicMessage = 'Something went wrong, please refresh the page.';
      return;
    }
  }
}
