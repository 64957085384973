import { inject, Injectable } from '@angular/core';
import { Locks } from '@vdms-hq/api-contract';
import { take } from 'rxjs/operators';
import moment from 'moment';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthService } from '@vdms-hq/auth';

@Injectable({ providedIn: 'root' })
export class MetadataLockService {
  #authService: AuthService = inject(AuthService);

  lockedAsset$ = new BehaviorSubject<Partial<Locks> | null>(null);

  isLockExpired() {
    const expires = this.lockedAsset$.value?.expires;
    return expires ? moment(expires).isBefore(moment()) : true;
  }

  filterLocksByType(lock: Locks) {
    return lock.type === 'AI_JOB_EDIT';
  }

  filterLocksByUser(lock: Locks) {
    let userId = null;
    this.#authService.id$.pipe(take(1), filter(Boolean)).subscribe((id) => (userId = id));
    return userId ? lock.user_uuid === userId : false;
  }
}
