import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';
import { FiltersView } from '@vdms-hq/users';
import { ContractListItem, PaginationAPIModel as Pagination, RightsContractsService } from '@vdms-hq/api-contract';
import { filterEmpty, RefreshService, SelectOption, SelectOptionKey, SortBy, SortDirection } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { inject, Injectable, TrackByFunction } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  startWith,
  debounceTime,
  switchMap,
  of,
  combineLatest,
  shareReplay,
  map,
  tap,
  catchError,
  EMPTY,
} from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ToastService } from '@vdms-hq/toast';
import { AuthService } from '@vdms-hq/auth';

export interface ContractAddData {
  key: string;
  label: string;
  uuid: string;
  start_date?: string;
  end_date?: string;
  contract_id?: string;
}
export type TriggerContractsFiltersView = {
  keyword: FormControl<string>;
  license_package_uuid?: FormControl<string>;
};

@Injectable({ providedIn: 'root' })
export class RightsContractsAddDataSourceService
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<FiltersView>, ContractListItem>
{
  private refreshService = inject(RefreshService);
  private activatedClientService = inject(ActivatedClientService);
  private rightsContractsService = inject(RightsContractsService);
  private toastService = inject(ToastService);
  private authService = inject(AuthService);

  isLoading$ = new BehaviorSubject(true);

  refresh$ = this.refreshService.refresh$;

  filters = new FormGroup<TriggerContractsFiltersView>({
    keyword: new FormControl<string>('', { nonNullable: true }),
    license_package_uuid: new FormControl<string>('', { nonNullable: true }),
  });

  sortBy$ = new BehaviorSubject<SortBy>('rightsContract.name');
  sortDirection$ = new BehaviorSubject<SortDirection>('asc');

  filters$ = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(300),
    switchMap(() => {
      this.changePageIndex$.next(0);
      return of(this.filters.value);
    }),
  );

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.authService.auth$,
    this.activatedClientService.clientId$,
  ]).pipe(
    switchMap(([, page, perPage, orderBy, orderDir, filters, user, client]) => {
      if (!client || !user) {
        return EMPTY;
      }
      this.isLoading$.next(true);
      const pagination: Pagination = Pagination.create({ page: page, perPage, orderBy, orderDir });
      return this.rightsContractsService.getMany(pagination, {
        keyword: filters.keyword ?? '',
        licensed_package_uuid: filters.license_package_uuid ?? undefined,
        sort: orderBy ?? '',
        end_after: new Date(Date.now()).toISOString(),
      });
    }),
    shareReplay(1),
  );

  allData$: Observable<ContractAddData[]> = this.responseData$.pipe(
    map((response) => response.data),
    map((contracts) =>
      contracts.map((contract) => ({
        key: contract.uuid,
        label: contract.name,
        uuid: contract.uuid,
        start_date: contract.start_date,
        end_date: contract.end_date,
        contract_id: contract.contract_id,
      })),
    ),
    tap(() => this.isLoading$.next(false)),
    catchError((error) => {
      this.toastService.error({
        id: 'error',
        message: 'An error occurred while fetching contracts data. Please try again.',
      });
      return EMPTY;
    }),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  connection$ = this.allData$;
  trackBy?: TrackByFunction<SelectOption<SelectOptionKey>> | undefined;
  disconnect?: (() => void) | undefined;
  pageSizeOptions$?: Observable<number[]> | undefined;
  pagination$?: BehaviorSubject<{ page: string; perPage: string } | null> | undefined;
  searchKeyword(keyword: string) {
    console.log(keyword);
  }
  getFormGroup() {
    return this.filters as FormGroup<FiltersView>;
  }
}
