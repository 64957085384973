import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type ButtonColors = 'primary' | 'secondary' | 'warn' | 'accent' | 'transparent' | 'primary-transparent';
export enum ButtonColorsEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARN = 'warn',
  TRANSPARENT = 'transparent',
}

export interface ButtonComponentConfig {
  color: ButtonColors;
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  iconStyle?: string;
  iconOnly: boolean;
  loading?: boolean;
  tooltip?: string;
  isSubmit?: boolean;
  size?: 'small' | 'medium' | 'fieldset' | 'full' | undefined;
  asDownloadLink?: boolean;
}

type IconStyle = 'outlined' | 'round' | 'sharp' | 'two-tone' | '';
type IconStyleSetType = Omit<{ [key in IconStyle]: string }, ''>;

@Component({
  selector: 'vdms-hq-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'disabled',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, ButtonComponentConfig {
  classes: string[] = [];

  @Input() color: ButtonColors = 'transparent';
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() iconOnly = false;
  @Input() iconStyle: IconStyle = '';
  @Input() loading = false;
  @Input() tooltip = '';
  @Input() isSubmit = false;
  // todo: remove fieldset after refactoring preview player controls
  @Input() size: 'small' | 'medium' | 'fieldset' | 'full' | undefined = undefined;
  @Input() asDownloadLink = false;

  matColor: ThemePalette;

  iconStylesSet: IconStyleSetType = {
    outlined: 'material-icons-outlined',
    round: 'material-icons-round',
    sharp: 'material-icons-sharp',
    'two-tone': 'material-icons-two-tone',
  };

  ngOnInit() {
    switch (this.color) {
      case 'primary':
        this.matColor = 'primary';
        break;
      case 'secondary':
        this.matColor = 'accent';
        break;
      case 'warn':
        this.matColor = 'warn';
        break;
    }

    this.classes = [];

    if (this.icon) {
      this.classes.push('has-icon');
    }

    if (this.iconOnly) {
      this.classes.push('icon-only');
    }

    if (this.disabled) {
      this.classes.push('disabled');
    }

    if (this.size) {
      this.classes.push('size-' + this.size);
    }
  }
}
