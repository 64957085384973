import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  AssetActionsService,
  AssetFlatView2Model,
  AssetResults2Component,
  AssetResultsModule,
  ResultsActions,
  assetResultsMenuConfig2,
  ContextMenuActionsService,
} from '@vdms-hq/asset-results';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { CountdownPipe, FieldsScopeKey, filterEmpty, FormatBytesPipe, RenamePipe, castTo } from '@vdms-hq/shared';
import { ToastModule } from '@vdms-hq/toast';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  UIEmptyResultsModule,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { SharedPackAssetsFooterComponent } from '../../components/shared-pack-assets-footer/shared-pack-assets-footer.component';
import { TranscodeDetailsDialogComponent } from '../../components/transcode-details-dialog/transcode-details-dialog.component';
import { CurrentOrderAssetsDataSource } from '../../logic/current-order-assets.ds';
import { CurrentOrderService } from '../../logic/current-order.service';
import { OrderActionsProperties, OrderAssetViewModel2, OrderViewModel } from '../../logic/models';
import { OrderActionsService } from '../../logic/order-actions.service';
import { OrderAssetsFilterService } from '../../logic/order-assets-filters.service';
import { sharedPacksMetadata } from '../../../logic/config';
import { LayoutService, Theme } from '@vdms-hq/theming';
import { TABLE_TYPE } from '@vdms-hq/view-settings';
import { FieldsFetcherService } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-shared-packs-details',
  templateUrl: './shared-packs-details.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    MatIconModule,
    TranslateModule,
    UILoaderModule,
    UILayoutModule,
    ToastModule,
    UIResultsWrapperModule,
    UISimpleDetailsListModule,
    AssetResultsModule,
    SharedPackAssetsFooterComponent,
    ReactiveFormsModule,
    AssetResults2Component,
    DataPresentationHeaderComponent,
    CountdownPipe,
    UIEmptyResultsModule,
  ],
  providers: [
    OrderAssetsFilterService,
    OrderMetadataTransformerService,
    DatePipe,
    FormatBytesPipe,
    OrderActionsService,
    RenamePipe,
  ],
  standalone: true,
})
export class SharedPacksDetailsComponent implements OnInit, OnDestroy {
  public currentOrderService = inject(CurrentOrderService);
  public dataSource = inject(CurrentOrderAssetsDataSource);
  public filterService = inject(OrderAssetsFilterService);
  private orderActionsService = inject(OrderActionsService);
  private dialog = inject(MatDialog);
  private assetActionsService = inject(AssetActionsService);
  private contextMenuActions = inject(ContextMenuActionsService);
  private layoutService = inject(LayoutService);
  private fieldsFetcherService = inject(FieldsFetcherService);

  protected readonly TABLE_TYPE = TABLE_TYPE;
  scope: FieldsScopeKey = 'shared-packs';
  #destroy$ = new Subject<void>();

  currentOrderSubject$ = new BehaviorSubject<OrderViewModel | null>(null);
  $castToOrderActionsProperties = castTo<{ key: OrderActionsProperties }>();

  currentOrder$ = this.currentOrderService.data$.pipe(
    takeUntil(this.#destroy$),
    filterEmpty(),
    tap((order) => {
      this.currentOrderSubject$.next(order);
    }),
    shareReplay(1),
  );
  thermometerImageType$ = this.layoutService
    .currentTheme()
    .pipe(
      map((theme) =>
        theme === Theme.dark
          ? '/assets/common/icons/order_warmup_dark.svg'
          : '/assets/common/icons/order_warmup_light.svg',
      ),
    );
  headerActions$: Observable<ActionContextLess[]> = this.currentOrder$.pipe(
    switchMap((data) => {
      return combineLatest([
        this.orderActionsService.buildRetryAllDeliveryDestinationsAction$(data),
        this.orderActionsService.buildDownloadAllAsperaAction$(undefined, data),
        this.orderActionsService.buildDownloadAllAsperaAction$(true, data),
        this.orderActionsService.buildDownloadClearanceNotesAction$(true),
      ]).pipe(map((actions) => actions.filter(Boolean) as ActionContextLess[]));
    }),
  );

  actions$: Observable<DataAction<OrderAssetViewModel2>[]> = this.currentOrder$.pipe(
    switchMap((order) => {
      return combineLatest([
        this.orderActionsService.buildDownloadHTTPSAction$(order),
        this.orderActionsService.buildDownloadAsperaAction$<OrderAssetViewModel2>(order),
        this.orderActionsService.buildRetryDeliveryDestinationsAction$<OrderAssetViewModel2>(order),
        this.orderActionsService.buildTranscodeStatusAction$<OrderAssetViewModel2>(),
        this.assetActionsService.buildPreviewAction$<AssetFlatView2Model>(),
      ]);
    }),
    map((actions) => actions.filter(Boolean) as DataAction<OrderAssetViewModel2>[]),
  );

  isSharedPackExpired$ = this.currentOrder$.pipe(
    map((order) => (order.expires_at ? moment(order.expires_at).isBefore(moment()) : false)),
  );
  isDownloadLimitExpired$ = this.currentOrder$.pipe(map((order) => !order.could_be_downloaded ?? true));
  downloadLimitExpiredAssetsExist$ = this.dataSource.selection.entities$.pipe(
    map((selectedAssets) => {
      return selectedAssets.some((item) => item.remainingDownloads === 0);
    }),
  );
  isExpiredSubtitle$ = this.isSharedPackExpired$.pipe(
    map((expired) => (expired ? ' (Expired - download disabled)' : '')),
  );
  embargoedAssetsSelected$ = new BehaviorSubject(false);
  refresh$ = this.currentOrderService.refresh$;
  currentOrderId: string | null = null;
  selectionDisabled = false;
  orderExpired = false;
  canShowWarmUpCouter$ = this.currentOrder$.pipe(map(({ estimated_restore_at }) => estimated_restore_at));
  coldAssetsExist$ = this.dataSource.selection.entities$.pipe(
    map((selectedAssets) => {
      return selectedAssets.some((item) => item.props.storage === 'cold' || !item.downloadUuid);
    }),
  );
  downloadInProgress$ = this.orderActionsService.downloadInProgress$;

  fieldsConfig$ = this.fieldsFetcherService.getConfiguration$(this.scope);

  readonly assetResultsMenuConfig2 = assetResultsMenuConfig2;

  get orderHasManyDeliveryDestinationConfigs() {
    if (
      this.currentOrderSubject$.value?.delivery_destinations?.length &&
      'delivery_destinations' in this.currentOrderSubject$.value
    ) {
      return (
        this.currentOrderSubject$.value.delivery_destinations.length ||
        this.currentOrderSubject$.value.delivery_destinations?.[0]?.configs?.length
      );
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.dataSource.sortBy$.next('orderItem.createdAt');
    this.currentOrderService.id$.pipe(takeUntil(this.#destroy$)).subscribe((id) => (this.currentOrderId = id));
    this.filterService.listenUntil(this.#destroy$);
    this.filterService.initConfig();
    this.currentOrderService.configure(sharedPacksMetadata);
    this.cacheOrderDetails();
    this.#listenEntitiesChanges();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  cacheOrderDetails(): void {
    this.currentOrder$.pipe(takeUntil(this.#destroy$)).subscribe((order) => {
      this.orderExpired = order?.expires_at ? moment(order.expires_at).isAfter(moment()) : false;
    });
  }

  #isDownloadReady(entities: OrderAssetViewModel2[]): boolean {
    return entities.every((entity) => entity.transcodeStatus === 'Download Ready');
  }

  handleHeaderAction($event: { key: OrderActionsProperties }) {
    this.currentOrder$
      .pipe(
        tap((order) => {
          this.orderActionsService.handleDownloadAllAsperaAction($event, order.uuid);
          this.orderActionsService.handleRetryDeliveryDestinationsAction($event, order.uuid);
          this.orderActionsService.handleDownloadClearanceNotesAction$($event, order);
        }),
        takeUntil(this.#destroy$),
      )
      .subscribe();
  }

  handleAction($event: { key: string; item: OrderAssetViewModel2 }) {
    this.currentOrder$
      .pipe(
        tap((order) => {
          this.assetActionsService.handlePreviewAction($event);
          this.orderActionsService.handleRetryDeliveryDestinationsAction($event, order.uuid);
          this.orderActionsService.handleDownloadAsperaAction($event, order.uuid);
          this.orderActionsService.handleDownloadHTTPSAction($event, order.uuid);
          this.orderActionsService.handleTranscodeStatusAction($event, order);
          this.contextMenuActions.handleAction($event);
        }),
        take(1),
      )
      .subscribe();
  }

  handleBatchAction($event: { action: ResultsActions; item?: OrderAssetViewModel2[] }) {
    const { item, action } = $event;

    if (!action || !item) {
      return false;
    }

    this.currentOrder$
      .pipe(
        tap((order) => {
          switch (action) {
            case ResultsActions.DOWNLOAD_SELECTED_HTTPS:
              this.orderActionsService.handleDownloadHTTPSAction<OrderAssetViewModel2[]>(
                {
                  key: ResultsActions.DOWNLOAD_SELECTED_HTTPS,
                  item,
                },
                order.uuid,
              );
              break;
            case ResultsActions.DOWNLOAD_SELECTED_ASPERA_FOLDERS:
              this.#downloadAssets(item, true).pipe(takeUntil(this.#destroy$), take(1)).subscribe();
              break;
            case ResultsActions.DOWNLOAD_SELECTED_ASPERA:
              this.#downloadAssets(item, false).pipe(takeUntil(this.#destroy$), take(1)).subscribe();
              break;
            default:
              break;
          }
        }),
        takeUntil(this.#destroy$),
      )
      .subscribe();

    return true;
  }

  #downloadAssets(items: OrderAssetViewModel2[], keepFoldersStructure: boolean): Observable<unknown> {
    if (items.every((item) => item.isAssetDownloadReady && item.downloadUuid !== null)) {
      return this.currentOrderService.id$.pipe(
        switchMap((id) => {
          this.orderActionsService.download(id, items, keepFoldersStructure);
          return EMPTY;
        }),
      );
    }
    if (this.orderHasManyDeliveryDestinationConfigs) {
      this.fieldsConfig$.pipe(take(1)).subscribe((config) => {
        this.dialog.open(TranscodeDetailsDialogComponent, {
          data: {
            orderActive: !this.orderExpired,
            items,
            orderId: this.currentOrderId,
            delivery_destinations: items[0].deliveryDestinations,
            definition: config.table.system.find((field) => field.sourceListKey === 'transcode_status')?.results2,
          },
        });
      });

      return EMPTY;
    }
    return this.currentOrderService.id$.pipe(
      switchMap((id) => {
        this.orderActionsService.download(id, items, keepFoldersStructure);
        return EMPTY;
      }),
    );
  }

  #listenEntitiesChanges() {
    this.dataSource.selection?.entities$.pipe(takeUntil(this.#destroy$)).subscribe((entities) => {
      this.selectionDisabled =
        !!(
          !entities.length ||
          (this.currentOrderSubject$.value?.expires_at &&
            moment(this.currentOrderSubject$.value?.expires_at).isBefore(moment()))
        ) || !this.#isDownloadReady(entities);
      this.embargoedAssetsSelected$.next(entities.some((entity) => entity.isEmbargoActive));
    });
  }
}
