import { Injectable } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EgressStatsForm {
  form = new FormGroup({
    selectedPeriod: new FormControl('30', { nonNullable: true }),
    transferSources: new FormControl<string>('vida,connect2,mediapulse', { nonNullable: true }),
  });

  formValue$ = this.form.valueChanges.pipe(startWith(this.form.value));
  periodOptions: SelectOption[] = [
    { key: '7', label: 'Last 7 days' },
    { key: '30', label: 'Last 30 days' },
    { key: '90', label: 'Last 90 days' },
    { key: '365', label: 'Last 365 days' },
  ];

  sourceOptions: SelectOption[] = [
    { key: 'vida,connect2,mediapulse', label: 'All' },
    { key: 'vida', label: 'VIDA' },
    { key: 'connect2', label: 'Connect2' },
    { key: 'mediapulse', label: 'Mediapulse' },
  ];
}
