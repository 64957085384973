import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderViewMode } from '../logic/model/mode.model';
import { UILoaderService } from '../logic/services/loader.service';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vdms-hq-ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  #destroy$ = new Subject<void>();
  #mode: LoaderViewMode = 'default';
  #isOverlayActive$ = this.loaderService.fullscreenLoaderActive$;
  showLoader?: boolean;

  @Input() set mode(value: LoaderViewMode) {
    if (this.#mode === 'over') {
      throw new Error(`Invalid operation. Can't change loader mode while in fullscreen`);
    } else {
      this.#mode = value;
    }
  }

  get mode() {
    return this.#mode;
  }

  @Input() center = true;
  @Input() centerPadding = true;
  @Input() backdrop = false;
  @Input() size = 30;
  @Input() text!: string;

  constructor(private loaderService: UILoaderService) {}

  ngOnInit() {
    if (this.mode !== 'over') {
      this.showLoader = true;
      return;
    }

    this.loaderService.setFullscreenLoaderActive(true);
  }

  ngAfterViewInit() {
    if (this.mode !== 'over') {
      return;
    }
    this.#isOverlayActive$.pipe(takeUntil(this.#destroy$), delay(100)).subscribe((isOverlayActive) => {
      this.showLoader = !isOverlayActive;
    });
  }

  ngOnDestroy() {
    if (this.mode === 'over') {
      this.loaderService.setFullscreenLoaderActive(false);
    }
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
