<div class="login-or-separator">
  <span>{{ 'common.auth.loginOrSeparator' | translate }}</span>
</div>

<div class="provider-buttons">
  <vdms-hq-ui-button
    color="secondary"
    size="full"
    *ngIf="settings.google"
    [loading]="loadingGoogle"
    (click)="loginViaGoogle()"
  >
    <div class="button-label">
      <img class="vendor-logo" alt="Google logo" src="assets/login/google.svg" />
      <span>{{ 'common.auth.provider.googleButtonLabel' | translate }}</span>
    </div>
  </vdms-hq-ui-button>

  <vdms-hq-ui-button
    color="secondary"
    *ngIf="settings.microsoft"
    [loading]="loadingMicrosoft"
    (click)="loginViaMicrosoft()"
    size="full"
  >
    <div class="button-label">
      <img class="vendor-logo" alt="Microsoft logo" src="assets/login/microsoft.svg" />
      <span>{{ 'common.auth.provider.microsoftButtonLabel' | translate }}</span>
    </div>
  </vdms-hq-ui-button>

  <vdms-hq-ui-button
    color="secondary"
    *ngIf="settings.okta"
    [loading]="loadingOkta"
    (click)="loginViaOkta()"
    size="full"
  >
    <div class="button-label">
      <img class="vendor-logo" alt="Okta logo" src="assets/login/okta.svg" />
      <span>{{ 'common.auth.provider.oktaButtonLabel' | translate }}</span>
    </div>
  </vdms-hq-ui-button>
</div>
