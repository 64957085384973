import { inject, Injectable } from '@angular/core';
import { SettingsFormService } from './settings-form.service';
import { BehaviorSubject, combineLatest, of, Subject, switchMap, throwError } from 'rxjs';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { PhoneType } from '@vdms-hq/ui';
import { UserApiService } from '@vdms-hq/api-contract';
import { ActivatedClientService, Permission, PermissionService } from '@vdms-hq/activated-client';
import { UserUpdateAttributes } from '@vdms-hq/firebase-contract';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({ providedIn: 'root' })
export class SettingsDataService {
  #form = inject(SettingsFormService);
  #userService = inject(UserApiService);
  #authService = inject(AuthService);
  #toastService = inject(ToastService);
  #activatedClientService = inject(ActivatedClientService);
  #permissionsService = inject(PermissionService);
  #clipboard = inject(Clipboard);

  isSaving$ = new BehaviorSubject(false);
  isFetching$ = new BehaviorSubject(false);
  userToken$ = new BehaviorSubject<string | null>(null);
  userTokenExpiredAt$ = new BehaviorSubject<string | null>(null);
  userUuid$ = new BehaviorSubject<string | null>(null);

  #destroyer$ = new Subject<void>();

  get #apiPayload(): { [p: string]: string | null | undefined } {
    const formValue = this.#form.value;
    const profileSettings = formValue.profileSettings;
    const systemSettings = formValue.systemSettings;

    const isOfficePhone = profileSettings.profileDetails.phone.type === PhoneType.OFFICE;

    const payload = {
      name: profileSettings.profileDetails.displayName,
      email: profileSettings.profileDetails.email,
      office_phone_number: isOfficePhone ? profileSettings.profileDetails.phone?.number : null,
      mobile_number: !isOfficePhone ? profileSettings.profileDetails.phone?.number : null,
      office_address: `${profileSettings.companyAddress.address1 || ''}%2C${
        profileSettings.companyAddress.postalCode || ''
      }%2C${profileSettings.companyAddress.country || ''}`,
      office_country: profileSettings.companyAddress.country,
      office_postcode: profileSettings.companyAddress.postalCode,
      date_format: systemSettings.dateFormat,
      time_format: systemSettings.timeFormat,
      department_field_uuid: profileSettings.department ? profileSettings.department : null,
    };

    return payload;
  }

  get #firebasePayload() {
    const value = this.#form.value;
    const attributes = value.systemSettings.attributes;

    return {
      preferredTimezone: attributes.preferredTimezone,
      vida: {
        ...attributes.vida,
        preferredTableView: value.systemSettings.attributes.vida.preferredTableView,
      },
    };
  }

  get #userUpdatePayload() {
    const payload: Partial<{ email: string; displayName: string }> = {};

    const displayNameCtrl = this.#form.profileSettingsControl.get('profileDetails.displayName');

    if (displayNameCtrl?.touched) {
      payload['displayName'] = displayNameCtrl.value;
    }
    const emailCtrl = this.#form.profileSettingsControl.get('profileDetails.email');

    if (displayNameCtrl?.touched) {
      payload['email'] = emailCtrl?.value;
    }

    return payload;
  }

  destroy(): void {
    this.#destroyer$.next();
    this.#destroyer$.complete();
    this.isSaving$.next(false);
    this.isFetching$.next(false);
  }

  fetch(): void {
    this.#form.disable();
    this.isFetching$.next(true);

    combineLatest([
      this.#authService.displayName$.pipe(take(1)),
      this.#authService.userAttributesDefinite$.pipe(take(1)),
      this.#userService.getUser(),
      this.#activatedClientService.tableDefaultView$.pipe(take(1)),
      this.#permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_USERS]),
    ])
      .pipe(
        takeUntil(this.#destroyer$),
        catchError((err) => {
          this.#form.disable();
          this.isFetching$.next(false);
          return throwError(() => err);
        }),
      )
      .subscribe(([displayName, attributes, user, defaultView, hasUsersEditPermission]) => {
        this.userToken$.next(user?.static_api_token_chars ?? null);
        this.userTokenExpiredAt$.next(user?.static_api_token_expires_at ?? null);
        this.userUuid$.next(user.uuid);
        this.#form.setForm(displayName, attributes, user, defaultView);
        this.#form.enable();

        if (!hasUsersEditPermission) {
          this.#form.disableEmail();
        }

        this.isFetching$.next(false);
      });
  }

  save(): void {
    if (!this.#form.isValid || this.#form.isPristine) {
      return;
    }

    this.isSaving$.next(true);

    this.#form.disable();
    this.#userService
      .patchCurrentUser(this.#apiPayload)
      .pipe(
        takeUntil(this.#destroyer$),
        switchMap(() => this.#authService.updateAttributes(this.#firebasePayload as UserUpdateAttributes)),
        switchMap(() => this.#authService.updateProfile(this.#userUpdatePayload)),
        catchError((err) => {
          if (err.code?.includes('auth/user-token-expired')) {
            return this.#authService.logout().then(() => {
              this.#toastService.info({
                id: 'profile',
                message: 'Reauthentication is required after personal data changes',
              });
            });
          }

          if (err?.error?.data) {
            return throwError(() => new Error(err.error.data));
          }

          return throwError(() => err);
        }),
      )
      .subscribe({
        next: () => {
          this.isSaving$.next(false);
          this.#form.enable();
          this.#toastService.success({ id: `settingsServiceSave`, message: 'Successfully updated profile' });
        },
        error: (e) => {
          this.isSaving$.next(false);
          this.#form.enable();
          return of(null);
        },
      });
  }

  generateToken(validDate: string | null) {
    if (!this.userUuid$.value) {
      return;
    }

    this.#userService.generateApiToken(this.userUuid$.value, validDate).subscribe(({ token }) => {
      this.#toastService.success({
        id: 'token_generated',
        message: 'New token has been generated and copied to your clipboard',
      });
      this.#clipboard.copy(token);
      this.userToken$.next(token);
    });
  }
}
