import { AssetType } from '../asset/models/asset-type.model';

export const ALL_SEPARATORS = ['space', 'hyphen', 'underscore', 'none'] as const;

export const StringProcessorsParamCountMap: Partial<{
  [key in StringProcessorType]: ProcessorParamType[];
}> = {
  padZero: ['number'],
  left: ['number'],
  right: ['number'],
  regexMatch: ['regex'],
  regexRemove: ['regex'],
};

export const ALL_NOTABLE_FIELDS = [
  'episodeName',
  'episodeNumber',
  'seriesName',
  'seriesNumber',
  'programmeNumber',
  'facilityOfOrigin',
  'language',
  'elements',
  'segments',
  'genre',
  'productionCompany',
  'contentClass',
  'contentType',
  'theme',
  'variation',
  'category',
  'seasonTitle',
  'releaseYear',
  'resolution',
  'codec',
  'orderPackageTitle',
  'orderNo',
  'audioLayout',
  'audioLanguage',
] as const;

export const DefaultForTypes = ['analyze-metadata', 'cart']; // 'license-package' is not used
export const DefaultForTypesForMetadataRecognition = ['analyze-metadata'];
export const ALL_STRING_PROCESSORS = [
  'trim', // Remove whitespace from both ends
  'trimStart', // Remove whitespace from the start
  'trimEnd', // Remove whitespace from the end
  'toLowerCase', // Change case to lowercase
  'toUpperCase', // Change case to UPPERCASE
  'titleCase', // Change case to Title Case
  'camelCase', // Change case to camelCase
  'pascalCase', // Change case to PascalCase
  'snakeCase', // Change case to snake_case
  'kebabCase', // Change case to kebab-case
  'noSpace', // Remove all spaces
  'toUnderscore', // Replace spaces with underscores
  'toHyphen', // Replace spaces with hyphens
  'removeDots', // Remove dots
  'fileSafe', // Allow only safe file system characters
  'padZero', // Pad zeros to the front of a number to ensure it has `n` digits; e.g., padZero(3) gives "002"
  'left', // Take the first `n` characters from the string; e.g., left(5) change "my-test-input" to "my-tes"
  'right', // Take the last `n` characters from the string; e.g., right(5) change "my-test-input" to "input"
  'regexMatch', // Keep only parts of the string that match a given regex pattern; e.g., regexMatch(/[a-z]/) would leave only small letters from a to z.
  'regexRemove', // Remove parts of the string that match a given regex pattern; e.g., regexRemove(/[^\d]/) would remove anything that is not a digit.
] as const;

export enum Separator {
  SPACE = 'space',
  HYPHEN = 'hyphen',
  UNDERSCORE = 'underscore',
  NONE = 'none',
}

export type ProcessorParamType = 'number' | 'regex';
export type StringProcessorType = (typeof ALL_STRING_PROCESSORS)[number];
export type SeparatorType = (typeof ALL_SEPARATORS)[number];

export type NotableFieldType = (typeof ALL_NOTABLE_FIELDS)[number];

export type DefaultForType = (typeof DefaultForTypes)[number];

export interface FilenameConventionDefaultFor {
  asset_type: AssetType;
  default_for_type: DefaultForType;
}

export interface GetFilenameConvention {
  uuid: string;
  name: string;
  template: string;
  separator: SeparatorType;
  default_for: FilenameConventionDefaultFor[];
}

export type PostFilenameConvention = Omit<GetFilenameConvention, 'uuid' | 'default_for'>;
export type PatchFilenameConvention = Omit<GetFilenameConvention, 'uuid' | 'default_for'>;

export interface PatchFilenameConventionDefaultFor {
  add?: FilenameConventionDefaultFor[];
  remove?: FilenameConventionDefaultFor[];
}

export interface TextTemplatePart {
  index: number;
  text: string;
}
export interface FieldTemplatePart {
  index: number;
  notableField: NotableFieldType;
  processors: ProcessorTemplate[];
}
export interface ProcessorTemplate {
  index: number;
  processor: StringProcessorType;
  params: string[];
}

export interface TransformedTemplate {
  rawTemplate: string;
  separator: SeparatorType;
  parts: (TextTemplatePart | FieldTemplatePart)[];
}

export interface FilenameConventionExtraData {
  orderPackageTitle: string;
  orderNo: string;
}

export interface FilenameConvention {
  id: number;
  uuid: string;
  name: string;
  template: string;
  separator: SeparatorType;
  group: string;
  defaultFor: string[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum FilenameConventionEndpoints {
  GET_ALL = 'filename-conventions',
  GET_ONE = 'filename-convention/{uuid}',
  CREATE = 'filename-convention',
  EDIT = 'filename-convention/{uuid}',
  EDIT_DEFAULT_FOR = 'filename-convention/{uuid}/default-for',
  DELETE = 'filename-convention/{uuid}',
}
