<vdms-hq-ui-actions-footer class="action-bar">
  <ng-container left>
    <ng-container *ngIf="enabledActions.addToLicensedPackages || enabledActions.setAsColdOnly">
      <ng-container
        *requiresPermissions="
          [Permission.EDIT_LICENSED_PACKAGES, Permission.SET_ASSETS_AS_COLD_ONLY];
          comparison: 'some'
        "
      >
        <vdms-hq-ui-button color="secondary" [matMenuTriggerFor]="menu" [iconOnly]="true" icon="more_vert">
        </vdms-hq-ui-button>
        <mat-menu #menu="matMenu">
          <div class="wrapper d-flex flex-col align-items-center">
            <ng-container *ngIf="enabledActions.addToLicensedPackages">
              <button
                mat-menu-item
                *requiresPermissions="[Permission.EDIT_LICENSED_PACKAGES]"
                (click)="actions.emit(ResultsActions.ADD_TO_LICENSED_PACKAGE)"
              >
                <span>{{ actionLabels.addToLicensedPackages ?? '' | translate }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="enabledActions.setAsColdOnly">
              <button
                mat-menu-item
                [disabled]="selectionTotal > 500 || selectedVirtualAssets || selectedQuarantinedAssets"
                [matTooltipDisabled]="!selectedVirtualAssets && selectionTotal <= 500"
                [matTooltip]="'Action disabled if selection has virtual assets or more than 500 assets selected'"
                [matTooltipClass]="'permissions'"
                *requiresPermissions="[Permission.SET_ASSETS_AS_COLD_ONLY]"
                (click)="actions.emit(ResultsActions.SET_AS_COLD)"
              >
                <span> {{ actionLabels.setAsColdOnly ?? '' | translate }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="enabledActions.imposeQuarantine">
              <button
                *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
                mat-menu-item
                (click)="actions.emit(ResultsActions.BATCH_IMPOSE_QUARANTINE)"
                [disabled]="selectedQuarantinedAssets"
                [matTooltipDisabled]="!selectedQuarantinedAssets"
                [matTooltip]="'Action disabled if selection has quarantined assets'"
              >
                <span>{{ 'pages.assets_list.impose_quarantine' | translate }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="enabledActions.liftQuarantine">
              <button
                *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
                mat-menu-item
                (click)="actions.emit(ResultsActions.BATCH_LIFT_QUARANTINE)"
                [disabled]="!selectedQuarantinedAssets"
              >
                <span>{{ 'pages.assets_list.lift_quarantine' | translate }}</span>
              </button>
            </ng-container>
          </div>
        </mat-menu>
      </ng-container>
    </ng-container>
    <vdms-hq-ui-button color="secondary" (click)="actions.emit(ResultsActions.CLEAR_SELECTION)">
      {{ 'common.global.deselect_all' | translate }}
    </vdms-hq-ui-button>
    <ng-container *ngIf="enabledActions.batchEdit">
      <vdms-hq-ui-button
        color="primary"
        *requiresPermissions="[Permission.EDIT_ASSETS]"
        (click)="actions.emit(ResultsActions.BATCH_UPDATE)"
      >
        {{ actionLabels.batchEdit ?? '' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <ng-container *ngIf="enabledActions.addToCollection">
      <vdms-hq-ui-button
        color="primary"
        *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
        (click)="actions.emit(ResultsActions.ADD_TO_COLLECTION)"
      >
        {{ actionLabels.addToCollection ?? '' | translate }}
      </vdms-hq-ui-button>
    </ng-container>

    <ng-container *ngIf="enabledActions.triggerNotifications">
      <vdms-hq-ui-button color="primary" (click)="actions.emit(ResultsActions.TRIGGER_NOTIFICATIONS)">
        {{ actionLabels.triggerNotifications ?? '' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </ng-container>

  <ng-container center>
    <vdms-hq-ui-button>
      {{ 'common.global.selected' | translate: { selected: selectionTotal } }}
    </vdms-hq-ui-button>
  </ng-container>

  <ng-container right>
    <ng-container *ngIf="enabledActions.delete">
      <vdms-hq-ui-button
        color="warn"
        *requiresPermissions="[Permission.DELETE_ASSETS]"
        (click)="actions.emit(ResultsActions.DELETE)"
      >
        {{ actionLabels.delete ?? '' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <ng-container *ngIf="enabledActions.cart && cartButtonVisibility">
      <vdms-hq-ui-button
        color="primary"
        *requiresPermissions="[Permission.SHOPPING_CART]"
        (click)="actions.emit(ResultsActions.ADD_TO_CART)"
        [disabled]="cartButtonDisabled"
      >
        {{ actionLabels.cart ?? '' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </ng-container>
</vdms-hq-ui-actions-footer>
