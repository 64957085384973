import { AssetFlat, AssetType } from '../../asset';
import { GroupCurrencyISO } from '@vdms-hq/shared';
import { JobTypePayloadInterface, ORDER_TYPE } from '../../order/order.model';

export interface InvalidCartAsset {
  assetUuid: string;
  assetOriginalFilename: string;
  cartItemUuids: string[];
  invalidTypes: string[];
}

export interface AssetFlatCartItem {
  uuid: string;
  timecode_in: null | string;
  timecode_out: null | string;
  note: string;
  asset: AssetFlat;
  clip_size: null | number;
}

export interface Counters {
  added?: number;
  not_added?: number;
  total: number;
}

export enum DISCOUNT_STATUS {
  DEPARTMENT = 'department',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  WRONG = 'wrong',
  NONE = 'none',
}

export interface CartSummary {
  total: number;
  cold: number;
  price: {
    egress?: number;
    restore?: number;
    processing?: number;
    transcode?: number;
    proxy_transcode?: number;
    video_analysis?: number;
    total?: number;
  };
  discount_status: DISCOUNT_STATUS;
  discount_name?: string;
  discount_prices: {
    core?: number;
    egress?: number;
    restore?: number;
    processing?: number;
    transcode?: number;
    proxy_transcode?: number;
    video_analysis?: number;
    total?: number;
  };
  settings: {
    currency: GroupCurrencyISO;
  };
}

export interface CartSummaryRequest {
  order_type: ORDER_TYPE;
  discount_code: string | null | undefined;
  destinations?: { uuid: string; config_uuid: string }[];
  jobs?: JobTypePayloadInterface[];
  warm_up?: boolean;
  download_limit?: number;
  department_field_uuid?: string;
}

export interface OrderPdfResponse {
  url: string;
  discount_status: DISCOUNT_STATUS;
  discount_prices?: {
    price_per_gb: number;
    total_price: string;
  };
}

export type RecentEmailsResponse = string[];

export interface AddPayload {
  type: 'collection' | 'library_item' | 'order';
  entity_uuid: string;
}

export interface AddAssetPayload {
  type: 'asset';
  entity_uuid: string;
  extra?: {
    offset?: string;
    timecode_in?: string;
    timecode_out?: string;
    note?: string;
    voiceover_required?: string;
  };
}

export type AddToCartPayload = (AddPayload | AddAssetPayload)[];

export interface PostCartValidateType {
  order_type: ORDER_TYPE;
  order_data: ValidateDataType<ORDER_TYPE>;
}

interface ValidateDataTypes {
  [ORDER_TYPE.EMAIL_DELIVERY]: object;
  [ORDER_TYPE.DELIVERY_DESTINATION]: {
    delivery_config_uuids: string[];
  };
  [ORDER_TYPE.WARM_UP]: object;
  [ORDER_TYPE.WORKFLOW]: {
    jobs: JobTypePayloadInterface[];
  };
}

export type ValidateDataType<T extends ORDER_TYPE> = ValidateDataTypes[T];

export interface CartValidationError<T extends ORDER_TYPE> {
  orderType: T;
  assetUuid?: string;
  cartItemUuids?: string[];
  assetOriginalFilename?: string;
  extra?: CartValidationErrorExtraType<T>;
  note: string;
  type: 'ERR' | 'WARN';
  subType?: 'missing-rates' | 'will-overwrite';
}

export type CartValidationErrorExtraType<T extends ORDER_TYPE> = CartValidationErrorExtraTypes[T];

interface CartValidationErrorExtraTypes {
  [ORDER_TYPE.EMAIL_DELIVERY]: {
    invalidTypes?: InvalidCartItemType[];
  };
  [ORDER_TYPE.DELIVERY_DESTINATION]: {
    configUuid?: string;
  };
  [ORDER_TYPE.WARM_UP]: object;
  [ORDER_TYPE.WORKFLOW]: {
    modelUuid: string;
    dependsOn: modelContext;
  };
}

export enum modelContext {
  LABEL_DETECTION = 'label-detection',
  FACE_DETECTION = 'face-detection',
  CELEBRITY_RECOGNITION = 'celebrity-recognition',
  VIDEO_SEGMENT_DETECTION = 'video-segment-detection',
  VIDEO_BLACK_DETECTION = 'video-black-detection',
  VIDEO_BORDERS_DETECTION = 'video-borders-detection',
  VIDEO_BORDERS_DETECTION_FULL = 'video-borders-detection-full',
  VIDEO_BORDERS_DETECTION_QUICK = 'video-borders-detection-quick',
  VIDEO_INTERLACING_ANALYSE = 'video-interlacing-analyse',
  VIDEO_INTERLACING_ANALYSE_FULL = 'video-interlacing-analyse-full',
  VIDEO_INTERLACING_ANALYSE_QUICK = 'video-interlacing-analyse-quick',
  TEXT_DETECTION = 'text-detection',
  CONTENT_MODERATION = 'content-moderation',
  TRANSLATE_SUBTITLES = 'translate-subtitles',
  TRANSLATE_VIDEO = 'translate-video',
  TRANSCRIBE = 'transcribe',
  VIDEO_SUMMARY = 'video-summary',
  LANGUAGE_DETECTION = 'language-detection',
  VIDEO_STATISTICS = 'video-statistics',
  AUDIO_STATISTICS = 'audio-statistics',
  AUDIO_SILENCE_DETECTION = 'audio-silence-detection',
  LOGGING = 'logging',
  AD_BREAKS = 'generate-ad-breaks',
  DETECT_SEAMLESS = 'detect-seamless',
}

export type InvalidCartItemType = 'embargoed';

export type ConventionDataFilenamesForCartPayload = {
  [index in AssetType]: string;
};
export interface FilenamesForCartPayload {
  order_package_title: string;
  order_no: string;
  overridden_conventions_data?: ConventionDataFilenamesForCartPayload;
}

export interface FilenameForCart {
  cart_item_uuid: string;
  original_filename: string;
  result: string | null;
  type: AssetType;
}

export interface FilenameForCartViewModel extends FilenameForCart {
  cart_item_uuid: string;
  original_filename: string;
  result: string | null;
  type: AssetType;
  override_filename_convention: {
    cart_item_uuid: string;
    value: string;
  };
}

export type FilenamesForCart = {
  cart_item_uuid: string;
  original_filename: string;
  result: string;
  type: AssetType;
}[];
