<vdms-hq-ui-dialog-wrapper size="wide">
  <ng-container title>
    <span class="title">{{ 'common.qc_analyse_results.label' | translate }}</span>
  </ng-container>
  <ng-container content>
    <vdms-hq-ui-multiple-data-presentation [dataSource]="qualityControlDatasource" [configuration]="configuration">
    </vdms-hq-ui-multiple-data-presentation>
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>

    <vdms-hq-ui-button (click)="refresh()" color="primary">{{ 'common.global.refresh' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
