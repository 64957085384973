import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  StatsType,
  UIButtonModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
  UIStatsModule,
} from '@vdms-hq/ui';
import { EgressStatsDataSource } from '../logic/egress-stats.datasource';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@vdms-hq/shared';
import { EgressListDataSource } from '../logic/egress-list.datasource';
import { EgressListItemViewModel } from '@vdms-hq/api-contract';
import { EgressStatsForm } from '../logic/egress-stats.form';
import { MatDialog } from '@angular/material/dialog';
import { ExportTransactionsDialogComponent } from '../export-transactions-dialog/export-transactions-dialog.component';

@Component({
  selector: 'vdms-hq-egress-stats',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UILoaderModule,
    MatIconModule,
    UIStatsModule,
    SharedModule,
    MultipleDataPresentationComponent,
    UIFormModule,
    UIEmptyResultsModule,
    UIButtonModule,
  ],
  templateUrl: './egress-stats.component.html',
  styleUrls: ['./egress-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EgressStatsComponent {
  public statsDataSource = inject(EgressStatsDataSource);
  public listDataSource = inject(EgressListDataSource);
  public egressStatsForm = inject(EgressStatsForm);
  public dialog = inject(MatDialog);

  protected readonly today = new Date();
  protected readonly StatsType = StatsType;

  configuration: MultipleViewConfiguration<EgressListItemViewModel> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
    },
    tableAdvanced: {
      columns: [
        { id: 'method', label: 'Method', valuePath: 'method', sortable: true },
        { id: 'application', label: 'Application', valuePath: 'application', sortable: true },
        { id: 'transferStart', label: 'Transfer Start', valuePath: 'transferStart', sortable: true },
        { id: 'transferEnd', label: 'Transfer End', valuePath: 'transferEnd', sortable: true },
        { id: 'orderTitle', label: 'Order Title/Contract Name', valuePath: 'orderTitle', sortable: true },
        { id: 'purchaseOrderNumber', label: 'Purchase Order Number', valuePath: 'purchaseOrderNumber', sortable: true },
        { id: 'userEmail', label: 'User Email', valuePath: 'userEmail', sortable: true },
        { id: 'destination', label: 'Destination', valuePath: 'destination', sortable: true },
        {
          id: 'status',
          label: 'Status',
          valuePath: 'status',
          viewFormat: {
            pills: {
              success: 'done',
              running: 'cold-blue',
            },
          },
          sortable: true,
        },
      ],
      columnsEnabled: [
        'application',
        'method',
        'transferStart',
        'transferEnd',
        'orderTitle',
        'purchaseOrderNumber',
        'userEmail',
        'destination',
        'country',
        'status',
      ],
    },
  };

  exportTransactions() {
    this.dialog.open(ExportTransactionsDialogComponent);
  }
}
