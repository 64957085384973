import { AfterViewInit, Directive, ElementRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Swiper } from 'swiper/types';

@Directive({
  selector: '[vdmsHqSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  private el = inject(ElementRef<SwiperContainer>);
  @Input() config?: SwiperOptions;
  @Output() sliderChange = new EventEmitter<number>();
  #isListening = false;
  #realIndex?: number;

  ngAfterViewInit(): void {
    Object.assign(this.el.nativeElement, this.config);
    this.el.nativeElement.initialize();

    if (this.#isListening) {
      return;
    }

    this.#isListening = true;

    this.#listenOnSliderChange();
  }

  #listenOnSliderChange() {
    this.el.nativeElement.addEventListener('swiperslidechange', (event: CustomEvent<Swiper[]>) => {
      const detail = event.detail[0];

      if (detail?.realIndex === this.#realIndex) {
        return;
      }

      this.#realIndex = detail?.realIndex;
      this.sliderChange.emit(this.#realIndex);
    });
  }
}
