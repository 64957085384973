<mat-menu
  [overlapTrigger]="false"
  [xPosition]="xPosition"
  [yPosition]="yPosition"
  backdropClass="tour-item__backdrop"
  [class]="_getClass()"
>
  <div
    class="container tour-item__container"
    (click)="$event.stopPropagation()"
    (keydown)="$event.stopPropagation()"
    tabindex="-1"
  >
    {{ tourCfg?.text }}
    <ng-content></ng-content>

    <ng-container *ngIf="templateRef && trigger">
      <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: data }"></ng-container>
    </ng-container>

    <div class="buttons-group">
      <vdms-hq-ui-button (click)="exitTour()" color="secondary" *ngIf="showExit">Exit</vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="close()" color="primary" *ngIf="showButton">{{ buttonText }}</vdms-hq-ui-button>
    </div>
  </div>
</mat-menu>
