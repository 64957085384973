<div class="padding-medium">
  <div class="grid grid-upto3">
    <div class="sample-view">
      <table [cellSpacing]="10">
        <tr>
          <td>Name</td>
          <td>Basic</td>
          <td>Disabled</td>
          <td>Loading</td>
          <td>Icon</td>
          <td>With icon</td>
        </tr>

        <tr>
          <td>primary</td>
          <td>
            <vdms-hq-ui-button color="primary">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" [disabled]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" [loading]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" [iconOnly]="true" icon="whatshot"></vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" icon="whatshot">Just test</vdms-hq-ui-button>
          </td>
        </tr>

        <tr>
          <td>secondary</td>
          <td>
            <vdms-hq-ui-button color="secondary">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="secondary" [disabled]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="secondary" [loading]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="secondary" [iconOnly]="true" icon="whatshot"></vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="secondary" icon="whatshot">Just test</vdms-hq-ui-button>
          </td>
        </tr>

        <tr>
          <td>transparent</td>
          <td>
            <vdms-hq-ui-button color="transparent">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="transparent" [disabled]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="transparent" [loading]="true">lorem</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="transparent" [iconOnly]="true" icon="whatshot"></vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="transparent" icon="whatshot">Just test</vdms-hq-ui-button>
          </td>
        </tr>
        <tr>
          <td>warning</td>
          <td>
            <vdms-hq-ui-button color="warn">warn</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="warn" [disabled]="true">warn</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="warn" [loading]="true">warn</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="warn" [iconOnly]="true" icon="whatshot"></vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="warn" icon="whatshot">Just test</vdms-hq-ui-button>
          </td>
        </tr>
      </table>

      Sizes: <br />
      <table>
        <tr>
          <td>warning</td>
          <td>
            <vdms-hq-ui-button color="primary" size="small">Small</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" size="medium">Medium</vdms-hq-ui-button>
          </td>
          <td>
            <vdms-hq-ui-button color="primary" size="fieldset">Fieldset</vdms-hq-ui-button>
          </td>
          <td style="width: 300px">
            <vdms-hq-ui-button color="primary" size="full">Full</vdms-hq-ui-button>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div class="sample-view">
        <vdms-hq-ui-loader></vdms-hq-ui-loader>
      </div>
      <div class="sample-view">
        <vdms-hq-ui-load-more [batchSize]="24" [currentLoaded]="10" [total]="100"></vdms-hq-ui-load-more>
      </div>
      <div class="sample-view">
        <vdms-hq-ui-empty-results></vdms-hq-ui-empty-results>
      </div>
    </div>
    <div>
      <div class="sample-view">
        <vdms-hq-ui-progress-status [value]="20" label="Uploading"></vdms-hq-ui-progress-status>
        <div class="pt-medium">
          <vdms-hq-ui-text-status [dot]="true" color="done">Done</vdms-hq-ui-text-status>
          <vdms-hq-ui-text-status [dot]="true" color="pending">Alert</vdms-hq-ui-text-status>
          <vdms-hq-ui-text-status [dot]="true" color="alert">Pending</vdms-hq-ui-text-status>
        </div>
        <div class="pt-medium">
          <vdms-hq-ui-button color="primary" (click)="openDialog()">Open confirmation dialog</vdms-hq-ui-button>
        </div>
        <div class="pt-medium">
          <vdms-hq-ui-button *ngIf="theme$ | async as theme" color="primary" (click)="toggleTheme()"
            >{{
              (theme === 'theme-dark' ? 'common.nav.switch_theme_to_white' : 'common.nav.switch_theme_to_dark')
                | translate
            }}
          </vdms-hq-ui-button>
        </div>
        <div class="pt-medium">
          <span [matTooltip]="'Lorem ipsum dolor sit amet'">Tooltip</span>
          <span [matTooltipHideDelay]="4000000" [matTooltip]="'Multi line \n Lorem \n\n\n ipsum dolor sit amet'"
            >Tooltip 4000000 hide delay</span
          >
        </div>
      </div>
      <div class="sample-view">
        <vdms-hq-native-upload-component></vdms-hq-native-upload-component>
      </div>
      <div class="sample-view">
        <vdms-hq-form-input-buttons-toggle [leftOptionLabel]="'Left'" [rightOptionLabel]="'Right'">
        </vdms-hq-form-input-buttons-toggle>
      </div>
    </div>
  </div>
</div>

<div class="sample-view">
  <vdms-hq-ui-tiles>
    <vdms-hq-ui-tile
      header="Lorem ipsum"
      subheader="Subheader"
      [background]="sampleImage"
      [backgroundHover]="sampleImageHover"
    ></vdms-hq-ui-tile>
    <vdms-hq-ui-tile
      header="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      [background]="sampleImage"
      [backgroundHover]="sampleImageHover"
    ></vdms-hq-ui-tile>
    <vdms-hq-ui-tile
      header="Lorem ipsum"
      [isLoading]="true"
      [background]="sampleImage"
      [backgroundHover]="sampleImageHover"
    ></vdms-hq-ui-tile>
    <vdms-hq-ui-tile
      header="Lorem dolor sit amet"
      icon="description"
      [background]="sampleImage"
      [backgroundHover]="sampleImageHover"
      [metadata]="[
        { value: 'metadata 1', type: 'text' },
        { value: '2034/01/01 22:22', type: 'date' },
        { value: 'example2', type: 'text' },
      ]"
    ></vdms-hq-ui-tile>
  </vdms-hq-ui-tiles>
</div>

<div class="sample-view grid-upto3 grid-gap-y grid grid-gap-x">
  <vdms-hq-ui-stats-wrapper
    *ngFor="let config of stats"
    [statsType]="config.type"
    [statsConfig]="config"
  ></vdms-hq-ui-stats-wrapper>
</div>
