import { Pipe, PipeTransform } from '@angular/core';
import { GroupCurrencyISO } from '../logic/misc/select-options';

@Pipe({
  name: 'clientCurrency',
})
export class ClientCurrencyPipe implements PipeTransform {
  transform = ClientCurrencyPipe.transform;
  static transform(value: number, currency: GroupCurrencyISO): string {
    const locales = getLocalesByCurrency(currency);
    const price = value / 100;
    return new Intl.NumberFormat(locales, { style: 'currency', currency: currency }).format(price);
  }
}

const getLocalesByCurrency = (currency: GroupCurrencyISO) => {
  switch (currency) {
    case GroupCurrencyISO.EUR:
      return 'de-DE';
    case GroupCurrencyISO.USD:
      return 'us-US';
    case GroupCurrencyISO.PLN:
      return 'pl-PL';
    default:
      return 'gb-GB';
  }
};
