<figure>
  <div class="grid-scale">
    @for (t of arr(chartData.gridScaleLineCount); track t) {
      <div class="grid-scale-line"></div>
    }
  </div>

  <div class="graphic">
    <div class="row">
      <div class="chart">
        <span class="block" [ngStyle]="{ width: chartData.percentValue + '%' }"></span>
      </div>
    </div>
  </div>
  @if (chartData.legendLabel) {
    <figcaption>
      <div class="label">
        @if (chartData.legendCounter) {
          <span class="label-counter">{{ chartData.legendCounter }}</span>
        }
        {{ chartData.legendLabel }}
      </div>
    </figcaption>
  }
</figure>
