import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsperaSelectorComponent, AsperaTransferBatch } from '@vdms-hq/storage';
import { AssetUploadService } from '../../logic/asset-upload.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { filter, switchMap } from 'rxjs/operators';
import { EMPTY, take } from 'rxjs';
import { AssetApiService } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-asset-placeholder-upload-dialog',
  standalone: true,
  imports: [
    CommonModule,
    AsperaSelectorComponent,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILayoutModule,
    UISimpleDetailsListModule,
  ],
  templateUrl: './asset-placeholder-upload-dialog.component.html',
})
export class AssetPlaceholderUploadDialogComponent {
  assetUploadService = inject(AssetUploadService);
  dialogRef = inject(MatDialogRef<AssetPlaceholderUploadDialogComponent>);
  assetApi: AssetApiService = inject(AssetApiService);
  data: { assetId: string } = inject(MAT_DIALOG_DATA);

  close() {
    this.dialogRef.close();
  }

  startUpload(batch: AsperaTransferBatch) {
    batch.state$
      .pipe(
        filter((state) => ['done', 'failed', 'cancelled'].includes(state.status)),
        take(1),
        switchMap((state) => {
          if (state.status === 'done') {
            this.close();
            return this.assetApi.sendAsperaCompleteStatus(batch.batchId, true);
          }
          return EMPTY;
        }),
      )
      .subscribe();

    this.assetUploadService.startPlaceholderUpload(batch, this.data.assetId);
  }
}
