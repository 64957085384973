import { inject, Injectable } from '@angular/core';
import { filterEmpty, SelectOption } from '@vdms-hq/shared';
import {
  BehaviorSubject,
  debounceTime,
  combineLatest,
  shareReplay,
  tap,
  map,
  switchMap,
  Observable,
  EMPTY,
  startWith,
  of,
} from 'rxjs';
import {
  LICENSED_PACKAGE_STATUS,
  LicensePackagesService,
  PaginationAPIModel as Pagination,
  LicensedPackageListItem,
} from '@vdms-hq/api-contract';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup } from '@angular/forms';
import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { HttpErrorResponse } from '@angular/common/http';
import { LicensePackagesRefreshService } from './license-packages-refresher.service';

export interface LicensePackagesAddData {
  key: string;
  label: string;
  number_of_assets: number;
  status: LICENSED_PACKAGE_STATUS;
}

export type LicensedPackageFiltersView = {
  keyword: string;
  sort: string;
  direction?: string;
  partners: string[];
  contracts: string[];
};

export type LicensedPackageFormFiltersView = {
  keyword: FormControl<string>;
  sort: FormControl<string>;
  partners: FormControl<string[]>;
  contracts: FormControl<string[]>;
};

@Injectable({ providedIn: 'root' })
export class LicensePackagesAddDsService
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<LicensedPackageFormFiltersView>, LicensedPackageListItem>
{
  private toastService = inject(ToastService);
  private licensePackagesService = inject(LicensePackagesService);
  private activatedClientService = inject(ActivatedClientService);
  private licensePackageRefresher = inject(LicensePackagesRefreshService);

  filters = new FormGroup({
    keyword: new FormControl<string>('', { nonNullable: true }),
    sort: new FormControl<string>('licensedPackage.createdAt_desc', { nonNullable: true }),
    partners: new FormControl<string[]>([], { nonNullable: true }),
    contracts: new FormControl<string[]>([], { nonNullable: true }),
  });

  refresh$ = this.licensePackageRefresher.refreshLicensePackagesList$;
  isLoading$ = new BehaviorSubject<boolean>(true);

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.filters.valueChanges.pipe(
      startWith(this.filters.value),
      debounceTime(300),
      switchMap(() => {
        const formValue = this.filters.value;
        const filters = <LicensedPackageFiltersView>{};

        if (formValue?.keyword) {
          filters.keyword = formValue.keyword;
        }

        if (formValue?.sort) {
          filters.sort = formValue.sort.split('_')[0];
          filters.direction = formValue.sort.split('_')[1];
        }

        if (formValue?.partners) {
          filters.partners = formValue.partners;
        }

        if (formValue?.contracts) {
          filters.contracts = formValue.contracts;
        }

        this.changePageIndex$.next(0);
        return of(filters);
      }),
    ),
    this.activatedClientService.clientId$,
  ]).pipe(
    switchMap(([_, page, perPage, filters, client]) => {
      if (!client) {
        return EMPTY;
      }
      this.isLoading$.next(true);
      const headers = Pagination.create({
        page,
        perPage,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });

      return this.licensePackagesService.getMany(headers, filters);
    }),
    shareReplay(1),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<LicensePackagesAddData[]> = this.responseData$.pipe(
    catchError((error) => {
      this.#errorHandler(error);
      throw error;
    }),
    map((response) =>
      response.data.map((item) => ({
        key: item.uuid,
        label: item.name,
        status: item.status,
        number_of_assets: item.number_of_assets,
      })),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<LicensedPackageFormFiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh(): void {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.controls['keyword'].setValue('');
      this.filters.controls['partners'].setValue([]);
      this.filters.controls['contracts'].setValue([]);
      this.filters.controls['sort'].setValue('licensedPackage.createdAt_desc');
      this.changePageIndex$.next(0);
    }
  }

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    this.emptyResults$ = of(true);
    console.error(err);
    return EMPTY;
  }
}
