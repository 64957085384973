import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetApiService, Policy, PolicyService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { map } from 'rxjs';
import { ClientFormService } from '../../logic/services/client-form.service';
import { CredentialsFetcher } from '../../logic/services/credentials-fetcher';
import { ClientsFieldsOptionsService } from '../../logic/services/fields-options.service';
import { ClientDialogData } from '../models/client-dialog-data.model';

@Component({
  selector: 'vdms-hq-clients-edit-create-dialog',
  templateUrl: './clients-edit-create-dialog.component.html',
  styles: ['.section-header { display: flex; justify-content: flex-end}'],
})
export class ClientsEditCreateDialogComponent implements OnInit, OnDestroy {
  private apiService = inject(AssetApiService);
  private toast = inject(ToastService);
  private policyService = inject(PolicyService);
  private clientsFieldsOptionsService = inject(ClientsFieldsOptionsService);
  clientForm = inject(ClientFormService);
  data = inject<ClientDialogData>(MAT_DIALOG_DATA);
  clientsEditCreateDialogRef = inject(MatDialogRef<ClientsEditCreateDialogComponent>);
  credentialsFetcher = inject(CredentialsFetcher);

  dialogTitle = '';

  policiesOptions$ = this.policyService.getPolicies().pipe(
    map((policies) => {
      return policies
        .map((element) => {
          return {
            ...element,
            key: element.uuid,
            label: element.name,
          };
        })
        .filter((policy: Policy) => {
          return policy.groups?.some((group) => {
            return group.uuid == this.data.client?.uuid;
          });
        });
    }),
    map((policies) => [{ key: null, label: 'None' }, ...policies]),
  );

  proxyFilenameOptions$ = this.clientsFieldsOptionsService.proxyFilenameOptions$;

  ngOnInit() {
    this.dialogTitle = this.data && this.data.edit ? 'Edit client' : 'Create client';
    if (this.data.client) this.clientForm.setForm(this.data.client);
  }

  ngOnDestroy() {
    this.clientForm.reset();
  }

  public onSubmit(): void {
    if (!this.clientForm.isValid) {
      return;
    }

    const externalSupervisorEmails = this.clientForm.get('external_supervisor_emails')?.value;
    const purge_assets_safety_net_days =
      this.clientForm.get('purge_assets_safety_net_days')?.value !== 0
        ? this.clientForm.get('purge_assets_safety_net_days')?.value
        : null;

    this.clientsEditCreateDialogRef.close({
      ...this.clientForm.value,
      purge_assets_safety_net_days,
      external_supervisor_emails:
        externalSupervisorEmails && externalSupervisorEmails.length > 0 ? externalSupervisorEmails.join(';') : null,
    });
  }

  public cancel(): void {
    this.clientsEditCreateDialogRef.close();
  }

  syncQueue() {
    const clientId = this.data.client?.uuid;
    if (!clientId) {
      return;
    }
    this.apiService.generateMediaPulseJson(this.data.client?.uuid).subscribe({
      complete: () => {
        this.toast.success({ id: 'sqs', message: 'common.notifications.trigger.done' });
      },
    });
  }
}
