import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DeliveryDestination,
  DeliveryDestinationConfig,
  DeliveryDestinationJobStatusEnum,
  GetOrderItemDeliveryDestinationJob,
  DownloadTypeEnum,
} from '@vdms-hq/api-contract';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { ToastService } from '@vdms-hq/toast';
import { DataColumn, UIButtonModule, UIConfirmationDialogService, UIDialogWrapperModule } from '@vdms-hq/ui';
import { BehaviorSubject, switchMap, take } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OrderAssetViewModel2 } from '../../logic/models';
import { MatExpansionModule } from '@angular/material/expansion';
import { OrderActionsService } from '../../logic/order-actions.service';
import { RefreshDeliveryDestinationPipe } from './refresh-validator.pipe';

@Component({
  selector: 'vdms-hq-transcode-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    MatExpansionModule,
    ActivatedClientModule,
    RefreshDeliveryDestinationPipe,
  ],
  templateUrl: './transcode-details-dialog.component.html',
  styleUrls: ['./transcode-details-dialog.component.scss'],
})
export class TranscodeDetailsDialogComponent extends WithPermissions() {
  loading$ = new BehaviorSubject(false);
  downloadInProgress$ = this.orderActionsService.downloadInProgress$;

  constructor(
    private orderActionsService: OrderActionsService,
    private dialogRef: MatDialogRef<TranscodeDetailsDialogComponent>,
    private toast: ToastService,
    private confirmationDialog: UIConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderActive: boolean;
      items: OrderAssetViewModel2[];
      orderId: string;
      delivery_destinations: DeliveryDestination[];
      definition: DataColumn;
      assetUuid: string;
      isEmbargoActive: boolean;
      hideDownload: boolean;
    },
  ) {
    super();
    this.data.delivery_destinations.forEach((destination) => {
      destination.summary = this.showSummary(destination.configs);
    });
  }

  close() {
    this.dialogRef.close();
  }

  download(configUuid: string) {
    this.orderActionsService.download(this.data.orderId, this.data.items, false, configUuid);
  }

  downloadHTTPS(configUuid: string) {
    this.orderActionsService.download2(this.data.orderId, this.data.items, {
      keep_folders_structure: false,
      config_uuid: configUuid,
      download_type: DownloadTypeEnum.HTTPS,
    });
  }

  getTranscodeJobsStatus(jobs: GetOrderItemDeliveryDestinationJob[]) {
    const status = 'completed';

    const transcodeJobs = jobs.filter((job) => job.type === 'transcode');
    if (transcodeJobs.length === 0) {
      return status;
    }

    return transcodeJobs[0].status;
  }

  showSummary(configs: DeliveryDestinationConfig[]) {
    const configsLength = configs.length;
    let downloadReady = 0;
    let publishJobs = 0;
    let publishCompleted = 0;
    configs.forEach((config) => {
      if (config.jobs.length === 0 || (config.jobs.length === 1 && config.jobs[0].type === 'publish')) {
        downloadReady++;
      }
      config.jobs.forEach((job) => {
        if (job.type === 'transcode' && job.status === 'completed') {
          downloadReady++;
        }
        if (job.type === 'publish') {
          publishJobs++;
          if (job.status === 'completed') {
            publishCompleted++;
          }
        }
      });
    });

    return `${
      downloadReady === configsLength ? `All download ready` : `${downloadReady}/${configsLength} download ready`
    }${
      publishJobs > 0
        ? `, ${publishJobs === publishCompleted ? 'all' : `${publishCompleted}/${publishJobs}`} published`
        : ''
    }`;
  }

  retryDeliveryDestination(config: DeliveryDestinationConfig) {
    const { jobs } = config;

    const cannotRetry =
      jobs.every(({ status }) => status === DeliveryDestinationJobStatusEnum.COMPLETED) ||
      jobs.some(({ status }) => status === DeliveryDestinationJobStatusEnum.NOT_INITIALIZED);

    if (!jobs?.length || cannotRetry) {
      return;
    }

    this.#retryDelivery(config);
  }

  #retryDelivery(config: DeliveryDestinationConfig) {
    const message = config.jobs.some((job) =>
      [DeliveryDestinationJobStatusEnum.SUBMITTED, DeliveryDestinationJobStatusEnum.STARTED].includes(job.status),
    )
      ? 'common.delivery_destinations.retry_dd.already_retried'
      : 'common.delivery_destinations.retry_dd.retry';

    this.confirmationDialog
      .open({
        message,
      })
      .pipe(
        take(1),
        filter(Boolean),
        filter(() => !!this.data?.orderId),
        tap(() => this.loading$.next(true)),
        switchMap(() => {
          const { assetUuid, orderId, uuid } = { ...this.data, ...config };
          const payload = [{ item_uuid: assetUuid, config_uuid: uuid }];
          return this.orderActionsService.retryDeliveryDestinations(orderId as string, { jobs: payload });
        }),
        tap(() => this.loading$.next(false)),
      )
      .subscribe({
        next: () =>
          this.toast.success({ id: 'dd_retry', message: 'common.delivery_destinations.retry_dd.retry_success' }),
      });
  }
}
