import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DolbySessionData } from '../dolbyrasp/dolby-v2.model';

@Injectable({
  providedIn: 'root',
})
export class PlayerDownloadSessionService {
  constructor(private http: HttpClient) {}

  fetchBootstrapPayload(sessionUrl: string): Observable<DolbySessionData> {
    return this.http.get<DolbySessionData>(sessionUrl);
  }
}
