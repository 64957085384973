import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { CssChartBarViewModel } from '../logic/models/css-chart-bar-view.model';

@Component({
  selector: 'vdms-hq-ui-stats-css-chart-bar',
  templateUrl: './stats-css-bar-chart.component.html',
  styleUrls: ['./stats-css-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle],
})
export class StatsCssBarChartComponent {
  arr = Array;
  @Input({ required: true })
  chartData!: CssChartBarViewModel;
}
