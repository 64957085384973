<vdms-hq-ui-dialog-wrapper class="mfa-code-dialog">
  <h1 title>{{ 'common.mfa.header' | translate }}</h1>
  <div content>
    <ng-container *ngIf="step$ | async as step">
      <ng-container [ngSwitch]="step">
        <ng-container *ngSwitchCase="AuthenticationSteps.SELECTION" [ngTemplateOutlet]="selection"></ng-container>
        <ng-container
          *ngSwitchCase="AuthenticationSteps.AUTHENTICATOR"
          [ngTemplateOutlet]="authenticator"
        ></ng-container>
        <ng-container *ngSwitchCase="AuthenticationSteps.SMS" [ngTemplateOutlet]="sms"></ng-container>
      </ng-container>
    </ng-container>

    <p
      class="authentication--selection__button"
      *ngIf="step$.value !== AuthenticationSteps.SELECTION && hints.length > 1"
    >
      Try a different
      <span (click)="step$.next(AuthenticationSteps.SELECTION)">authentication method</span>
    </p>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <ng-container *ngIf="step$ | async as step">
      <ng-container [ngSwitch]="step">
        <ng-container *ngSwitchCase="AuthenticationSteps.SELECTION" [ngTemplateOutlet]="selectionFooter"></ng-container>
        <ng-container
          *ngSwitchCase="AuthenticationSteps.AUTHENTICATOR"
          [ngTemplateOutlet]="authenticatorFooter"
        ></ng-container>
        <ng-container *ngSwitchCase="AuthenticationSteps.SMS" [ngTemplateOutlet]="smsFooter"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #sms>
  <h3 class="subtitle">
    SMS Verification code sent to {{ selectedFactor.value?.phoneNumber }}. Haven’t received code?
    <span (click)="sendCode()">Resend it now.</span>
  </h3>
  <vdms-hq-ui-form-input-grid [textLength]="6" [inputType]="'number'" [formControl]="code"></vdms-hq-ui-form-input-grid>
</ng-template>
<ng-template #authenticator>
  <h3 class="subtitle">{{ selectedFactor.value?.displayName }} (Find your code in the Authenticator App)</h3>
  <vdms-hq-ui-form-input-grid
    [textLength]="6"
    [inputType]="'number'"
    [formControl]="otpFromAuthenticator"
  ></vdms-hq-ui-form-input-grid>
</ng-template>
<ng-template #selection>
  <vdms-hq-ui-form-radio-list [formControl]="selectedFactor" [selectOptions]="selectOptions">
  </vdms-hq-ui-form-radio-list>
</ng-template>

<ng-template #authenticatorFooter>
  <vdms-hq-ui-button
    color="primary"
    [disabled]="otpFromAuthenticator.invalid"
    (click)="confirm()"
    [loading]="processing"
  >
    {{ 'common.mfa.confirm' | translate }}
  </vdms-hq-ui-button>
</ng-template>
<ng-template #smsFooter>
  <vdms-hq-ui-button color="primary" [disabled]="code.invalid" (click)="confirm()" [loading]="processing">
    {{ 'common.mfa.confirm' | translate }}
  </vdms-hq-ui-button>
</ng-template>
<ng-template #selectionFooter>
  <vdms-hq-ui-button color="primary" [disabled]="selectedFactor.invalid" (click)="confirmMethod()">
    {{ (selectedFactor.value?.factorId === 'totp' ? 'common.mfa.confirm' : 'common.mfa.send_code') | translate }}
  </vdms-hq-ui-button>
</ng-template>
