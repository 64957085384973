import { AuthorizedClient, Permission } from '@vdms-hq/activated-client';
import { AnySidebarItem } from '@vdms-hq/ui';
import { DISCOUNTS_ROUTER_BASE } from '@vdms-hq/discounts';
import { filterEmpty } from '@vdms-hq/shared';
import { FIELDS_BASE_PATHS, resultsSettingsNav } from '@vdms-hq/fields-admin';

export function adminNavItemsConfig(client: AuthorizedClient): AnySidebarItem[] {
  const configs = Object.entries(client.assetView).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  const libraryConfigs = client.libraryConfigs ?? [
    {
      id: 'legacy-default',
      isClient: false,
      name: 'Client legacy default',
      structure: client.library.structure,
    },
  ];

  const resultsSettings = resultsSettingsNav(client);
  const navItems: (AnySidebarItem | undefined)[] = [
    {
      name: 'View configuration',
      routerLink: '/admin/general',
    },
    {
      name: 'Storage and Database',
      routerLink: '/admin/storage',
    },
    'separator',
    {
      name: 'common.admin.sidebar.tables_and_lists.name',
      routerLink: `/admin/${FIELDS_BASE_PATHS.PAGE_RESULTS_SETTINGS}/default`,
      children: resultsSettings,
    },
    {
      name: 'Details & Form',
      routerLink: '/admin/asset-details/',
      children: [
        ...configs.map(([key, config]) => ({
          name: config.name,
          routerLink: `/admin/asset-details/${key}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/asset-details/add',
        },
      ],
    },
    {
      name: 'Library browser',
      routerLink:
        libraryConfigs.length > 0 ? `/admin/browse-library/${libraryConfigs[0].id}` : '/admin/browse-library/add',
      children: [
        ...libraryConfigs.map((schema) => ({
          name: schema.name,
          routerLink: `/admin/browse-library/${schema.id}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/browse-library/add',
        },
      ],
    },
    'separator',
    {
      name: 'Field definitions',
      routerLink: '/admin/fields',
    },
    {
      name: 'Field sources',
      routerLink: '/admin/field-sources',
    },
    {
      name: 'Mandatory fields',
      routerLink: 'mandatory-fields',
    },
    {
      name: 'Client languages',
      routerLink: 'languages',
    },
    {
      name: 'Video Codecs',
      routerLink: 'video-codecs',
    },
    {
      name: 'Reindex Automatization',
      routerLink: 'reindex-automatization',
    },
    {
      name: 'Metadata snapshots',
      routerLink: 'metadata-snapshots',
    },
    client.permissions.includes(Permission.PERMISSION_BROWSE_QA_CONFIGS)
      ? {
          name: 'QA Configurations',
          routerLink: 'quality-control',
        }
      : undefined,
    client.permissions.includes(Permission.BROWSE_LANDINGPAD_ASSETS)
      ? {
          name: 'LandingPad',
          routerLink: 'landingpad',
        }
      : undefined,

    'separator',
    client.permissions.includes(Permission.BROWSE_DISCOUNTS)
      ? {
          name: 'Discounts',
          routerLink: DISCOUNTS_ROUTER_BASE.ROOT,
        }
      : undefined,

    client.permissions.includes(Permission.BROWSE_RATES)
      ? {
          name: 'Billing configuration',
          routerLink: 'billing',
          children: [
            {
              name: 'Billing reports settings',
              routerLink: 'billing/reports-settings',
            },
          ],
        }
      : undefined,
    'separator',
    {
      name: 'Connect2',
      routerLink: 'connect2-admin',
    },
    {
      name: 'Manage Notifications',
      routerLink: 'notification-subscriptions',
    },
    client.permissions.includes(Permission.MANAGE_QUARANTINE_CONFIGS)
      ? {
          name: 'Quarantine',
          routerLink: 'quarantine',
        }
      : undefined,

    'separator',
    {
      name: 'Aspera Keys',
      routerLink: 'aspera-keys',
    },
    client.permissions.includes(Permission.BROWSE_CREDENTIALS)
      ? {
          name: 'Credentials',
          routerLink: 'credentials',
        }
      : undefined,

    {
      name: 'Settings snapshots',
      routerLink: 'settings-snapshots',
    },
    'separator',

    client.permissions.includes(Permission.BROWSE_THRESHOLDS)
      ? {
          name: 'Global configuration',
          routerLink: 'global',
        }
      : undefined,
    {
      name: 'Email text',
      routerLink: 'email-text',
    },
  ];
  return navItems.filter(filterEmpty) as AnySidebarItem[];
}
