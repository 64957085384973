<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.dialogs.license_package.add_assets.title' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-selectable-tiles
      [dataSource]="dataSource"
      [filters]="dataSource.filters"
      [config]="licensedPackagesSelectableConfig"
      (updateSelectedListEmit)="licensedPackagesSelectableConfig.selectedList.data = $event"
      [selected]="selectedLicensePackagesControl.value ?? []"
      (selectedChangeEmit)="selectedLicensePackagesControl.setValue($event)"
      (updateConfigEmit)="licensedPackagesSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
    ></vdms-hq-ui-selectable-tiles>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" [disabled]="isSaving" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="save()"
      [loading]="isSaving"
      [disabled]="
        isSaving ||
        selectedLicensePackagesControl.invalid ||
        (selectedLicensePackagesControl.value.length === 0 && data.packages?.length === 0)
      "
      color="primary"
      >{{ 'common.dialogs.license_package.add_assets.button_confirm' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
