import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationFormService } from '../../logic/destination-form.service';
import { FormControl, FormsModule, Validators } from '@angular/forms';
import { InfoBarType, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { AssetMasterType, SelectorsModule, SelectorSourceType } from '@vdms-hq/selectors';
import {
  AssetType,
  CredentialInterface,
  CredentialsApiService,
  CredentialTypeEnum,
  DestinationAccessMethodEnum,
  VideoCodec,
  VideoCodecsApiService,
} from '@vdms-hq/api-contract';
import {
  bucketNameValidator,
  DestroyComponent,
  E2eIdDirective,
  filterEmpty,
  prefixValidator,
  SelectOption,
  SelectOptionKey,
} from '@vdms-hq/shared';
import { EMPTY, Observable, switchMap, tap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DestinationDataSourceService } from '../../logic/destination-data-source';
import {
  AwsRegionsOptions,
  CredentialsTypeOptions,
  ResolutionsOptions,
  TranscodeDeliveryDestinationTypesOptions,
} from '../../logic/delivery-destination-options';
import { DeliveryDestinationsValidators } from '../../logic/delivery-destinations-validators';
import { DeliveryDestinationFilenameConventionsService } from '../../logic/delivery-destination-filename-conventions.service';

@Component({
  selector: 'vdms-hq-destination-edit-config',
  templateUrl: './destination-edit-config.component.html',
  styleUrls: ['./destination-edit-config.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    UIFormModule,
    UILayoutModule,
    TranslateModule,
    MatExpansionModule,
    UIButtonModule,
    SelectorsModule,
    E2eIdDirective,
  ],
  standalone: true,
})
export class DestinationEditConfigComponent extends DestroyComponent implements OnInit, OnDestroy {
  private videoCodecMappingService: VideoCodecsApiService = inject(VideoCodecsApiService);
  private credentialsService: CredentialsApiService = inject(CredentialsApiService);
  formService: DestinationFormService = inject(DestinationFormService);
  dataSource: DestinationDataSourceService = inject(DestinationDataSourceService);
  deliveryDestinationFilenameConventionsService: DeliveryDestinationFilenameConventionsService = inject(
    DeliveryDestinationFilenameConventionsService,
  );

  loading = false;

  newContainerControl = new FormControl<SelectOptionKey | null>(null, [Validators.required]);

  protected readonly SelectorSourceType = SelectorSourceType;
  protected readonly transcodeDeliveryDestinationTypes = TranscodeDeliveryDestinationTypesOptions;
  protected readonly resolutionsDataSource: SelectOption[] = ResolutionsOptions;
  protected readonly CredentialTypeEnum = CredentialTypeEnum;
  protected readonly AwsRegionsOptions = AwsRegionsOptions;
  protected readonly InfoBarType = InfoBarType;

  credentialTypeOptions = CredentialsTypeOptions.filter(({ key }) =>
    [
      CredentialTypeEnum.AWS,
      CredentialTypeEnum.ASPERA_HSTS,
      CredentialTypeEnum.ASPERA_AOC,
      CredentialTypeEnum.ASPERA_SHARES,
      CredentialTypeEnum.ASPERA_YOUTUBE,
      CredentialTypeEnum.FTPS,
      CredentialTypeEnum.YOUTUBE,
      CredentialTypeEnum.INGEST_IN_VIDA,
    ].includes(key as unknown as CredentialTypeEnum),
  );

  credentialsAWS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAWS: SelectOption[] = [];

  credentialsFTP: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceFTP: SelectOption[] = [];

  credentialsAsperaHSTS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAsperaHSTS: SelectOption[] = [];

  credentialsAsperaAOC: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAsperaAOC: SelectOption[] = [];

  credentialsAsperaSHARES: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAsperaSHARES: SelectOption[] = [];

  credentialsAsperaYOUTUBE: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAsperaYOUTUBE: SelectOption[] = [];

  credentialsFTPS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceFTPS: SelectOption[] = [];

  credentialsYoutube: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceYoutube: SelectOption[] = [];

  videoCodecsDataSource$: Observable<SelectOption[]> = this.videoCodecMappingService.getAll().pipe(
    map((codecs: VideoCodec[]) =>
      [...new Set(codecs.map(({ display_name }) => display_name))].map((display_name) => ({
        key: display_name,
        label: display_name,
      })),
    ),
  );
  availableAssetTypes$: Observable<SelectOption[]> = this.formService.currentConfigValue$.pipe(
    filterEmpty(),
    map(
      (config: Partial<{ rules: Partial<{ assetType: string }>[] }>) =>
        <string[]>config?.rules?.map(({ assetType }) => assetType).filter((type) => !!type) ?? [],
    ),
    map((usedAssetTypes) =>
      AssetMasterType.filter(({ key }) => (usedAssetTypes ? !usedAssetTypes.includes(key) : true)),
    ),
  );

  isPublishTypeLoader = true;

  ngOnInit(): void {
    this.#listenFormChanges();
    this.#resetConfigOnChange();

    this.formService.currentConfigValue$.pipe(this.takeUntilDestroyed(), filterEmpty()).subscribe((config) => {
      config?.rules?.forEach((rule) => {
        if (rule.publish?.type === DestinationAccessMethodEnum.AWS) {
          this.getCredentialsByType(CredentialTypeEnum.AWS);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.ASPERA_HSTS) {
          this.getCredentialsByType(CredentialTypeEnum.ASPERA_HSTS);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.ASPERA_AOC) {
          this.getCredentialsByType(CredentialTypeEnum.ASPERA_AOC);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.ASPERA_SHARES) {
          this.getCredentialsByType(CredentialTypeEnum.ASPERA_SHARES);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.ASPERA_YOUTUBE) {
          this.getCredentialsByType(CredentialTypeEnum.ASPERA_YOUTUBE);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.FTPS) {
          this.getCredentialsByType(CredentialTypeEnum.FTPS);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.YOUTUBE) {
          this.getCredentialsByType(CredentialTypeEnum.YOUTUBE);
        }
        if (rule.publish?.type === DestinationAccessMethodEnum.INGEST_IN_VIDA) {
          this.isPublishTypeLoader = false;
        }
      });
    });
  }

  override ngOnDestroy() {
    this.newContainerControl.reset();
    super.ngOnDestroy();
  }

  addNewRule(): void {
    const assetType: AssetType = this.newContainerControl.value as unknown as AssetType;

    if (!assetType) {
      return;
    }

    this.formService.addNewRule(assetType);
  }

  #listenFormChanges() {
    this.formService.currentConfigFormGroup$
      .pipe(
        this.takeUntilDestroyed(),
        switchMap((form) => (form ? form.valueChanges.pipe(map(() => form.controls.rules)) : EMPTY)),
      )
      .subscribe((form) => {
        const formValue = form.value;
        for (const key in formValue) {
          const rule = form.controls[key];
          const transcodeType = rule.controls.transcode.controls.type;
          const transcodeEnabled = rule.controls.transcode.controls.enabled;
          if (transcodeEnabled.value && rule.controls.assetType.value === 'video') {
            transcodeType.setValidators(Validators.required);
          } else {
            transcodeType.removeValidators(Validators.required);
            transcodeType.patchValue(null, { emitEvent: false });
            rule?.controls.transcode.controls.overrideProfile.patchValue(null, { emitEvent: false });
            rule?.controls.transcode.controls.audioTracks.patchValue([], { emitEvent: false });
          }
          rule.controls.transcode.controls.overrideProfile.setValidators(
            DeliveryDestinationsValidators.hybrikProfileJsonValidator(rule),
          );

          const publishRule = rule.controls.publish;
          const publishType = publishRule.controls?.type;
          const publishEnabled = publishRule.controls?.enabled;
          const credentials = publishRule.controls?.credentialUuid;
          const region = publishRule.controls?.configData.controls?.region;
          const bucket = publishRule.controls?.configData.controls?.bucketName;
          const prefix = publishRule.controls?.configData.controls?.prefix;
          const channel = publishRule.controls?.configData.controls?.channel;
          const earPassphrase = publishRule.controls?.configData.controls?.earPassphrase;

          if (!publishEnabled?.value) {
            publishType.removeValidators(Validators.required);
            credentials.removeValidators(Validators.required);
            region.removeValidators(Validators.required);
            bucket.removeValidators(Validators.required);
            earPassphrase.removeValidators(Validators.required);
            channel.removeValidators(Validators.required);
            publishType.patchValue(null, { emitEvent: false });
            credentials.patchValue(null, { emitEvent: false });
            channel.patchValue(null, { emitEvent: false });
            region.patchValue(null, { emitEvent: false });
            bucket.patchValue(null, { emitEvent: false });
            prefix.patchValue('', { emitEvent: false });
            earPassphrase.patchValue(null, { emitEvent: false });
          } else {
            publishType.setValidators(Validators.required);
            switch (publishType.value) {
              case DestinationAccessMethodEnum.FTPS:
                credentials.setValidators(Validators.required);
                prefix.removeValidators(Validators.required);
                break;
              case DestinationAccessMethodEnum.AWS:
                credentials.setValidators(Validators.required);
                region.setValidators(Validators.required);
                bucket.setValidators([Validators.required, bucketNameValidator()]);
                prefix.setValidators(prefixValidator());
                break;
              case DestinationAccessMethodEnum.ASPERA_HSTS:
              case DestinationAccessMethodEnum.ASPERA_AOC:
                credentials.setValidators(Validators.required);
                earPassphrase.removeValidators(Validators.required);
                prefix.setValidators(prefixValidator());
                break;
              case DestinationAccessMethodEnum.ASPERA_YOUTUBE:
                credentials.setValidators(Validators.required);
                channel.setValidators(Validators.required);
                earPassphrase.removeValidators(Validators.required);
                prefix.setValidators(prefixValidator());
                break;
            }
          }
        }
      });
  }

  #resetConfigOnChange() {
    this.formService.currentConfigId$.pipe(this.takeUntilDestroyed()).subscribe(() => this.newContainerControl.reset());
  }

  getCredentialsByType(type: CredentialTypeEnum) {
    if (type === CredentialTypeEnum.AWS && this.credentialsAWS.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.FTP && this.credentialsFTP.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.ASPERA_HSTS && this.credentialsAsperaHSTS.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.ASPERA_YOUTUBE && this.credentialsAsperaYOUTUBE.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.ASPERA_AOC && this.credentialsAsperaAOC.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.ASPERA_SHARES && this.credentialsAsperaSHARES.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.FTPS && this.credentialsFTPS.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.YOUTUBE && this.credentialsYoutube.length > 0) {
      return;
    }
    if (type === CredentialTypeEnum.INGEST_IN_VIDA) {
      return;
    }

    this.credentialsService
      .getByType(type)
      .pipe(
        shareReplay(1),
        this.takeUntilDestroyed(),
        tap(() => (this.isPublishTypeLoader = true)),
      )
      .subscribe((credentials) => {
        switch (type) {
          case CredentialTypeEnum.AWS:
            this.credentialsAWS = credentials;
            this.credentialsSelectorSourceAWS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.FTP:
            this.credentialsFTP = credentials;
            this.credentialsSelectorSourceFTP = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.ASPERA_HSTS:
            this.credentialsAsperaHSTS = credentials;
            this.credentialsSelectorSourceAsperaHSTS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.ASPERA_YOUTUBE:
            this.credentialsAsperaYOUTUBE = credentials;
            this.credentialsSelectorSourceAsperaYOUTUBE = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.ASPERA_AOC:
            this.credentialsAsperaAOC = credentials;
            this.credentialsSelectorSourceAsperaAOC = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.ASPERA_SHARES:
            this.credentialsAsperaSHARES = credentials;
            this.credentialsSelectorSourceAsperaSHARES = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.FTPS:
            this.credentialsFTPS = credentials;
            this.credentialsSelectorSourceFTPS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isPublishTypeLoader = false;
            return;
          case CredentialTypeEnum.YOUTUBE:
            this.credentialsYoutube = credentials;
            this.credentialsSelectorSourceYoutube = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));

            this.isPublishTypeLoader = false;
            return;
        }
      });
  }
}
