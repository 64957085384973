import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LicensePackagesActionsService } from '../../../logic/license-packages-actions';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import {
  AssetSearchFilterParam,
  LICENSED_PACKAGE_STATUS,
  LicensedPackagePatchItems,
  LicensePackagesService,
  SimpleType,
} from '@vdms-hq/api-contract';
import { LicensePackagesAddData, LicensePackagesAddDsService } from '../../../logic/license-packages-add-ds.service';
import { combineLatest, concat, Observable, Subject, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LicensePackagesRefreshService } from '../../../logic/license-packages-refresher.service';
import { WithPermissions } from '@vdms-hq/activated-client';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { RightsContractsAddDsService } from '@vdms-hq/rights-contracts';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { MatDividerModule } from '@angular/material/divider';
import { getFilters } from '../../../logic/license-package-filters';
import { ActiveAssetService } from '@vdms-hq/asset-details';
import { SelectableWrapperConfig, UiSelectableTilesComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { ViewSettingsService } from '@vdms-hq/view-settings';
import { Destroyable } from '@vdms-hq/shared';

export interface LicensedPackagesAddDialogData {
  selectedIds?: string[];
  packages?: SimpleType[];
  filters?: AssetSearchFilterParam;
}

@Component({
  selector: 'vdms-hq-licensed-packages-add-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    MatDividerModule,
    UiAddDialogSelectableTilesComponent,
    UIEmptyResultsModule,
    UiSelectableTilesComponent,
  ],
  templateUrl: './licensed-packages-add-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
})
export class LicensedPackagesAddDialogComponent extends Destroyable(WithPermissions()) implements OnInit, OnDestroy {
  public dataSource = inject(LicensePackagesAddDsService);
  private licensePackagesService = inject(LicensePackagesService);
  private licensedPackagesActions = inject(LicensePackagesActionsService);
  private licensedPackagesRefreshService = inject(LicensePackagesRefreshService);
  private rightsContractsAddDsService = inject(RightsContractsAddDsService);
  private rightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private dialogRef = inject(MatDialogRef<LicensedPackagesAddDialogComponent>);
  private activeAsset = inject(ActiveAssetService);
  public data: LicensedPackagesAddDialogData = inject(MAT_DIALOG_DATA);
  private viewSettingsService = inject(ViewSettingsService);

  isSaving = false;

  form = new FormGroup({
    selected: new FormControl<string[]>([], { validators: Validators.required, nonNullable: true }),
  });

  filtersConfig: DynamicFilterInput[] = [];

  selectedLicensePackagesControl = this.form.get('selected') as FormControl<string[]>;

  private destroyed$: Subject<void> = new Subject<void>();

  licensedPackagesTilesConfig: TileSelectableConfig<LicensePackagesAddData> = {
    label: 'label',
    key: 'key',
    icon: 'folder_special',
    metadata: [
      {
        label: 'Status',
        valuePath: 'status',
        viewFormat: {
          pills: {
            active: 'done',
            draft: '',
          },
        },
      },
      {
        label: 'Assets',
        valuePath: 'number_of_assets',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
    ],
  };

  licensedPackagesSelectableConfig: SelectableWrapperConfig<LicensePackagesAddData> = {
    fieldsConfig: this.filtersConfig,
    tileConfig: this.licensedPackagesTilesConfig,
    selectedList: {
      title: 'Selected licensed packages',
      data: this.data.packages ?? [],
    },
    searchView: {
      placeholder: 'Type name',
      label: 'Search licensed packages',
      showSearch: true,
    },
    datasourceTitle: 'Available Licensed packages',
    emptyMessage: 'No licensed packages found',
    loadingText: 'Loading licensed packages',
    filters: {
      wrap: true,
    },
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    combineLatest([this.rightsContractsAddDsService.allData$, this.rightsPartnersAddDsService.allData$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([contracts, partners]) => {
        this.licensedPackagesSelectableConfig.fieldsConfig = getFilters(contracts, partners);
      });
    this.viewSettingsService.preferredSelectableView$.pipe(takeUntil(this.isDestroyed$)).subscribe((view) => {
      this.licensedPackagesSelectableConfig.searchView.showSearch = view === 'search';
    });
    this.selectedLicensePackagesControl.setValue(this.data.packages?.map((packages) => packages.uuid) ?? []);
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }

  save() {
    this.isSaving = true;
    const items: LicensedPackagePatchItems = {
      items: this.data.selectedIds?.map((uuid) => ({ asset_uuid: uuid })) ?? [],
    };
    const addItems: Observable<string[]>[] = [];
    this.selectedLicensePackagesControl.value.forEach((uuid) => {
      if (!this.data.filters) {
        addItems.push(
          this.licensePackagesService.addItems(uuid, items).pipe(
            catchError(() => {
              this.licensedPackagesActions.popToast.ADD_ITEMS_FAILURE(
                `Adding asset to licensed package with uuid ${uuid} failed`,
              );
              return [];
            }),
          ),
        );
      } else {
        addItems.push(
          this.licensePackagesService.addFilters(uuid, this.data.filters).pipe(
            catchError(() => {
              this.licensedPackagesActions.popToast.ADD_ITEMS_FAILURE(
                `Adding asset to licensed package with uuid ${uuid} failed`,
              );
              return [];
            }),
          ),
        );
      }
    });

    concat(...addItems).subscribe({
      next: () => {
        this.licensedPackagesRefreshService.refreshSingleLicensePackage$.next(true);
        this.licensedPackagesRefreshService.refreshLicensePackagesList$.next(true);
        if (this.data.selectedIds?.length === 1) {
          this.licensedPackagesActions.popToast.ADD_SINGLE_ITEM_SUCCESS();
          this.activeAsset.setActiveAsset(this.data.selectedIds[0]);
        } else {
          this.licensedPackagesActions.popToast.ADD_ITEMS_SUCCESS();
        }
        this.close();
        this.isSaving = false;
      },
      error: () => {
        this.isSaving = false;
      },
    });
  }

  close() {
    this.dataSource.refresh();
    this.dialogRef.close();
  }
}
