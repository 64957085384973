import { BehaviorSubject, Observable } from 'rxjs';

export enum SelectionState {
  NONE = 'none',
  SOME = 'some',
  ALL_ON_PAGE = 'all-on-page',
}

export enum SelectionVisibility {
  DISABLED = 'disabled',
  HIDDEN = 'hidden',
  ENABLED = 'enabled',
}

export type SelectionIdentifier = string | number;

export type SelectIdFunction<T> = (item: T, index?: number, array?: T[]) => SelectionIdentifier;

export interface SelectableDataSource<T> {
  selection: Selection<T>;
}

export interface Selection<T> {
  state$: Observable<SelectionState>;
  visibility$: Observable<SelectionVisibility>;
  identifiersSnapshot: SelectionIdentifier[];
  entities$: Observable<T[]>;
  total$: Observable<number>;
  selected$: Observable<boolean>;
  identifiers$: BehaviorSubject<SelectionIdentifier[]>;
  clear: () => void;
  toggleAllVisible: (checked: boolean) => void;
  toggleAll: (checked: boolean) => void;

  isSelected$(value: T): Observable<boolean>;
  /**
   * @deprecated, use change One instead
   * @param value
   */
  updateSelected(value: SelectionIdentifier[]): void;
  changeVisibility(value: SelectionVisibility): void;

  changeOne(item: T, value: boolean, continuePreviousSelection: boolean): void;

  changeMany(ids: SelectionIdentifier[], value: boolean): void;
}

export const isSelectableDataSource = (obj: any): obj is SelectableDataSource<unknown> => {
  return 'selection' in obj;
};
