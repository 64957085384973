import { AssetFlat, AssetSearchFilters, Thumbnail } from '../../asset';

import { AwsV4Signature } from '../../upload/upload';
import { SimpleType } from '../../types.model';

export enum CollectionAccessType {
  DASHBOARD = 'dashboard',
  SHARED = 'shared',
  OWNED = 'owned',
  TEAM = 'team',
  ALL = 'all',
}

export interface CollectionModelFlat {
  created_at: string;
  custom_cover: boolean;
  custom_cover_path: string | null;
  description: string;
  name: string;
  number_of_assets: number;
  number_of_embargoed: number;
  owner?: string;
  owner_name?: string;
  shared_to_teams?: SimpleType[];
  thumbnails: Thumbnail[];
  updated_at: string;
  uuid: string;
  access_type: CollectionAccessType;
  users?: CollectionsUserGet[];
  parents: SimpleType[];
  /** @deprecated it does not exist in the flat model */
  items?: CollectionItemGet[];
  /** @deprecated it does not exist in the flat model */
  groups: CollectionsGroupGet[];
  /** @deprecated it does not exist in the flat model */
  order?: number;
}

export interface CollectionAccessTypes {
  access_type: CollectionModelFlat['access_type'];
  uuid: CollectionModelFlat['uuid'];
}

export interface CollectionsFilters {
  filter?: string;
}

export interface CollectionModelGet {
  access_type?: CollectionAccessType;
  children: Omit<CollectionModelGet, 'shared_to_teams' | 'users'>[];
  created_at: string;
  custom_cover: boolean;
  custom_cover_path: string;
  description: string;
  name: string;
  number_of_assets: number;
  number_of_embargoed: number;
  owner?: string;
  owner_name?: string;
  parents: SimpleType[];
  shared_to_teams?: SimpleType[];
  thumbnails: Thumbnail[];
  updated_at: string;
  uuid: string;
  users?: CollectionsUserGet[];
  order?: number;
}

export interface CollectionModelPost {
  uuid: string;
  name: string;
  description: string;
}

export interface CollectionModelPatch {
  name?: string;
  description?: string;
  custom_cover?: boolean;
  filename?: string;
  shared_to_team_uuids?: string[];
  add_items?: {
    item_uuid?: string;
    asset_uuid?: string;
    logging_type?: string;
    timecode_in?: string;
    timecode_out?: string;
    note?: string;
    type?: string;
  }[];
  delete_items?: string[];
  owner?: string;
  users?: string[];
  parentIds?: {
    uuid: string;
    attached: boolean;
  }[];
  collection_uuids?: string[];
}

export interface CollectionItemsPatch {
  items: CollectionItemPatch[];
}

export interface CollectionExportMetadataPost {
  fields: string[];
}

export interface CollectionItemPatch {
  item_uuid?: string;
  asset_uuid?: string;
  logging_type?: string;
  timecode_in?: string;
  timecode_out?: string;
  note?: string;
  type?: string;
}

export interface IdData {
  id: number;
}

export interface CollectionAssetFilters {
  filter: string;
  'tx-date-from': string;
  'tx-date-to': string;
  'facility-of-origin': string;
}

export interface CollectionItemGet extends AssetFlat {
  parent_uuids: string[];
  item_uuid: string;
  asset_uuid: string;
}

export interface CollectionsUserGet {
  emailAddress?: string;
  uuid: string;
  name: string;
  picture: string;
}

interface CollectionsGroupGet {
  uuid: string;
  name: string;
}

export interface CollectionCreateUploadRequest {
  filename: string;
  file_uuid: string;
  file_type: string;
}

export interface CollectionCreateUploadResponse {
  data: {
    upload_access: AwsV4Signature;
  };
  status: 'success' | 'error';
}

export interface CollectionUploadConfirmationRequest {
  filename: string;
  file_key: string;
}

export interface CollectionUploadConfirmationResponse {
  data: string;
  status: string;
}

export interface FolderCollectionUploadRequest {
  query: FoldersQuery;
  collections: string[];
  collection_name?: string;
  users?: string[];
}

export interface FoldersQuery {
  text: string;
  filters: AssetSearchFilters;
}
