import { inject, Injectable } from '@angular/core';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { BehaviorSubject, combineLatest, Observable, shareReplay, tap, distinctUntilChanged } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { DestinationModel, DISCOUNT_STATUS } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { DeliveryMethods } from '../models/delivery-methods.model';
import { CheckoutStepsRequiredPermissions, CheckoutPathsConfig } from '../checkout-config';
import { DeliveryConfig } from '../models/delivery-config.model';

export enum CheckoutStepName {
  CART = 'cart',
  DELIVERY_METHOD = 'delivery-method',
  DELIVERY_DETAILS = 'delivery-details',
  FILENAMES = 'filenames',
  RECIPIENT_DETAILS = 'recipient-details',
  WORKFLOW = 'workflow',
  DELIVERY_SUMMARY = 'summary',
}

export const CheckoutStepsNames = [
  CheckoutStepName.CART,
  CheckoutStepName.DELIVERY_METHOD,
  CheckoutStepName.DELIVERY_DETAILS,
  CheckoutStepName.FILENAMES,
  CheckoutStepName.RECIPIENT_DETAILS,
  CheckoutStepName.WORKFLOW,
  CheckoutStepName.DELIVERY_SUMMARY,
];

export interface DiscountState {
  discountCode?: string | null;
  discountStatus: DISCOUNT_STATUS;
  discountName?: string;
}

export interface Department {
  uuid?: string;
  name?: string;
}

export interface CurrentStep {
  current: CheckoutStepName;
  previous: CheckoutStepName;
  next: CheckoutStepName;
}

@Injectable({ providedIn: 'root' })
export class CartStateService {
  private readonly activatedClientService = inject(ActivatedClientService);

  #orderTypeSelected$ = new BehaviorSubject<DeliveryMethods[number] | null>(null);

  readonly isClipOnlyAndPermitted$ = this.activatedClientService.vida$.pipe(
    withLatestFrom(this.activatedClientService.permissions$),
    map(([vida, permissions]) => !vida?.clipsOnly && permissions.includes(Permission.SHOPPING_CART)),
  );

  readonly isClipOnly$ = this.activatedClientService.vida$.pipe(map((vida) => !!vida?.clipsOnly));

  readonly isUpdating$ = new BehaviorSubject(false);
  readonly isSubmitting$ = new BehaviorSubject(false);

  readonly isBlocked$ = combineLatest([this.isUpdating$, this.isSubmitting$, this.isClipOnly$]).pipe(
    map(([isUpdating, isSubmitting, isClipOnly]) => isUpdating || isSubmitting || isClipOnly),
  );

  readonly isEnabled$: Observable<boolean> = this.isUpdating$.asObservable().pipe(startWith(false));
  readonly checkoutStepsRequiredPermissions = CheckoutStepsRequiredPermissions;

  readonly checkoutPathsConfig$ = this.activatedClientService.permissions$.pipe(
    map((permissions) => {
      const config = { ...CheckoutPathsConfig };
      Object.keys(config).forEach((key) => {
        config[key].steps = config[key].steps.filter((step) => {
          const requiredPermissions =
            this.checkoutStepsRequiredPermissions[step as keyof typeof CheckoutStepsRequiredPermissions];
          return !requiredPermissions || requiredPermissions.every((permission) => permissions.includes(permission));
        });
        config[key].stepperSteps = config[key].stepperSteps?.filter((step) => {
          const requiredPermissions =
            this.checkoutStepsRequiredPermissions[step as keyof typeof CheckoutStepsRequiredPermissions];
          return !requiredPermissions || requiredPermissions.every((permission) => permissions.includes(permission));
        });
      });
      this.checkoutPathsConfigSnapshot = config;
      return config;
    }),
    shareReplay(1),
  );

  checkoutPathsConfigSnapshot?: DeliveryConfig;

  clientDiscountEnabled$ = this.activatedClientService.clientDiscountEnabled$;
  advancedCheckoutDepartmentRequired$ = this.activatedClientService.advancedCheckoutDepartmentRequired$;

  refresh$ = new BehaviorSubject<number>(Date.now());

  orderTypeSelected$ = this.#orderTypeSelected$.asObservable();

  currentStep?: CurrentStep;
  checkoutStepName$ = new BehaviorSubject<CheckoutStepName>(CheckoutStepName.CART);

  currentStep$: Observable<CurrentStep> = this.checkoutStepName$.pipe(
    withLatestFrom(this.orderTypeSelected$, this.checkoutPathsConfig$),
    map(([stepName, orderType, checkoutPathsConfig]) => {
      const type = orderType ?? DeliveryMethods.MANUAL;
      const maxStepIndex = checkoutPathsConfig[type].steps.length - 1;
      const stepIndex = checkoutPathsConfig[type].steps.indexOf(stepName);

      if (stepIndex === -1) {
        return {
          current: null,
          previous: null,
          next: null,
        } as unknown as CurrentStep;
      }

      return {
        current: stepName,
        previous: stepIndex - 1 < 0 ? null : checkoutPathsConfig[type].steps[stepIndex - 1],
        next: stepIndex + 1 > maxStepIndex ? null : checkoutPathsConfig[type].steps[stepIndex + 1],
      } as CurrentStep;
    }),
    distinctUntilChanged((prev, curr) => {
      return JSON.stringify(prev) === JSON.stringify(curr);
    }),
    tap((currentStep) => {
      this.currentStep = currentStep;
    }),
    shareReplay(1),
  );

  checkoutStepIndex$ = this.checkoutStepName$.pipe(
    withLatestFrom(this.orderTypeSelected$, this.checkoutPathsConfig$),
    map(([stepName, orderType, checkoutPathsConfig]) => {
      const type = orderType ?? DeliveryMethods.MANUAL;
      const maxStepIndex = checkoutPathsConfig[type].steps.length - 1;
      const stepIndex = checkoutPathsConfig[type].steps.indexOf(stepName);

      if (stepIndex === -1 || stepIndex > maxStepIndex) {
        return 0;
      }

      return stepIndex;
    }),
  );

  isOrderApproved$ = new BehaviorSubject<boolean>(false);
  isValidated$ = new BehaviorSubject<boolean>(false);

  discount$ = new BehaviorSubject<DiscountState>({ discountCode: undefined, discountStatus: DISCOUNT_STATUS.NONE });
  department$ = new BehaviorSubject<Department>({ uuid: undefined, name: undefined });

  destinationsData$ = new BehaviorSubject<{ destinations: DestinationModel[]; options: SelectOption[] } | null>(null);

  get discountCode() {
    return this.discount$.value.discountCode;
  }

  get department() {
    return this.department$.value;
  }

  setOrderTypeSelected(orderType: DeliveryMethods[number] | null) {
    this.#orderTypeSelected$.next(orderType);
  }

  checkDiscount(reset = false, resetDepartment = false) {
    if (reset) {
      this.discount$.next({ discountCode: null, discountStatus: DISCOUNT_STATUS.NONE });
      if (resetDepartment) {
        this.department$.next({ uuid: undefined, name: undefined });
      }
    }
    this.refresh$.next(Date.now());
  }

  refreshCart = () => {
    this.isUpdating$.next(false);
    this.isSubmitting$.next(false);
    this.isValidated$.next(false);
    this.refresh$.next(Date.now());
  };

  resetAfterAssetsRemoved() {
    this.isUpdating$.next(false);
    this.isSubmitting$.next(false);
    this.isValidated$.next(true);
    this.nextStep(CheckoutStepName.CART, 'CartStateService resetAfterAssetsRemoved');
    this.refresh$.next(Date.now());
  }

  nextStep(stepName?: CheckoutStepName, caller?: string) {
    if (stepName) {
      this.checkoutStepName$.next(stepName);
      return;
    }

    if (this.currentStep?.next) {
      this.checkoutStepName$.next(this.currentStep.next);
      return;
    }

    this.checkoutStepName$.next(CheckoutStepName.CART);
  }
}
