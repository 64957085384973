import { inject, Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedClientService } from '../services/activated-client.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationGuard implements CanMatch {
  private router = inject(Router);
  private activatedClientService = inject(ActivatedClientService);

  #cartUrlTree: UrlTree = this.router.parseUrl('/cart');
  #cartV3UrlTree: UrlTree = this.router.parseUrl('/cart-v3');

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.activatedClientService.clientDefiniteValueChanges$.pipe(
      map((clientDef) => clientDef.vida?.advancedCheckoutEnabled ?? false),
      map((hasAdvancedCheckout) => {
        const firstSegmentPath = segments[0].path;

        if (hasAdvancedCheckout && firstSegmentPath === 'cart-v3') {
          return true;
        } else if (hasAdvancedCheckout) {
          return this.#cartV3UrlTree;
        } else if (firstSegmentPath === 'cart') {
          return true;
        } else {
          return this.#cartUrlTree;
        }
      }),
    );
  }
}
