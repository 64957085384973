import { getCookieDomain, isRunningOnLocal } from './environment.shared';
import { Environment } from './environment.type';

export const environment = <Environment>{
  version: '1d68609ae32127011f0a6d679fa962cb66b02419',
  production: false,
  type: isRunningOnLocal ? 'local' : 'stage',
  firebaseConfig: {
    apiKey: 'AIzaSyAglUTDOh19GYjyZ2z7q8_pxl2YPvV3qcc',
    authDomain: 'vida-stg.firebaseapp.com',
    databaseURL: 'https://vida-stg.firebaseio.com',
    projectId: 'vida-stg',
    storageBucket: 'vida-stg.appspot.com',
    messagingSenderId: '1098012227249',
    appId: '1:1098012227249:web:8b85c70d9951dd77a6d490',
    measurementId: 'G-5LSSWVV4DL',
  },
  apiUrl: 'https://api.vida-stg.vdms-remote.com',
  launchpadUrl: 'https://vida-launchpad-stg.vdms-remote.com',
  websocketUrl: 'wss://6cjh18s9g1.execute-api.eu-west-1.amazonaws.com/vida-stg',
  cookieDomain: getCookieDomain(),
  asperaRemoteHost: 'ams-1.vidatx.net',
  asperaRemoteUser: 'xfer',
  asperaRemotePassword: '$mallClub72',
  asperaDestinationRoot: 'VDMS/',
  asperaSDKLocation: '//d3gcli72yxqn2z.cloudfront.net/connect/v4',
  microsoftEnabled: true,
  oktaEnabled: true,
  microsoftTenantId: '53a24f1c-a975-4f47-8969-de492f993eb2',
  releaseNotes: {
    dark: 'https://docs.google.com/document/d/e/2PACX-1vSLhsgBBXW5gny1hwe4u7fYM-UBHvnUP5C6R5tWMyDqB-OPwpkuyEMEYAxxLupU16AUKzJFlcMaGoPQ/pub?embedded=true',
    light:
      'https://docs.google.com/document/d/e/2PACX-1vRsDi-X9alwvN6ARhll9dMT-Zae_SqLnupX3N4hm-bMMs7RhCaM2sBTTtKjMt3JD6MGptAZngcwCs3z/pub?embedded=true',
  },
  cdn: `https://player.${getCookieDomain()}`,
  voiceAIApiUrl: 'https://api.elevenlabs.io/v1',
  voiceAIApiKey: '8f8e416beaef338ce4ab77bfe286fe09',
  sentryDSN: 'https://e8c688630f4c7b7440baf3c53e5dba36@o4504332307660800.ingest.sentry.io/4506098954403840',
  dolbyRaspApiUrl: 'https://api.vida-dev-02.dolbyrasp.com',
  dolbyRaspApiUrlV2: 'https://api.vida-dev04.dolbyrasp.com',
  googleOAuthClientId: '547884013008-31botfbhqqdh7mojueonl02c9thvc8pv.apps.googleusercontent.com',
};
