<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.home.menu.users'"
    [actions]="headerActions"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  @if (viewConfiguration$ | async; as config) {
    <vdms-hq-ui-multiple-data-presentation
      [dataSource]="dataSource"
      [configuration]="config"
      (action)="handleAction($event)"
      [drawerVisible]="isPaneVisible"
    >
      <vdms-hq-dynamic-form-group-renderer
        filters
        [form]="filters"
        [fieldsConfig]="filtersConfig"
      ></vdms-hq-dynamic-form-group-renderer>

      @if (currentUser$ | async; as user) {
        <ng-container drawer-footer>
          <vdms-hq-ui-button
            *requiresPermissions="[Permission.DELETE_USERS]"
            (click)="handleAction({ key: 'delete', item: user })"
            color="warn"
            >{{ 'common.global.delete' | translate }}
          </vdms-hq-ui-button>
        </ng-container>
      }

      <ng-container drawer-content>
        @if (isCurrentUserLoading$ | async) {
          <vdms-hq-ui-loader [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
        }

        @if (currentUser$ | async; as user) {
          <ng-container>
            <div class="content">
              <vdms-hq-ui-form-input-text
                [ngModel]="user?.email"
                [disabled]="true"
                [placeholder]="'pages.settings.email_placeholder' | translate"
                [label]="'pages.settings.email' | translate"
              >
              </vdms-hq-ui-form-input-text>

              <vdms-hq-ui-form-input-text
                [ngModel]="user?.name"
                [disabled]="true"
                [placeholder]="'pages.settings.name_placeholder' | translate"
                [label]="'pages.settings.name' | translate"
              >
              </vdms-hq-ui-form-input-text>

              <vdms-hq-ui-timeline
                [timelines]="user?.activity?.result || []"
                [title]="'pages.users.latest_activity'"
              ></vdms-hq-ui-timeline>
            </div>
          </ng-container>
        }
      </ng-container>
    </vdms-hq-ui-multiple-data-presentation>
  }

  @if ((dataSource?.selection?.total$ | async) ?? 0 > 0) {
    <vdms-hq-ui-actions-footer class="action-bar">
      <ng-container left>
        <vdms-hq-ui-button color="secondary" (click)="dataSource.selection.clear()">
          {{ 'common.global.deselect_all' | translate }}
        </vdms-hq-ui-button>
      </ng-container>

      @if (dataSource?.selection?.total$ | async; as total) {
        <ng-container center>
          <vdms-hq-ui-button>
            {{ 'common.global.selected' | translate: { selected: total } }}
          </vdms-hq-ui-button>
        </ng-container>
      }

      <ng-container right>
        <vdms-hq-ui-button
          color="warn"
          *requiresPermissions="[Permission.DELETE_USERS]"
          (click)="handleBulkAction('delete')"
        >
          {{ 'pages.users.table.bulk_actions.delete' | translate }}
        </vdms-hq-ui-button>
      </ng-container>
    </vdms-hq-ui-actions-footer>
  }
</div>
