import { StatsStaticViewModel } from './stats-static.model';
import { StatsChartViewModel, StatsChartViewModelV2 } from './stats-chart-view.model';
import { StatsType } from './stats-type.enum';
import { CssChartBarViewModel } from './css-chart-bar-view.model';

export class StatsConfig {
  type: StatsType = StatsType.STATIC;
  title = '';
  icon = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  viewModel: StatsStaticViewModel | StatsChartViewModel = {} as
    | StatsStaticViewModel
    | StatsChartViewModel
    | StatsChartViewModelV2
    | CssChartBarViewModel;

  constructor(
    type: StatsType,
    title: string,
    icon: string,
    viewModel: StatsStaticViewModel | StatsChartViewModel | StatsChartViewModelV2 | CssChartBarViewModel,
  ) {
    this.type = type;
    this.title = title;
    this.icon = icon;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.viewModel = viewModel;
  }
}
