export interface ApiResponse<T> {
  status: string;
  data: T;
}

export interface ApiLicensedPackageResponse<T, R> {
  status: string;
  data: {
    licensedPackage: T;
    uploadData: R;
  };
}

export interface ApiEmptyResponse {
  status: string;
}

export interface ApiPaginatedResponse<T> extends ApiResponse<T[]> {
  total: number;
}

export interface ApiChildResourcePaginatedResponse<T> {
  total: number;
  data: T[];
}

export interface ApiDetailedResourcePaginatedResponse<T> {
  total: number;
  data: T;
  status?: string;
}

interface IdData {
  id: number;
}

export interface DefaultFiltersView {
  keyword: string;
  sort: string;
}

export type IdResponse = ApiResponse<IdData>;

export type IdArrayResponse = ApiPaginatedResponse<IdData[]>;

interface RangeAgg {
  data: {
    count: number;
    from: number;
    to: number;
  }[];
  name: string;
  type: 'range';
}

interface ValueAgg {
  data: {
    value: string;
    count: number;
  }[];
  name: string;
  type: 'value';
}

interface StatsAgg {
  data: {
    max: number;
    min: number;
    count: number;
  }[];
  name: string;
  type: 'stats';
}

export type Aggregations = (ValueAgg | RangeAgg | StatsAgg)[];

export const isValueAgg = (obj: any) => {
  return obj && obj.type === 'value' && (obj as ValueAgg);
};

export const isEmbargoAgg = (obj: any) => {
  return obj && obj.type === 'embargo' && (obj as ValueAgg);
};

export const isRangeAgg = (obj: any) => {
  return obj && obj.type === 'range' && (obj as RangeAgg);
};

export const isStatsAgg = (obj: any) => {
  return obj && obj.type === 'stats' && (obj as StatsAgg);
};

export interface AggregatedResponse {
  aggs: Aggregations;
}
