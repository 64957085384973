import { SortBy, SortDirection } from '../../models/sort/sort.model';
import moment from 'moment';
import objectPath from 'object-path';

export const sort = <T extends { [key: string]: any }>(items: T[], sortBy: SortBy, sortDirection: SortDirection) => {
  if (!sortBy) {
    return items;
  }

  const direction = sortDirection === 'desc' ? -1 : 1;
  return items.sort((left, right) => {
    const leftValue = objectPath.get(left, sortBy);
    const rightValue = objectPath.get(right, sortBy);

    const dateLeft = moment(leftValue);
    const dateRight = moment(rightValue);
    if (dateLeft.isValid() && dateRight.isValid()) {
      return (dateLeft.isBefore(dateRight) ? 1 : -1) * direction;
    }

    if (typeof leftValue === 'string') {
      return direction * leftValue?.toLowerCase().localeCompare(rightValue?.toLowerCase());
    }

    return direction * (leftValue - rightValue);
  });
};
