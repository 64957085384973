import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { SuffixConfig } from '../form-input-text/form-input-text.component';
import { SelectOption } from '@vdms-hq/shared';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-form-input-text-area',
  templateUrl: './form-input-text-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTextAreaComponent),
    },
  ],
  styleUrls: ['./form-input-text-area.component.scss'],
})
export class FormInputTextAreaComponent extends FormControlValueAccessorComponent implements OnInit, OnChanges {
  ngZone = inject(NgZone);

  innerFormControl = new UntypedFormControl(null);
  listOpen = false;
  initialized = false;
  @Input() minRows = 4;
  @Input() small = false;
  @Input() disableEnterBreakline = false;
  @Input() suffixConfig: SuffixConfig | null = null;
  @Input() fallbackSuffixIcon?: string;
  @Input() alternativeAppearance = false;
  @Input() autocompleteList?: SelectOption[];
  @Output() clickSuffix = new EventEmitter();
  @Output() clickEnter = new EventEmitter();

  @ViewChild('autosize')
  set autosize(autosize: any) {
    this.ngZone.onStable.pipe(debounceTime(50), take(1)).subscribe(() => {
      autosize.resizeToFitContent(true);
    });
  }
  @HostListener('document:click')
  clickout() {
    this.#closeList();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.#handleAutocompleteList();
  }

  ngOnChanges() {
    this.#handleAutocompleteList();
  }

  override writeValue(value: string | null | undefined) {
    super.writeValue(value);
    this.#closeList();
  }

  handleEnter(event: Event) {
    this.disableEnterBreakline && event.preventDefault();
    this.clickEnter.emit();
  }

  #handleAutocompleteList() {
    if (this.initialized || !this.autocompleteList?.length) {
      return;
    }

    this.innerFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        tap(() => !this.initialized && (this.initialized = true)),
      )
      .subscribe((phrase: string) => (phrase?.length > 3 ? this.#openList() : this.#closeList()));
  }

  #openList() {
    this.listOpen = true;
  }

  #closeList() {
    this.listOpen = false;
  }

  suffixClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.clickSuffix.emit();
  }
}
