import { DestinationEditConfigComponent } from '../components/destination-edit-config/destination-edit-config.component';
import { Injectable } from '@angular/core';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { DestinationFormService } from './destination-form.service';
import { DestinationDetailsComponent } from '../pages/destination-details/destination-details.component';

@Injectable({ providedIn: 'root' })
export class DeliveryDestinationsDeactivateGuard implements CanDeactivate<DestinationEditConfigComponent> {
  dialogExist = false;

  constructor(
    private dialogService: UIConfirmationDialogService,
    private translateService: TranslateService,
    private destinationForm: DestinationFormService,
  ) {}

  canDeactivate(
    component: DestinationEditConfigComponent | DestinationDetailsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ) {
    const isFormDirty = this.destinationForm?.form.controls.configs.dirty;
    if (!isFormDirty) {
      return true;
    }

    const insideDD = nextState?.url.includes('delivery-destination');

    if (!insideDD && component instanceof DestinationDetailsComponent) {
      return true;
    }

    if (!this.dialogExist) {
      this.dialogExist = true;
      const dirtyConfigName: string[] = [];
      this.destinationForm?.form.controls.configs.controls.forEach((control) => {
        if (!control.pristine && control.value.name) {
          dirtyConfigName.push(control.value.name);
        }
      });
      return this.dialogService
        .open({
          title: this.translateService.instant('pages.delivery_destinations.confirmation.changed.title'),
          message: this.translateService.instant('pages.delivery_destinations.confirmation.changed.desc', {
            configName: dirtyConfigName.join(', '),
          }),
          okAction: {
            label: this.translateService.instant('pages.delivery_destinations.confirmation.changed.save'),
            color: 'primary',
          },
          abortAction: {
            label: this.translateService.instant('pages.delivery_destinations.confirmation.changed.discard'),
            color: 'secondary',
          },
        })
        .pipe(
          map((comeBack) => !comeBack),
          tap((exitRoute) => {
            if (exitRoute) {
              this.destinationForm.resetToCurrent();
            }

            this.dialogExist = false;
          }),
        );
    }
    return true;
  }
}
