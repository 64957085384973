<vdms-hq-ui-dialog-wrapper>
  <h1 title>
    Are you sure you want to delete {{ data.total }} {{ data.total === 1 ? 'user' : 'users' }} permanently from VIDA?
  </h1>

  <div content class="dialog-content">
    <form [formGroup]="form">
      <vdms-hq-ui-form-input-text
        formControlName="reason"
        label="Reason"
        placeholder="Please enter the reason for this deletion…"
      ></vdms-hq-ui-form-input-text>
    </form>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="abort()" color="secondary">{{
      'common.dialogs.assets.buttons.button_cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="ok()" color="warn" [disabled]="form.invalid" [isSubmit]="true">{{
      'common.dialogs.assets.buttons.button_confirm_delete' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
