<vdms-hq-ui-dialog-wrapper [size]="'wide'">
  <div title>
    <span class="title">{{
      'common.client-teams.dialogs.add_collection_dialog_title' | translate: { collectionName: data.collectionName }
    }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-selectable-tiles
      [dataSource]="teamsAddDS"
      [filters]="teamsAddDS.filters"
      [config]="teamsAddSelectableConfig"
      (updateSelectedListEmit)="teamsAddSelectableConfig.selectedList.data = $event"
      [selected]="this.selectedControl.value ?? []"
      (selectedChangeEmit)="this.selectedControl.setValue($event)"
      (updateConfigEmit)="teamsAddSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
    >
    </vdms-hq-ui-selectable-tiles>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" [disabled]="isSaving" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="save()"
      [loading]="isSaving"
      [disabled]="selectedControl.invalid || (selectedControl.value.length === 0 && data.shared_to_teams?.length === 0)"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
