import { DeliveryMethods } from './models/delivery-methods.model';
import { Permission } from '@vdms-hq/activated-client';
import { DeliveryConfig } from './models/delivery-config.model';

export const CheckoutStepsNames = {
  CART: 'cart',
  DELIVERY_METHOD: 'delivery-method',
  DELIVERY_DETAILS: 'delivery-details',
  FILENAMES: 'filenames',
  WORKFLOW: 'workflow',
  RECIPIENT_DETAILS: 'recipient-details',
  DELIVERY_SUMMARY: 'summary',
} as const;

export const CheckoutStepsRequiredPermissions = {
  [CheckoutStepsNames.FILENAMES]: [Permission.USE_FILENAME_CONVENTIONS],
};

export const CheckoutPathsConfig: DeliveryConfig = {
  [DeliveryMethods.MANUAL]: {
    steps: [
      CheckoutStepsNames.CART,
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.FILENAMES,
      CheckoutStepsNames.RECIPIENT_DETAILS,
      CheckoutStepsNames.DELIVERY_SUMMARY,
    ],
    stepperSteps: [
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.FILENAMES,
      CheckoutStepsNames.RECIPIENT_DETAILS,
    ],
  },
  [DeliveryMethods.PREDEFINED]: {
    steps: [
      CheckoutStepsNames.CART,
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.RECIPIENT_DETAILS,
      CheckoutStepsNames.DELIVERY_SUMMARY,
    ],
    stepperSteps: [
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.RECIPIENT_DETAILS,
    ],
  },

  [DeliveryMethods.WARM_UP]: {
    steps: [
      CheckoutStepsNames.CART,
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.DELIVERY_SUMMARY,
    ],
    stepperSteps: [CheckoutStepsNames.DELIVERY_METHOD, CheckoutStepsNames.DELIVERY_DETAILS],
  },
  [DeliveryMethods.WORKFLOW]: {
    steps: [
      CheckoutStepsNames.CART,
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.WORKFLOW,
      CheckoutStepsNames.DELIVERY_SUMMARY,
    ],
    stepperSteps: [
      CheckoutStepsNames.DELIVERY_METHOD,
      CheckoutStepsNames.DELIVERY_DETAILS,
      CheckoutStepsNames.WORKFLOW,
    ],
  },
};
