import { Component, Input, ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIFormModule, UIButtonModule } from '@vdms-hq/ui';
import { SharedModule } from '@vdms-hq/shared';
import { UserAdminDialogService } from '../../logic/user-admin-dialog.service';
import { UserAdminDialogDataSource } from '../../logic/user-admin-dialog-datasource';

@Component({
  standalone: true,
  selector: 'vdms-hq-manage-policies-list[dataSource]',
  templateUrl: './manage-policies-list.component.html',
  styleUrls: ['./manage-policies-list.component.scss'],
  imports: [CommonModule, UIFormModule, SharedModule, UIButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagePoliciesListComponent {
  private userAdminDialogService = inject(UserAdminDialogService);

  #dataSource?: UserAdminDialogDataSource;

  @Input() set dataSource(ds: UserAdminDialogDataSource) {
    this.#dataSource = ds;
  }

  get dataSource() {
    if (!this.#dataSource) {
      throw new Error('dataSource is not set');
    }
    return this.#dataSource;
  }

  copyPoliciesFrom(userUuid: string) {
    this.userAdminDialogService.copyPoliciesFrom(userUuid);
  }
}
