<div *ngIf="!readonly; else readonlyValue">
  <mat-form-field appearance="outline" class="material-custom-field">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let chip of value" [removable]="true" (removed)="removeChip(chip.name, chip.value)">
        {{ chip.name }}: {{ chip.value }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        [placeholder]="placeholder"
        #input
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        (keyup)="keyUp($event)"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addChip($event)"
      />
    </mat-chip-grid>
    <mat-error> </mat-error>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of hints$ | async" [value]="tag" class="multiline">
      <span [innerHTML]="tag.name | highlight: searchControl.value"></span>
      <div class="related">
        <span
          class="related-item"
          *ngFor="let related of tag.related"
          [innerHTML]="related | highlight: searchControl.value"
        >
        </span>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
<ng-template #readonlyValue>
  <div class="field-readonly">
    <span class="label">{{ label }}</span>
    <span class="value value--fallback" *ngIf="!value || value.length === 0; else valueChip">{{ 'N/A' }}</span>
  </div>
  <ng-template #valueChip>
    <mat-chip-listbox class="value">
      <mat-chip-option *ngFor="let chip of value; trackBy: trackByFn" [selectable]="false" [removable]="true">
        {{ chip.name }}: {{ chip.value }}
      </mat-chip-option>
    </mat-chip-listbox>
  </ng-template>
</ng-template>
