import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISimpleDetailsListComponent } from './simple-details-list/simple-details-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIPipesModule } from '../ui-pipes/ui-pipes.module';
import { UILoaderModule } from '../ui-loader';
import { TruncatePipe } from '@vdms-hq/shared';

@NgModule({
  declarations: [UISimpleDetailsListComponent],
  imports: [CommonModule, TranslateModule, UIPipesModule, UILoaderModule, TruncatePipe],
  exports: [UISimpleDetailsListComponent],
})
export class UISimpleDetailsListModule {}
