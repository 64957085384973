import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';
import { ButtonComponentConfig } from '../../../ui-button/button/button.component';

export interface BreadcrumbsButtonConfig extends ButtonComponentConfig {
  label?: string;
  action?: () => void;
}

@Component({
  selector: 'vdms-hq-ui-layout-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  readonly MAX_BREADCRUMBS_SIZE = 3;
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() loading = false;
  @Input() size: 'small' | 'medium' = 'small';
  @Input() additionalInfo = '';
  @Input() buttonConfig?: BreadcrumbsButtonConfig;
  @Output() selectedBreadCrumb = new EventEmitter<{ crumb: BreadCrumb; index: number }>();
  @Output() buttonAction = new EventEmitter<void>();
}
