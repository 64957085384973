<div class="player__controls" [class.player__controls--fixed]="fixedControls">
  <div class="advanced-player-slider timeline-control">
    <mat-slider
      class="progressbar"
      discrete
      [displayWith]="formatSliderTooltip"
      [step]="0.01"
      [min]="0"
      [max]="sliderMax"
      (mousemove)="onMouseMoveTimelineBar($event)"
      (mouseleave)="onMouseLeaveTimelineBar($event)"
    >
      <input matSliderThumb [ngModel]="sliderValue" (ngModelChange)="onSliderValueChange($event)" />
    </mat-slider>
    <div class="preview" [ngStyle]="spriteCss" *ngIf="spriteCss">
      <div class="thumb-label-timecode"></div>
    </div>
  </div>
</div>
<div
  class="player__controls"
  [class.player__controls--fixed]="fixedControls"
  *ngIf="player"
  xmlns="http://www.w3.org/1999/html"
>
  <div class="player__controls__left">
    <vdms-hq-ui-button
      (click)="emitAction({ key: 'togglePlayPause' })"
      size="small"
      [icon]="player.video.isPlaying() ? 'pause' : 'play_arrow'"
      [iconOnly]="true"
      [tooltip]="(player.video.isPlaying() ? 'player.tooltips.pause' : 'player.tooltips.play') | translate"
    >
    </vdms-hq-ui-button>

    <div class="player__controls__voice-section" *ngIf="!peakMeterConnected; else volumeOff">
      <vdms-hq-ui-button
        size="small"
        class="player__controls__voice-section__mute-btn"
        (click)="emitAction({ key: 'toggleMute' })"
        [icon]="player.video.getVolume() === 0 ? 'volume_off' : 'volume_up'"
        [iconOnly]="true"
        [tooltip]="'player.tooltips.mute' | translate"
      >
      </vdms-hq-ui-button>

      <div class="advanced-player-slider voice-level-slider">
        <mat-slider discrete [displayWith]="formatVolumeTooltip" [step]="0.05" [min]="0" [max]="1">
          <input
            matSliderThumb
            [ngModel]="player.video.getVolume()"
            (ngModelChange)="emitAction({ key: 'updateVolume', volume: $event })"
          />
        </mat-slider>
      </div>
    </div>

    <div class="player__controls__left__timestamp-section">
      <ng-container>
        <span>{{ currentTimecode | formatTime }}</span>
        <span class="lighter">/ {{ duration }}</span>
      </ng-container>
    </div>

    <ng-template #volumeOff>
      <vdms-hq-ui-button
        size="small"
        class="player__controls__voice-section__mute-btn"
        [icon]="'volume_mute'"
        [iconOnly]="true"
        [disabled]="true"
      >
      </vdms-hq-ui-button>
    </ng-template>
  </div>

  <div class="player__controls__right">
    <vdms-hq-ui-button
      size="small"
      [iconOnly]="true"
      (click)="fixedControls = !fixedControls"
      [icon]="'push_pin'"
      [iconColor]="fixedControls ? 'primary' : 'disabled'"
      [tooltip]="(fixedControls ? 'player.tooltips.unpin' : 'player.tooltips.pin') | translate"
    >
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      size="small"
      [iconOnly]="true"
      icon="settings"
      [matMenuTriggerFor]="settingsMenu"
      [tooltip]="'player.tooltips.menu' | translate"
    ></vdms-hq-ui-button>
    <vdms-hq-ui-button
      size="small"
      [iconOnly]="true"
      (click)="emitAction({ key: 'toggleFullScreen' })"
      icon="fullscreen"
      [tooltip]="'player.tooltips.full-screen' | translate"
    >
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      size="small"
      [iconOnly]="true"
      icon="picture_in_picture_alt"
      (click)="emitAction({ key: 'togglePictureInPicture' })"
      [disabled]="!isPictureInPictureAvailable"
      [tooltip]="'player.tooltips.pip' | translate"
    ></vdms-hq-ui-button>
  </div>
</div>

<mat-menu #subtitlesMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <button
      mat-menu-item
      (click)="emitAction({ key: 'changeSubtitles' })"
      [class.highlighted]="currentSubtitles === null"
    >
      {{ 'common.advanced_player.action_buttons.no_subtitles' | translate }}
    </button>
    <button
      mat-menu-item
      *ngFor="let subtitle of loadedSubtitles"
      (click)="emitAction({ key: 'changeSubtitles', subtitles: subtitle })"
      [class.highlighted]="currentSubtitles?.path === subtitle.path"
    >
      {{ subtitle.language }}
    </button>
  </div>
</mat-menu>

<mat-menu #qualityMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <button
      mat-menu-item
      (click)="emitAction({ key: 'changeQualityLevel', qualityLevel: -1 })"
      [class.highlighted]="!currentQualityPlayerV2 && currentQuality === -1"
    >
      {{ 'common.advanced_player.action_buttons.quality_auto' | translate }}
    </button>
    <button
      mat-menu-item
      *ngFor="let quality of qualityOptions; let i = index"
      (click)="
        emitAction({
          key: 'changeQualityLevel',
          qualityLevel: isAdvancedV2 ? quality?.key?.toString() : i,
        })
      "
      [class.highlighted]="currentQuality === i || currentQualityPlayerV2 === quality.key"
    >
      {{ quality.label }}
    </button>
  </div>
</mat-menu>

<mat-menu #audioTracksMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <button
      mat-menu-item
      *ngFor="let audio of audioTracksOptions; let i = index"
      (click)="
        emitAction({
          key: 'changeAudioTrack',
          audioTrack: Number(audio.key),
        })
      "
      [class.highlighted]="currentAudioTrack === audio.key"
    >
      {{ audio.label }}
    </button>
  </div>
</mat-menu>

<mat-menu #playbackRateMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <button
      mat-menu-item
      *ngFor="let rate of playbackRates"
      (click)="emitAction({ key: 'changePlaybackRate', rate: rate })"
      [class.highlighted]="currentPlaybackRate === rate"
    >
      {{ rate }}x
    </button>
  </div>
</mat-menu>

<mat-menu #settingsMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="subtitlesMenu">
    <div class="sub-menu">
      <div class="sub-menu__icon">
        <mat-icon>{{ closedCaptions ? 'closed_caption' : 'closed_caption_disabled' }}</mat-icon>
      </div>
      <div class="sub-menu__label">
        {{ closedCaptions ? currentSubtitles?.language : 'No Timed Texts' }}
      </div>
    </div>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="playbackRateMenu">
    <div class="sub-menu">
      <div class="sub-menu__icon">
        <mat-icon>speed</mat-icon>
      </div>
      <div class="sub-menu__label">
        <div>Playback Rate</div>
        <div>{{ currentPlaybackRate }}x</div>
      </div>
    </div>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="qualityMenu">
    <div class="sub-menu">
      <div class="sub-menu__icon">
        <mat-icon>hd</mat-icon>
      </div>
      <div class="sub-menu__label">
        <div>Quality</div>
        <div *ngIf="currentQualityPlayerV2">
          {{ currentQualityPlayerV2 ? (qualityOptions | extractLabel: currentQualityPlayerV2) : 'Auto' }}
        </div>
        <div *ngIf="!currentQualityPlayerV2">
          {{ currentQuality === -1 ? 'Auto' : (qualityOptions | extractLabel: currentQuality) }}
        </div>
      </div>
    </div>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="audioTracksMenu" *ngIf="audioTracksOptions.length">
    <div class="sub-menu">
      <div class="sub-menu__icon">
        <mat-icon>headphones</mat-icon>
      </div>
      <div class="sub-menu__label">
        <div>Audio</div>
        <div>{{ audioTracksOptions | extractLabel: currentAudioTrack }}</div>
      </div>
    </div>
  </button>

  <button
    mat-menu-item
    (click)="
      emitAction({
        key: 'toggleHelpDialog',
      })
    "
  >
    <div class="sub-menu">
      <div class="sub-menu__icon"><mat-icon>help</mat-icon></div>
      <div class="sub-menu__label">
        <div>Help</div>
      </div>
    </div>
  </button>
</mat-menu>
