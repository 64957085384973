import { Provider } from '@angular/core';
import { TRANSCRIPTION_CONFIG, TranscriptionConfig } from './lib/logic/config-tokens';

export const configureTranscription = (config: TranscriptionConfig): Provider[] => {
  return [
    {
      provide: TRANSCRIPTION_CONFIG,
      useValue: config,
    },
  ];
};
