import { inject, Injectable } from '@angular/core';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import {
  LicensePackagesService,
  LicensedPackageListItem,
  LicensedPackageFiltersView,
  LICENSED_PACKAGE_VIEW_STATE,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  Observable,
  of,
  share,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { RefreshService } from '@vdms-hq/shared';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup } from '@angular/forms';
import { LicensePackagesRefreshService } from './license-packages-refresher.service';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import moment from 'moment-timezone';
import { AuthService } from '@vdms-hq/auth';

@Injectable({ providedIn: 'root' })
export class LicensePackagesResultsMultiDs
  extends RouterParamsPagination
  implements MultipleViewDataSource<LicensedPackageListItem>
{
  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);
  isLoading$ = new BehaviorSubject(false);
  refresh$ = this.licensePackageRefresher.refreshLicensePackagesList$;

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    status: new FormControl<string | null>(null),
    sort: new FormControl<string | null>('licensedPackage.createdAt_desc'),
    partners: new FormControl<string[] | null>(null),
    contracts: new FormControl<string[] | null>(null),
    view_state: new FormControl<LICENSED_PACKAGE_VIEW_STATE | null>(null),
    contract_in_out: new FormControl<{
      from: moment.Moment;
      to: moment.Moment;
    } | null>(null),
  });

  values$: Observable<LicensedPackageFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <LicensedPackageFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      if (formValue?.status) {
        filters.status = formValue.status;
      }

      if (formValue?.sort) {
        const sortExploded = formValue.sort.split('_');
        if (sortExploded[0] == 'contract') {
          filters.sort = sortExploded.slice(0, -1).join('_');
          filters.direction = sortExploded[sortExploded.length - 1];
        } else {
          filters.sort = formValue.sort.split('_')[0];
          filters.direction = formValue.sort.split('_')[1];
        }
      }

      if (formValue?.partners) {
        filters.partners = formValue.partners;
      }

      if (formValue?.contracts) {
        filters.contracts = formValue.contracts;
      }

      if (formValue?.view_state) {
        filters.view_state = formValue.view_state;
      }

      if (formValue?.contract_in_out?.from) {
        filters.contract_in = formValue.contract_in_out?.from.valueOf();
      }

      if (formValue?.contract_in_out?.to) {
        filters.contract_out = formValue.contract_in_out?.to.valueOf();
      }
      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<LicensedPackageListItem[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.values$,
    this.authService.auth$,
    this.refresh$,
    this.refreshService.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([index, size, filters, user]) => {
      if (!user) {
        return EMPTY;
      }
      const headers = Pagination.create({
        page: index,
        perPage: size,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });
      return this.titlesService
        .getMany(headers, filters)
        .pipe(catchError((err: HttpErrorResponse) => this.#errorHandler(err)));
    }),
    tap((response) => this.total$.next(response.total)),
    tap((response) => this.emptyResults$.next(response.total === 0)),
    map((response) => response.data),
    tap(() => this.isLoading$.next(false)),
  );
  connection$: Observable<LicensedPackageListItem[]> = this.allData$;

  constructor(
    private toastService: ToastService,
    private titlesService: LicensePackagesService,
    private licensePackageRefresher: LicensePackagesRefreshService,
    private refreshService: RefreshService,
  ) {
    super();
  }

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }
}
