import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FilterByPropPipe } from './pipes/filter-by-prop.pipe';
import { EliminateNegativesPipe } from './pipes/eliminate-negatives.pipe';
import { ObjectValueByPathPipe } from './pipes/object-value-by-path.pipe';
import { ZeroToEmptyPipe } from './pipes/zero-to-empty.pipe';

const exported = [FilterByPropPipe, EliminateNegativesPipe, ObjectValueByPathPipe, ZeroToEmptyPipe];

@NgModule({
  declarations: [...exported],
  exports: [...exported],
  imports: [CommonModule],
  providers: [AsyncPipe],
})
export class UIPipesModule {}
