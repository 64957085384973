import { ChangeDetectorRef, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedClientService, ClientModel } from '@vdms-hq/activated-client';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, switchMap, tap } from 'rxjs';
import { SelectedFilesService } from '@vdms-hq/ui';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientsService, ExportGroupConfigPayload } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-import-export-dialog',
  templateUrl: './import-export-dialog.component.html',
  styleUrls: ['./import-export-dialog.component.scss'],
})
export class ImportExportDialogComponent implements OnInit, OnDestroy {
  clientsService = inject(ClientsService);
  dialogRef = inject(MatDialogRef<ImportExportDialogComponent>);
  selectedFilesService = inject(SelectedFilesService);
  activatedClient = inject(ActivatedClientService);
  sanitizer = inject(DomSanitizer);
  cdr = inject(ChangeDetectorRef);
  @ViewChild('downloadLink') link: ElementRef | undefined;

  hasSelected$ = this.selectedFilesService.hasSelected$;
  #destroyed = new Subject<void>();
  loading = false;
  exportPaths: string[] = [];
  downloadJsonHref?: SafeUrl;
  filename = 'export.json';
  payload?: ExportGroupConfigPayload;
  exportOptions: SelectOption[] = [
    { key: 'assetView', label: 'Configuration for asset view per page' },
    { key: 'columns', label: 'Configuration for columns in tables' },
    { key: 'contentCorner', label: 'Configuration for content corner' },
    { key: 'fields', label: 'Custom field names' },
    { key: 'filters', label: 'Filters available in search' },
    { key: 'hostnames', label: 'Hostnames for all vida apps' },
    { key: 'integrations', label: 'Integrations with other apps' },
    { key: 'launchpad', label: 'Configuration for launchpad' },
    { key: 'library', label: 'Configuration for asset library' },
    { key: 'statsConfig', label: 'Configuration for ingress dashboards' },
    { key: 'storefront', label: 'Configuration for storefront' },
    { key: 'vida', label: 'Configuration for vida' },
    { key: 'rates', label: 'Billing configuration' },
  ];

  updatePayload() {
    this.payload = {
      database: { rates: this.exportPaths.includes('rates') },
      firestore: {
        assetView: this.exportPaths.includes('assetView'),
        columns: this.exportPaths.includes('columns'),
        contentCorner: this.exportPaths.includes('contentCorner'),
        fields: this.exportPaths.includes('fields'),
        filters: this.exportPaths.includes('filters'),
        hostnames: this.exportPaths.includes('hostnames'),
        integrations: this.exportPaths.includes('integrations'),
        launchpad: this.exportPaths.includes('launchpad'),
        library: this.exportPaths.includes('library'),
        statsConfig: this.exportPaths.includes('statsConfig'),
        storefront: this.exportPaths.includes('storefront'),
        vida: this.exportPaths.includes('vida'),
      },
    };
  }

  export() {
    if (!this.payload) {
      return;
    }
    this.activatedClient.clientIdDefinite$
      .pipe(
        take(1),
        switchMap((id: string) => {
          if (this.payload) {
            return this.clientsService.configExport(id, this.payload);
          } else {
            throw new Error('Missing payload');
          }
        }),
        tap((response) => {
          const stringifiedResponse = JSON.stringify(response);
          this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(
            'data:text/json;charset=UTF-8,' + encodeURIComponent(stringifiedResponse),
          );
          this.cdr.detectChanges();
          this.link?.nativeElement.click();
        }),
      )
      .subscribe();
  }

  save(client: Partial<ClientModel>) {
    this.loading = true;
    this.activatedClient.clientIdDefinite$
      .pipe(
        take(1),
        switchMap((id: string) => {
          return this.clientsService.configImport(id, client);
        }),
        tap(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  uploadFile() {
    this.selectedFilesService.selectedFiles$.pipe(take(1)).subscribe((files) => {
      const file = files[0];

      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          this.save(JSON.parse(fileReader.result));
        }
      };
      fileReader.onerror = (error) => {
        console.log(error);
      };
    });
  }

  ngOnInit(): void {
    this.activatedClient.clientDefinite$.pipe(takeUntil(this.#destroyed)).subscribe((client) => {
      this.filename = `Export client settings [${client.name}] ${new Date().toISOString().split('T')[0]}.json`;
    });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
