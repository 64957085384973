import { inject, Injectable } from '@angular/core';
import { AuthService } from '@vdms-hq/auth';
import { combineLatest, Observable, of, Subscription, switchMap, zip } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { SavedLatestSearchesModel, UserContractService } from '@vdms-hq/firebase-contract';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetSearchService, DefinedFilter } from '@vdms-hq/api-contract';
import { SearchParamSerializerService } from './search-param-serializer.service';
import { UrlSerializer } from '@angular/router';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { QueryAutocomplete } from '../../search-bar/smart/search-autocomplete-options/search-autocomplete-options.component';

@Injectable({
  providedIn: 'root',
})
export class LastFiltersService {
  #listeners?: Subscription;

  routeSerializer = inject(SearchParamSerializerService);
  urlSerializer = inject(UrlSerializer);
  fieldsFetcherService = inject(FieldsFetcherService);

  #savedSearches$ = combineLatest([this.#firebasePathElements$(), this.activatedClientService.clientIdDefinite$]).pipe(
    map(([element, clientId]) => ({ ...element, clientId })),
    switchMap((data) => this.userContractService.getSavedUserSearches(data.userEmail, data.clientId)),
  );

  latestQueries$: Observable<QueryAutocomplete[]> = this.#savedSearches$.pipe(
    switchMap((items) => {
      const itemsConverted = items
        .filter((item) => item.url?.includes('query'))
        .map((item) => {
          const params = this.urlSerializer.parse(item.url ?? '').queryParamMap;
          return combineLatest([this.routeSerializer.fromRoute(params, ''), of(item)]);
        });
      return itemsConverted.length > 0 ? zip(itemsConverted) : of([]);
    }),
    map((searchesDefined) => {
      return searchesDefined.map(([search, item]) => {
        const filtersArray = Object.entries(search.query.filters)
          .map(([k, v]) => {
            return {
              key: k as string,
              value: v as DefinedFilter,
            };
          })
          .slice(0, 2);
        return {
          text: search.query.text ?? undefined,
          filters: filtersArray,
          params: search,
          key: item.id,
          extraMetadata:
            Object.keys(search.query.filters).length > 2 ? Object.keys(search.query.filters).length - 2 : undefined,
        };
      });
    }),
  );

  constructor(
    private readonly auth: AuthService,
    private readonly activatedClientService: ActivatedClientService,
    private userContractService: UserContractService,
    private assetSearchService: AssetSearchService,
  ) {}

  registerListeners = () => {
    if (this.#listeners) {
      return;
    }
    this.#listeners = this.assetSearchService.currentParams$
      .pipe(
        switchMap((params) => {
          const saveToSuggestions = params.save && (params.query.text || Object.keys(params.query.filters).length > 0);
          return zip([
            this.routeSerializer.toRoute(params),
            of(saveToSuggestions),
            of(params.save),
            of(params.initial),
          ]);
        }),
      )
      .subscribe(([params, saveToSuggestions, saveParam, initial]) => {
        if (saveParam) {
          localStorage.removeItem('lastAssetQuery');
        }
        if (saveToSuggestions) {
          localStorage.setItem('lastAssetQuery', this.routeSerializer.buildUrl(params, ''));
        }
        if (initial) {
          this.#saveLastSearches(this.routeSerializer.buildUrl(params, ''));
        }
      });
  };

  #firebasePathElements$(): Observable<{ userEmail?: string; clientId: string }> {
    return this.auth.auth$.pipe(first(), withLatestFrom(this.activatedClientService.clientIdDefinite$)).pipe(
      first(),
      map(([firebaseUser, selectedClient]) => ({ userEmail: firebaseUser?.email, clientId: selectedClient })),
    );
  }

  async #saveLastSearches(url?: string) {
    if (!url) {
      return;
    }

    const searchObj: SavedLatestSearchesModel = {
      createdAt: Date.now(),
      url: url,
      key: url,
    };
    this.#savedSearches$
      .pipe(first(), withLatestFrom(this.#firebasePathElements$()))
      .subscribe(([searchObjects, firebasePathElements]) => {
        const isExist = !!searchObjects.filter((searchObject) => searchObject.url === url).length;
        if (!isExist) {
          this.userContractService.saveUserSearches(
            firebasePathElements.userEmail,
            firebasePathElements.clientId,
            searchObj,
          );
        }
      });
  }

  removeSavedSearchPhrase(searchItem) {
    this.#firebasePathElements$()
      .pipe(first())
      .subscribe((data) => {
        this.userContractService.removeUserSearchItem(searchItem.key as string, data.userEmail, data.clientId);
      });
  }
}
