import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { WebsocketEntitiesUpdateInterface, WebsocketNotificationInterface } from '@vdms-hq/shared';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from '@vdms-hq/auth';
import { API_CLIENT_ID_PROVIDER, API_CONFIG, ApiConfig, ClientIdProvider } from '../config-token';
import { Notification } from '../rest/notification-subscriptions/notification-subscriptions.model';

type NotificationsWebsocketResponse = {
  uuids: Notification['uuid'][];
};

@Injectable({ providedIn: 'root' })
export class NotificationsStreamService {
  protected auth = inject(AuthService);
  protected env = inject<ApiConfig>(API_CONFIG);
  protected clientIdProvider = inject<ClientIdProvider>(API_CLIENT_ID_PROVIDER);

  readonly streamName = '{groupUuid}/notifications';
  readonly connection$: Observable<
    WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<NotificationsWebsocketResponse>>
  > = combineLatest([this.clientIdProvider.clientIdDefinite$, this.auth.auth$]).pipe(
    switchMap(([clientId, auth]) => {
      if (!auth) {
        return [];
      }

      return this.auth.activeToken$.pipe(map((token) => ({ clientId: clientId, token, uid: auth.id })));
    }),
    map((params) => {
      const url = new URL(`${this.env.websocketUrl}`);
      url.searchParams.set('token', `${params.token}`);
      url.searchParams.set('stream', `${this.streamName.replace('{groupUuid}', params.clientId as string)}`);
      return url;
    }),
    switchMap((url) => {
      return webSocket<WebsocketNotificationInterface<NotificationsWebsocketResponse>>({
        url: url.toString(),
      });
    }),
    filter(
      (msg: WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<NotificationsWebsocketResponse>>) =>
        typeof msg !== 'string',
    ),
    share(),
  );
}
