import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, filter, takeUntil, withLatestFrom, of } from 'rxjs';
import {
  DialogResponse,
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import {
  Destroyable,
  DiacriticsRevertConverter,
  E2eIdDirective,
  emailPatternValidator,
  FilterType,
  ResourceModel,
  ValueFormat,
} from '@vdms-hq/shared';
import { SelectableWrapperConfig, UiSelectableTilesComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ToastService } from '@vdms-hq/toast';
import { ViewSettingsService } from '@vdms-hq/view-settings';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { UsersShareData, UsersShareDataSource } from '../../logic/users-share-ds.service';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import { AuthService } from '@vdms-hq/auth';
import { CollectionsService } from '@vdms-hq/api-contract';

export interface ShareUsersResponse {
  status: DialogResponse;
}

export interface ShareUsersInput {
  shared_to_users?: {
    name: string;
    uuid: string;
  }[];
  collectionUuid?: string;
  supplierUuid?: string;
}

@Component({
  selector: 'vdms-hq-users-share-dialog',
  templateUrl: './users-share-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    UIEmptyResultsModule,
    MatDividerModule,
    UiAddDialogSelectableTilesComponent,
    UiSelectableTilesComponent,
    E2eIdDirective,
  ],
})
export class UsersShareDialogComponent extends Destroyable() implements OnInit {
  private dialogRef = inject(MatDialogRef<UsersShareDialogComponent>);
  private dialog = inject(MatDialog);
  private toastService = inject(ToastService);
  private viewSettingsService = inject(ViewSettingsService);
  private activatedClientService = inject(ActivatedClientService);
  private collectionsService = inject(CollectionsService);
  public usersShareDS = inject(UsersShareDataSource);
  private authService = inject(AuthService);

  public data: ShareUsersInput = inject(MAT_DIALOG_DATA);

  isSaving = false;
  isLoading = true;
  hideUsersList$ = this.activatedClientService.userListHidden$;
  userEmail$ = this.authService.email$;

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'Search by name or email',
      resource: [ResourceModel.CLIENT_TEAM],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-users'],
    },
  ];

  usersTilesConfig: TileSelectableConfig<UsersShareData> = {
    label: 'label',
    key: 'key',
    icon: 'person',
    metadata: [
      {
        label: 'Email',
        valuePath: 'mail',
        viewFormat: {
          modifiers: {
            truncateCharacters: 50,
          },
        },
      },
    ],
  };

  usersAddSelectableConfig: SelectableWrapperConfig<UsersShareData> = {
    fieldsConfig: this.filtersConfig,
    tileConfig: this.usersTilesConfig,
    selectedList: {
      data: this.data.shared_to_users ?? [],
      title: 'Collection shared with',
    },
    searchView: {
      placeholder: 'Type name or user email',
      label: 'Search Users',
      showSearch: true,
    },
    datasourceTitle: 'Users list',
    emptyMessage: 'No users found',
    loadingText: 'Loading users',
    hasEmptyActionButton: true,
    emptyActionButtonText: 'Create new user',
    showPaginator: true,
  };

  #form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });
  selectedControl = this.#form.get('selected') as FormControl<string[]>;

  ngOnInit() {
    combineLatest([
      this.viewSettingsService.preferredSelectableView$,
      this.hideUsersList$,
      this.userEmail$,
      this.data.collectionUuid ? this.collectionsService.getCollectionData(this.data.collectionUuid) : of(null),
    ])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([view, hidden, userEmail, collectionData]) => {
        this.usersShareDS.showUsers$.next(!hidden);
        if (hidden) {
          this.usersAddSelectableConfig.hasEmptyActionButton = (value) => value !== userEmail;
          this.usersShareDS.filters.controls.keyword.setValidators(emailPatternValidator());
          this.usersAddSelectableConfig.searchView.showSearch = false;
          this.usersAddSelectableConfig.showPaginator = false;
          this.usersAddSelectableConfig = {
            ...this.usersAddSelectableConfig,
            searchView: {
              ...this.usersAddSelectableConfig.searchView,
              showSearch: true,
              only: true,
              errorMsg: 'common.validators.email',
            },
            datasourceTitle: 'Share to:',
            loadingText: 'Searching for user...',
          };
          this.filtersConfig[0].label = "Enter user's email and if the user exists, click on its field to add";
        } else {
          this.usersAddSelectableConfig.searchView.showSearch = view === 'search';
        }

        if (collectionData) {
          this.usersAddSelectableConfig.selectedList.data = collectionData.users ?? [];
          const sharedUsersUuids = collectionData.users?.map((user) => user.uuid) ?? [];
          this.selectedControl.setValue(sharedUsersUuids);
        }

        this.isLoading = false;
      });
  }

  createNewUser() {
    const email = DiacriticsRevertConverter(this.usersShareDS.filters.controls['keyword'].value);
    this.dialog
      .open(AddUserDialogComponent, { data: { email } })
      .afterClosed()
      .pipe(
        filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK),
        filter((dialogResponse) => !!dialogResponse?.user),
        map((data) => data.user),
      )
      .subscribe({
        next: (user) => {
          if (!user) {
            return;
          }
          this.usersShareDS.filters.controls['keyword'].setValue('');
          this.usersShareDS.filters.controls['keyword'].setValue(email);
          this.usersShareDS.filters.controls['keyword'].markAsDirty();
        },
        error: () => {
          this.toastService.error({
            id: 'create_field_failure',
            message: 'common.notifications.users.create.exists_other_client',
            interpolatedParams: { reason: 'Not specified' },
          });
        },
      });
  }

  close(saved = false) {
    this.usersShareDS.refresh(saved);
    this.dialogRef.close(
      saved
        ? {
            status: DialogResponse.OK,
            userIds: this.selectedControl.value,
          }
        : undefined,
    );
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }
}
