import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { authenticate401, redirect404, redirectNotPermitted403 } from './error-handling.types';
import { ErrorHandlingMessages, ErrorHandlingRoutes } from './error-handling.enum';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { ERRORS_CATCHER_CONFIG } from '@vdms-hq/api-contract';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private permissionService = inject(PermissionService);
  showErrors = false;

  constructor() {
    this.permissionService
      .verifyWithOwnedPermissions$([Permission.PERMISSION_SHOW_ERROR_MESSAGES])
      .subscribe((hasPermission) => {
        this.showErrors = hasPermission;
      });
  }

  #getMessageThatMatches = (patterns?: string[], errorCode?: string, errorMessage?: string) => {
    if (!patterns) {
      return;
    }

    const matchMessage = patterns.find((pattern) => {
      if (pattern === errorMessage) {
        return true;
      }

      return errorMessage?.includes(pattern);
    });

    if (matchMessage) {
      return matchMessage;
    }

    const matchCode = patterns.find((pattern) => pattern === errorCode);

    if (matchCode) {
      return matchCode;
    }

    return patterns.find((pattern) => pattern === '*');
  };

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const contextV2 = request.context.get(ERRORS_CATCHER_CONFIG);

    return next.handle(request.clone()).pipe(
      catchError((error) => {
        if (request.context.get(redirect404) && error.status === +ErrorHandlingRoutes.NOT_FOUND) {
          this.errorHandlingService.redirectToErrorPage();
        }
        if (request.context.get(redirectNotPermitted403) && error.status === +ErrorHandlingRoutes.NOT_PERMITTED) {
          this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_PERMITTED);
        }
        if (
          request.context.get(authenticate401) &&
          error.status === +ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS &&
          error.error.data === ErrorHandlingMessages.REQUIRED_2FA
        ) {
          this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_AUTHORIZED_PAGE);
        }

        if (error.status === +ErrorHandlingRoutes.INTERNAL_SERVER_ERROR) {
          this.errorHandlingService.triggerErrorToast({
            id: 'internal-server-error',
            additionalMessage: 'common.errors.general',
            message: 'common.errors.exception_types.internal_server_error',
          });
          return throwError(error);
        }

        if (contextV2) {
          const patterns = Object.keys(contextV2.messagesMap);
          const errorMessage = error.error?.data;
          const errorCode = error.error?.error;

          const matched = this.#getMessageThatMatches(patterns, errorCode, errorMessage);

          if (!matched) {
            return throwError(error);
          }

          const message = contextV2.messagesMap[matched];

          if (!message) {
            return throwError(error);
          }

          let additionalMessage;
          if (this.showErrors) {
            additionalMessage = error?.error?.data ?? String(error) ?? 'Unknown error';
          } else {
            additionalMessage = 'common.errors.exception_types.' + error.error.type;
          }

          this.errorHandlingService.triggerErrorToast({
            id: contextV2.toastId,
            additionalMessage,
            message,
          });
        }

        return throwError(error);
      }),
    );
  }
}
