<vdms-hq-ui-dialog-wrapper>
  <ng-container title>
    <h1>Export transactions to csv</h1>
  </ng-container>
  <ng-container content>
    <ng-container [formGroup]="form">
      <vdms-hq-form-input-date-range formControlName="range"></vdms-hq-form-input-date-range>
    </ng-container>
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      [disabled]="form.invalid || processing()"
      [loading]="processing()"
      (click)="export()"
      color="primary"
      >{{ 'common.global.send_to_email' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
