<div class="multi-data-container" [sharedE2eId]="'license-packages-wrapper'">
  <vdms-hq-ui-data-presentation-header
    (action)="customActionHandler($event)"
    [actions]="headerActions$ | async"
    [title]="'common.license_package.table.title' | translate"
    [counterIndicator]="licensePackagesResultsMultiDs.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-multiple-data-presentation
    *ngIf="licensePackageConfig$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="licensePackagesResultsMultiDs"
    (action)="customActionHandler($event)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="licensePackagesResultsMultiDs.filters"
      [fieldsConfig]="filtersConfig$ | async"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
