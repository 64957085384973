<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'pages.fields.create_field' | translate }}</h1>
  <div class="fit-inputs dialog-content" content>
    <form [formGroup]="createSelectOptionForm" class="fit-inputs metadata-wrapper">
      <vdms-hq-ui-form-input-text
        formControlName="label"
        [label]="'pages.fields.columns.label' | translate"
      ></vdms-hq-ui-form-input-text>

      <vdms-hq-autocomplete
        formControlName="type_name"
        [label]="'pages.fields.columns.type' | translate"
        [autocompleteList]="typeSelectOptions"
      ></vdms-hq-autocomplete>
    </form>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="cancel()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button [disabled]="!createSelectOptionForm.valid" (click)="save()" color="primary"
      >{{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
