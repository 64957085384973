<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <vdms-hq-ui-dialog-wrapper [loading]="isLoading">
    <div title>
      <span class="title">{{ 'pages.edl.label' | translate }}</span>
    </div>
    <div class="dialog-content fit-inputs grid grid-gap-y" content>
      <vdms-hq-ui-form-input-select
        [selectOptions]="(types$ | async) ?? []"
        [label]="'pages.edl.type_placeholder' | translate"
        formControlName="type"
        [loading]="isLoading"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-form-input-timecode
        formControlName="tcIn"
        [framerate]="framerate"
        [label]="'pages.edl.tc_in' | translate"
      >
      </vdms-hq-form-input-timecode>

      <vdms-hq-form-input-timecode
        formControlName="tcOut"
        [framerate]="framerate"
        [label]="'pages.edl.tc_out' | translate"
      >
      </vdms-hq-form-input-timecode>

      <vdms-hq-ui-form-input-text-area formControlName="note" [label]="'pages.edl.note' | translate">
      </vdms-hq-ui-form-input-text-area>
    </div>
    <ng-container footer>
      <vdms-hq-ui-button (click)="onNoClick()" color="secondary">{{
        'common.global.cancel' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button [disabled]="form.invalid" (click)="onSubmit()" color="primary"
        >{{ (data.isEdit ? 'common.global.edit' : 'common.global.create') | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-dialog-wrapper>
</form>
