import { LoggingInfo, LoggingInfoEntire, modelContext, VideoAnalysisResultsResponse } from '@vdms-hq/api-contract';
import { Timecode } from '@vdms-hq/timecode';
import { PlayerMetadataItemLocalDB } from '../metadata-list.model';

const emptyItem = 'N/A';

export const fromLogging = (item: LoggingInfoEntire, nextIndex = 0): VideoAnalysisResultsResponse => {
  const content = item.data?.note || emptyItem;

  return {
    id: nextIndex,
    start: item.start,
    end: item.end,
    type: modelContext.LOGGING,
    confidence: 100,
    data: {
      content,
      loggingType: item.data?.content,
      loggingUuid: item.uuid,
    },
  };
};

export const fromLoggingInfo = (item: LoggingInfo, nextIndex = 0): Partial<PlayerMetadataItemLocalDB> => {
  const content = item.note || emptyItem;

  return {
    id: nextIndex,
    loggingType: item.logging_type.name,
    loggingUuid: item.uuid,
    start: (Timecode.fromTimecode(item.timecode_in)?.countSeconds() ?? 0) * 1000,
    end: (Timecode.fromTimecode(item.timecode_out)?.countSeconds() ?? 0) * 1000,
    content: content,
  };
};
