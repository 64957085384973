<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'common.license_agreement.modal.title' | translate }}</h1>

  <div content class="material-custom-field checkbox-outer">
    <vdms-hq-license-content *ngIf="additionalInfo"></vdms-hq-license-content>

    <mat-checkbox [(ngModel)]="accepted" *ngIf="!additionalInfo">
      <div>
        {{ 'common.license_agreement.modal.checkbox_label' | translate }}
        <span [class.link]="!additionalInfo" (click)="$event.preventDefault(); showAdditional()">
          {{ 'common.license_agreement.modal.checkbox_link' | translate }}
        </span>
      </div>
    </mat-checkbox>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="decline()" color="secondary">{{
      'common.global.decline' | translate
    }}</vdms-hq-ui-button>

    <div [matTooltip]="'common.license_agreement.modal.warning' | translate" [matTooltipDisabled]="accepted">
      <vdms-hq-ui-button (click)="onSubmit()" [disabled]="!accepted" color="primary">{{
        (additionalInfo ? 'common.global.accept' : 'common.global.continue') | translate
      }}</vdms-hq-ui-button>
    </div>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
