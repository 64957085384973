import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionContextLess, CounterIndicator, TranslatableName } from '../logic/common-config';
import { TranslateModule } from '@ngx-translate/core';
import { ActionStatePipe } from '../pipes/action-state.pipe';
import { BreadCrumb, UILayoutModule } from '../../ui-layout';
import { UIButtonModule } from '../../ui-button';
import { ThemeSwitcherService } from '@vdms-hq/theming';
import { SimpleDetailsList, UISimpleDetailsListModule } from '../../ui-simple-details-list';
import { E2eIdDirective } from '@vdms-hq/shared';

interface Pill {
  text: string;
  color: string;
}

@Component({
  selector: 'vdms-hq-ui-data-presentation-header',
  templateUrl: './data-presentation-header.component.html',
  styleUrls: ['./data-presentation-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    UIButtonModule,
    ActionStatePipe,
    UISimpleDetailsListModule,
    E2eIdDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPresentationHeaderComponent {
  /** <b>Title of the header</b> - should be used with brackets in HTML, to avoid creating HTML default tooltip<br>
   * <b>Example:</b><br>
   * <vdms-hq-ui-data-presentation-header <b>[title]="'title'"</b>></vdms-hq-ui-data-presentation-header> */
  @Input() title?: TranslatableName;
  @Input() counterIndicator?: CounterIndicator;
  @Input() actions: ActionContextLess[] | null = [];
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() listConfig: SimpleDetailsList[] = [];
  @Input() subtitle?: TranslatableName;
  @Input() pill?: Pill;
  @Input() size: 'default' | 'large' = 'default';
  @Output() action = new EventEmitter<{ key: string }>();

  constructor(public themeSwitcherService: ThemeSwitcherService) {}
  emitAction($event: Event, action: ActionContextLess) {
    if (action?.disabled) {
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();
    this.action.emit({ key: action.key });
  }
}
