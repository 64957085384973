import { ChangeDetectionStrategy, Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchBarVisibilityService } from '../../logic/search-bar-visibility.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleAiQueryInputComponent } from '../simple-ai-query-input/simple-ai-query-input.component';
import { SearchAutocompleteComponent } from '../search-autocomplete/search-autocomplete.component';
import { SearchResultsConfigFieldsService } from '../../../logic/services/search-results-config-fields.service';
import { Observable, map, take } from 'rxjs';
import { FilterDefinitionModel, FilterType, SelectOption } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-browse-search-hero',
  templateUrl: './search-hero.component.html',
  styleUrls: ['./search-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, SimpleAiQueryInputComponent, SearchAutocompleteComponent],
  providers: [],
})
export class SearchHeroComponent implements OnInit, OnDestroy {
  searchResultsConfigService = inject(SearchResultsConfigFieldsService);
  searchBarVisibility = inject(SearchBarVisibilityService);

  @Input() header = 'pages.home.search.hero.header';
  @Input() subheader = 'pages.home.search.hero.subheader';
  public searchWithAI = false;

  availableFilters$: Observable<SelectOption<string>[]> = this.searchResultsConfigService.filters$.pipe(
    take(1),
    map((config) => {
      return config.filter((filter) => !!filter) as FilterDefinitionModel[];
    }),
    map((config: FilterDefinitionModel[]) => {
      return [
        {
          key: 'keyword',
          label: 'Keyword',
        },
        ...config
          .filter((filter) =>
            [FilterType.TEXT, FilterType.TEXT_AUTOCOMPLETE, FilterType.CHIPS].includes(filter.filters.type),
          )
          .filter((filter) => ['tags', 'transcriptions', 'uuid', 'originalFilename'].includes(filter.id))
          .map((filter) => ({
            key: filter.id,
            label: filter.label,
          })),
      ];
    }),
  );

  ngOnDestroy(): void {
    this.searchBarVisibility.show();
  }

  ngOnInit(): void {
    this.searchBarVisibility.hide();
  }

  @HostListener('window:keydown.control.a', ['$event'])
  useAISearch(event: KeyboardEvent) {
    event.preventDefault();
    this.header = !this.searchWithAI ? 'pages.home.search.ai_hero.header' : 'pages.home.search.hero.header';
    this.subheader = !this.searchWithAI ? 'pages.home.search.ai_hero.subheader' : 'pages.home.search.hero.subheader';
    this.searchWithAI = !this.searchWithAI;
  }
}
