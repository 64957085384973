import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsConfigService } from '@vdms-hq/fields';
import { FieldRelationsService, FormBuilderService } from '@vdms-hq/dynamic-form';
import { AssetViewConfig, Container, FieldConfig, isFormField } from '@vdms-hq/firebase-contract';
import { InputDefinitionModel } from '@vdms-hq/shared';
import { take, withLatestFrom } from 'rxjs/operators';
import { transformLegacyConfigToNew } from '../../logic/transformation/new-player-transformer';

export interface BatchActionDialogInput {
  assetsCounter: number;
  batchConfig: string;
}

export interface BatchActionDialogOutput {
  data: Record<string, unknown> | undefined;
}

@Component({
  selector: 'vdms-hq-batch-action-dialog',
  templateUrl: './batch-action-dialog.component.html',
  styleUrls: ['./batch-action-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchActionDialogComponent implements OnInit, OnDestroy {
  containers: Container[] = [];
  missingConfig = false;
  #enabledDefinitions!: InputDefinitionModel[];
  #batchConfig!: AssetViewConfig;

  get batchConfig() {
    return this.#batchConfig;
  }

  constructor(
    public dialogRef: MatDialogRef<BatchActionDialogComponent, BatchActionDialogOutput>,
    private activatedClientService: ActivatedClientService,
    public formBuilder: FormBuilderService,
    private fieldRelation: FieldRelationsService,
    private configService: FieldsConfigService,
    @Inject(MAT_DIALOG_DATA) public data: BatchActionDialogInput,
  ) {}

  save() {
    if (this.formBuilder.formGroup?.invalid) {
      this.formBuilder.formGroup.markAsTouched();
      return;
    }

    const data = this.formBuilder.modifiedValue;
    this.dialogRef.close({ data });
  }

  ngOnInit() {
    this.activatedClientService.clientDefinite$
      .pipe(take(1), withLatestFrom(this.configService.inputDefinitions$))
      .subscribe(([client, definitions]) => {
        const batchConfig = transformLegacyConfigToNew(
          client.assetView[this.data.batchConfig] || client.assetView['batch_update'],
        );
        if (!batchConfig) {
          this.missingConfig = true;
          return;
        }

        const enabledFields = (batchConfig.elements.filter(isFormField) as FieldConfig[]).map(
          (field: FieldConfig) => field.definition,
        );

        this.#batchConfig = batchConfig;
        this.#enabledDefinitions = definitions.filter((definition) => enabledFields.includes(definition.id));
        this.formBuilder.set(this.#enabledDefinitions);
        this.formBuilder.unlock();
        this.formBuilder.formGroup?.disable();
        this.containers = batchConfig.containers ?? [];
      });
  }

  ngOnDestroy() {
    this.formBuilder.unset(false);
  }

  close() {
    this.dialogRef.close();
  }

  clear() {
    this.formBuilder.reset();
    this.fieldRelation.reset();
  }
}
