import { Injectable } from '@angular/core';
import { ThemeSwitcherService } from './theme-switcher.service';
import { Observable } from 'rxjs';
import { Theme, ThemeInput } from '../theme.model';

/**
 * @description LayoutService is a factory service which expose methods to public api.
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  toggleTheme = (): void => this.switcherService.toggleTheme();
  currentTheme = (): Observable<Theme.dark | Theme.white> => this.switcherService.theme$;

  set(config: ThemeInput) {
    if (config.theme) {
      this.switcherService.set(config.theme);
    }

    if (config.cssVars) {
      Object.entries(config.cssVars).forEach(([name, value]) => {
        if (name && value) {
          document.body.style.setProperty(`--${name}`, value);
        }
      });
    }
  }

  constructor(private switcherService: ThemeSwitcherService) {}
}
