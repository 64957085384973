import { InjectionToken } from '@angular/core';

export interface ProvidersSettings {
  microsoft: boolean;
  google: boolean;
  okta: boolean;
}

export interface LoginPageConfig {
  app: 'vida' | 'launchpad' | 'workflow' | 'storefront' | 'vida-premiere-panel' | 'connect2';
  useBrandLogin: boolean;
  providers: ProvidersSettings;
  /**
   * when true vida email template is used, (user/login endpoint)
   */
  useCustomPasswordlessEmail?: boolean;
}

export const LOGIN_PAGE_CONFIG: InjectionToken<LoginPageConfig> = new InjectionToken<LoginPageConfig>(
  'LOGIN_PAGE_CONFIG_TOKEN',
);
