<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <vdms-hq-ui-dialog-wrapper [loading]="isLoading">
    <div title>
      <span class="title">{{ 'pages.metadata_list.edit.title' | translate }}</span>
    </div>
    <div class="dialog-content fit-inputs grid grid-gap-y" content>
      <vdms-hq-form-input-timecode
        formControlName="tcIn"
        [framerate]="framerate"
        [label]="'pages.metadata_list.edit.tc_in' | translate"
      >
      </vdms-hq-form-input-timecode>

      <vdms-hq-form-input-timecode
        formControlName="tcOut"
        [framerate]="framerate"
        [label]="'pages.metadata_list.edit.tc_out' | translate"
      >
      </vdms-hq-form-input-timecode>

      <vdms-hq-ui-form-input-text-area
        formControlName="content"
        [label]="'pages.metadata_list.edit.content' | translate"
      >
      </vdms-hq-ui-form-input-text-area>
    </div>
    <ng-container footer>
      <vdms-hq-ui-button (click)="onNoClick()" color="secondary"
        >{{ 'common.global.cancel' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button [disabled]="form.invalid" (click)="onSubmit()" color="primary"
        >{{ 'common.global.confirm' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-dialog-wrapper>
</form>
