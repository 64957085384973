<div class="multi-data-container" *ngIf="configuration$ | async; let configuration">
  <vdms-hq-ui-data-presentation-header
    [title]="title | translate"
    [counterIndicator]="dataSource.total$ | async"
    [actions]="headerActions"
    (action)="handleHeaderAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    [configuration]="configuration"
    [dataSource]="dataSource"
    (action)="emitAction($event)"
    (changeDefaultView)="changeDefaultView.emit($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filtersForm.filters"
      [fieldsConfig]="filtersConfig"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>

  <vdms-hq-ui-actions-footer class="action-bar" *ngIf="(dataSource.selection.total$ | async) > 0">
    <ng-container left>
      <vdms-hq-ui-button color="secondary" (click)="dataSource.selection.clear()">
        {{ 'common.global.deselect_all' | translate }}
      </vdms-hq-ui-button>
    </ng-container>

    <ng-container center *ngIf="dataSource.selection.total$; let total">
      <vdms-hq-ui-button>
        {{ 'common.global.selected' | translate: { selected: total | async } }}
      </vdms-hq-ui-button>
    </ng-container>
    <ng-container right>
      <vdms-hq-ui-button
        color="secondary"
        *requiresPermissions="[Permission.MANAGE_ORDER_DISCOUNTS]"
        (click)="action.emit({ key: OrderActionsProperties.CHANGE_DISCOUNT })"
      >
        {{ 'common.orders.change_discount.change' | translate }}
      </vdms-hq-ui-button>
    </ng-container>

    <ng-container right>
      <vdms-hq-ui-button
        color="primary"
        *requiresPermissions="[Permission.GENERATE_ORDER_DELIVERY_CONFIRMATION]"
        (click)="action.emit({ key: OrderActionsProperties.DOWNLOAD_MANY_DELIVERY_CONFIRMATION })"
        [disabled]="(downloadManyDisabled$ | async) ?? true"
        [loading]="(downloadManyLoading$ | async) ?? false"
      >
        {{ 'orders.download.delivery_confirmations' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-actions-footer>
</div>
