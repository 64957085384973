<div class="policies-settings-wrapper">
  <div class="user-selector">
    <vdms-hq-ui-form-input-select
      class="user-selector__select"
      #userSelect
      [label]="'client-user-admin.copy.title' | translate"
      [selectOptions]="(dataSource.usersSelectOptions$ | async) ?? []"
      [formControl]="dataSource.templateUser"
      [loading]="!!(dataSource.isLoading$ | async)"
      (inputChange)="dataSource.searchUsers($event)"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-button
      [disabled]="dataSource.templateUser.invalid"
      [loading]="!!(dataSource.isLoading$ | async)"
      class="user-selector__button"
      (click)="copyPoliciesFrom(userSelect.innerFormControl.value)"
      color="primary"
    >
      {{ 'client-user-admin.copy.apply' | translate }}
    </vdms-hq-ui-button>
  </div>
  <div class="policies-filter">
    <vdms-hq-ui-form-input-text
      [suffixConfig]="{ type: 'primary-transparent', text: 'Search', onFocus: false }"
      [fallbackSuffixIcon]="'search'"
      placeholder="Search policy name"
      [formControl]="dataSource.filter"
    ></vdms-hq-ui-form-input-text>
  </div>
  <div class="policies-list">
    <h3 class="title">Policies</h3>
    <div *ngIf="dataSource.policiesList$ | async; let policies">
      <vdms-hq-ui-form-checkbox-list
        [columns]="1"
        [formControl]="dataSource.userPolicies"
        [available]="(dataSource.policiesOptions$ | async) ?? []"
      >
      </vdms-hq-ui-form-checkbox-list>
    </div>
  </div>

  <div class="effective-permissions-list">
    <h3 class="title">
      Enabled permissions <span>({{ dataSource.permissionsListTotal$ | async }})</span>
    </h3>
    <ul class="permissions" *ngFor="let permissionsSection of dataSource.permissionsList$ | async | keyvalue">
      <div class="permissions__section">
        <p class="permissions__section__label">{{ permissionsSection.key }}</p>
        <ng-container *ngFor="let permission of permissionsSection.value">
          <li class="permissions__section__item--disabled mat-body-2">
            {{ permission.name }}
          </li>
        </ng-container>
      </div>
    </ul>
  </div>
</div>
