<vdms-hq-ui-results-wrapper
  [dataSource]="dataSource"
  [breadcrumbs]="[{ name: ('common.mandatory-fields.title' | translate) }]"
  [configuration]="headerActions$ | async"
  (headerAction)="handleAction($event)"
>
  @if (dataSource.isLoading$ | async) {
    <vdms-hq-ui-loader [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
  }

  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="dataSource"
    [configuration]="viewConfig$ | async"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
