import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { Subscription } from 'rxjs';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

type OuterValue = SelectOptionKey | null | undefined | Array<SelectOptionKey>;
type InnerValue = SelectOptionKey | null;

@Component({
  selector: 'vdms-hq-ui-form-input-select',
  templateUrl: './form-input-select.component.html',
  styleUrls: ['form-input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputSelectComponent),
    },
  ],
})
export class FormInputSelectComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements AfterViewChecked
{
  readonly ITEM_SIZE_PX = 48;
  innerFormControl = new UntypedFormControl(null);
  @Input() requiredOptionsLengthToDisplayFilter = 6;
  @Input() requiredOptionsLengthToDisplayFooter = 2;
  @Input() enableSelectAll = false;
  @Input() enableDeselectAll = false;
  @Input() enableClear = false;
  @Input() selectOptions: SelectOption[] = [];
  @Input() multiple = false;
  @Input() loading = false;
  @Input() virtualScroll = false;
  @Input() isSortedByLabel = false;
  @Input() disabledMsg = '';

  @Input() autocompleteOptions: SelectOption[] = [];
  @Output() inputChange = new EventEmitter<string>();
  @ViewChild('selector') selector?: MatSelect;
  @ViewChild('input') filterInput?: ElementRef<HTMLFormElement>;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort!: CdkVirtualScrollViewport;
  filterValue = '';
  #filterVisible = false;
  private changeListener?: Subscription;

  #selectedMap = new Set<SelectOptionKey>();

  trackBy = (_: any, item: { [key: string]: any }) => item['key'];

  filteredElementsLength = (filteredValues: Array<SelectOption>) => {
    this.#virtualScrollSpacerHandler(filteredValues.filter((el) => !el?.hidden)?.length * this.ITEM_SIZE_PX);
    return filteredValues.filter((el) => !el?.hidden)?.length;
  };

  override ngAfterViewChecked(): void {
    super.ngAfterViewChecked();
    if (!this.filterInput) {
      return;
    }
    const isVisible = this.filterInput.nativeElement.offsetParent !== null;
    if (this.#filterVisible !== isVisible) {
      this.filterValue = '';

      this.#filterVisible = isVisible;
      setTimeout(() => {
        this.#filterVisible ? this.filterInput?.nativeElement.focus() : this.filterInput?.nativeElement.blur();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  get selectedLabels(): string[] | null {
    if (!this.selectOptions) {
      return null;
    }

    return this.selectOptions
      .filter((item: SelectOption) => {
        if (this.multiple) {
          return this.innerFormControl.value?.includes(item.key);
        }

        return this.innerFormControl.value === item.key;
      })
      .map((items) => items.label);
  }

  get selectedAndVirtualScrollEnabled(): boolean {
    return !!this.selectedLabels?.length && this.virtualScroll;
  }

  deselectAll(): void {
    this.#selectedMap = new Set();
    this.propagateToOuter([]);
  }

  clear() {
    this.innerFormControl.setValue(null);
    this.propagateToOuter(null);
  }

  selectAll(): void {
    const allowedOptions = this.selectOptions.filter((option) => !option.disabled);
    this.#selectedMap = new Set(allowedOptions.map((option) => option.key));
    this.propagateToOuter([]);
  }

  emitInputChange() {
    this.inputChange.emit(this.filterValue);
  }

  override writeValue(value: string | null | undefined): void {
    this.outerValue = value;
    const inner = this.transformOuterToInner(value);
    if (this.multiple) {
      this.innerFormControl.setValue(inner && !Array.isArray(inner) ? [inner] : inner);
      this.#selectedMap = new Set(this.innerFormControl.value);
      return;
    }
    if (Array.isArray(inner)) {
      this.innerFormControl.setValue(inner[0]);
      return;
    }
    this.innerFormControl.setValue(inner);
  }

  override propagateToOuter = (nextValue: OuterValue) => {
    if (this.multiple) {
      if (Array.isArray(nextValue)) {
        nextValue = Array.from(this.#selectedMap.values());
      }
    }
    this.innerFormControl.setValue(nextValue);
    this.propagateChange(nextValue);
    this.valueChange.emit(nextValue);
  };

  onSelectionChange($event: MatOptionSelectionChange<SelectOptionKey>) {
    if (!$event.isUserInput) {
      return;
    }
    const { source } = $event;
    if (this.#selectedMap.has(source.value)) {
      this.#selectedMap.delete(source.value);
    } else {
      this.#selectedMap.add(source.value);
    }
  }

  #virtualScrollSpacerHandler(heightValue: number) {
    const element =
      this.cdkVirtualScrollViewPort?.elementRef?.nativeElement.querySelector('.cdk-virtual-scroll-spacer');
    if (!element) {
      return;
    }

    element.setAttribute('style', `height: ${heightValue}px`);
  }
}
