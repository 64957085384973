import { inject, Injectable } from '@angular/core';
import { CredentialsTypeOptions } from '../logic/delivery-destination-options';
import {
  CredentialInterface,
  CredentialsApiService,
  CredentialTypeEnum,
  DestinationAccessMethodEnum,
} from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeliveryDestinationCredentialsService {
  credentialTypeOptions = CredentialsTypeOptions.filter(
    ({ key }) =>
      key === CredentialTypeEnum.AWS ||
      key === CredentialTypeEnum.ASPERA_HSTS ||
      key === CredentialTypeEnum.ASPERA_AOC ||
      key === CredentialTypeEnum.ASPERA_SHARES ||
      key === CredentialTypeEnum.ASPERA_YOUTUBE,
  );
  credentialsAWS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsAsperaHSTS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsAsperaAOC: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsAsperaSHARES: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsAsperaYOUTUBE: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsFTPS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAWS: SelectOption[] = [];
  credentialsSelectorSourceAsperaHSTS: SelectOption[] = [];
  credentialsSelectorSourceAsperaAOC: SelectOption[] = [];
  credentialsSelectorSourceAsperaSHARES: SelectOption[] = [];
  credentialsSelectorSourceAsperaYOUTUBE: SelectOption[] = [];
  credentialsSelectorSourceFTPS: SelectOption[] = [];
  credentialsSelectorSourceYoutube: SelectOption[] = [];
  credentialsYoutube: CredentialInterface<CredentialTypeEnum>[] = [];

  isLoading$ = new BehaviorSubject<boolean>(false);

  credentialsService = inject(CredentialsApiService);

  getCredentialsByType(type: DestinationAccessMethodEnum | CredentialTypeEnum) {
    if (type === DestinationAccessMethodEnum.VIDA) {
      return;
    }
    if (type === DestinationAccessMethodEnum.INGEST_IN_VIDA) {
      return;
    }
    if (type === DestinationAccessMethodEnum.AWS && this.credentialsAWS.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.ASPERA_HSTS && this.credentialsAsperaHSTS.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.ASPERA_AOC && this.credentialsAsperaAOC.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.ASPERA_SHARES && this.credentialsSelectorSourceAsperaSHARES.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.ASPERA_YOUTUBE && this.credentialsAsperaYOUTUBE.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.FTPS && this.credentialsFTPS.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.YOUTUBE && this.credentialsYoutube.length > 0) {
      return;
    }

    this.isLoading$.next(true);

    this.credentialsService
      .getByType(type.toUpperCase() as CredentialTypeEnum)
      .pipe(shareReplay(1))
      .subscribe((credentials) => {
        switch (type) {
          case CredentialTypeEnum.AWS:
          case DestinationAccessMethodEnum.AWS:
            this.credentialsAWS = credentials;
            this.credentialsSelectorSourceAWS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.ASPERA_HSTS:
          case DestinationAccessMethodEnum.ASPERA_HSTS:
            this.credentialsAsperaHSTS = credentials;
            this.credentialsSelectorSourceAsperaHSTS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.ASPERA_YOUTUBE:
          case DestinationAccessMethodEnum.ASPERA_YOUTUBE:
            this.credentialsAsperaHSTS = credentials;
            this.credentialsSelectorSourceAsperaYOUTUBE = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.ASPERA_AOC:
          case DestinationAccessMethodEnum.ASPERA_AOC:
            this.credentialsAsperaAOC = credentials;
            this.credentialsSelectorSourceAsperaAOC = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.ASPERA_SHARES:
          case DestinationAccessMethodEnum.ASPERA_SHARES:
            this.credentialsAsperaSHARES = credentials;
            this.credentialsSelectorSourceAsperaSHARES = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.FTPS:
          case DestinationAccessMethodEnum.FTPS:
            this.credentialsFTPS = credentials;
            this.credentialsSelectorSourceFTPS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case DestinationAccessMethodEnum.YOUTUBE:
          case CredentialTypeEnum.YOUTUBE:
            this.credentialsYoutube = credentials;
            this.credentialsSelectorSourceYoutube = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
        }
      });
  }
}
