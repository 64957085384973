import { Pipe, PipeTransform } from '@angular/core';

import { Parse } from '../utils/parse';
import { isArray, isUndefined, toArray } from '../utils/utils';

@Pipe({ name: 'groupBy', standalone: true })
export class GroupByPipe implements PipeTransform {
  private $parse = Parse();

  transform(collection: any, property: string): { [key: string]: Array<any> } {
    if (!isArray(collection)) {
      collection = toArray(collection);
    }

    const result: { [key: string]: Array<any> } = {},
      getter = this.$parse(property);
    collection.forEach((elm: any) => {
      const prop = getter(elm);
      if (isUndefined(result[prop])) {
        result[prop] = [];
      }
      result[prop].push(elm);
    });
    return result;
  }
}
