<vdms-hq-ui-dialog-wrapper>
  <ng-container content>
    <div class="aspera-message">
      <div class="aspera-message__icon">
        <img src="/assets/common/logos/aspera.svg" alt="Aspera Connect" />
      </div>

      <div class="aspera-message__content">
        <p>Aspera Connect is not detected!</p>
        <p>Need some help getting started with Aspera Connect?</p>
      </div>
      <div class="aspera-button__container">
        <a href="https://vida-support.freshdesk.com/a/solutions/articles/103000287077" target="_blank">Click here</a>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">CLOSE</vdms-hq-ui-button>
    <a href="https://test-connect.ibmaspera.com/" target="_blank">
      <vdms-hq-ui-button color="primary">START DIAGNOSTICS</vdms-hq-ui-button>
    </a>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
