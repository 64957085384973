<div class="multi-data-container" [sharedE2eId]="'rights-contracts-wrapper'">
  <vdms-hq-ui-data-presentation-header
    [actions]="headerActions$ | async"
    (action)="customActionHandler($event)"
    [title]="'common.rights_contracts.table.title' | translate"
    [counterIndicator]="rightsContractsResultsMultiDs.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="rightsContractsConfig$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="rightsContractsResultsMultiDs"
    (action)="customActionHandler($event)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="rightsContractsResultsMultiDs.filters"
      [fieldsConfig]="filtersConfig$ | async"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
