<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="handleHeaderAction($castToOrderActionsProperties($event))"
    [actions]="headerActions$ | async"
    [breadcrumbs]="[
      { name: 'common.orders.shared_packs.title', path: '../' },
      { name: (currentOrderSubject$ | async)?.title ?? '' },
    ]"
    [title]="'common.orders.shared_packs.title' | translate"
    [counterIndicator]="(dataSource.total$ | async) ?? 0"
    [subtitle]="(isExpiredSubtitle$ | async) ?? ''"
    [listConfig]="(currentOrderSubject$ | async)?.metadata ?? []"
  >
  </vdms-hq-ui-data-presentation-header>

  <ng-container *ngIf="actions$ | async; let actions; else: loading">
    <ng-container [ngTemplateOutlet]="warmingUp"></ng-container>

    <vdms-hq-asset-results-2
      [dataSource]="dataSource"
      [actions]="actions"
      [scopeName]="scope"
      [contextActions]="assetResultsMenuConfig2"
      [multiViewKey]="TABLE_TYPE.ASSET_DOWNLOAD"
      (action)="handleAction($event)"
    >
      <vdms-hq-dynamic-form-group-renderer
        filters
        *ngIf="filterService.config as fieldsConfig"
        [form]="filterService.form"
        [fieldsConfig]="fieldsConfig"
        [isLoading]="(dataSource.isLoading$ | async) ?? false"
      ></vdms-hq-dynamic-form-group-renderer>
    </vdms-hq-asset-results-2>
  </ng-container>

  <vdms-hq-shared-pack-assets-footer
    (actions)="handleBatchAction($event)"
    [downloadButtonsDisabled]="
      selectionDisabled ||
      (isSharedPackExpired$ | async) ||
      (isDownloadLimitExpired$ | async) ||
      (downloadLimitExpiredAssetsExist$ | async) ||
      (embargoedAssetsSelected$ | async) ||
      (coldAssetsExist$ | async) ||
      ((downloadInProgress$ | async) ?? false)
    "
    [selectionManager]="dataSource.selection"
  >
  </vdms-hq-shared-pack-assets-footer>

  <ng-template #loading>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </ng-template>
</div>

<ng-template #warmingUp>
  <div class="order-warming" *ngIf="canShowWarmUpCouter$ | async; let counter">
    <vdms-hq-ui-empty-results
      type="compact"
      [path]="thermometerImageType$ | async"
      message="Assets are getting warmed up"
    >
      {{ 'ETA: ' }} {{ counter | countdown | async }}
    </vdms-hq-ui-empty-results>
  </div>
</ng-template>
