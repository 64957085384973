import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe, DatePipe } from '@angular/common';
import moment from 'moment';
import { AuthService } from '@vdms-hq/auth';

@Pipe({ name: 'tzDate', standalone: true, pure: true })
export class TzDatePipe implements PipeTransform {
  private readonly datePipe = inject(DatePipe);
  private readonly asyncPipe = inject(AsyncPipe);
  private readonly authService = inject(AuthService);

  timezoneSource$: Observable<string> = this.authService.userAttributesDefinite$.pipe(
    map((attributes) => attributes.preferredTimezone),
  );

  transform(value: Date | string | number, format: string): string | null {
    const dateWithoutTimezone = moment(value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
    return this.asyncPipe.transform(
      this.timezoneSource$.pipe(
        map((timezone: string) => this.datePipe.transform(dateWithoutTimezone, format, timezone)),
      ),
    );
  }
}
