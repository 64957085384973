<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span>{{ 'common.global.batch_update' | translate }}</span>
  </div>
  <div content class="dialog-content dialog-content-bigger">
    <vdms-hq-batch-action-form
      [containers]="containers"
      [batchConfig]="batchConfig"
      [columns]="1"
      [withStateToggle]="true"
    ></vdms-hq-batch-action-form>
    <vdms-hq-ui-empty-results
      *ngIf="missingConfig"
      icon="warning"
      [message]="'pages.assets_list.missing_config' | translate"
    ></vdms-hq-ui-empty-results>
  </div>

  <ng-container footer>
    <div class="buttons-group">
      <vdms-hq-ui-button (click)="close()" color="secondary">Close</vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="clear()" color="warn">Clear</vdms-hq-ui-button>
    </div>
    <vdms-hq-ui-button
      *ngIf="!missingConfig"
      (click)="save()"
      color="primary"
      [disabled]="formBuilder.formGroup!.invalid"
      >Save ({{ data.assetsCounter }})</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
