import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-ui-button-advanced',
  templateUrl: './button-advanced.component.html',
  styleUrls: ['./button-advanced.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage],
})
export class ButtonAdvancedComponent {
  @Input() imgUrl = '';
  @Input() imgAlt?: string;
  @Input() title = 'Title';
  @Input() subtitle = 'SubTitle';
  @Input() selected = false;
  @Input() size: 'default' | 'wide' = 'default';
}
