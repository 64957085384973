<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="handleAction($event)"
    [actions]="headerActions$ | async"
    [title]="'pages.home.menu.cart' | translate"
    [counterIndicator]="dataSource.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-asset-results-2
    [dataSource]="dataSource"
    [actions]="actions$ | async"
    [contextActions]="assetResultsMenuConfig2"
    [scopeName]="scopeName"
    [multiViewKey]="TABLE_TYPE.ASSET_CART"
    (action)="handleAction($event)"
  ></vdms-hq-asset-results-2>
</div>

<vdms-hq-cart-results-actions
  *ngIf="(dataSource.total$ | async) > 0"
  [selectionManager]="dataSource.selection"
  (actions)="handleFooterActions($event)"
></vdms-hq-cart-results-actions>
