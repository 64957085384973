<vdms-hq-ui-dialog-wrapper [sharedE2eId]="'logs-dialog'" size="wide">
  <h1 title>{{ data.title | translate }}</h1>
  <div class="multi-data-container" content>
    <vdms-hq-ui-multiple-data-presentation
      (action)="handleAction($event)"
      [configuration]="viewConfiguration"
      [dataSource]="data.dataSource"
      [drawerVisible]="drawerVisible"
    >
      <vdms-hq-dynamic-form-group-renderer
        [fieldsConfig]="filtersConfig"
        [form]="filters"
        [hasLoader]="true"
        filters
      ></vdms-hq-dynamic-form-group-renderer>
      <vdms-hq-json-viewer [logsDataSource]="data.dataSource" drawer-content></vdms-hq-json-viewer>
    </vdms-hq-ui-multiple-data-presentation>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="closeDialog()" [sharedE2eId]="'logs-dialog-cancel'" color="secondary">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
