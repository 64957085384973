<div class="logo-wrapper" [routerLink]="['/home']" [ngSwitch]="selectedClientId$ | async">
  <img
    src="assets/common/logos/bbcmg_getty.svg"
    alt="BBC Motion Gallery logo"
    class="logo"
    *ngSwitchCase="'4dcb23ae-7efc-4e9b-b5e9-41061dd898dd'"
  />
  <img
    src="assets/common/logos/bbcmg_getty.svg"
    alt="BBC Motion Gallery logo"
    class="logo"
    *ngSwitchCase="'6ec24f81-55bc-4432-8216-142652924bbc'"
  />
  <img
    src="assets/common/logos/bbcmg_getty.svg"
    alt="BBC Motion Gallery logo"
    class="logo"
    *ngSwitchCase="'5e260134-4320-4995-b259-d2f6f9ceeb20'"
  />
  <img
    src="assets/BBCS_logo.svg"
    alt="BBC Studios logo"
    class="logo"
    *ngSwitchCase="'b3b3929f-5298-4620-b1d6-9f0415941651'"
  />
  <img
    src="assets/BBCS_logo.svg"
    alt="BBC Studios logo"
    class="logo"
    *ngSwitchCase="'58925d6a-308f-401b-b856-a53817a3a6c6'"
  />
  <img
    src="assets/lionsgate.png"
    alt="Lionsgate logo"
    class="logo"
    *ngSwitchCase="'8e28c71c-ae41-40b9-9b1e-07274ab2d72c'"
  />
  <img
    src="assets/Netflix_Logo_RGB.png"
    alt="Netflix logo"
    class="logo netflix-logo"
    *ngSwitchCase="'683d0e31-d230-40bb-a929-77104d125431'"
  />
  <img src="assets/vida.svg" alt="VIDA logo" class="logo-vida" *ngSwitchDefault />
</div>
