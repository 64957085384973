import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Destroyable, E2eIdDirective, FilterType, ResourceModel, SelectOption, ValueFormat } from '@vdms-hq/shared';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, of, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightsContractsService, RightsContractStatus } from '@vdms-hq/api-contract';
import { RightsContractsRefreshService } from '../../logic/rights-contracts-refresh.service';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import {
  FormSectionComponent,
  TileSelectableConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RightsContractsAddDsService } from '../../logic/rights-contracts-add-ds.service';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { MatDividerModule } from '@angular/material/divider';
import {
  RightsContractsLicensePackagesDsService,
  LicensePackagesAddData,
} from '../../logic/rights-contracts-license-packages-ds.service';
import { SingleRightsContractDataSource } from '../../logic/single-rights-contract.data-source';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { SelectableWrapperConfig, UiSelectableTilesComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { ViewSettingsService } from '@vdms-hq/view-settings';

@Component({
  selector: 'vdms-hq-rights-contracts-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    FormSectionComponent,
    ReactiveFormsModule,
    UIFormModule,
    MatPaginatorModule,
    DynamicFiltersModule,
    MatDividerModule,
    UIEmptyResultsModule,
    MatTooltipModule,
    UiSelectableTilesComponent,
    E2eIdDirective,
  ],
  templateUrl: './rights-contracts-create-edit-dialog.component.html',
  styles: [],
})
export class RightsContractsCreateEditDialogComponent extends Destroyable() implements OnInit {
  private viewSettingsService = inject(ViewSettingsService);
  public licensedPackagesAddDsService = inject(RightsContractsLicensePackagesDsService);
  private rightsContractsService = inject(RightsContractsService);
  public rightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private rightsContractsAddDsService = inject(RightsContractsAddDsService);
  private rightsContractsActionsService = inject(RightsContractsActionsService);
  private rightsContractsRefreshService = inject(RightsContractsRefreshService);
  private singleRightsContractDataSource = inject(SingleRightsContractDataSource);
  public dialogRef = inject(MatDialogRef<RightsContractsCreateEditDialogComponent>);

  public data: { uuid: string | undefined } = inject(MAT_DIALOG_DATA);

  readyToReleaseControl = new FormControl<boolean>(false);
  form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    contract_id: new FormControl<string>(''),
    start_date: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    end_date: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    partner_uuid: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    licensed_package_uuids: new FormControl<string[]>([], { nonNullable: true }),
    notifications_enabled: new FormControl(false),
    status: new FormControl<RightsContractStatus | null>(null),
    download_limit: new FormControl<number>(0, { validators: Validators.required }),
  });

  selectedLicensePackagesControl = this.form.get('licensed_package_uuids') as FormControl<string[]>;
  baseLicensedPackagesUuids: string[] = [];
  isSaving = false;

  isInitialized$ = new BehaviorSubject(false);

  activatedClient: ActivatedClientService = inject(ActivatedClientService);

  title = '';
  saveButton = '';
  editPackagesButton = '';

  partnersOptions: SelectOption[] = [];

  filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.license_package.table.filter_placeholder',
      resource: [ResourceModel.ASSET_LICENSED_PACKAGE],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-rights-contracts'],
    },
  ];

  licensedPackagesTilesConfig: TileSelectableConfig<LicensePackagesAddData> = {
    label: 'label',
    key: 'key',
    icon: 'copyright',
    metadata: [
      {
        label: 'Status',
        valuePath: 'status',
        viewFormat: {
          pills: {
            active: 'done',
            draft: '',
          },
        },
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
    ],
  };

  licensedPackagesSelectableConfig: SelectableWrapperConfig<LicensePackagesAddData> = {
    fieldsConfig: this.filtersConfig,
    tileConfig: this.licensedPackagesTilesConfig,
    selectedList: {
      title: 'Licensed packages added',
      data: [],
    },
    searchView: {
      placeholder: 'Type licensed package name',
      label: 'Search Licensed Packages',
      showSearch: true,
    },
    datasourceTitle: 'Available license packages',
    loadingText: 'Loading license packages...',
    emptyMessage: 'No license packages found',
  };

  ngOnInit() {
    this.viewSettingsService.preferredSelectableView$.pipe(takeUntil(this.isDestroyed$)).subscribe((view) => {
      this.licensedPackagesSelectableConfig.searchView.showSearch = view === 'search';
    });
    this.licensedPackagesAddDsService.refresh$.next(Date.now());
    this.rightsPartnersAddDsService.allData$
      .pipe(takeUntil(this.isDestroyed$), take(1), withLatestFrom(this.activatedClient.clientDefinite$))
      .subscribe(([partnersData, client]) => {
        this.partnersOptions = partnersData;
        if (this.data.uuid) {
          this.saveButton = 'common.dialogs.rights_contracts.edit.uploading_data';
          this.editPackagesButton = 'common.dialogs.rights_contracts.edit.edit_packages';
          this.setEditMode(this.data.uuid);
        } else {
          this.isInitialized$.next(true);
          this.readyToReleaseControl.setValue(false);
          this.readyToReleaseControl.disable();
          this.title = 'common.dialogs.rights_contracts.create.title';
          this.saveButton = 'common.dialogs.rights_contracts.create.button';
          this.editPackagesButton = 'common.dialogs.rights_contracts.create.add_packages';
          this.form.controls.download_limit.setValue(client.vida?.downloadLimit ?? null);
        }
      });
  }

  get isReadyToRelease() {
    return this.readyToReleaseControl.value && this.readyToReleaseControl.enabled;
  }

  setReadyToRelease() {
    return this.rightsContractsService.setReadyToRelease(this.data.uuid as string).pipe(
      take(1),
      tap({
        next: () => {
          this.rightsContractsActionsService.popToast.UPDATE_SUCCESS();
          this.handleComplete();
        },
        error: () => {
          this.rightsContractsActionsService.popToast.UPDATE_FAILURE();
          this.handleComplete();
        },
      }),
    );
  }

  setEditMode(uuid: string) {
    this.title = 'common.dialogs.rights_contracts.edit.title';

    this.rightsContractsService
      .getOne(uuid)
      .pipe(takeUntil(this.isDestroyed$), take(1))
      .subscribe({
        next: (contract) => {
          this.form.patchValue({
            name: contract.name,
            contract_id: contract.contract_id,
            start_date: contract.start_date,
            end_date: contract.end_date,
            partner_uuid: contract.partner ? contract.partner.uuid : '',
            licensed_package_uuids: contract.licensed_packages
              ? contract.licensed_packages.map((licensed_package) => licensed_package.uuid)
              : [],
            notifications_enabled: contract.notifications_enabled,
            status: contract.status,
            download_limit: contract.download_limit ?? 0,
          });
          contract.status !== RightsContractStatus.COLLECTING_CONTENT &&
            (() => {
              this.readyToReleaseControl.setValue(true);
              this.readyToReleaseControl.disable();
            })();
          this.licensedPackagesSelectableConfig.selectedList.data = contract.licensed_packages ?? [];
          this.baseLicensedPackagesUuids = contract.licensed_packages.map((item) => item.uuid);
          this.saveButton = 'common.dialogs.rights_contracts.edit.button';
          this.isInitialized$.next(true);
        },
        error: (error) => {
          this.rightsContractsActionsService.popToast.GET_ONE_FAILURE();
          throw error;
        },
      });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.isSaving = true;
    if (this.form.invalid) {
      return;
    }

    if (this.data.uuid) {
      const toDetach = this.baseLicensedPackagesUuids.filter(
        (base) => !this.selectedLicensePackagesControl.value.includes(base),
      );
      const toAttach = this.selectedLicensePackagesControl.value.filter(
        (selected) => !this.baseLicensedPackagesUuids.includes(selected),
      );

      const edited = {
        name: this.form.value.name as string,
        start_date: this.form.value.start_date as string,
        end_date: this.form.value.end_date as string,
        contract_id: this.form.value.contract_id as string,
        partner_uuid: this.form.value.partner_uuid as string,
        licensed_package_uuids: this.form.value.licensed_package_uuids as string[],
        licensed_packages_to_attach: toAttach,
        licensed_packages_to_detach: toDetach,
        notifications_enabled: this.form.value.notifications_enabled,
        download_limit: this.form.value.download_limit,
      };
      this.rightsContractsService
        .patch(this.data.uuid, edited)
        .pipe(
          takeUntil(this.isDestroyed$),
          take(1),
          switchMap(() => {
            return this.isReadyToRelease ? this.setReadyToRelease() : of(true);
          }),
        )
        .subscribe({
          next: () => {
            this.rightsContractsActionsService.popToast.UPDATE_SUCCESS();
            this.handleComplete();
          },
          error: (error) => {
            this.rightsContractsActionsService.popToast.UPDATE_FAILURE();
            this.isSaving = false;
            throw error;
          },
        });
    } else {
      const newContract = {
        name: this.form.value.name as string,
        start_date: this.form.value.start_date as string,
        end_date: this.form.value.end_date as string,
        partner_uuid: this.form.value.partner_uuid as string,
        licensed_package_uuids: this.selectedLicensePackagesControl.value as string[],
        notifications_enabled: this.form.value.notifications_enabled,
        contract_id: this.form.value.contract_id as string,
        download_limit: this.form.value.download_limit ?? 0,
      };
      this.rightsContractsService
        .create(newContract)
        .pipe(
          takeUntil(this.isDestroyed$),
          take(1),
          switchMap(() => {
            return this.isReadyToRelease ? this.setReadyToRelease() : of(true);
          }),
        )
        .subscribe({
          next: () => {
            this.rightsPartnersAddDsService.refresh$.next(true);
            this.singleRightsContractDataSource.refresh$.next(true);
            this.rightsContractsActionsService.popToast.CREATE_SUCCESS();
            this.handleComplete();
          },
          error: (error) => {
            this.rightsContractsActionsService.popToast.CREATE_FAILURE();
            this.isSaving = false;
            throw error;
          },
        });
    }
  }

  handleComplete() {
    this.isSaving = false;
    this.rightsContractsRefreshService.refreshRightsContractsList$.next(true);
    this.rightsContractsAddDsService.refresh$.next(true);

    this.closeDialog(true);
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }

  closeDialog(saved = false): void {
    this.licensedPackagesAddDsService.refresh(saved);
    this.dialogRef.close();
  }
}
