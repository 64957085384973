import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ResultDefinitionModel } from '@vdms-hq/shared';
import { PaginationAPIModel as Pagination, PaginationAPIProps as PaginationProps } from '../pagination.model';
import { Observable } from 'rxjs';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiChildResourcePaginatedResponse, ApiEmptyResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { AwsV4Signature } from '../upload/upload';
import {
  CollectionAccessTypes,
  CollectionAssetFilters,
  CollectionCreateUploadRequest,
  CollectionCreateUploadResponse,
  CollectionExportMetadataPost,
  CollectionItemGet,
  CollectionItemsPatch,
  CollectionModelFlat,
  CollectionModelGet,
  CollectionModelPatch,
  CollectionModelPost,
  CollectionsFilters,
  CollectionUploadConfirmationRequest,
  CollectionUploadConfirmationResponse,
  FolderCollectionUploadRequest,
  IdData,
} from './models/collection.model';
import { COLLECTIONS_ENDPOINTS } from './models/collections-enpoints.model';
import { createErrorContext } from '../../error-handler';

@Injectable({ providedIn: 'root' })
export class CollectionsService {
  apiService = inject(ApiService);

  getList = (filters: Omit<CollectionsFilters, 'access_type'>, pagination?: Pagination, collectionType = 'all') => {
    let headers = new HttpHeaders();

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    let url: string;

    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.collections_error' },
      'collections-get',
    );

    switch (collectionType) {
      case 'owned':
        url = `${COLLECTIONS_ENDPOINTS.OWNED}`;
        break;
      case 'shared':
        url = `${COLLECTIONS_ENDPOINTS.SHARED}`;
        break;
      default:
        url = `${COLLECTIONS_ENDPOINTS.COLLECTIONS}`;
    }

    return this.apiService
      .get<ApiChildResourcePaginatedResponse<CollectionModelFlat>>(url, { headers, context })
      .pipe(GetResponsePaginationData);
  };

  getCollectionData(id: string) {
    const headers = new HttpHeaders({
      'Cache-Control': 'max-age=0',
    });

    const context = createErrorContext(
      {
        '*': 'common.notifications.collections.general.collection_error',
        'Invalid group': false,
      },
      'collections-get',
    );

    return this.apiService
      .get<ApiResponse<CollectionModelGet>>(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${id}`, {
        headers,
        context,
      })
      .pipe(GetResponseData);
  }

  getCollectionAccessType(uuids: CollectionModelFlat['uuid'][]): Observable<CollectionAccessTypes[]> {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.access_type_error' },
      'collections-get-access-types',
    );

    return this.apiService
      .post<
        { collection_uuids: CollectionModelFlat['uuid'][] },
        ApiResponse<{ uuid: CollectionModelFlat['uuid']; access_type: CollectionModelFlat['access_type'] }[]>
      >(`${COLLECTIONS_ENDPOINTS.COLLECTIONS}/get-access-types`, { collection_uuids: uuids }, undefined, context)
      .pipe(GetResponseData);
  }

  getAssets = (uuid: string, pagination: Pagination, filters?: Partial<CollectionAssetFilters>) => {
    let headers = new HttpHeaders();
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.get_assets_error' },
      'collections-get-access-types',
    );

    headers = headers.append('Cache-Control', 'max-age=0');
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));
    return this.apiService
      .get<ApiChildResourcePaginatedResponse<CollectionItemGet>>(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/assets`, {
        headers,
        context,
      })
      .pipe(GetResponsePaginationData);
  };

  create = (json: CollectionModelPost) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.create.failure' },
      'create_field_failure',
    );

    return this.apiService.post<CollectionModelPost, ApiResponse<IdData>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}`,
      json,
      undefined,
      context,
    );
  };

  update = (uuid: string, json: CollectionModelPatch) => {
    const context = createErrorContext(
      {
        '*': json.shared_to_team_uuids
          ? 'common.notifications.collections.general.add_to_teams_error'
          : 'common.notifications.collections.update.failure',
      },
      'update_field_failure',
    );

    return this.apiService.patch<CollectionModelPatch, ApiResponse<IdData>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`,
      json,
      undefined,
      context,
    );
  };
  generateThumbnail = (uuid: string) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.generate_thumbnail_error' },
      'generate_thumbnail_failure',
    );
    return this.apiService.patch<unknown, ApiEmptyResponse>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/thumbnail`,
      {},
      undefined,
      context,
    );
  };

  updateAndGetSignature = (uuid: string, json: CollectionModelPatch) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.signature_error' },
      'update_signature_failure',
    );
    return this.apiService.patch<CollectionModelPatch, ApiResponse<AwsV4Signature>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`,
      json,
      undefined,
      context,
    );
  };

  delete = (uuid: string) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.delete.failure' },
      'collection_delete_failure',
    );
    return this.apiService.delete(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`,
      undefined,
      undefined,
      undefined,
      context,
    );
  };

  deleteSubcollection = (uuid: string, json: CollectionModelPatch) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.delete.failure' },
      'collection_delete_failure',
    );
    return this.apiService.patch<CollectionModelPatch, ApiResponse<IdData>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.REMOVE_SUBCOLLECTION}`,
      json,
      undefined,
      context,
    );
  };

  export = (listUuid: string, exportFieldDefs?: ResultDefinitionModel[], pagination?: PaginationProps) => {
    const headers = Pagination.create(pagination).applyToHeaders(
      new HttpHeaders({
        accept: 'text/csv',
        'content-type': 'text/csv',
      }),
    );

    const context = createErrorContext(
      { '*': 'common.notifications.collections.export.failure' },
      'collection_export_failure',
    );
    const fields = exportFieldDefs?.map((column) => column.results2.exportObjectPath as string) ?? [];

    return this.apiService
      .post<
        CollectionExportMetadataPost,
        ApiResponse<[]>
      >(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${listUuid}/${COLLECTIONS_ENDPOINTS.EXPORT}`, { fields }, headers, context)
      .pipe(GetResponseData);
  };

  addItems(uuid: string, items: { items: { asset_uuid?: string; item_uuid?: string; type?: string }[] }) {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.add_assets.failure' },
      'collection_add_items_failure',
    );
    return this.apiService.patch<CollectionItemsPatch, ApiEmptyResponse>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.ADD_ITEMS}`,
      items,
      undefined,
      context,
    );
  }

  addItemsFromCart(uuid: string) {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.add_assets.failure' },
      'collection_add_items_failure',
    );
    return this.apiService.patchNoBody<ApiEmptyResponse>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.ADD_ITEMS_FROM_CART}`,
      undefined,
      context,
    );
  }

  removeItems = (uuid: string, items: string[]) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.remove_asset.failure' },
      'collection_remove_items_failure',
    );

    return this.apiService.delete(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.REMOVE_ITEMS}`, {
      items,
      undefined,
      context,
    });
  };

  prepareUpload = (data: CollectionCreateUploadRequest): Observable<CollectionCreateUploadResponse> => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.upload_prepare_error' },
      'collection_upload_prepare',
    );
    return this.apiService.post<CollectionCreateUploadRequest, CollectionCreateUploadResponse>(
      COLLECTIONS_ENDPOINTS.UPLOAD,
      data,
      undefined,
      context,
    );
  };

  confirmUpload = (data: CollectionUploadConfirmationRequest) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.import.failure' },
      'collection_delete_failure',
    );
    return this.apiService.post<CollectionUploadConfirmationRequest, CollectionUploadConfirmationResponse>(
      COLLECTIONS_ENDPOINTS.UPLOAD_CONFIRMATION,
      data,
      undefined,
      context,
    );
  };

  addItemsFromFolder = (data: FolderCollectionUploadRequest) => {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.add_folder.failure' },
      'add_folder_failure',
    );

    return this.apiService.put<FolderCollectionUploadRequest, CollectionUploadConfirmationResponse>(
      `${COLLECTIONS_ENDPOINTS.ADD_ITEMS_FROM_FOLDER}`,
      data,
      undefined,
      context,
    );
  };

  addAssetsFromOrder(order_uuid: string, selectedCollectionsId: string) {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.add_assets_from_order_error' },
      'add_assets_from_order',
    );
    return this.apiService.patch(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${selectedCollectionsId}/add-order`, {
      order_uuid,
      undefined,
      context,
    });
  }

  addAssetsFromCart(selectedCollectionsId: string) {
    const context = createErrorContext(
      { '*': 'common.notifications.collections.general.add_assets_from_order_error' },
      'add_assets_from_cart',
    );
    return this.apiService.patch<void, void>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${selectedCollectionsId}/add-cart`,
      undefined,
      undefined,
      context,
    );
  }
}
