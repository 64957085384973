<div class="dialog-header">
  <div class="dialog-header__title">
    <span *ngIf="drawerDialogService.config.header" class="title__header__main">
      {{ drawerDialogService.config.header }}
    </span>
    <span *ngIf="drawerDialogService.config.subheader" class="title__header__data">
      {{ drawerDialogService.config.subheader }}
    </span>
  </div>

  <vdms-hq-ui-button
    class="dialog-header__title__settings"
    icon="settings"
    [iconOnly]="true"
    size="medium"
    [matMenuTriggerFor]="menu"
  >
  </vdms-hq-ui-button>

  <vdms-hq-ui-button
    class="dialog-header__title__close"
    icon="close"
    [iconOnly]="true"
    size="medium"
    (click)="drawerDialogService.close()"
  >
  </vdms-hq-ui-button>
</div>
<mat-menu #menu="matMenu" class="settings-menu">
  <vdms-hq-ui-form-radio-list
    [ngModel]="drawerDialogService.config.state"
    (ngModelChange)="drawerDialogService.changeState($event)"
    [selectOptions]="drawerDialogService.config.actionOptions"
  >
  </vdms-hq-ui-form-radio-list>
</mat-menu>
