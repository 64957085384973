import moment from 'moment-timezone';
import {
  ApprovalStatus,
  AssetFlat,
  DeliveryDestinationJobStatusEnum,
  OrderAssetModel,
  OrderModel,
} from '@vdms-hq/api-contract';
import { SimpleDetailsList } from '@vdms-hq/ui';
import { AssetFlatView2 } from '@vdms-hq/asset-results';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Timecode } from '@vdms-hq/timecode';

export enum OrderActionsProperties {
  APPROVE_ASSET = 'order.approve_asset',
  REJECT_ASSET = 'order.reject_asset',
  DOWNLOAD_CLEARANCE_NOTES = 'order.download_clearance_notes',
  PREVIEW_SALESFORCE_REQUEST = 'order.preview_salesforce_request',
  APPROVE_ORDER = 'order.approve_order',
  DOWNLOAD_ASPERA = 'order.download_aspera',
  ADD_TO_CART = 'order.add_to_cart',
  RETRY_DELIVERY_DESTINATIONS = 'order.retry_delivery_destinations',
  TRANSCODE_STATUS = 'order.transcode_status',
  TRANSCODE_STATUS_NO_DOWNLOAD = 'order.transcode_status_no_download',
  RETRIGGER_CLIPPING = 'order.retrigger_clipping',
  FORCE_SALESFORCE_REQUEST = 'order.force_salesforce_request',
  REDELIVER_ORDER = 'order.redeliver_order',
  DOWNLOAD_ALL_ASPERA_FOLDERS = 'order.download_all_aspera_folders',
  DOWNLOAD_ALL_ASPERA = 'order.download_all_aspera',
  DOWNLOAD_HTTPS = 'order.download_https',
  DOWNLOAD_DELIVERY_CONFIRMATION = 'order.download_delivery_confirmation',
  DOWNLOAD_MANY_DELIVERY_CONFIRMATION = 'order.download_many_delivery_confirmation',
  CHANGE_DISCOUNT = 'order.change_discount',
  SHOW_NEW_ASSETS = 'order.show_new_assets',
}

export interface AssetFiltersForm {
  [key: string]: any;

  text?: string;
  facilityOfOrigin?: string;
  assetMasterType?: string[];
  txDate?: {
    from?: moment.Moment;
    to?: moment.Moment;
  };
}

export type OrderViewModel = OrderModel & {
  title?: string;
  metadata?: SimpleDetailsList[];
};

export type OrderAssetViewModel = AssetFlat & OrderAssetModel;

export class OrderAssetViewModel2 extends AssetFlatView2 {
  constructor(
    public override props: OrderAssetModel['asset'],
    private originalOrder: OrderAssetModel,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    super(props, {
      dataProvider: deps.dataProvider,
      storageUrlService: deps.storageUrlService,
      activatedClientService: deps.activatedClientService,
    });
  }

  static fromOrderItem(
    props: OrderAssetModel,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ): OrderAssetViewModel2 {
    return new OrderAssetViewModel2(props.asset, props, deps);
  }

  get uuid() {
    return this.asset.uuid;
  }

  get orderUuid() {
    return this.originalOrder.uuid;
  }

  get asset() {
    return this.props;
  }

  get restoreStatus() {
    return this.props.storage === 'cold' ? 'warming up' : 'warm';
  }

  get approvalStatus(): ApprovalStatus {
    return this.originalOrder.approval_status;
  }

  get publishProgress() {
    return this.originalOrder?.publish_progress;
  }

  get publishStatus() {
    let publishJobsCompleted = 0;
    let publishJobsFailed = 0;
    let publishJobs = 0;

    this.originalOrder.delivery_destinations?.forEach((destination) => {
      destination.configs.forEach((config) => {
        config.jobs.forEach((job) => {
          if (job.type === 'publish') {
            publishJobs++;
            if (job.status === DeliveryDestinationJobStatusEnum.COMPLETED) {
              publishJobsCompleted++;
            }
            if (job.status === DeliveryDestinationJobStatusEnum.FAILED) {
              publishJobsFailed++;
            }
          }
        });
      });
    });

    if (publishJobs === 0) {
      return 0;
    }

    if (publishJobsCompleted === publishJobs) {
      return 'Completed';
    }

    if (publishJobsFailed > 0) {
      return `Failed (${publishJobsFailed}/${publishJobs})`;
    }

    return 'In Progress';
  }

  get newAssetUuidsCount() {
    let newAssetUuids = 0;

    this.originalOrder.delivery_destinations?.forEach((destination) => {
      destination.configs.forEach((config) => {
        config.jobs.forEach((job) => {
          if (job.extra?.newAssetUuids) {
            newAssetUuids++;
          }
        });
      });
    });

    return newAssetUuids;
  }

  get remainingDownloads() {
    return this.originalOrder?.remaining_downloads;
  }

  get remainingDownloadsColumn() {
    switch (this.originalOrder?.remaining_downloads) {
      case -1:
        return 'Unlimited';
      case 0:
        return '0';
      default:
        return this.originalOrder?.remaining_downloads;
    }
  }

  override get storageStatus() {
    return this.props.storage_status === 'active' && this.props.storage === 'cold'
      ? this.props.estimated_restore_at
      : this.props.storage_status;
  }

  get isApproved(): boolean {
    return this.approvalStatus === ApprovalStatus.APPROVED;
  }

  get isRejected(): boolean {
    return this.approvalStatus === ApprovalStatus.REJECTED;
  }

  get approval_status() {
    return ApprovalStatus[this.originalOrder.approval_status];
  }

  get clippingStatus() {
    return this.originalOrder.clipping_status;
  }

  get clipSalesforceName() {
    return this.originalOrder.clip_sales_force_name;
  }

  get deliveryDestinations() {
    return this.originalOrder.delivery_destinations;
  }

  get downloadUuid() {
    return this.originalOrder.download_uuid;
  }

  override get timecodeIn() {
    if (this.originalOrder.timecode_in) {
      return Timecode.fromTimecode(this.originalOrder.timecode_in, this.framerate, false);
    }
    return super.timecodeIn;
  }

  override get timecodeOut() {
    if (this.originalOrder.timecode_out) {
      return Timecode.fromTimecode(this.originalOrder.timecode_out, this.framerate, false);
    }
    return super.timecodeOut;
  }

  get salesForceUri() {
    return this.originalOrder.sales_force_uri;
  }

  get context() {
    return { note: this.note };
  }

  get note() {
    return this.originalOrder.note;
  }

  get externalId() {
    return this.originalOrder.external_id;
  }

  get isAssetDownloadReady(): boolean {
    if (!this.deliveryDestinations?.length) {
      return true;
    }

    if (this.remainingDownloads === 0) {
      return false;
    }

    return this.deliveryDestinations.every((destination) =>
      destination.configs.every((config) => {
        const condition = config.jobs
          .filter((job) => job.type === 'transcode')
          .every((job) => !['not_initialized', 'started', 'submitted', 'failed'].includes(job.status));

        return condition;
      }),
    );
  }

  get clipping_status() {
    return this.originalOrder.clipping_status;
  }

  get publish_progress() {
    let progress: null | number = null;
    let publishJobs = 0;
    this.originalOrder.delivery_destinations?.forEach((destination) => {
      destination.configs.forEach((config) => {
        config.jobs.forEach((job) => {
          if (job.type === 'publish') {
            publishJobs++;
            if (progress === null) {
              progress = 0;
            }
            progress = (progress + job.progress) / publishJobs;
          }
        });
      });
    });

    return progress;
  }

  get transcode_progress() {
    let progress: null | number = null;
    let transcodeJobs = 0;
    this.originalOrder.delivery_destinations?.forEach((destination) => {
      destination.configs.forEach((config) => {
        config.jobs.forEach((job) => {
          if (job.type === 'transcode') {
            transcodeJobs++;
            if (progress === null) {
              progress = 0;
            }
            progress = (progress + job.progress) / transcodeJobs;
          }
        });
      });
    });

    return progress;
  }

  get transcodeStatus() {
    if (this.approvalStatus === ApprovalStatus.REJECTED) {
      return 'rejected';
    }
    if (this.originalOrder.expires_at && moment(this.originalOrder.expires_at).isBefore(moment())) {
      return 'Expired';
    }
    if (this.remainingDownloads === 0) {
      return 'Download limit reached';
    }

    if (this.asset.storage === 'cold' && this.asset.storage_status === 'active') {
      return 'Awaiting restoration';
    }

    if (this.deliveryDestinations) {
      const hasTranscodeJobs = this.deliveryDestinations.some((dd) =>
        dd.configs.some((config) => config.jobs.length && config.jobs.some((job) => job.type === 'transcode')),
      );
      if (!hasTranscodeJobs) {
        return 'Download ready';
      } else {
        // if all transcode jobs are failed, return Failed
        const allJobsFailed = this.deliveryDestinations.every((dd) =>
          dd.configs.every((config) => config.jobs.every((job) => job.type === 'transcode' && job.status === 'failed')),
        );
        if (allJobsFailed) {
          return 'Failed';
        }

        // if all transcode jobs are completed, return Download Ready
        const allJobsCompleted = this.deliveryDestinations.every((dd) =>
          dd.configs.every((config) =>
            config.jobs.every((job) => job.type === 'transcode' && job.status === 'completed'),
          ),
        );
        if (allJobsCompleted) {
          return 'Download Ready';
        }
        // if some transcode jobs are failed, return Failed partially
        const failedSomeJobs = this.deliveryDestinations.some((dd) =>
          dd.configs.some((config) => config.jobs.some((job) => job.type === 'transcode' && job.status === 'failed')),
        );
        if (failedSomeJobs) {
          return 'Failed partially';
        }

        // if else, return In Progress
        return 'In Progress';
      }
    }
    if (!this.deliveryDestinations) {
      if (this.clipping_status === 'completed') {
        return 'Download Ready';
      } else {
        return this.clipping_status ?? 'Download Ready';
      }
    }

    return 'Download Ready';
  }

  get assetAvailableToRetrigger() {
    return this.deliveryDestinations?.some((dd) =>
      dd.configs.some((config) =>
        config.jobs.some((job) =>
          [DeliveryDestinationJobStatusEnum.SUBMITTED, DeliveryDestinationJobStatusEnum.STARTED].includes(job.status),
        ),
      ),
    );
  }
}
