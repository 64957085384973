<vdms-hq-ui-dialog-wrapper size="narrow">
  <h1 title>{{ data.title || default.title | translate }}</h1>

  <div
    content
    class="dialog-content dialog-content-small"
    [sharedE2eId]="'confirmation-dialog-message'"
    [innerHTML]="
      data.message ? (data.message | translate | sanitize: SANITIZE_TYPE.HTML) : (default.message | translate)
    "
  ></div>
  <ng-container footer>
    <vdms-hq-ui-button
      *ngIf="data?.abortAction !== null"
      (click)="abort()"
      [color]="data.abortAction?.color || defaultAbort.color"
      >{{ data.abortAction?.label || defaultAbort.label | translate }}</vdms-hq-ui-button
    >
    <vdms-hq-ui-button
      *ngIf="data?.okAction !== null"
      (click)="ok()"
      [color]="data.okAction?.color || defaultOk.color"
      [sharedE2eId]="'confirmation-dialog-ok'"
      >{{ data.okAction?.label || defaultOk.label | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
