import { Injectable, inject } from '@angular/core';
import { ApiService } from '../api.service';
import {
  GetFilenameConvention,
  PostFilenameConvention,
  PatchFilenameConvention,
  PatchFilenameConventionDefaultFor,
  FilenameConventionEndpoints,
} from './filename-convention.model';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FilenameConventionsService {
  private apiService = inject(ApiService);

  getAll = () =>
    this.apiService
      .get<ApiResponse<GetFilenameConvention[]>>(FilenameConventionEndpoints.GET_ALL, {
        headers: new HttpHeaders({
          'cache-control': 'max-age=0',
        }),
      })
      .pipe(GetResponseData);

  getOne = (uuid: string) =>
    this.apiService
      .get<ApiResponse<GetFilenameConvention>>(FilenameConventionEndpoints.GET_ONE.replace('{uuid}', uuid))
      .pipe(GetResponseData);

  create = (data: PostFilenameConvention) =>
    this.apiService.post<PostFilenameConvention, GetFilenameConvention>(FilenameConventionEndpoints.CREATE, data);

  edit = (uuid: string, data: PatchFilenameConvention) =>
    this.apiService.patch<PatchFilenameConvention, GetFilenameConvention>(
      FilenameConventionEndpoints.EDIT.replace('{uuid}', uuid),
      data,
    );

  editDefaultFor = (uuid: string, data: PatchFilenameConventionDefaultFor) =>
    this.apiService.patch<PatchFilenameConventionDefaultFor, GetFilenameConvention>(
      FilenameConventionEndpoints.EDIT_DEFAULT_FOR.replace('{uuid}', uuid),
      data,
    );

  /*
   * @response 204
   */
  delete = (uuid: string) => this.apiService.delete(FilenameConventionEndpoints.DELETE.replace('{uuid}', uuid));
}
