import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TileSelectableConfig,
  UIButtonModule,
  UIDataSelectorComponent,
  UIDialogWrapperModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { UsersSelectorComponent } from '@vdms-hq/users';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  LicensePackagesService,
  NotificationSubscriptionsService,
  PaginationAPIModel as Pagination,
  RightsContractsService,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, debounceTime, map, Subject, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { emailsArrayValidator, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { TRIGGER_NOTIFICATIONS_ERROR_REASONS } from '../../logic/trigger-notifications';
import { SelectableWrapperConfig, UiSelectableTilesComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import {
  ContractAddData,
  RightsContractsAddDataSourceService,
} from '../../logic/rights-contracts-add-data-source.service';

export interface TriggerNotificationPayload {
  emails?: string[];
  asset_uuids: string[];
  rights_contract_uuids?: string[];
}

@Component({
  selector: 'vdms-hq-trigger-notifications-dialog',
  templateUrl: './trigger-notifications-dialog.component.html',
  styleUrls: ['./trigger-notifications-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UsersSelectorComponent,
    UIDataSelectorComponent,
    MatTooltipModule,
    UiSelectableTilesComponent,
  ],
})
export class TriggerNotificationsDialogComponent implements OnInit, OnDestroy {
  #ref = inject(MatDialogRef<TriggerNotificationsDialogComponent>);
  #licensePackageService = inject(LicensePackagesService);
  #notificationsService = inject(NotificationSubscriptionsService);
  #inputText = new BehaviorSubject('');
  #dialogData: { packageUuid: string; assetUuids: string[] } = inject(MAT_DIALOG_DATA);
  #translate = inject(TranslateService);
  #toast = inject(ToastService);
  contractsDataSource = inject(RightsContractsAddDataSourceService);
  #contractsService = inject(RightsContractsService);
  cdr = inject(ChangeDetectorRef);

  contractsFiltersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'Name or email',
      resource: [ResourceModel.RIGHTS_CONTRACT],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'text',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['licensed-package'],
    },
  ];

  rightsTilesConfig: TileSelectableConfig<ContractAddData> = {
    label: '.',
    icon: 'description',
    key: 'key',
    metadata: [
      {
        label: 'Name',
        valuePath: 'label',
        viewFormat: {
          modifiers: {
            truncateCharacters: 40,
          },
        },
        fullLine: true,
      },
      {
        label: 'Contract ID',
        valuePath: 'contract_id',
        viewFormat: {
          modifiers: {
            truncateCharacters: 40,
          },
        },
      },
      {
        label: 'Start date',
        valuePath: 'start_date',
        viewFormat: {
          modifiers: {
            dateFormat: 'date',
          },
        },
      },
      {
        label: 'End date',
        valuePath: 'end_date',
        viewFormat: {
          modifiers: {
            dateFormat: 'date',
          },
        },
      },
    ],
  };

  contractsSelectableConfig: SelectableWrapperConfig<ContractAddData> = {
    fieldsConfig: this.contractsFiltersConfig,
    tileConfig: this.rightsTilesConfig,
    selectedList: {
      data: [],
      title: 'Selected contracts',
    },
    searchView: {
      placeholder: 'Type name of contract',
      label: 'Search contracts',
      showSearch: true,
    },
    datasourceTitle: 'All Contracts',
    emptyMessage: 'No contracts found',
    loadingText: this.#translate.instant('common.notifications_subscriptions.trigger.loading'),
    keepSelectedListState: true,
  };

  emailToggle = true;

  emailOptions: { key: string; label: string }[] = [];
  loading$ = new BehaviorSubject<boolean>(false);
  form = new FormControl<string[] | null>(null, [Validators.required, emailsArrayValidator(true)]);

  contract_ids = new FormControl<string[]>([], [Validators.required]);

  #destroyed$ = new Subject<void>();

  ngOnDestroy() {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  ngOnInit(): void {
    this.contractsDataSource.filters.controls.license_package_uuid?.setValue(this.#dialogData.packageUuid);
    this.#contractsService
      .getMany(Pagination.create({ page: 0, perPage: 1000 }), {
        end_after: new Date().toISOString(),
        keyword: '',
        licensed_package_uuid: this.#dialogData.packageUuid,
        partners: [],
        sort: '',
        start_before: new Date().toISOString(),
      })
      .pipe(takeUntil(this.#destroyed$))
      .subscribe((response) => {
        this.contract_ids.setValue(response.data.map((d) => d.uuid));
        response.data.forEach((d) => {
          this.contractsSelectableConfig.selectedList.data.push({
            name: d.name,
            uuid: d.uuid,
          });
        });
      });

    this.#inputText
      .pipe(
        takeUntil(this.#destroyed$),
        debounceTime(400),
        switchMap((text) => {
          return this.#licensePackageService.getPackagesUsers(this.#dialogData.packageUuid, { text }).pipe(
            map((response) => response.data.map(({ email }) => ({ key: email, label: email }))),
            tap((response) => {
              this.cdr.detectChanges();
              this.emailOptions = response;
            }),
          );
        }),
      )
      .subscribe();
  }

  get canSave() {
    return (
      (this.emailToggle && this.form.valid && !this.form.pristine) || (!this.emailToggle && this.contract_ids.valid)
    );
  }
  close() {
    this.#ref.close();
  }

  confirm() {
    if (!this.canSave) {
      return;
    }
    const payload: TriggerNotificationPayload = {
      asset_uuids: this.#dialogData.assetUuids,
    };
    if (this.emailToggle) {
      payload.emails = this.form.value as string[];
    } else {
      payload.rights_contract_uuids = this.contract_ids.value as string[];
    }
    const { packageUuid } = this.#dialogData;
    this.loading$.next(true);
    this.#notificationsService
      .triggerNotifications(packageUuid, payload)
      .pipe(
        catchError(
          (err: {
            error: HttpErrorResponse & { data: Array<{ email: string; reason: TRIGGER_NOTIFICATIONS_ERROR_REASONS }> };
          }) => {
            const message =
              err.error.error +
              '<br>' +
              err.error.data
                .map(
                  (item) =>
                    item.email +
                    ' - ' +
                    this.#translate.instant('common.notification_subscriptions.trigger.' + item.reason.toLowerCase()),
                )
                .join('<br>');
            this.#toast.error({ id: 'trigger', message });
            this.form.markAsPristine();
            this.loading$.next(false);
            return throwError(() => err);
          },
        ),
      )
      .subscribe({
        next: () => {
          this.#ref.close();
          this.loading$.next(false);
          this.#toast.success({ id: 'trigger', message: 'common.notification_subscriptions.trigger.trigger_success' });
        },
      });
  }

  valueChange(text: string) {
    this.#inputText.next(text ?? '');
  }
}
