import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { AsperaFiles, AssetApiService, AssetAsperaPost } from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, concatMap, EMPTY, Observable, switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AsperaTransferBatch, AsperaUpload2Service } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { EmbargoSelectorDialogComponent } from '../components/embargo-selector-dialog/embargo-selector-dialog.component';

@Injectable({ providedIn: 'root' })
export class AssetUploadService {
  private aspera = inject(AsperaUpload2Service);
  private assetApiService = inject(AssetApiService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);
  private dialog = inject(MatDialog);
  private uploadQueue = new BehaviorSubject<Observable<unknown>>(EMPTY);
  #listening = this.uploadQueue.pipe(concatMap((next) => next)).subscribe();

  startUpload(batch: AsperaTransferBatch) {
    const payload: AssetAsperaPost = {
      files: batch.files.map((file) => ({
        uuid: file.id,
        originalFilename: file.filename,
        internalFilename: file.destinationFilename,
        batchId: batch.batchId,
        sourcePath: file.sourcePath,
      })),
    };

    const nextToQueue = this.activatedClientService.clientDefinite$.pipe(
      take(1),
      map((client) => [client?.vida?.embargo, client?.permissions] as [boolean, Permission[]]),
      switchMap(([embargo, permissions]) => {
        if (embargo && (permissions || []).includes(Permission.EDIT_EMBARGO_DATES)) {
          return this.#embargoDialog(payload).pipe(
            tap((payload) => {
              if (!payload) {
                this.aspera.finalizeCancelTransfer(batch);
              }
            }),
            filter(Boolean),
            switchMap((payload) =>
              this.assetApiService.createAsperaAssets({ files: payload as unknown as AsperaFiles[] }).pipe(
                tap((response) => {
                  this.aspera.startUpload(response, batch);
                }),
              ),
            ),
          );
        }

        return this.assetApiService.createAsperaAssets(payload).pipe(
          tap((response) => {
            this.aspera.startUpload(response, batch);
          }),
        );
      }),
      catchError((error) => {
        batch.setError(error);
        return EMPTY;
      }),
    );

    this.addToQueue(nextToQueue);
  }

  startPlaceholderUpload(batch: AsperaTransferBatch, uuid: string) {
    if (batch.files.length !== 1) {
      this.toastService.error({
        id: 'upload-error',
        message: 'Failed to start upload - only one file can be uploaded at a time',
      });

      return;
    }

    const file = batch.files[0];
    file.updateId(uuid);

    const flatPayload = {
      uuid: file.id,
      originalFilename: file.filename,
      internalFilename: file.destinationFilename,
      batchId: batch.batchId,
      sourcePath: file.sourcePath,
    };

    const nextToQueue = this.assetApiService.placeholderAssetUpload(uuid, flatPayload).pipe(
      tap((response) => {
        this.aspera.startUpload(response, batch);
      }),
      catchError((error) => {
        batch.setError(error);
        return EMPTY;
      }),
    );

    this.addToQueue(nextToQueue);
  }

  cancelUpload(batch: AsperaTransferBatch) {
    const assetUuids = batch.files.map((file) => file.id);

    this.assetApiService
      .deleteAssets({ deleteReason: 'Aspera upload canceled', deletionBillable: false, assetUuids })
      .pipe(tap(() => this.aspera.finalizeCancelTransfer(batch)))
      .subscribe();
  }

  #embargoDialog(files: AssetAsperaPost): Observable<MatDialogRef<EmbargoSelectorDialogComponent>> {
    const dialog = this.dialog.open(EmbargoSelectorDialogComponent, {
      data: {
        files,
      },
    });
    return dialog.afterClosed();
  }

  private addToQueue(nextToQueue: Observable<unknown>) {
    this.uploadQueue.next(nextToQueue);
  }
}
