<vdms-hq-ui-loader *ngIf="loading" [mode]="'over-parent'" [backdrop]="true"></vdms-hq-ui-loader>
<div class="placeholder" appDnd (fileDropped)="onFileDropped($event)" *ngIf="selectedFiles$ | async; let selectedFiles">
  <input
    type="file"
    class="native-upload-input"
    (change)="selectFile($event.target)"
    #fileUpload
    [accept]="mimeTypes"
  />

  <mat-icon class="upload-icon">cloud_upload</mat-icon>
  <ul class="list" *ngIf="selectedFiles.length > 0">
    <li *ngFor="let selected of selectedFiles; let index = index" class="list__item">
      {{ selected.name }}
      <mat-icon (click)="removeFromList(index)">close</mat-icon>
    </li>
  </ul>
  <span class="hint">
    {{ hint | translate }}
  </span>

  <ng-content></ng-content>
  <vdms-hq-ui-button (click)="fileUpload.click()" [disabled]="disabled" color="primary">
    {{ selectFileButtonLabel | translate }}
  </vdms-hq-ui-button>
</div>
