import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { ActionContextLess, MultipleViewConfiguration } from '@vdms-hq/ui';
import { ClientsDataSource } from '../../logic/services/clients-data-source';
import { Client, ClientsService } from '@vdms-hq/api-contract';
import { ClientsEditCreateDialogComponent } from '../clients-edit-create-dialog/clients-edit-create-dialog.component';
import { filter, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CLIENTS_CONFIG, ClientsFirebaseProjectIdConfig } from '../../../index';
import { map } from 'rxjs/operators';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'vdms-hq-clients-table',
  templateUrl: './clients-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsTableComponent implements OnInit, OnDestroy {
  enabled = ['uuid', 'name', 'actions'];
  viewConfiguration$!: Observable<MultipleViewConfiguration<Client>>;

  headerActions$: Observable<ActionContextLess[]> = this.permissionsService
    .verifyWithOwnedPermissions$([Permission.CREATE_GROUPS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'add',
                label: 'common.global.add',
              },
            ]
          : [],
      ),
    );

  #destroy$ = new Subject<void>();

  #popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'common.notifications.clients.create.success',
      }),
    CREATE_FAILURE: (reason = 'Not specified') =>
      this.toastService.error({
        id: 'create_field_failure',
        message: 'common.notifications.clients.create.failure',
        interpolatedParams: { reason },
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.clients.update.success',
      }),
    UPDATE_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.clients.update.failure',
      }),
  };

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.clients.table.filter',
      resource: [ResourceModel.CLIENT],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-clients'],
    },
  ];

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });

  constructor(
    private clientsService: ClientsService,
    public clientsDataSource: ClientsDataSource,
    public readonly matDialog: MatDialog,
    public toastService: ToastService,
    @Inject(CLIENTS_CONFIG) private clientsConfig: ClientsFirebaseProjectIdConfig,
    private cdr: ChangeDetectorRef,
    private permissionsService: PermissionService,
  ) {}

  ngOnInit() {
    this.clientsDataSource.applyFilter('');
    this.filters.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe((value) => {
      this.clientsDataSource.applyFilter(value.keyword ?? '', ['name']);
    });

    this.viewConfiguration$ = this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_GROUPS]).pipe(
      map((canEdit) => {
        return {
          tableAdvanced: {
            actions: canEdit
              ? [
                  {
                    key: 'edit',
                    label: 'common.global.edit',
                    icon: 'edit',
                    onDoubleClick: false,
                    hiddenIf: () => !canEdit,
                  },
                ]
              : [],
            columnsEnabled: this.enabled,
            columns: [
              {
                id: 'uuid',
                label: 'Uuid',
                valuePath: 'uuid',
              },
              {
                id: 'name',
                label: 'Name',
                valuePath: 'name',
                sortable: true,
              },
              {
                id: 'actions',
                type: 'actions',
              },
            ],
          },
        };
      }),
    );
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  handleAction($event: { key: string; item?: Client }) {
    switch ($event.key) {
      case 'add':
        this.popCreateClientDialog();
    }

    if (!$event.item) return;
    switch ($event.key) {
      case 'edit':
        this.popEditClientDialog($event.item);
    }
  }

  popEditClientDialog(clientData: Client) {
    const dialogRef = this.matDialog.open(ClientsEditCreateDialogComponent, {
      data: { client: clientData, edit: true },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((client: Client) => !!client),
        switchMap((client: Client) => {
          this.clientsDataSource.isLoading$.next(true);
          return this.clientsService.patchClient(client.uuid, client).pipe(
            tap(() => this.#triggerRefresh()),
            tap(() => {
              this.#popToast.UPDATE_SUCCESS();
              this.cdr.detectChanges();
            }),
          );
        }),
      )
      .subscribe({
        error: (err) => {
          this.#popToast.UPDATE_FAILURE();
          this.clientsDataSource.isLoading$.next(false);
          this.cdr.detectChanges();
          throw err;
        },
      });
  }

  popCreateClientDialog() {
    const dialogRef = this.matDialog.open(ClientsEditCreateDialogComponent, {
      data: { edit: false },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((client: Client) => !!client),
        switchMap((client: Client) => {
          const uuid = uuidv4();
          const bucketName =
            this.clientsConfig.firebaseProjectId +
            '-' +
            client.name.replace(/\s+/g, '').toLowerCase() +
            '-' +
            uuid.substring(0, 6);
          const newClient = {
            ...client,
            uuid: uuid,
            internal_bucket_name: bucketName,
            internal_bucket_host: 'amazonaws.com',
            internal_bucket_region: 'eu-west-1',
          };

          return this.clientsService.postClient(newClient).pipe(
            tap(() => this.#triggerRefresh()),
            tap(() => {
              this.#popToast.CREATE_SUCCESS();
              this.cdr.detectChanges();
            }),
          );
        }),
      )
      .subscribe({
        error: (err) => {
          this.#popToast.CREATE_FAILURE();
          this.cdr.detectChanges();
          throw err;
        },
      });
  }

  #triggerRefresh = () => this.clientsDataSource.refresh();
}
