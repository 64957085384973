import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../api.service';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { GetResponsePaginationData, GetResponseData } from '../../operators/get-response-data.operator';
import {
  DiscountsFiltersView,
  GetDiscountCodeInterface,
  PatchDiscountCodeInterface,
  PostDiscountCodeInterface,
} from './discounts.model';
import { PaginationAPIModel } from '../pagination.model';

enum DISCOUNTS_ENDPOINTS {
  ROOT = 'discount-codes',
  SINGLE = 'discount-code',
}

@Injectable({ providedIn: 'root' })
export class DiscountsService {
  constructor(private readonly apiService: ApiService) {}

  getAllNoPagination = (filters?: DiscountsFiltersView) => {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    headers = headers.append('Cache-Control', 'max-age=0');
    if (filters) {
      params = filters.keyword ? params.append('keyword', filters.keyword) : params;
      params = filters.active !== undefined ? params.append('active', filters.active) : params;
    }
    const url = `${DISCOUNTS_ENDPOINTS.ROOT}/all`;
    return this.apiService
      .get<ApiPaginatedResponse<GetDiscountCodeInterface>>(url, { headers, params })
      .pipe(GetResponsePaginationData);
  };

  getAll = (pagination?: PaginationAPIModel, filters?: DiscountsFiltersView) => {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    headers = headers.append('Cache-Control', 'max-age=0');
    if (filters) {
      params = filters.keyword ? params.append('keyword', filters.keyword) : params;
      params = filters.active !== undefined ? params.append('active', filters.active) : params;
    }
    const url = `${DISCOUNTS_ENDPOINTS.ROOT}`;
    return this.apiService
      .get<ApiPaginatedResponse<GetDiscountCodeInterface>>(url, { headers, params })
      .pipe(GetResponsePaginationData);
  };

  getOne = (uuid: string) => {
    const headers = new HttpHeaders().set('Cache-Control', 'max-age=0');
    const url = `${DISCOUNTS_ENDPOINTS.SINGLE}/${uuid}`;
    return this.apiService.get<ApiResponse<GetDiscountCodeInterface>>(url, { headers }).pipe(GetResponseData);
  };

  create = (discount: PostDiscountCodeInterface) => {
    return this.apiService
      .post<PostDiscountCodeInterface, ApiResponse<GetDiscountCodeInterface>>(`${DISCOUNTS_ENDPOINTS.SINGLE}`, discount)
      .pipe(GetResponseData);
  };

  update = (uuid: string, discount: PatchDiscountCodeInterface) => {
    return this.apiService
      .patch<
        PatchDiscountCodeInterface,
        ApiResponse<GetDiscountCodeInterface>
      >(`${DISCOUNTS_ENDPOINTS.SINGLE}/${uuid}`, discount)
      .pipe(GetResponseData);
  };

  delete = (uuid: string) => {
    return this.apiService.delete(`${DISCOUNTS_ENDPOINTS.SINGLE}/${uuid}`);
  };
}
