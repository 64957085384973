import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UsersDatasourceService } from '../../logic/users-datasource.service';
import {
  ActionContextLess,
  DataPresentationHeaderComponent,
  DialogResponse,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UIDialogModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UiTimelineModule,
} from '@vdms-hq/ui';
import { UserApiService, UserModel, UserModelFlat, UserPatchRequest } from '@vdms-hq/api-contract';
import { MatDialog } from '@angular/material/dialog';
import { AddUserDialogComponent } from '../../components/add-user-dialog/add-user-dialog.component';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, EMPTY, Observable, Subject, takeUntil, throwError } from 'rxjs';
import {
  ActivatedClientModule,
  ActivatedClientService,
  Permission,
  PermissionService,
  WithPermissions,
} from '@vdms-hq/activated-client';
import { ToastService } from '@vdms-hq/toast';
import {
  ClientListDialogComponent,
  ClientListDialogInput,
} from '../../components/client-list-dialog/client-list-dialog.component';
import {
  PolicyListDialogComponent,
  PolicyListDialogInput,
} from '../../components/policy-list-dialog/policy-list-dialog.component';
import { UserDialogPatchResponse, UserDialogResponse } from '../../models/user-dialog.model';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { isArray } from 'lodash';
import { UsersDeletionConfirmationDialogComponent } from '../../components/user-deletion-confirmation-dialog/user-deletion-confirmation-dialog.component';

@Component({
  standalone: true,
  selector: 'vdms-hq-users',
  imports: [
    CommonModule,
    UIResultsWrapperModule,
    UILoaderModule,
    MatSidenavModule,
    UIButtonModule,
    TranslateModule,
    ActivatedClientModule,
    UIFormModule,
    UIEmptyResultsModule,
    UiTimelineModule,
    UIDialogModule,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
    UILayoutModule,
  ],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent extends WithPermissions() implements OnInit, OnDestroy {
  dataSource = inject(UsersDatasourceService);
  dialog = inject(MatDialog);
  userApi = inject(UserApiService);
  toast = inject(ToastService);
  permissionService = inject(PermissionService);
  activatedClientService = inject(ActivatedClientService);

  constructor() {
    super();
  }
  #destroy$ = new Subject<void>();
  isLoading$ = this.dataSource.isLoading$;
  isPaneVisible = false;
  currentUser$ = new BehaviorSubject<UserModel | null>(null);
  isCurrentUserLoading$ = new BehaviorSubject(false);
  viewConfiguration$!: Observable<MultipleViewConfiguration<UserModel>>;

  headerActions: ActionContextLess[] = [
    {
      key: 'add_user',
      label: 'common.global.add',
    },
  ];

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.users.table.filter',
      resource: [ResourceModel.USER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-users'],
    },
  ];

  handleBulkAction(key: string): void {
    switch (key) {
      case 'delete':
        this.#delete(this.dataSource.selection.identifiersSnapshot as string[]);
        break;
    }
  }

  handleAction({ key, item }: { key: string; item?: UserModel }): void {
    switch (key) {
      case 'add_user':
        this.#openEditDialog(undefined);
        break;
    }

    if (!item || key === 'close_drawer') {
      this.isPaneVisible = false;
      return;
    }
    switch (key) {
      case 'edit':
        this.#openEditDialog(item);
        break;
      case 'groups':
        this.#openGroupDialog(item);
        break;
      case 'policy':
        this.#openPolicyDialog(item);
        break;
      case 'preview':
        this.#preview(item);
        break;
      case 'delete':
        this.#delete(item.uuid);
        break;
    }
  }

  #openEditDialog(data: UserModel | undefined): void {
    this.dialog
      .open<AddUserDialogComponent, UserModelFlat, UserDialogResponse>(AddUserDialogComponent, {
        data,
      })
      .afterClosed()
      .pipe(filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK))
      .subscribe({
        next: (dialogResponse) => {
          this.toast.success({
            id: dialogResponse?.edited ? 'user_edited' : 'user_created',
            message: dialogResponse?.edited
              ? 'common.notifications.users.update.success'
              : 'common.notifications.users.create.success',
          });
          this.dataSource.applyFilter(this.dataSource.text$?.value ?? '');
        },
      });
  }

  #openGroupDialog(user: UserModel): void {
    this.dialog
      .open<ClientListDialogComponent, ClientListDialogInput, UserDialogPatchResponse>(ClientListDialogComponent, {
        data: {
          user,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (dialogResponse?.status === DialogResponse.OK) {
            const request: UserPatchRequest = {
              uuid: user.uuid,
              groups: dialogResponse.user?.groups,
            };
            return this.userApi.patchUser(user.uuid, request);
          }
          return EMPTY;
        }),
      )
      .subscribe({
        next: () => this.toast.success({ id: 'edit_success', message: 'common.notifications.update.done' }),
        complete: () => this.dataSource.applyFilter(this.dataSource.text$?.value ?? ''),
      });
  }

  #openPolicyDialog(user: UserModel): void {
    this.dialog
      .open<PolicyListDialogComponent, PolicyListDialogInput, UserDialogPatchResponse>(PolicyListDialogComponent, {
        data: {
          user,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (dialogResponse?.status === DialogResponse.OK) {
            const request: UserPatchRequest = {
              uuid: user.uuid,
              policy_uuids: dialogResponse.user?.policy_uuids,
            };
            return this.userApi.patchUser(user.uuid, request);
          }
          return EMPTY;
        }),
      )
      .subscribe({
        next: () => this.toast.success({ id: 'edit_success', message: 'common.notifications.update.done' }),
        complete: () => this.dataSource.applyFilter(this.dataSource.text$?.value ?? ''),
      });
  }

  #preview(item: UserModel): void {
    this.isCurrentUserLoading$.next(true);
    this.userApi
      .getUser(item.uuid, { page: 0, perPage: 24 })
      .pipe(
        catchError((error) => {
          this.toast.error({ id: 'delete_error', message: 'common.notifications.update.failed' });
          return throwError(error);
        }),
      )
      .subscribe((user) => {
        this.currentUser$.next(user);
        this.isCurrentUserLoading$.next(false);
      });

    this.isPaneVisible = true;
  }

  #delete(users: string[] | string): void {
    this.dialog
      .open(UsersDeletionConfirmationDialogComponent, {
        data: { total: isArray(users) ? users.length : 1 },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter((response) => response),
        switchMap((response) => {
          if (isArray(users)) {
            return this.userApi.bulkDelete(users, response.reason);
          }
          return this.userApi.delete(users);
        }),
      )
      .subscribe({
        next: () => this.toast.success({ id: 'delete_success', message: 'common.notifications.remove.done' }),
        complete: () => {
          this.dataSource.applyFilter(this.dataSource.text$?.value ?? '');
          this.isPaneVisible = false;
        },
      });
  }

  ngOnInit() {
    this.dataSource.applyFilter('');
    this.filters.valueChanges.pipe(debounceTime(250), takeUntil(this.#destroy$)).subscribe((value) => {
      this.dataSource.applyFilter(value.keyword ?? '');
    });
    this.viewConfiguration$ = this.activatedClientService.permissions$.pipe(
      map((permissions) => {
        return this.permissionService.verifyPermissions([Permission.EDIT_USERS], permissions);
      }),
      map((canEdit) => {
        return {
          tableAdvanced: {
            actions: [
              {
                key: 'edit',
                icon: 'edit',
                label: 'common.global.edit',
                onDoubleClick: false,
                hiddenIf: () => !canEdit,
              },
              {
                key: 'groups',
                icon: 'groups',
                label: 'common.global.client',
                onDoubleClick: false,
                hiddenIf: () => !canEdit,
              },
              {
                key: 'policy',
                icon: 'policy',
                label: 'common.global.policy',
                onDoubleClick: false,
                hiddenIf: () => !canEdit,
              },
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.global.preview',
                onDoubleClick: true,
              },
            ],
            columns: [
              {
                id: 'select',
                type: 'select',
              },
              {
                id: 'email',
                label: 'Email',
                valuePath: 'email',
              },
              {
                id: 'name',
                label: 'User Name',
                valuePath: 'name',
              },
              {
                id: 'groups',
                label: 'Enabled clients',
                valuePath: 'groupsNames',
                foldValues: true,
                viewFormat: {
                  maxVisibleValues: 5,
                },
              },
              {
                id: 'actions',
                type: 'actions',
              },
            ],
            columnsEnabled: ['select', 'email', 'name', 'groups', 'actions'],
          },
        };
      }),
    );
    this.permissionService.verifyPermissions();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
    this.dataSource.selection.clear();
  }
}
