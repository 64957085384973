<vdms-hq-ui-dialog-wrapper size="wide">
  <div title>Keyboard shortcuts</div>
  <div content>
    <div class="helper-outer" *ngIf="src$ | async; let src; else: loading">
      <img [src]="src" alt="Shortcuts helper" class="helper" />
    </div>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
<ng-template #loading><vdms-hq-ui-loader></vdms-hq-ui-loader></ng-template>
