import { Provider } from '@angular/core';
import { LOGIN_PAGE_CONFIG, LoginPageConfig } from './logic/config-tokens';

export const configureLoginPage = (config: LoginPageConfig): Provider[] => {
  return [
    {
      provide: LOGIN_PAGE_CONFIG,
      useValue: config,
    },
  ];
};
