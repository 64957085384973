import { inject, Injectable } from '@angular/core';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import {
  LICENSED_PACKAGE_VIEW_STATE,
  LicensedPackageFiltersView,
  LicensedPackageListItem,
  LicensePackagesService,
  PaginationAPIModel as Pagination,
  RightsContractsService,
} from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@vdms-hq/toast';
import { SingleRightContractDetailsResolverService } from './single-right-contract-details-resolver.service';
import { SingleRightsContractDetailsViewModel } from './single-rights-contract-details.model';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class SingleRightsContractDataSource
  extends RouterParamsPagination
  implements MultipleViewDataSource<LicensedPackageListItem>
{
  titlesService = inject(LicensePackagesService);
  toastService = inject(ToastService);
  singleRightContractDetailsResolver = inject(SingleRightContractDetailsResolverService);
  rightsContractsService = inject(RightsContractsService);

  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);
  isLoading$ = new BehaviorSubject(false);
  refresh$ = new BehaviorSubject(false);
  contractUuid$ = this.singleRightContractDetailsResolver.currentUuidDefinite$;
  contractUuid!: string;

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    status: new FormControl<string | null>(null),
    sort: new FormControl<string | null>('licensedPackage.createdAt_desc'),
    partners: new FormControl<string[] | null>(null),
    view_state: new FormControl<LICENSED_PACKAGE_VIEW_STATE | null>(null),
    contract_in_out: new FormControl<{
      from: moment.Moment;
      to: moment.Moment;
    } | null>(null),
  });

  values$: Observable<LicensedPackageFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    withLatestFrom(this.contractUuid$),
    switchMap(([, contractUuid]) => {
      const formValue = this.filters.value;
      const filters = <LicensedPackageFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      if (formValue?.status) {
        filters.status = formValue.status;
      }

      if (formValue?.sort) {
        const sortExploded = formValue.sort.split('_');
        if (sortExploded[0] == 'contract') {
          filters.sort = sortExploded.slice(0, -1).join('_');
          filters.direction = sortExploded[sortExploded.length - 1];
        } else {
          filters.sort = formValue.sort.split('_')[0];
          filters.direction = formValue.sort.split('_')[1];
        }
      }

      if (formValue?.partners) {
        filters.partners = formValue.partners;
      }

      if (formValue?.view_state) {
        filters.view_state = formValue.view_state;
      }

      if (formValue?.contract_in_out?.from) {
        filters.contract_in = formValue.contract_in_out?.from.valueOf();
      }

      if (formValue?.contract_in_out?.to) {
        filters.contract_out = formValue.contract_in_out?.to.valueOf();
      }

      if (contractUuid) {
        filters.contracts = [contractUuid];
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  currentContract$: Observable<SingleRightsContractDetailsViewModel> = combineLatest([
    this.contractUuid$,
    this.refresh$,
  ]).pipe(
    tap(([uuid]) => (this.contractUuid = uuid)),
    switchMap(([uuid]) => this.rightsContractsService.getOne(uuid).pipe(take(1), shareReplay(1))),
    map((contract) => ({
      ...contract,
      metadata: [
        {
          title: 'common.rights_contracts.single_view.created_at',
          value: contract.created_at,
          type: 'date-time',
        },
        {
          title: 'common.rights_contracts.single_view.updated_at',
          value: contract.updated_at,
          type: 'date-time',
        },
        {
          title: 'common.rights_contracts.single_view.start_date',
          value: contract.start_date,
          type: 'date-time',
        },
        {
          title: 'common.rights_contracts.single_view.end_date',
          value: contract.end_date,
          type: 'date-time',
        },
        {
          title: 'common.rights_contracts.single_view.partner_users_count',
          value: contract.partner.partner_users_count,
          size: 'wide',
        },
      ],
    })),
  );

  allData$: Observable<LicensedPackageListItem[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.values$,
    this.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([index, size, filters]) => {
      const headers = Pagination.create({
        page: index,
        perPage: size,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });

      return this.titlesService
        .getMany(headers, filters)
        .pipe(catchError((err: HttpErrorResponse) => this.#errorHandler(err)));
    }),
    tap((response) => this.total$.next(response.total)),
    tap((response) => this.emptyResults$.next(response.total === 0)),
    map((response) => response.data),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );
  connection$: Observable<LicensedPackageListItem[]> = this.allData$;

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }
}
