import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
@Component({
  selector: 'vdms-hq-ui-sticky-message',
  templateUrl: './sticky-message.component.html',
  styleUrls: ['./sticky-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, MatProgressSpinner, NgIf],
  standalone: true,
})
export class StickyMessageComponent {
  isCollapsed = localStorage.getItem('stickyMessage') === 'collapsed';

  @Input({ required: true }) messageExpanded!: string;
  @Input({ required: true }) messageCollapsed!: string;

  @Input() keepStateInLocalStorage = true;

  changeState() {
    this.isCollapsed = !this.isCollapsed;
    if (this.keepStateInLocalStorage && this.isCollapsed) {
      localStorage.setItem('stickyMessage', 'collapsed');
    } else {
      localStorage.removeItem('stickyMessage');
    }
  }
}
