import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PaginationAPIModel, PaginationAPIProps } from '../pagination.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { AssetSearchFilters } from '../asset';
import { CART_ENDPOINTS } from './models/cart-endpoints.model';
import {
  AddToCartPayload,
  AssetFlatCartItem,
  CartSummary,
  CartSummaryRequest,
  CartValidationError,
  Counters,
  FilenamesForCart,
  FilenamesForCartPayload,
  OrderPdfResponse,
  PostCartValidateType,
  RecentEmailsResponse,
} from './models/cart.model';
import { JobTypePayloadInterface, ORDER_TYPE } from '../order/order.model';
import { createErrorContext } from '../../error-handler';

@Injectable({ providedIn: 'root' })
export class CartApiService {
  private readonly apiService = inject(ApiService);

  getCartAssets = (options?: {
    pagination?: PaginationAPIProps;
    embargoed?: boolean;
    quarantined?: boolean;
  }): Observable<ApiPaginatedResponse<AssetFlatCartItem>> => {
    let headers = PaginationAPIModel.create(options?.pagination).applyToHeaders(
      options?.embargoed !== undefined
        ? new HttpHeaders().set('embargoed', options.embargoed ? 'true' : 'false')
        : new HttpHeaders(),
    );

    headers =
      options?.quarantined !== undefined ? headers.set('quarantined', options.quarantined ? 'true' : 'false') : headers;

    const context = createErrorContext({ '*': 'Cart assets could not be loaded' }, 'get-cart-assets');

    return this.apiService.get<ApiPaginatedResponse<AssetFlatCartItem>>(
      `${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.ASSETS}`,
      { headers, context },
    );
  };

  getCartSummary = (
    order_type: ORDER_TYPE,
    destinations?: CartSummaryRequest['destinations'],
    discount_code?: string | null,
    workflow?: JobTypePayloadInterface[],
    download_limit?: number,
    department_field_uuid?: string,
  ) => {
    const context = createErrorContext(
      { '*': 'pages.cart.notifications.general.get_summary_failed' },
      'get-cart-summary',
    );

    return this.apiService
      .post<CartSummaryRequest, ApiResponse<CartSummary>>(
        `${CART_ENDPOINTS.CART}/summary`,
        {
          order_type,
          discount_code: discount_code,
          destinations: destinations?.length ? destinations : undefined,
          jobs: workflow?.length ? workflow : undefined,
          download_limit,
          department_field_uuid,
        },
        undefined,
        context,
      )
      .pipe(GetResponseData);
  };

  getOrderPdf = (orderUuid: string) => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.submit.pdf_failed' }, 'cart_order_pdf');

    return this.apiService
      .get<ApiResponse<OrderPdfResponse>>(`${CART_ENDPOINTS.ORDER}/${orderUuid}/pdf`, { context })
      .pipe(GetResponseData);
  };

  getRecentEmails = () => {
    const context = createErrorContext(
      { '*': 'pages.cart.notifications.general.get_recent_emails' },
      'get-recent-emails',
    );

    return this.apiService
      .get<ApiResponse<RecentEmailsResponse>>(`${CART_ENDPOINTS.ORDER}/${CART_ENDPOINTS.EMAILS}`, { context })
      .pipe(GetResponseData);
  };

  getTotal = (): Observable<number> => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.general.get_total' }, 'cart_order_pdf');

    return this.apiService
      .get<ApiResponse<number>>(`${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.COUNT}`, { context })
      .pipe(GetResponseData);
  };

  delete = (ids: string[]): Observable<Counters> => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.remove.failed' }, 'cart_item_remove');

    return this.apiService
      .delete<ApiResponse<Counters>, string[]>(`${CART_ENDPOINTS.CART}`, ids, undefined, undefined, context)
      .pipe(GetResponseData);
  };

  deleteAllItems = (): Observable<boolean> => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.remove.failed' }, 'cart_order_pdf');

    return this.apiService
      .delete<ApiResponse<boolean>>(`${CART_ENDPOINTS.CART}`, undefined, undefined, undefined, context)
      .pipe(GetResponseData);
  };

  addFromSearchQuery = (searchFilters: AssetSearchFilters) => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.add.try_again' }, 'cart_put');

    return this.apiService.put(
      `${CART_ENDPOINTS.CART_FOLDER}`,
      { text: '', filters: searchFilters },
      undefined,
      context,
    );
  };

  addToCart = (payload: AddToCartPayload) => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.add.failed' }, 'cart_add');

    return this.apiService.put<AddToCartPayload, ApiEmptyResponse>(
      `${CART_ENDPOINTS.CART}`,
      payload,
      undefined,
      context,
    );
  };

  validate = (payload: PostCartValidateType): Observable<CartValidationError<ORDER_TYPE>[]> => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.general.validate_cart' }, 'cart_validate');

    return this.apiService
      .post<
        PostCartValidateType,
        ApiResponse<CartValidationError<ORDER_TYPE>[]>
      >(`${CART_ENDPOINTS.CART}/validate`, payload, undefined, context)
      .pipe(GetResponseData);
  };

  filenames = (payload: FilenamesForCartPayload): Observable<FilenamesForCart> => {
    const context = createErrorContext({ '*': 'pages.cart.notifications.general.filenames_failed' }, 'cart_filenames');

    return this.apiService
      .post<
        FilenamesForCartPayload,
        ApiResponse<FilenamesForCart>
      >(CART_ENDPOINTS.FILENAMES, payload, undefined, context)
      .pipe(GetResponseData);
  };
}
