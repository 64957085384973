<ng-container *ngIf="config; else missingData">
  <div class="tile-container" [scrollTop]="loading$ | async" [class.search-view]="searchView">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="config | disableIfRow: item; let disabledRowState">
        <div
          class="tile-container__item"
          *ngIf="item | objectValueByPath: config.key; let itemValue"
          (click)="!disabledRowState.disabled && selectedChange.emit(item)"
          [class.selected-highlight]="selected.includes(itemValue)"
          [class.disabled]="disabledRowState.disabled"
          [matTooltip]="disabledRowState.disabled ? (config.disabledIf?.label(item) ?? '' | translate) : ''"
          [sharedE2eId]="'add-dialog-selectable-tile'"
        >
          <mat-icon [class.blocked]="disabledRowState.disabled">{{
            disabledRowState.disabled ? 'block' : config.icon
          }}</mat-icon>
          <span
            *ngIf="item | objectValueByPath: config.label; let title"
            class="tile-container__title"
            #titleContainer
            [matTooltip]="title"
            [matTooltipDisabled]="titleContainer.offsetWidth >= titleContainer.scrollWidth"
            [matTooltipClass]="'selectable-title-tooltip'"
            >{{ item | objectValueByPath: config.label }}</span
          >
          <div class="tile-container__metadata">
            <ng-container *ngFor="let metadata of config.metadata">
              <div *ngIf="!(metadata | metadataHidden: item)" [class.w-full]="metadata.fullLine">
                <span class="tile-container__label" *ngIf="metadata.label">{{ metadata.label | translate }}:</span>
                <vdms-hq-ui-value-renderer
                  [value]="item | objectValueByPath: metadata.valuePath"
                  [format]="metadata.viewFormat"
                ></vdms-hq-ui-value-renderer>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #missingData>
  <vdms-hq-ui-runtime-error message="Missing configuration"></vdms-hq-ui-runtime-error>
</ng-template>
