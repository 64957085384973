import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  InfoBarClickAction,
  InfoBarType,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { ValidationErrorList } from '../order-dialog/order-dialog.component';
import { ORDER_TYPE } from '@vdms-hq/api-contract';

export interface validationErrorsOutput {
  confirmed: boolean;
}

export interface validationErrorsInput {
  errors: ValidationErrorList[];
  orderType?: ORDER_TYPE;
}

interface errorsGroup {
  [key: string]: ValidationErrorList[];
}

@Component({
  selector: 'vdms-hq-checkout-validation-error-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    FormsModule,
    ReactiveFormsModule,
    UIFormModule,
    UILayoutModule,
    MatDividerModule,
  ],
  templateUrl: './checkout-validation-errors-dialog.component.html',
  styleUrls: ['./checkout-validation-errors-dialog.component.scss'],
})
export class CheckoutValidationErrorsDialogComponent implements OnInit {
  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;

  errorsGroup: errorsGroup = {};
  groupedErrors = false;

  constructor(
    public dialogRef: MatDialogRef<CheckoutValidationErrorsDialogComponent, validationErrorsOutput>,
    @Inject(MAT_DIALOG_DATA) public data: validationErrorsInput,
  ) {}

  ngOnInit() {
    if (this.data.orderType && this.data.orderType === ORDER_TYPE.WARM_UP) {
      this.groupedErrors = true;
    } else {
      this.errorsGroup = this.data.errors.reduce((acc, error) => {
        const groupKey = `${error.assetOriginalFilename} (${error.assetUuid})` || 'unknown';
        if (error.assetOriginalFilename && !acc[groupKey]) {
          acc[groupKey] = [];
        }
        error.assetOriginalFilename && acc[groupKey].push(error);
        return acc;
      }, {} as errorsGroup);
    }
  }

  cancel() {
    this.dialogRef.close({
      confirmed: false,
    });
  }

  confirm() {
    this.dialogRef.close({
      confirmed: true,
    });
  }
}
