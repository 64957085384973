<div class="video" #videoHost>
  <video
    oncontextmenu="return false;"
    [id]="simplePlayerService.VIDEO_DOM_ID"
    class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered"
    controls
  ></video>
  <div class="preview" [ngStyle]="spriteCss" *ngIf="spriteCss"></div>

  <ng-content></ng-content>
  <ng-container *ngIf="state$ | async; let state">
    <vdms-hq-ui-loader *ngIf="state === 'loading'" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
    <vdms-hq-ui-empty-results
      *ngIf="state === 'error'"
      message="Unable to load this video, please contact support."
    ></vdms-hq-ui-empty-results>
  </ng-container>
</div>
