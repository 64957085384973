import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Theme } from '../theme.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  private currentTheme = Theme.dark;
  private renderer: Renderer2;

  theme$ = new BehaviorSubject<Theme.dark | Theme.white>(Theme.dark);
  isLight$ = this.theme$.pipe(map((theme) => theme === Theme.white));
  initialize = async (): Promise<boolean> => {
    const savedTheme = localStorage.getItem('theme') as Theme;

    if (savedTheme) {
      this.theme$.next(savedTheme);
    }

    this.theme$.subscribe((theme) => {
      this.currentTheme = theme;

      if (theme === Theme.white) {
        this.renderer.addClass(document.body, Theme.white);
        this.renderer.removeClass(document.body, Theme.dark);
      } else {
        this.renderer.addClass(document.body, Theme.dark);
        this.renderer.removeClass(document.body, Theme.white);
      }

      localStorage.setItem('theme', this.currentTheme);
    });

    return true;
  };

  toggleTheme = () => {
    this.theme$.next(this.currentTheme === Theme.dark ? Theme.white : Theme.dark);
  };

  set = (theme: Theme) => {
    this.theme$.next(theme);
  };

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
}
