<div class="messages" [ngClass]="toastConfig.position">
  <div
    *ngFor="let message of messages$ | async; trackBy: trackByFn"
    [@flyIn]
    [@flyOut]
    class="message"
    [ngClass]="message.colorClass"
  >
    <div class="state-indicator">
      <mat-icon class="icon message-icon" *ngIf="!isProcessing(message)">{{ message.icon }}</mat-icon>
      <mat-spinner [diameter]="24" class="loader" *ngIf="isProcessing(message)"></mat-spinner>
    </div>
    <div class="message-content flex flex-col">
      <div [innerHTML]="message.message"></div>
      <div *ngIf="message.additionalMessage" class="message-additional" [innerHTML]="message.additionalMessage"></div>
    </div>
    <mat-icon class="icon close" *ngIf="toastConfig.isCloseButton" (click)="toastService.close(message.id)"
      >close</mat-icon
    >
  </div>
</div>
