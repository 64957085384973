import { Provider, Type } from '@angular/core';
import { CONTROL_VALUE_FORMATTER_LIB } from './form-builder/logic/control-value-formatter-config';
import { DYNAMIC_VALUE_FORMATTER } from './logic/dynamic-value-formatter-config';

export const configureDynamicForm = (config: { valueFormatterService: Type<any> }): Provider[] => {
  return [
    {
      provide: DYNAMIC_VALUE_FORMATTER,
      useClass: config.valueFormatterService,
    },
    {
      provide: CONTROL_VALUE_FORMATTER_LIB,
      useClass: config.valueFormatterService,
    },
  ];
};
