<div class="hero-search">
  <div class="hero-search__content">
    <h1>{{ header | translate }}</h1>
    <h2>{{ subheader | translate }}</h2>
    <vdms-hq-search-autocomplete
      *ngIf="!searchWithAI"
      [isHero]="true"
      class="hero-search__input"
      [availableFilters]="availableFilters$ | async"
    ></vdms-hq-search-autocomplete>
    <vdms-hq-simple-ai-query-input *ngIf="searchWithAI" class="hero-search__input"></vdms-hq-simple-ai-query-input>
  </div>
</div>
