<vdms-hq-ui-layout-page-wrapper>
  <div class="stats">
    <div class="stats--grid">
      <div class="stats--grid__top-panel">
        <ng-container *ngTemplateOutlet="infoBoard"></ng-container>
      </div>
      <div class="stats--grid__tiles-panel">
        <ng-container *ngTemplateOutlet="egressLast30Days"></ng-container>
        <ng-container *ngTemplateOutlet="egressSinceLastBill"></ng-container>
        <ng-container *ngTemplateOutlet="filesProcessed"></ng-container>
        <ng-container *ngTemplateOutlet="storageDailyCost"></ng-container>
      </div>
      <div class="stats--grid__chart">
        <ng-container *ngTemplateOutlet="egressChart"></ng-container>
      </div>
      <div class="stats--grid__list">
        <ng-container *ngTemplateOutlet="bottomPanel"></ng-container>
      </div>
    </div>
  </div>
</vdms-hq-ui-layout-page-wrapper>

<ng-template #loading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>

<ng-template #emptyChart>
  <vdms-hq-ui-empty-results
    [icon]="'bar_chart'"
    [showButton]="false"
    [message]="'No data available for the applied filters'"
  ></vdms-hq-ui-empty-results>
</ng-template>

<ng-template #infoBoard>
  <div class="tile info-board">
    <div class="details">
      <h2 class="title" *ngIf="statsDataSource.clientInfo$ | async; let name">{{ name }} Egress Dashboard</h2>
      <h3 class="date">
        {{ today | date }}
      </h3>
      <span class="subtitle">Dashboard and current stats</span>
    </div>
    <div class="action">
      <vdms-hq-ui-button color="secondary" (click)="exportTransactions()">
        Export transactions to csv
      </vdms-hq-ui-button>
    </div>
  </div>
</ng-template>

<ng-template #egressLast30Days>
  <div class="tile">
    <span class="chart-title"><mat-icon>dashboard</mat-icon>TB Egress last 30 days</span>
    <div class="chart-data">
      <div class="count">
        {{ (statsDataSource.totals$ | async)?.bytes_egressed_last_30days ?? 0 | formatBytes }}
      </div>
      <span class="description-upper">Data processed</span>
    </div>
  </div>
</ng-template>

<ng-template #egressSinceLastBill>
  <div class="tile">
    <span class="chart-title"><mat-icon>local_fire_department</mat-icon>Egress since last bill</span>
    <div class="chart-data">
      <div class="count">
        {{ (statsDataSource.totals$ | async)?.egress_since_last_bill ?? 0 | formatBytes }}
      </div>
      <span class="description-upper">Data processed</span>
    </div>
  </div>
</ng-template>

<ng-template #filesProcessed>
  <div class="tile">
    <span class="chart-title"
      ><mat-icon>ac_unit </mat-icon> {{ 'Number of files downloaded / delivered since last bill' }}</span
    >
    <div class="count">
      {{ (statsDataSource.totals$ | async)?.files_delivered_since_last_bill ?? 0 }}
    </div>
    <span class="description-upper">Assets</span>
  </div>
</ng-template>

<ng-template #storageDailyCost>
  <ng-container *ngIf="statsDataSource.showStorageDailyCost$ | async">
    <div class="tile" *ngIf="statsDataSource.totals$ | async; let totals">
      <span class="chart-title"><mat-icon>paid</mat-icon>Storage daily cost</span>
      <div class="chart-data">
        <div class="count">{{ $any(totals).daily_cost ?? 0 | clientCurrency: totals.currency }}</div>
        <span class="description-upper">Daily estimate</span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #egressChart>
  <ng-container *ngIf="statsDataSource.connection$ | async; let stats; else: loading">
    <div class="section-header">
      <h2>Egress Chart</h2>
      <div class="section-filters" [formGroup]="egressStatsForm.form">
        <vdms-hq-ui-form-input-select
          [formControl]="egressStatsForm.form.controls['selectedPeriod']"
          [selectOptions]="egressStatsForm.periodOptions"
          [isSortedByLabel]="true"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-select
          [formControl]="egressStatsForm.form.controls['transferSources']"
          [selectOptions]="egressStatsForm.sourceOptions"
          [isSortedByLabel]="true"
        ></vdms-hq-ui-form-input-select>
      </div>
    </div>
    <div class="tile egress-chart" *ngIf="(statsDataSource.emptyChartResults$ | async) === false; else emptyChart">
      <vdms-hq-ui-stats-wrapper
        class="chart-wrapper"
        [withoutPadding]="true"
        [statsType]="StatsType.CHART_LINE"
        [statsConfig]="stats[0]"
      >
      </vdms-hq-ui-stats-wrapper>
    </div>
  </ng-container>
</ng-template>

<ng-template #bottomPanel>
  <div class="bottom-panel">
    <h2>Transfer List</h2>
    <div class="table-wrapper">
      <vdms-hq-ui-multiple-data-presentation
        [dataSource]="listDataSource"
        [configuration]="configuration"
      ></vdms-hq-ui-multiple-data-presentation>
    </div>
  </div>
</ng-template>
