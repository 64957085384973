import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonColors, UIButtonModule } from '../../ui-button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'vdms-hq-ui-button-info-container',
  templateUrl: './button-info-container.component.html',
  styleUrls: ['./button-info-container.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, UIButtonModule, MatIcon],
})
export class ButtonAdvancedInfoContainerComponent {
  @Input() buttonColor: ButtonColors = 'transparent';
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() header = '';
  @Input() subheader = '';
  @Input() showInfo = true;
  @Input() loading = false;
  @Input() tooltip = '';
  @Output() buttonClick = new EventEmitter<void>();
}
